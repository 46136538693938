import React, { Component } from 'react';
import { Select, Modal, Button } from 'antd';
import Zenfy from '../libs/Zenfy';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';

import Labels from '../libs/Labels';

class Contract extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            items: [],
            value: props.value,
            showModal: false,
            modalLoading: false,
            //date: moment().format('DD/MM/YYYY'),
            client: props.client,
            canvasClass: ''
        }

        this.padRef = React.createRef();
        this.key = Math.random();
    }

    componentWillReceiveProps(newProps) {

        //if(newProps.value){
            this.setState({
                value: newProps.value,
                client: newProps.client,
            });
        //}

    }

    componentDidMount() {

        /*this.setState({loading: true});

        Zenfy.get(this.props.url).then((res) => {

            this.setState({
                items: res.data, 
                loading: false,
            });

        }).catch(error => {
            this.setState({loading: false});
        });*/
        
    }

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
           // console.log(Object.assign({}, this.state, changedValue));
          onChange(changedValue);
        }
    }

    handleChange = (value) => {

        this.setState({
            value: value,
        });

       /* if(this.props.itemChange){
            this.props.itemChange(this.state.items.find((element) => {
                return element[this.props.idName] == value;
            }));
        }*/

        this.triggerChange(value);
    }

    handleModalOk = () => {
        this.setState({
            modalLoading: true,
            canvasClass: '',
        });

        if(this.padRef.current.isEmpty()){
            
            this.setState({
                canvasClass: 'red',
                modalLoading: false,
            });

        }else{

            let img = "<img style='background: #023b4f;padding: 10px;border-radius: 5px;' src='http://www.carlsongraciemontreal.com/wp-content/uploads/2016/12/carlsongracie-1.png'><br><br>Carlson Gracie Montréal (Gravité jiu-jitsu brésilien & yoga inc,.)<br>275 Rue Sherbrooke O, Montréal, QC H2X 1Y1 ";

            if(localStorage.orgId == 3){
                img = "<img style='background: #ffffff;padding: 10px;border-radius: 5px;width: 150px;' src='http://centre-elf.com/wp-content/uploads/2017/08/ELFitness-tshirt-01-01-1.png'><br><br>Ernst Laraque Fitness<br>1822 Rue des Carrières, Montréal, QC H2G 1W3";
            }else if(localStorage.orgId != 1){
                img = `<img style='background: #ffffff;padding: 10px;border-radius: 5px;width: 150px;' src='${window.org.logo}'><br><br>${window.org.location}`;
            }

            let html = img
            + document.getElementById(`htmlToPdf${this.key}`).innerHTML + 
            `<br/><img src='${this.padRef.current.getTrimmedCanvas().toDataURL('image/png')}'>`;
            Zenfy.post('uploadpdfhtml', {
                html,
                filename: this.props.fileName
            }).then((res) => {
                this.handleChange(`${Zenfy.getRoot()}uploads/${res.data}`);
                this.setState({
                    showModal: false,
                    modalLoading: false,
                });
            });
        }

        
       /* var iframe=document.createElement('iframe');
        document.body.appendChild(iframe);
        setTimeout(() => {
            var iframedoc=iframe.contentDocument||iframe.contentWindow.document;
            iframedoc.body.innerHTML=html_string;
            html2canvas(iframedoc.body).then(canvas => {
                document.body.removeChild(iframe);

                var imgData = canvas.toDataURL("image/jpeg",1);
                var pdf = new jsPDF("p", "mm", "a4");
                var pageWidth = pdf.internal.pageSize.getWidth();
                var pageHeight = pdf.internal.pageSize.getHeight();
                var imageWidth = canvas.width;
                var imageHeight = canvas.height;

                var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
                //pdf = new jsPDF(this.state.orientation, undefined, format);
                pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
                pdf.save("invoice.pdf");
               
            });
        }, 10);*/
        
    }

    showContractModal = () => {
        this.setState({
            showModal: true,
        });
    }

    clear = () => {
        this.padRef.current.clear();
    }

    trim = () => {
        /*this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png')})*/
    }

    render() {

        let contractHtml = null;

        if(this.props.contractHtml){
            contractHtml = this.props.contractHtml;
            let obj = this.state.client;
            obj.date = moment().format('DD/MM/YYYY');
            obj.org_name = window.org.name;
            
            /*if(obj.childs){
                obj.child_name = "";
                obj.childs.map((child) => {
                    obj.child_name += child.name + ' ';
                });
            }*/

            contractHtml = Zenfy.replacer(contractHtml, obj);
        }

        return (
        <>
            <Modal
                title={Labels.get("Contract")}
                visible={this.state.showModal}
                onOk={this.handleModalOk}
                okText={Labels.get("Sign")}
                cancelText={Labels.get("Cancel")}
                onCancel={()=> this.setState({
                    showModal: false,
                })}
                okButtonProps={{loading: this.state.modalLoading}}
            >   
                <div id={`htmlToPdf${this.key}`} style={{overflow: 'scroll', height: 280, width: '100%', overflowX: 'hidden'}} >
                {contractHtml ? (
                    <div dangerouslySetInnerHTML={{__html: contractHtml}} />
                ) : 
                    <>
                    <p><strong>Termes et conditions&nbsp;</strong></p>
                    <p>En consid&eacute;ration mon inscription et ma participation :&nbsp;</p>
                    <p>Je m&rsquo;engage &agrave; respecter les &eacute;quipements et les installations mis &agrave; ma disposition dans le cadre des cours offerts.</p>
                    <p>Je m&rsquo;engage &agrave; respecter strictement les r&egrave;gles de s&eacute;curit&eacute; et les obligations relatives &agrave; l&rsquo;apprentissage du Jiujitsu Br&eacute;silien avec l&rsquo;&eacute;quipe CARLSON GRACIE MONTR&Eacute;AL.</p>
                    <p>J&rsquo;ai lu attentivement le document annex&eacute; &laquo;&nbsp;D&eacute;charge de responsabilit&eacute;&nbsp;&raquo;, je comprends enti&egrave;rement et je le signe librement et volontairement.</p>
                    <p>Je comprends clairement qu&rsquo;en apposant ma signature sur le pr&eacute;sent document &laquo; Contrat de Louage de service &agrave; ex&eacute;cution successive &raquo;, je renonce, en mon propre nom et en celui de mes h&eacute;ritiers et de tous autres
                        ayants droits, au droit de poursuivre en justice l&rsquo;&eacute;quipe CARLSON GRACIE MONTR&Eacute;AL incluant, notamment : les directeurs, employ&eacute;s, officiers, membres, instructeurs, entraineurs, b&eacute;n&eacute;voles, juges, participants,
                        propri&eacute;taires, mandataires et repr&eacute;sentants.</p>
                    <p>Tous les &eacute;quipements doivent &ecirc;tre remis &agrave; leur place apr&egrave;s l&rsquo;utilisation.</p>
                    <p>Cartes d&rsquo;identit&eacute;s avec photos (Permis de conduire, carte &eacute;tudiante) doivent &ecirc;tre en votre possession en tout temps et doivent &ecirc;tre montr&eacute;es sous demande.</p>
                    <p>Chaque membre doit respecter et agir d&rsquo;une mani&egrave;re appropri&eacute;e envers les autres membres, les instructeurs et le mat&eacute;riel en tout temps.</p>
                    <p>L&rsquo;&eacute;quipe CARLSON GRACIE MONTR&Eacute;AL se r&eacute;serve le droit de r&eacute;voquer les droits d&rsquo;un membre qui ne respecte pas les termes et conditions de son inscription.&nbsp;</p>
                    <p>Je d&eacute;clare avoir lu et compris le contenu de ce document et avoir &eacute;t&eacute; inform&eacute; de mes responsabilit&eacute;s en tant que consommateur.</p>
                    <p>Mention exig&eacute;e par la loi sur la protection du consommateur</p>
                    <p>Le consommateur peut r&eacute;silier le pr&eacute;sent contrat sans frais ni p&eacute;nalit&eacute; avant que le commer&ccedil;ant n&rsquo;ait commenc&eacute; &agrave; ex&eacute;cuter son obligation principale, en envoyant la formule ci-annex&eacute;e
                        ou un autre avis &eacute;crit &agrave; cet effet au commer&ccedil;ant.&nbsp;</p>
                    <p>Si le commer&ccedil;ant a commenc&eacute; &agrave; ex&eacute;cuter son obligation principale, le consommateur peut r&eacute;silier le pr&eacute;sent contrat dans d&eacute;lai &eacute;gal &agrave; 1/10 de la dur&eacute;e pr&eacute;vue au pr&eacute;sent
                        contrat, en envoyant la formule ci-annex&eacute;e ou un autre avis &eacute;crit &agrave; cet effet au commer&ccedil;ant. Ce d&eacute;lai a comme pour de d&eacute;part le moment o&ugrave; le commer&ccedil;ant commence &agrave; ex&eacute;cuter son obligation
                        principale. Dans ce cas, le commer&ccedil;ant ne peut exiger au plus, d consommateur, que le paiement d&rsquo;un dixi&egrave;me du prix total pr&eacute;vu au contrat.</p>
                    <p>Le contrat est r&eacute;sili&eacute;, sans autre formalit&eacute;, d&egrave;s l&rsquo;envoi de la formule ou de l&rsquo;avis.&nbsp;</p>
                    <p>Dans les dix jours qui suivent la r&eacute;siliation du contrat, le commer&ccedil;ant doit restituer au consommateur l&rsquo;argent qu&rsquo;il lui doit.&nbsp;</p>
                    <p>Mention exig&eacute;e par la Loi sur la protection des renseignements personnels</p>
                    <p>Les renseignements que vous nous fournissez et que nous recueillerons relativement aux services que nous vous offrons sont conserv&eacute;s dans votre dossier de consommateur au si&egrave;ge social de l&rsquo;entreprise. Seuls les employ&eacute;s autoris&eacute;s
                        ont le droit de consulter votre dossier. &Agrave; moins d&rsquo;une exception pr&eacute;vue par la loi, vous avez le droit de consulter et de faire rectifier les renseignements personnels contenus dans votre dossier en nous adressant une demande &eacute;crite.
                        Vous devez nous informer sans d&eacute;lai de tout changement apport&eacute; aux renseignements que vous nous avez fournis.&nbsp;</p>
                    <p>Adresse courriel: {this.state.client.email}</p>
                    <p>En vertu de loi sur la protection des renseignements personnels, le consommateur consent &agrave; fournir son adresse courriel au commer&ccedil;ant comme moyen de communication entre les parties. Ce consentement autorise le commer&ccedil;ant &agrave;
                        utiliser le courrier &eacute;lectronique pour transmettre au consommateur toute l&rsquo;information &agrave; l&rsquo;&eacute;gard de son abonnement, renouvellement, offre promotionnelle ou toutes autres informations relatives aux abonnements.&nbsp;</p>
                    <p>Dans l&rsquo;&eacute;ventualit&eacute; ou le consommateur ne d&eacute;sire plus &ecirc;tre contact&eacute; par courriel &eacute;lectronique, le consommateur doit faire la demande &eacute;crite au commer&ccedil;ant.</p>
                    <br />
                    <p>Sign&eacute; &agrave; Montr&eacute;al, le {moment().format('DD/MM/YYYY')}, j&rsquo;ai lu ce document attentivement et je suis inform&eacute;(e) de mes responsabilit&eacute;s et es effe de ce formulaire. J&rsquo;ai sign&eacute; volontairement et selon mon propre gr&eacute;.&nbsp;</p>
                    <p>Nom du consommateur: {this.state.client.name}</p>
                    <p>Signature du consommateur (Si mineur, parent ou tuteur légal): </p>
                    </>
                }
            
                </div>
                <p style={{marginTop: 10, marginBottom: 5}}>{Labels.get("Please sign in the grey square below:")}</p>
                <SignatureCanvas 
                    canvasProps={{height: 150, className: `sigCanvas ${this.state.canvasClass}`, style: {width: '100%', background: '#eee'}}} 
                    ref={this.padRef}
                />
                <Button onClick={this.clear}>{Labels.get("Clear")}</Button>
            </Modal>
            
            <Button type="dashed" onClick={this.showContractModal}>
                {Labels.get("Sign Contract")}
            </Button>

            {this.state.value ? (
                <a href={this.state.value} target="_blank" style={{marginLeft: '10px'}}>{Labels.get("View Contract")}</a>
            ) : null}

        </>);
    }
}

export default Contract;