import React, { Component } from "react";

import { Checkbox, Button, message, Row, Col, Select, Divider, Modal, DatePicker, Input, Icon, Card, Result, AutoComplete } from 'antd';

import Zenfy from "../libs/Zenfy";
import CreditCard from '../components/CreditCard';
import PayForm from '../components/PayForm';
import SelectFromUrl from '../components/SelectFromUrl';
import axios from 'axios';

import Labels from '../libs/Labels';
import moment from 'moment';

import Currency from 'react-currency-formatter';

class Donation extends Component {

  constructor(props) {
        super(props);

        this.showOptions = Zenfy.getParameterByName('options') ? true : false;
        this.cmp_id = Zenfy.getParameterByName('cmp_id') ? Zenfy.getParameterByName('cmp_id') : null;
        this.lang = Zenfy.getParameterByName('lang') ? Zenfy.getParameterByName('lang') : "en";

        this.showSuggestions = Zenfy.getParameterByName('suggestions') ? true : false;
        this.forceReccuring = Zenfy.getParameterByName('reccuring') ? true : false;

        let suggestions = [18, 36, 72, 100, 180, 360];

        if(!this.forceReccuring){
            suggestions = [36, 72, 100, 180, 360];
        }

        this.state = {
            current: 0,
            client: {},
            invoice: {},
            amount: "",
            msg: null,
            type: "credit",
            success: false,
            addresses: [],
            isLoading: false,
            org: {},
            hideCreditCard: this.showOptions,
            checkData: null,
            suggestions,
            cmp_id: undefined,
        }

        localStorage.Lang = this.lang;

        this.payForm = React.createRef();
        this.productsSelect = React.createRef();
        this.amountInput = React.createRef();

        let orgUrl = this.props.match.params.org;

        Zenfy.get(`orgs/${orgUrl}`).then((res) => {

            if (localStorage.Lang == 'fr' && res.data.logo_fr) {
                res.data.logo = res.data.logo_fr;
            }

            this.setState({
                org: res.data
            });

            if(res.data.id){
                let oldOrgId = localStorage.orgId;
                localStorage.orgId = res.data.id;
                localStorage.org_id = res.data.id;
                window.org = res.data;

                if(oldOrgId != res.data.id){
                    this.forceUpdate();
                }
            }

        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.checkH();
        }, 1000);
    }

    setAmount = (amount) => {
        this.setState({
            amount
        });
    }

    checkH = (goTop) => {
        setTimeout(() => {
          window.parent.postMessage({h: document.getElementById("root").offsetHeight + 400},"*");
          if(goTop){
            window.parent.postMessage({scroll: 1},"*");
          }
        }, 100);
    }

    handleAddressChange = (address) => {
        let lang = "";
        if(localStorage.Lang && localStorage.Lang == 'fr'){
            lang = "&language=fr-CA";
        }

        axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=pk.eyJ1IjoiemVuZnkiLCJhIjoiY2tzNWdpZmN5MGo1ZjJwcHRhOTR0ZWhwZSJ9.L7awLBemyNyDpqsSa99iNQ&country=CA,US,IL${lang}`)
        .then(response => {

          let addresses = [];
          let chosenPlace = null;
            
          response.data.features.map((place) => {
            addresses.push(place.place_name);

            if(place.place_name == address){
                chosenPlace = place;
            }
          });

          let client = this.state.client;
          client.address = address; // if copy and paste

          if(chosenPlace){
            
            client.address = `${chosenPlace.address ? chosenPlace.address + " " : ""}${chosenPlace.text}`;
            
            chosenPlace.context.map((context) => {
                if(context.id.indexOf("post") >= 0){
                    client.postal = context.text;
                }else if(context.id.indexOf("place") >= 0){
                    client.city = context.text;
                }else if(context.id.indexOf("country") >= 0){
                    client.country = context.text;
                }else if(context.id.indexOf("region") >= 0){
                    client.state = context.text;
                }
            })
          }else if(address == ''){
              addresses = [];
          }
          
          
          this.setState({
            addresses,
            client
          });

        });
    }

    handleInputChange = ({ target }) => {

        if(target.name == 'name' || target.name == 'email' || target.name == 'telephone'){

            let client = this.state.client;
                client[target.name] = target.value;

            this.setState({
                client
            });

        }else{

            if(target.name == 'anonymous'){
                target.value = target.checked ? 1 : 0;
            }

            this.setState({ [target.name]: target.value });
        }
        
    }

    createClient = (data, callback) => {

        data.org_id = localStorage.org_id;

        Zenfy.post('clients', data).then((res) => {
            let client = this.state.client;
            client.id = res.data;
           
            this.setState({
                client
            }, () => {
                if(callback){
                    callback();
                }
            });
        });
    }

    donate = () => {

        if(this.state.amount == 0){
            alert('Amount has to be greater than 0.');
        }

        if(!this.state.client.name){
            alert('Name is required.');
        }

        if(this.state.client.name != '' && this.state.amount != 0){
            
            this.setState({
                loading: true,
            });

            this.createClient(this.state.client, () => {

                Zenfy.createOrderInvoice(this.state.client.id, window.org.id, 3681, this.state.amount, (invoice_id, product_order_id) => {
                    
                    this.payForm.current.validateFieldsAndScroll((err, values) => {
                        console.log(err, values);
                        if (!err) {
            
                            values.client_id = this.state.client.id;
                            values.org_id = localStorage.orgId;
                            values.invoice_id = invoice_id;
            
                            values.amount = this.state.amount;
                            values.type = this.state.type;
                            values.reference = this.state.reference;

                            if(values.type == 'credit'){
                                localStorage.cc_desc = window.org.cc_desc;
                                window.creditCardForm.handleOk(values.amount, (res) => {
                                    values.txn_id = res.id;
                                    if(res.recurring_end){
                                        values.recurring_end = res.recurring_end;
                                    }

                                    if(res.recurring == 1){
                                        values.recurring = 1;

                                        Zenfy.post('products_orders', {
                                            id: product_order_id,
                                            recurring: 1,
                                            ends_at: values.recurring_end,
                                            next_at: moment().add(1, 'months').format('YYYY-MM-DD HH:mm:ss')
                                        });
                                    }

                                    this.postTransaction(values);
                                }, () => {
                                    // save error in history
                                    this.setState({
                                        loading: false,
                                    });
                                });
                            }else{
                                this.postTransaction(values);
                            }

                        }
                    });

                });


            })

            
        }
    }

    postTransaction = (values) => {

        if(this.showOptions && this.cmp_id){
            values.cmp_id = this.cmp_id;
        }

        if(this.state.checkData){
            values.cheque_date = this.state.checkData;
            values.created_at = this.state.checkData;
        }

        if(this.state.anonymous){
            values.anonymous = this.state.anonymous;
        }

        if (this.state.msg) {
            values.msg = this.state.msg;
        }
    
        Zenfy.post('transactions', values).then((res) => {
    
            this.setState({
                success: true,
            });
            
            let salesData = this.state.client;
            salesData.invoice = {
                paid: values.amount,
            };

            //Zenfy.post('salesforce', salesData);

            Zenfy.get(`${values.org_id}/sendemail/${values.client_id}`, {
                amount: values.amount,
                t_type: values.type,
                type: 'donation',
                id: values.invoice_id,
            });
        });
    }

    goBack = () => {
        if(localStorage.orgId == 18){
            this.showOptions ? window.location.reload() : window.location = 'https://fcfund.org';
        }else{
            window.location.reload();
        }   
    }

    render() {

    let thankDesc = localStorage.orgId == 18 ? "Your donation helps us build a stronger community. Struggling families in Flatbush are endlessly grateful for your gift." : "";
    
    document.body.style['touch-action'] = "manipulation";

    return (
      <>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport"/>
        
        <Row type="flex" justify="space-around" align="middle" style={{margin: '0px 0 0 0'}} className="donation">
            <Col 
                className="gutter-row" 
                lg={8}
                xl={8}
                xs={20}
                sm={12}
                md={12}
            >   
                
                <Card style={{marginTop: 5}}>

                    {!this.state.success ? (
                        <>
                            <img src={this.state.org.logo} style={{maxWidth: 90, width: '100%', marginBottom: 25}}/>

                            <Input
                                //style={{marginTop: '25px'}}
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder={Labels.get("Name")}
                                pattern="[\d| ]{16,22}"
                                required
                                onChange={this.handleInputChange}
                            />

                            <Checkbox name="anonymous" onChange={this.handleInputChange} style={{marginTop: '5px'}}>
                                {Labels.get("Remain anonymous")}
                            </Checkbox>

                            <Input
                                style={{marginTop: '25px'}}
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder={Labels.get("Email")}
                                required
                                onChange={this.handleInputChange}
                            />

                            <Input
                                style={{marginTop: '25px'}}
                                type="text"
                                name="telephone"
                                className="form-control"
                                placeholder={Labels.get("Phone")}
                                required
                                onChange={this.handleInputChange}
                            />

                            <AutoComplete
                                dataSource={this.state.addresses}
                                style={{marginTop: '25px', width: '100%'}}
                                type="text"
                                name="address"
                                className="form-control"
                                placeholder={Labels.get("Address")}
                                required
                                onChange={this.handleAddressChange}
                            />

                            <Input
                                style={{marginTop: '25px', marginBottom: '25px'}}
                                type="number"
                                name="amount"
                                className="form-control"
                                placeholder={Labels.get("Amount")}
                                required
                                value={this.state.amount}
                                onChange={this.handleInputChange}
                                ref={this.amountInput}
                            />

                            {this.showSuggestions ? (
                                <div style={{ marginBottom: '25px' }}>
                                    {this.state.suggestions.map((sug) => 
                                        <div className="suggest-btn" key={sug} onClick={()=>{
                                            this.setAmount(sug);
                                        }}><Currency quantity={sug} pattern="!##,###." /></div>
                                    )}
                                    <div className="suggest-btn" onClick={()=>{
                                        this.setAmount("");
                                        this.amountInput.current.focus();
                                    }}>Other</div>
                                </div>
                            ) : null}

                            {this.showOptions ? (
                                <>
                                    <SelectFromUrl 
                                        style={{width: '100%'}}
                                        preLoad={(items) => {
                                            items.teams.push({id: 343, name: 'Main Campaign'})
                                            return items.teams;
                                        }}
                                        name="name"
                                        idName="id"
                                        placeholder="Select a team"
                                        url={`${localStorage.orgId ? localStorage.orgId : 1}/cmps/343`} 
                                        itemChange={(team) => {
                                            if (!team) {
                                                return;
                                            }
                                            this.cmp_id = team.id
                                            this.setState({
                                                cmp_id: team.id
                                            })
                                        }}
                                        value={this.state.cmp_id}
                                    />
                                    <Input.TextArea
                                        style={{marginTop: '25px', marginBottom: '25px'}}
                                        name="msg"
                                        className="form-control"
                                        placeholder={Labels.get("Message")}
                                        onChange={this.handleInputChange}
                                    />
                                    <Select
                                        placeholder="Transaction Type"
                                        style={{width: '100%', marginBottom: '25px'}}
                                        onChange={(type) => {
                                            if(type == 'credit'){
                                                this.setState({
                                                    hideCreditCard: false,
                                                    type
                                                })
                                            }else{
                                                this.setState({
                                                    hideCreditCard: true,
                                                    type
                                                })
                                            }
                                        }}
                                        name="type"
                                    >
                                        <Select.Option value="cash">Cash</Select.Option>
                                        <Select.Option value="e-transfer">E-transfer</Select.Option>
                                        <Select.Option value="cheque">Check</Select.Option>
                                        <Select.Option value="credit">Credit Card</Select.Option>
                                        <Select.Option value="pledge">Pledge</Select.Option>
                                    </Select>
                                    
                                </>
                            ) : null}

                            {this.state.type != 'credit' ? (
                                <>
                                    <Input
                                        style={{marginBottom: '25px'}}
                                        type="text"
                                        name="reference"
                                        className="form-control"
                                        placeholder={Labels.get("Reference")}
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </>
                            ) : null}
                            
                            {this.showOptions ? (
                            <DatePicker 
                                format={"YYYY-MM-DD"} 
                                style={{width: '100%', marginBottom: '20px'}}
                                placeholder="Transaction Date" 
                                onChange={(date, dateString) => {
                                    this.setState({
                                        checkData: dateString
                                    });
                                }}
                            />) : null}
                            
                            <PayForm 
                                cur={window.org ? window.org.currency : 'usd'}
                                client={this.state.client} 
                                invoice={this.state.invoice}
                                ref={this.payForm}   
                                hideType 
                                hideReference
                                hideCredit={this.state.hideCreditCard}
                                force_monthly={this.forceReccuring}
                            />

                            <div style={{textAlign: 'center'}}>
                                <Button type="primary" loading={this.state.loading} style={{marginTop: 25}} onClick={this.donate}>
                                    {Labels.get("Donate")}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Result
                                    icon={<Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />}
                                    title={Labels.get("Thank you for your generous support!")}
                                    subTitle={Labels.get(thankDesc)}
                                    extra={[
                                    <Button type="primary" key="console" onClick={this.goBack}>
                                        {Labels.get("Go Back")}
                                    </Button>,
                                    ]}
                                />
                            </>
                    )}

                    

                </Card>

            </Col>
        </Row>

      </>
    );
  }
}

export default Donation;