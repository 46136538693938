import React, { Component } from 'react';
import { Select, Modal, DatePicker, Form, Input, Icon } from 'antd';
import Zenfy from '../libs/Zenfy';
import moment from 'moment';
import Labels from '../libs/Labels';
import DynamicForm from '../components/DynamicForm';

const { Option } = Select;

class DynamicChilds extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: [],
            row: {},
        }

        this.form = React.createRef();
        this.editingIndex = null;
    }

    componentWillReceiveProps(newProps) {
        //if(newProps.value){
            /*this.setState({
                value: newProps.value ? newProps.value : [],
            });*/
        //}

    }

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
          onChange(changedValue);
        }
    }

    handleChange = (value) => {

        this.setState({
            value: value,
        });

        this.triggerChange(value);
    }


    componentDidMount() {
        if(this.props.url){
            Zenfy.get(this.props.url).then((res) => {
                /*this.setState({
                    value: res.data
                });*/
                if(res.data){
                    this.handleChange(res.data);
                }
            });
        }
    }

    handleModalOk = () => {
        this.form.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.org_id = localStorage.orgId;

                if(this.props.client_id){
                    values.client_id = this.props.client_id;
                }
            
                let rows = this.state.value;
            
                Zenfy.post(this.props.type, values).then((res) => {
                    
                    values.id = res.data;
                    if(this.editingIndex == null){
                        rows.push(values);
                    }else{
                        rows[this.editingIndex] = values;
                        this.editingIndex = null;
                    }

                    if(this.props.afterAdd){
                        this.props.afterAdd(res.data);
                    }

                    this.handleChange(rows);
                
                    this.setState({
                        showModal: false,
                        value: rows,
                    });
                });

            }
        });
    }

    showModal = (retart) => {
        

        let obj = {
            showModal: true,
        };

        if(retart){
            obj.row = {};
            this.editingIndex = null;

            if(this.form.current){
                this.form.current.resetFields();
            }
        }

        this.setState(obj);
    }

    delete = (index, record) => {

        const { confirm } = Modal;

        confirm({
            title: Labels.get('Do you want to delete?'),
            okText: Labels.get('Yes'),
            onOk: () => {
                /*Zenfy.delete(`clients/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });*/
                let rows = this.state.value;
                rows.splice(index, 1);

                Zenfy.delete(`${this.props.type}/${record.id}`).then((res) => {
                    //this.drawerRef.current.load();
                });

                this.setState({
                    value: rows
                });
            },
            onCancel: () => {
              
            },
        });

    }

    render() {
        
        let showAdd = true;

        if(this.props.onlyOne && this.state.value.length == 1){
            showAdd = false;
        }

        if(this.props.max && Array.isArray(this.state.value) && this.props.max == this.state.value.length){
            showAdd = false;
        }

        return (
        <>
            <Modal
                title={Labels.get(Zenfy.capitalizeFirstLetter(this.props.type))}
                visible={this.state.showModal}
                onOk={this.handleModalOk}
                okText={Labels.get("Save")}
                onCancel={()=> this.setState({
                    showModal: false,
                })}
                cancelText={Labels.get("Cancel")}
                okButtonProps={{loading: this.state.modalLoading}}
            >   
                <DynamicForm keys={this.props.keys ? this.props.keys : []} type={this.props.type} row={this.state.row} ref={this.form} hideFooter />
            </Modal>

                 <>
                    {this.state.value && Array.isArray(this.state.value) ? this.state.value.map((child, index) => (
                        <div>
                            <a href="javascript:;" onClick={() => {
                                this.setState({
                                    row: child
                                }, () =>{
                                    this.showModal();
                                });
                                this.editingIndex = index;
                            }}>
                                
                            {this.props.keys ? this.props.keys.map((object, i) =>  <span>{ child[object.key] && object.key != 'include' ? `${child[object.key]}${i == this.props.keys.length - 1 ? '' : ', '}`  : ''}</span>) : null}
                            
                            </a> <Icon type="delete" onClick={() => {this.delete(index, child);}}/>
                        </div>
                    )) : null}

                    {showAdd ? (
                        <a href="javascript:;" className="hide-pdf" style={{textDecoration: 'underline'}} onClick={() => {this.showModal(true)}}>
                            {Labels.get("Add")}
                        </a>
                    ) : null}
                    
                </>
            
            
        </>);
    }
}

export default DynamicChilds;