import React, { Component } from "react";

import {
    Layout, Menu
} from 'antd';

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import Orders from './Orders';

const { SubMenu } = Menu;
const {
  Header, Content, Footer, Sider,
} = Layout;

class Trading extends Component {

    constructor(props) {
        super(props);

        const url = props.location.pathname.split('/');

        this.state = {
            selectedTab: url[url.length-1]//url.length > 3 ? url[url.length-1] : 'jobs'
        }
    }

    render() {
        return (
            <>
                <Layout style={{ padding: '24px 0', background: '#fff' }}>

                    <HashRouter>
                        <Sider width={200} style={{ background: '#fff'}}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={this.state.selectedTab}
                                style={{ height: '100%' }}
                            >
                                <Menu.Item key="dashboard"><NavLink to={`/trading/dashboard`}>Dashboard</NavLink></Menu.Item>
                                <Menu.Item key="orders"><NavLink to={`/trading/orders`}>Orders</NavLink></Menu.Item>
                                <Menu.Item key="journal"><NavLink to={`/trading/journal`}>Journal</NavLink></Menu.Item>
                            </Menu> 
                        </Sider>
                    </HashRouter>

                    <Content style={{ padding: '0 24px' }}>
                        <Route exact path={`/trading/orders`} component={Orders} />
                    </Content>

                </Layout>
            </>
        )
    }
}

export default Trading;