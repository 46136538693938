import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Badge, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import PayForm from '../components/PayForm';
import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";
import WrappedInvoiceForm from "./InvoiceForm";

class Attendances extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            client: props.client,
            showModal: false,
            selectedInvoice: {}
        }

        this.drawerRef = React.createRef();
        this.formRef = React.createRef();
        this.payForm = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

        // this.componentHasGotData(newProps);
        if(this.state.client.id != newProps.client.id){

            this.setState({
                client: newProps.client
            },() => {
               this.componentDidMount();
            });
        }

    }


    load = () => {

        if(!this.state.client.id){
            this.setState({
                rows: []
            });
            return;
        }

        this.setState({
            loading: true,
        });

        Zenfy.get(`${localStorage.orgId}/attendances/${this.state.client.id}`).then((res) => {
            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
            })
        });
    }

    onSave = () => {

        this.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.client_id = this.state.client.id;
                values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                values.id = this.state.selectedInvoice.id ? this.state.selectedInvoice.id : null;
              
                Zenfy.post('invoices', values).then((res) => {
                    this.load();
                    this.props.reLoad();

                    this.drawerRef.current.hideDrawer();
                });

            }
        });

        
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedInvoice: record,
        });
    }

    deleteAttendance = (record) => {
        Zenfy.delete(`attendances/${record.id}`).then((res) => {
            this.load();
            this.props.reLoad();
        });
    }

    render() {

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            //render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            sorter: (a, b) => a.id - b.id,
        },{
            title: 'Day',
            dataIndex: 'created_at',
            key: 'created_at',
            //render: (text) => {return moment(text).format("MMMM Do YYYY")},
        },{
            title: 'Passed',
            dataIndex: 'passed',
            key: 'passed',
            sorter: (a, b) => a.passed.length - b.passed.length,
            render: (text) => text == 1 ? "Yes" : "No",
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="5" onClick={(e)=>this.deleteAttendance(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }
    ];

        return (
        <>
            <DrawerTableForm 
                title="Add new attendace" 
                modal 
                noAdd
                onSave={this.onSave} 
                columns={columns} 
                rows={this.state.rows} 
                recordOnClick={this.recordOnClick} 
                ref={this.drawerRef} 
                //form={<WrappedInvoiceForm drawer={this.drawerRef} reLoad={this.load} invoice={this.state.selectedInvoice} ref={this.formRef} />} 
                loading={this.state.loading} 
            />
        </>
        );
    }
}

export default Attendances;