import React, { Component } from "react";
import { Tag, Badge, Tooltip, Menu, Button, Icon, Dropdown, Modal, notification, message } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import ClientForm from './ClientForm';
import Triacom from './Triacom';
import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";
import SelectFromUrl from "../../components/SelectFromUrl";

import eco from '../../eco.json';

import Labels from '../../libs/Labels';

class Clients extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            selectedClient: {},
            searchVal: '',
            membershipCategories: [],
            membershipStatus: [{text: 'Active', value: 'Active'}, {text: 'Ended', value: 'Ended'}],
            statusCat: [],
            showAttendanceModal: false,
        }

        this.drawerRef = React.createRef();
        this.tableRows = [];
        this.imported = 0;
        this.importedData = [];
        this.created_at = null;
    }

    componentDidMount() {
        this.load();
    }

    cleanRows = (rows) => {
        let exportRows = [];

        rows.map((row) => {

            let exportObj = {
                name: row.name,
                email: row.email ? row.email : '',
                //"Child Name": row.child_name ? row.child_name : '',
                //"Child DOB": row.child_dob ? row.child_dob : '',
                //"Child Gender": row.child_gender && row.child_name != '' ? row.child_gender : '',
                //"Child Health/Comments": row.child_conditions ? row.child_conditions : '',
                //gender: row.gender ? row.gender : '',
                //dob: row.dob ? row.dob : '',
                address: row.address ? row.address : '',
                city: row.city ? row.city : '',
                state: row.state ? row.state : '',
                postal: row.postal ? row.postal : '',
                country: row.country ? row.country : '',
                phone: row.telephone ? row.telephone : '',
                //"Active Membership": row.activeMembershipName,
                comments: row.health ? row.health : '',
                last_invoice: row.lastInvoice ? row.lastInvoice : '',
                last_invoice_amount: row.last_invoice_amount ? row.last_invoice_amount : '',
                tags: row.membershipCategories ? row.membershipCategories.toString() : '',
                //last_invoice_amount:
                //"Emergency Contact": row.emergency_name ? row.emergency_name : '',
                //"Emergency Contact Phone": row.emergency_phone ? row.emergency_phone : '',
            };

            if(localStorage.orgId != 13){
                exportObj.first_name = Zenfy.getFirstName(row.name);
                exportObj.last_name = Zenfy.getLastName(row.name);
            }

            exportRows.push(exportObj);
        });

        return exportRows;
    }

    load = (id) => {

        // fix for not restarting the form :(
        let selectedClient = this.state.selectedClient;
        
        if(id){
            selectedClient.id = id;
        }

        this.setState({
            loading: true,
            selectedClient,
        });

        Zenfy.get(`crmclients/${localStorage.orgId ? localStorage.orgId : 1}`).then((res) => {

            let status = [];
            let membershipCategories = [];

            if(res.data.length > 0){
                res.data.map((client) => {
                    if(client.crm_status && status.indexOf(client.crm_status) == -1){
                        status.push(client.crm_status);
                    }

                    // finding the oldest membership
                    let orderedMemberships = client.invoices.sort(function(a, b){
                        return new Date(a.ends_at) > new Date(b.ends_at) ? -1 : 1;
                    });

                    //console.log(orderedMemberships[0].balance);
                    if(orderedMemberships.length > 0){
                        //console.log(orderedMemberships[0].balance);
                    }

                    if(orderedMemberships.length > 0 && orderedMemberships[0].balance >= 0) {
                        client.activeMembership = orderedMemberships[0];
                        client.activeMembershipName = orderedMemberships[0].name;
                        client.activeMembershipEnd = orderedMemberships[0].ends_at;
                    }else{
                        client.activeMembership = null;
                        client.activeMembershipName = '';
                        client.activeMembershipEnd = '';
                    }

                    client.membershipCategories = client.tags.map((tag) => {
                        if(!membershipCategories.includes(tag.name)){
                            membershipCategories.push(tag.name);
                        }
                        return tag.name;
                    });

                   

                    console.log(client.membershipCategories);

                    if(client.invoices && client.invoices.length > 0){
                        client.invoices.map((invoice) => {
                            invoice.products.map((product) => {
                                if(!membershipCategories.includes(product.product_name) && product.product_name){
                                    membershipCategories.push(product.product_name);
                                }
                                client.membershipCategories.push(product.product_name);

                                if(product.recurring == 1){
                                    let recurringName = product.product_name + " (recurring)";
                                    client.membershipCategories.push(recurringName);

                                    if(!membershipCategories.includes(recurringName)){
                                        membershipCategories.push(recurringName);
                                    }
                                }
                            });
                        });
                    }

                });

                for (var i = 0; i < status.length; ++i){
                    status[i] = {
                        text: Zenfy.capitalizeFirstLetter(status[i]),
                        value: status[i]
                    };
                }

            }

            membershipCategories.sort((a, b) => a.localeCompare(b));

            for (var i = 0; i < membershipCategories.length; ++i){
                membershipCategories[i] = {
                    text: membershipCategories[i],
                    value: membershipCategories[i],
                }
            }

            this.setState({
                loading: false,
                membershipCategories,
                //statusCat: status,
                selectedClient,
                statusCat: Zenfy.getCategoriesFilters(res.data, 'crm_status'),
                rows: Zenfy.setKeysToList(res.data),
            });

        });
    }

    recordOnClick = (record, e) => {
        if(e){
            e.stopPropagation();
        }

        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedClient: record,
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });
    
    }

    delete = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this contact?',
            okText: 'Yes',
            okType: 'danger',
            onOk: () => {
                Zenfy.delete(`clients/${record.id}`).then((res) => {
                    this.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    updateStripe = (record) => {
        Zenfy.post(`${localStorage.orgId}/updatestripe`, {
            email: record.email,
            name: record.name,
            customerId: record.customer_id,
        }).then((res) => {
            notification.success({
                message: `Updated with success`,
                placement: 'bottomLeft',
            });
        });
    }

    renderMembership = (record) => {
        /*const res = record.act ? record.invoices.map((invoice) => {
            if(invoice.ends_at){
                return <span>{new Date(invoice.ends_at) > new Date() ? 'Ending' : 'Ended '} {moment(invoice.ends_at).fromNow()}</span>;
            }
        }) : '';*/

        const res = record.activeMembership && record.activeMembership.ends_at ? <span>{new Date(record.activeMembership.ends_at) > new Date() ? 'Ending' : 'Ended '} {moment(record.activeMembership.ends_at).fromNow()}</span> : '';

        return res;
    }

    renderProducts = (record) => {

        let res = record.invoices ? record.invoices.map((invoice) => {
            if((invoice.balance >= 0 && invoice.amount_due > 0) || localStorage.orgId == 13){
                return invoice.products.map((product) => {
                    if(product.product_id){
                        return <Tooltip>
                            <Tag color={product.recurring == 1 ? "green" : "blue"} style={{marginBottom: '5px'}}><span dangerouslySetInnerHTML={{__html: `${product.product_name} ${product.recurring ? " <b>(recurring)</b>" : ""}`}}></span></Tag>
                        </Tooltip>;
                    }
                });
            }
        }) : '';

        if(record.tags){
            let tags = record.tags.map((tag) => {
                return <Tooltip>
                    <Tag color="volcano" style={{marginBottom: '5px'}}>{tag.name}</Tag>
                </Tooltip>;
            });

            res = res.concat(tags);
        }
        
        return res;
    } 

    refund = (record, e, callback, noTransactions) => {
        if(e){
            e.stopPropagation();
        }

        if(record.invoices.length == 1){
            Zenfy.get(`${localStorage.orgId}/transactions`, {
                invoice_id: record.invoices[0].id
            }).then((res) => {
                let trans = res.data[0];

                if(trans){
                    Zenfy.post('refund', trans).then((res) => {
                        if(callback){
                            callback(record.invoices[0].id);
                        }
                    })
                }else{
                    if(noTransactions){
                        console.log(record.invoices[0].id);
                        noTransactions(record.invoices[0].id);
                    }
                }
            });



        }else{
           
            console.log(record.name + ' invoices are weird ' + record.id);
        }
    }

    refundClients = (rows, callback) =>  {
        if(rows.length > 0){

            let client = rows.pop();

            this.imported++;
            let pur = 100 * this.imported / this.importedData.length;
            document.querySelector('.importing_number span').innerHTML  = `${this.imported} / ${this.importedData.length} (${pur.toFixed()}%)`;

            let foundClient = null;

            if(client.email){
                foundClient = this.state.rows.find((element) => {
                    return element.email === client.email;
                });
            }else if(client.name){
                foundClient = this.state.rows.find((element) => {
                    if(element.name){
                        return element.name.trim() === client.name.trim();
                    }
                })
            }

            if(foundClient){
                this.refund(foundClient, null, (invoice_id) => {
                    this.makePay(foundClient, client, invoice_id, () => {
                        this.refundClients(rows, callback);
                    });
                }, (invoice_id) => {
                    this.makePay(foundClient, client, invoice_id, () => {
                        this.refundClients(rows, callback);
                    });
                })
            }else{
                this.refundClients(rows, callback);
                console.log(client.name + ' not found');
            }
        }
    }

    makePay = (foundClient, client, invoice_id, callback) => {
        Zenfy.post('pay', {
            client: {
                email: foundClient.email,
                org_id: localStorage.orgId,
                id: foundClient.id
            },
            cc_desc: 'FCFUND',
            cc: client.cc.number,
            cvc: client.cc.cvv,
            month: client.cc.date.substring(0,2),
            year: client.cc.date.slice(-2),
            amount: client.cc.amount,
            cur: 'usd'
        }).then((res) => {
                
            let id = res.data.id;

            this.postTransaction({
                txn_id: id,
                amount: client.cc.amount,
                type: 'credit',
                org_id: localStorage.orgId,
                cmp_id: 322,
                client_id: foundClient.id,
                created_at: client.created_at,
                invoice_id,
            }, ()=> {
                
                if(client.email){
                    Zenfy.get(`${localStorage.orgId}/sendemail/${foundClient.id}`, {
                        subject: 'Your recent donation',
                        email_body: "<p>Dear Flatbush Community Fund Supporter,</p><p>Recently, we switched to a new payment platform and unfortunately, the charges that were made were done in Canadian currency. We regret this error and will make sure it does not occur again. In the meantime, we are refunding the Canadian transactions, which will also undo any foreign transaction fees incurred and we will be recharging you for the correct amount in American dollars.</p><p>If you have any questions or concerns, please feel free to reach out to yweinberg@fcfund.org.</p><p>Thank you very much for your donation and have a Gmar Chasima Tova</p>",
                        type: 'text',
                        client: {
                            email: client.email
                        }
                    });
                }
                
                callback();
            });
        }).catch(error => {
            if(error.response && error.response.data){
                document.querySelector('.errors').innerHTML += client.name + ": " + error.response.data + "<br>";
                callback();
            }
        });
    }

    addClient = (rows, callback) => {
        if(rows.length > 0){

            let client = rows.pop();

            this.imported++;
            let pur = 100 * this.imported / this.importedData.length;
            document.querySelector('.importing_number span').innerHTML  = `${this.imported} / ${this.importedData.length} (${pur.toFixed()}%)`;

            Zenfy.post(`clients`, client).then((res) => {
              if(client.cc && client.cc.amount){
                client.id = res.data;
                Zenfy.createOrderInvoice(res.data, localStorage.orgId, 3681, client.cc.amount, (invoice_id) => {
                    
                    if(client.cc.number){
                        Zenfy.post('pay', {
                            client: {
                                email: client.email,
                                org_id: localStorage.orgId,
                                id: client.id
                            },
                            cc_desc: 'FCFUND',
                            cc: client.cc.number,
                            cvc: client.cc.cvv,
                            month: client.cc.date.substring(0,2),
                            year: client.cc.date.slice(-2),
                            amount: client.cc.amount,
                            cur: 'usd'
                        }).then((res) => {
                            
                            let id = res.data.id;
    
                            this.postTransaction({
                                txn_id: id,
                                amount: client.cc.amount,
                                type: 'credit',
                                org_id: localStorage.orgId,
                                cmp_id: 323,
                                client_id: client.id,
                                created_at: client.created_at,
                                invoice_id,
                            }, ()=> {
                                
                                if(client.email){
                                    Zenfy.get(`${localStorage.orgId}/sendemail/${client.id}`, {
                                        amount: client.cc.amount,
                                        type: 'donation',
                                    });
                                }
                                
                                this.addClient(rows, callback);
                            });
                            
                        }).catch(error => {
                            if(error.response && error.response.data){
                                document.querySelector('.errors').innerHTML += client.name + ": " + error.response.data + "<br>";
                                this.addClient(rows, callback);
                            }
                        });
                    }else{

                       
                        this.postTransaction({
                            amount: client.cc.amount,
                            type: 'cheque',
                            org_id: localStorage.orgId,
                            cmp_id: 322,
                            client_id: client.id,
                            created_at: client.created_at,
                            invoice_id,
                        }, ()=> {
                            
                            if(client.email){
                                Zenfy.get(`${localStorage.orgId}/sendemail/${client.id}`, {
                                    amount: client.cc.amount,
                                    type: 'donation',
                                    t_type: 'cheque'
                                });
                            }
                            
                            this.addClient(rows, callback);
                        });

                    }
                    
            

                });
              }else{
                this.addClient(rows, callback);
              }
              //this.addClient(rows, callback);
            });
      
          }else{
            if(callback){
              callback();
            }
          }
    }

    postTransaction = (data, callback) => {
        Zenfy.post('transactions', data).then((res) => {
            if(callback){
                callback();
            }
        });
    }

    importDatas = (dataParse, callback) => {

        

        let datas = [];

        dataParse.map((obj, index) => {

            if(localStorage.orgId == 13){
                let data = {
                    name: obj[2],
                    web: obj[19],
                    org_id: localStorage.orgId
                }
    
                if(data.name && data.name != 'name'){
    
                    eco.response.data.map((entreprise) => {
                        if(entreprise.fieldData['Nom entreprise'] == data.name){
                            data.picture = entreprise.fieldData['LogoEntreprise'];
                            data.published = 1;
                            data.produits = entreprise.fieldData['Description'];
                        }
                    });
    
                    datas.push(data);
                }
            }else{
                /*0: "TITLE"
1: "FNAME"
2: "LNAME"
3: "FIRM"
4: "ADDRESS"
5: "CITY"
6: "STATE"
7: "ZIP"
8: "PLEDGE"
9: "ID"
10: "DATE"
11: "WFNAME"
12: "TEL1"
13: "TEL2"
14: "TELB"
15: "TELC"
16: "HW"
17: "CARD_NO"
18: "CARD_TYPE"
19: "EXP_DATE"
20: "CVV"
21: "EMAIL RECEIPTS"
22: "NOTES"
/*/
                if(index != 0 && obj[2]){

                    let firstName = obj[1] ? obj[1] : '';
                    
                    let data = {
                        title: obj[0],
                        name: firstName + ' ' + obj[2],
                        firm: obj[3],
                        address: obj[4],
                        city: obj[5],
                        state: obj[6],
                        postal: obj[7],
                        created_at: this.created_at,
                        wife: obj[11],
                        telephone: obj[12],
                        fax: obj[13],
                        cell: obj[15],
                        email: obj[21],
                        health: obj[22],
                        org_id: localStorage.orgId,
                        
                    };

                    if(obj[8]){
                        data.cc = {
                            amount: obj[8],
                            number: obj[17],
                            date: obj[19],
                            cvv: obj[20]
                        }
                    }

                   // console.log(data);

                    datas.push(data);

                }


               
            }
            
            
            
        });

        this.importedData = datas;

        /*this.refundClients([...this.importedData], () => {

            if(callback){
                callback();
            }

            notification.success({
                message: `${this.imported} contacts imported with success!`,
                placement: 'bottomLeft',
            });

            document.querySelector('.importing_number').style.display = 'none';
            this.imported = 0;
            this.load();
        });
        return;*/

        document.querySelector('.importing_number').style.display = 'block';

        this.addClient([...this.importedData], () => {

            if(callback){
                callback();
            }

            notification.success({
                message: `${this.imported} contacts imported with success!`,
                placement: 'bottomLeft',
            });

            document.querySelector('.importing_number').style.display = 'none';
            this.imported = 0;
            this.load();
        });

    }
    
    render() {

        let rows = this.state.rows.filter((obj) => {
            
            if (this.state.searchVal != '') {
                let name = obj.name ? obj.name : '';
                if(name){
                    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                }

                let childName = obj.child_name ? obj.child_name : '';
                if(childName){
                    childName = childName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                }

                let health = obj.health ? obj.health : '';
                let email = obj.email ? obj.email : '';
                let customerId = obj.customer_id ? obj.customer_id : '';

                return name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 
                || email.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 
                || childName.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 
                || customerId.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 
                || health.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
            }
        
            return true;
        });

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name && b.name ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : 1,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text ? text : record.email}</a>,
        },{
            title: 'Status',
            dataIndex: 'crm_status',
            key: 'crm_status',
            filters: this.state.statusCat,
            sorter: (a, b) => a.crm_status > b.crm_status,
            onFilter: (value, record) => record.crm_status && record.crm_status.indexOf(value) === 0,
            render: (text, record) => text ? <Tag color="green">{Zenfy.capitalizeFirstLetter(text)}</Tag> : '',
        },{
            title: 'Comments',
            dataIndex: 'health',
            key: 'health',
            sorter: (a, b) => a.health ? a.health.length : 0 - b.health ? b.health.length : 0,
        },{
            title: 'Amount Due',
            dataIndex: 'total_amount_due',
            key: 'total_amount_due',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.total_amount_due - b.total_amount_due,
        },{
            title: 'Paid Amount',
            dataIndex: 'total_paid_amount',
            key: 'total_paid_amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.total_paid_amount - b.total_paid_amount,
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.balance - b.balance,
        },{
            title: 'Last Invoice',
            dataIndex: 'lastInvoice',
            key: 'lastInvoice',
            render: (text) => {return text ? <Tooltip title={text}>{moment(text).format("MMMM Do YYYY")}</Tooltip> : null},
            sorter: (a, b) => new Date(a.lastInvoice) - new Date(b.lastInvoice),
        },{
            title: 'Tags',
            dataIndex: 'membershipCategories',
            key: 'membershipCategories',
            filters: this.state.membershipCategories,
            render: (text, record) => {return this.renderProducts(record)},
            onFilter: (value, record) => record.membershipCategories.includes(value),
        },{
            title: 'Ends',
            dataIndex: 'activeMembershipEnd',
            key: 'activeMembershipEnd',
            filters: this.state.membershipStatus,
            filterMultiple: false,
            render: (text, record) => {return this.renderMembership(record)},
            sorter: (a, b) => {return a.activeMembership && b.activeMembership ? new Date(a.activeMembership.ends_at) - new Date(b.activeMembership.ends_at) : null },
            onFilter: (value, record) => {
                if (record.activeMembership){
                    if (new Date(record.activeMembership.ends_at) > new Date() && value == 'Active'){
                        return true;
                    }else if(new Date(record.activeMembership.ends_at) < new Date() && value == 'Ended') {
                        return true;
                    }
                }else{
                    return false;
                }
            },
        }/*,{
            title: 'Published',
            dataIndex: 'published',
            key: 'published',
            render: (text, record) => text == 1 ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
            sorter: (a, b) => a.published ? a.published.length : 0 - b.published ? b.published.length : 0,
        }*/, {
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={(e)=>this.recordOnClick(record, e.domEvent)}>{Labels.get("Edit")}</Menu.Item>
                  <Menu.Item key="3" onClick={(e)=>this.updateStripe(record, e.domEvent)}>{Labels.get("Update Stripe")}</Menu.Item>
                  <Menu.Item key="2" onClick={(e)=>this.delete(record, e.domEvent)}>{Labels.get("Delete")}</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm 
                searchOnChange={this.searchOnChange} 
                showSearch 
                columns={columns} 
                rows={rows} 
                recordOnClick={this.recordOnClick} 
                ref={this.drawerRef} 
                form={localStorage.url == 'cid' ? (<ClientForm drawer={this.drawerRef} reLoad={this.load} client={this.state.selectedClient} />) : <Triacom drawer={this.drawerRef} reLoad={this.load} client={this.state.selectedClient} />} 
                type='contact' 
                loading={this.state.loading} 
                rowClassName={(record, index) => {
                        
                    if(record.activeMembershipEnd && new Date(record.activeMembership.ends_at) < new Date()){
                        return 'red';
                    }else if(record.status == 'Pending'){
                        return 'yellow';
                    }else{
                        return '';
                    }

                    // return `${index % 2 !== 0 ? 'impair' : ''}`;
                }}
                cleanRows={this.cleanRows}
                export
                email
                import
                importDatas={this.importDatas}
                dateOnChange={(date)=>{
                    this.created_at = date;
                }}
            />
        </>
        );
    }
}

export default Clients;