import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Badge, notification } from 'antd';

import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";
import Labels from "../libs/Labels";

import NormalContactForm from "./ContactForm";

class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            client: props.client,
            showModal: false,
            selectedContact: {},
        }

        this.drawerRef = React.createRef();
        this.formRef = React.createRef();
        this.payForm = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

        // this.componentHasGotData(newProps);

        if(this.state.client.id !== newProps.client.id){
            this.setState({
                client: newProps.client
            },() => {
               this.componentDidMount();
            });
        }

    }


    load = () => {

        this.setState({
            loading: true,
        });

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/contacts?clientId=${this.state.client.id}`).then((res) => {

            /*res.data.map((invoice) => {
                if(invoice.amount && invoice.amount > 0 && invoice.paid >= invoice.amount){
                    invoice.status = "Paid";
                }else if(invoice.amount && invoice.amount > 0) {
                    invoice.status = "Pending";
                } 
            });*/

            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
            })
        });
    }

    onSave = () => {

        this.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.client_id = this.state.client.id;
                values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                values.id = this.state.selectedContact.id ? this.state.selectedContact.id : null;
              
                Zenfy.post('contacts', values).then((res) => {
                    this.load();
                    this.drawerRef.current.hideDrawer();

                    notification.success({
                        message: `Contact saved with success!`,
                        placement: 'bottomLeft',
                    });

                    this.formRef.current.getForm().resetFields();
                });

            }
        });

        
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedContact: record,
        });
    }

    deleteContact = (record) => {

        const { confirm } = Modal;

        confirm({
            title: Labels.get('Do you want to delete this?'),
            onOk: () => {
                Zenfy.delete(`contacts/${record.id}`).then((res) => {
                    this.load();
                });
            },
            onCancel: () => {
            
            },
        });
        
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>
                </span>
              ),
        },{
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },{
            title: 'Phone',
            dataIndex: 'telephone',
            key: 'telephone',
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => (
              <span>
                <a href="javascript:;" onClick={()=>this.deleteContact(record)}>{Labels.get('Delete')}</a>
              </span>
            ),
          },
    ];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
            <div style={this.props.style}>
                {this.state.client.id ? (
                    <DrawerTableForm 
                    showSearch
                    forceSmall={this.props.forceSmall}
                    title={Labels.get("Add new contact")}
                    modal 
                    onSave={this.onSave} 
                    type="contacts"
                    columns={columns} 
                    rows={this.state.rows} 
                    recordOnClick={this.recordOnClick} 
                    ref={this.drawerRef} 
                    form={<NormalContactForm 
                        drawer={this.drawerRef} 
                        reLoad={this.load} 
                        contact={this.state.selectedContact}
                        ref={this.formRef} />} 
                        loading={this.state.loading} />
                ) : (
                    <span>{Labels.get("Please save before adding contacts.")}</span>
                )}
            </div>
        );
    }
}

export default Contacts;