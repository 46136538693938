import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag, Modal, Select } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Labels from '../../libs/Labels';

import Zenfy from "../../libs/Zenfy";

class Templates extends Component {

    constructor(props) {
        super(props);

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    delete = (record) => {
        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this template?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`templates/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });
    }

    duplicate = (record) => {

    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{record.name ? record.name : Zenfy.capitalizeFirstLetter(Labels.get(record.type))}{record.lang ? ` - ${Labels.get(record.lang)}` : ''}</a> ,
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            className: 'hide',
            
            type: <Select placeholder={Zenfy.capitalizeFirstLetter("Select type")}>
                <Select.Option value="email">{Labels.get("Email Template")}</Select.Option>
                <Select.Option value="invoice_email">{Labels.get("invoice_email")}</Select.Option>
                <Select.Option value="receipt_email">{Labels.get("receipt_email")}</Select.Option>
                <Select.Option value="receipt_pdf">{Labels.get("receipt_pdf")}</Select.Option>
                <Select.Option value="donation_desc">{Labels.get("donation_desc")}</Select.Option>
                <Select.Option value="contract">{Labels.get("Default Contract")}</Select.Option>
            </Select>
        },{
            title: Labels.get("HTML"),
            dataIndex: 'html',
            key: 'html',
            className: 'hide',
            type: 'text',
        },{
            title: Labels.get("Subject"),
            dataIndex: 'subject',
            key: 'subject',
            className: 'subject',
        },{
            title: Labels.get("Language"),
            className: 'hide',
            dataIndex: 'lang',
            key: 'lang',
            type: 'lang',
            required: true,
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>{Labels.get("Edit")}</Menu.Item>
                    <Menu.Item key="1" onClick={() => this.drawerRef.current.duplicate(record)}>{Labels.get("Duplicate")}</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.delete(record)}>{Labels.get("Delete")}</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        return (
        <>
            <DrawerTableForm columns={columns} ref={this.drawerRef} form={null} type="templates" load="templates" />
        </>
        );
    }
}

export default Templates;