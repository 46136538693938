import React, { Component } from 'react';
import { Select, Modal, Button, Form, Input, Row, Col } from 'antd';

import Zenfy from "../libs/Zenfy";
import ContactForm from './ContactForm';

class ContactModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showModal: props.showModal,
            modalLoading: false,
        }

        this.form = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.showModal !== this.state.showModal){

            this.setState({
                showModal: nextProps.showModal
            });

        }

    }

    addContact = (values) => {
        values.org_id = localStorage.orgId ? localStorage.orgId : 2;

        Zenfy.post('contacts', values).then((res) => {
            
            if(this.props.afterAddCallBack){
                this.props.afterAddCallBack(res.data);
            }

            values.id = res.data;
        });
    }

    handleModalOk = () => {
        this.form.current.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.addContact(values);
            }
        });
    }

    showModal = () => {
        this.setState({
            showModal: true,
        });
    }

    render() {

        return (
            <Modal
                title="Add new contact"
                visible={this.state.showModal}
                onOk={this.handleModalOk}
                okText="Add"
                onCancel={()=> {
                    if(this.props.afterAddCallBack){
                        this.props.afterAddCallBack();
                    }
                    
                    this.setState({
                        showModal: false,
                    });
                }}
                okButtonProps={{loading: this.state.modalLoading}}
            >   
                <ContactForm ref={this.form} showCompany contact={{}} />
            </Modal>  
        );

    }
}

export default ContactModal;