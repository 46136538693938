import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select
} from 'antd';
import moment from 'moment';
import SelectFromUrl from '../components/SelectFromUrl';
import Zenfy from "../libs/Zenfy";

import ProductModal from '../components/ProductModal';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

class NormalProductForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: props.product,
            loading: false,
            showNewProductModal: false,
            productsKey: Math.random(),
        }

        this.quantity = this.state.product && this.state.product.quantity ? this.state.product.quantity : 0;
        this.unit = this.state.product && this.state.product.unit ? this.state.product.unit : 0;

        this.templatesRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.product.id !== this.state.product.id){

            this.props.form.resetFields();

            this.setState({
                product: nextProps.product
            });

        }

    }

    afterAddCallBack = (data) => {
        this.newProductId = data;

        console.log(data);

        this.productOnChange(data);

        this.setState({
            showNewProductModal: false,
            productsKey: Math.random(),
        });
    }

    productsAfterLoad = (products) => {

        // getting prices
        if(this.props.order && this.props.order.id){
            Zenfy.get(`${localStorage.orgId}/prices?clientId=${this.props.order.client_id}`).then((res) => {
                
                
                if(res.data.length > 0){

                    let items = [];

                    res.data.map((priceItem) => {
                        let found = false;
                        
                        this.templatesRef.current.state.items.map((item) => {
                            if(priceItem.name == item.name){
                                found = true;
                                item.price = priceItem.price;
                            }
                        });
                        
                        if(!found){
                            items.push(priceItem);
                        }
                    });
                    
                    items = [...items, ...this.templatesRef.current.state.items];

                    this.templatesRef.current.changeState({
                        items
                    });
    
                }
                
                
            });
        }
        

        if(this.newProductId){
            let product = this.state.product;

            let selectedProduct = products.find((product) => {
                return product.id == this.newProductId;
            });

            product.amount = selectedProduct.price;
            product.description = selectedProduct.name;

            product.product_id = parseInt(this.newProductId); // for some reason it was reading as a string
            this.setState({
                product,
            });

            this.newProductId = null;
        }
    }

    productOnChange = (item) => {

        if(!item){
            item = {};
        }

        if(this.props.order && item.name){
            if(item.name.toLowerCase().indexOf('zone 1') !== -1 && this.props.order.zone1 > 0){
                item.price = this.props.order.zone1;
            }else if(item.name.toLowerCase().indexOf('zone 2') !== -1 && this.props.order.zone2 > 0){
                item.price = this.props.order.zone2;
            }else if(item.name.toLowerCase().indexOf('zone 3') !== -1 && this.props.order.zone3 > 0){
                item.price = this.props.order.zone3;
            }else if(item.name.toLowerCase().indexOf('port fees') !== -1 && this.props.order.port_fees > 0){
                item.price = this.props.order.port_fees;
            }

        }

        this.setState((prevState, props) => ({
            product: {
                ...prevState.product,
                amount: item.price == 0 ? undefined : item.price,
                description: item.name,
                fuel: item.fuel == 0 ? undefined : item.fuel,
                html: item.desc,
                //ends_at: item.months > 0 ? moment().add(item.months, 'months').format('YYYY-MM-DD HH:mm:ss') : undefined,
            }
        }));
    }

    recurringOnChange = (value) => {
        let product = this.state.product;

        product.recurring = value.target.checked ? 1 : 0;

        this.setState({
            product
        });
    }

    numberChange = (e) => {

        if(e.target.name == 'quantity'){
            this.quantity = e.target.value;
        }else if(e.target.name == 'unit'){
            this.unit = e.target.value;
        }

        let product = this.state.product;
        product.amount = this.quantity * this.unit;

        this.setState({
            product
        });

        this.props.form.setFieldsValue({
            amount: product.amount
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>

                <Form className="order-form">
                
                    <Form.Item
                        {...formItemLayout}
                        label="Product Template"
                        >
                        {getFieldDecorator('product_id', {
                            initialValue: this.state.product.product_id == 0 ? undefined : this.state.product.product_id,
                            rules: [
                                { required: false, message: 'Product is required' }
                            ],
                        })(
                            <SelectFromUrl 
                                ref={this.templatesRef}
                                key={this.state.productsKey} 
                                category="category"
                                itemChange={this.productOnChange} 
                                url={`${localStorage.orgId ? localStorage.orgId : 1}/products`} 
                                idName="id" 
                                name="name" 
                                afterLoad={this.productsAfterLoad}
                            />
                        )}
                    </Form.Item>

                    <a href="javascript:;" style={{display: 'inherit', marginTop: '-26px', float: 'right'}} onClick={() => {
                        this.setState({
                            showNewProductModal: true,
                        });
                    }}>Add new product template</a>

                    <Form.Item
                        {...formItemLayout}
                        label="Title"
                        >
                        {getFieldDecorator('description', {
                            initialValue: this.state.product.description ? this.state.product.description : this.state.product.name,
                            rules: [
                                {
                                    required: false,
                                    message: 'Title',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Description"
                        >
                        {getFieldDecorator('html', {
                            initialValue: this.state.product.html,
                            rules: [
                                {
                                    required: false,
                                    message: 'Description is required',
                                },
                            ],
                        })(
                            <Input.TextArea />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Quantity"
                        >
                        {getFieldDecorator('quantity', {
                            initialValue: this.state.product.quantity == 0 ? "" : this.state.product.quantity,
                        })(
                            <Input onChange={this.numberChange} name="quantity" /*addonAfter={<Select defaultValue="" style={{ width: 80 }}>
                                <Option value=""></Option>
                                <Option value="$">$</Option>
                                <Option value="h">h</Option>
                            </Select>}*/ />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Unit Price"
                        >
                        {getFieldDecorator('unit', {
                            initialValue: this.state.product.unit == 0 ? "" : this.state.product.unit,
                        })(
                            <Input onChange={this.numberChange} name="unit" />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Total"
                        >
                        {getFieldDecorator('amount', {
                            initialValue: this.state.product.amount,
                            rules: [
                                {
                                    required: true,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    
                    {localStorage.type == 'crm' ? null : (
                        <Form.Item
                            {...formItemLayout}
                            label="Fuel Surcharge"
                            >
                            {getFieldDecorator('fuel', {
                                initialValue: this.state.product.fuel,
                                rules: [
                                    {
                                        required: false,
                                        message: 'Amount is required',
                                    },
                                ],
                            })(
                                <Input addonAfter="%" />
                            )}
                        </Form.Item>
                    )}
                    
                    <Form.Item
                        {...formItemLayout}
                        label="Apply Taxes"
                        >
                        {getFieldDecorator('tax', {
                            initialValue: this.state.product.tax,
                            valuePropName: 'checked',
                            rules: [
                                {
                                    required: false,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Checkbox />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Recurring"
                        >
                        {getFieldDecorator('recurring', {
                            initialValue: this.state.product.recurring,
                            valuePropName: 'checked',
                            rules: [
                                {
                                    required: false,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Checkbox onChange={this.recurringOnChange} />
                        )}
                    </Form.Item>
                    
                    {this.state.product.recurring == 1 ? (
                        <>
                            <Form.Item
                                {...formItemLayout}
                                    label="Next Period"
                                >
                                {getFieldDecorator('next_at', {
                                    initialValue: this.state.product.next_at  ? moment(this.state.product.next_at, "YYYY-MM-DD HH:mm:ss") : moment().add(1, 'months'),
                                    rules: [
                                        { required: false}
                                    ]
                                })(
                                    <DatePicker />
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                    label="End Date"
                                    help="Leave empty if never ending recurrence."
                                >
                                {getFieldDecorator('ends_at', {
                                    initialValue: this.state.product.ends_at  ? moment(this.state.product.ends_at, "YYYY-MM-DD HH:mm:ss") : null,
                                    rules: [
                                        { required: false}
                                    ]
                                })(
                                    <DatePicker />
                                )}
                            </Form.Item>
                        </>
                    ) : null}

                    {localStorage.url == 'cid' ? (
                        <>
                            <Form.Item
                                {...formItemLayout}
                                label="Certificat"
                                >
                                {getFieldDecorator('certificat', {
                                    initialValue: this.state.product.certificat,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Amount is required',
                                        },
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="Cohorte"
                                >
                                {getFieldDecorator('cohorte', {
                                    initialValue: this.state.product.cohorte,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Amount is required',
                                        },
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="Accompagnement"
                                >
                                {getFieldDecorator('acco', {
                                    initialValue: this.state.product.acco,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Amount is required',
                                        },
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </>
                    ) : null}
                    
                </Form>
                
                <ProductModal showModal={this.state.showNewProductModal} afterAddCallBack={this.afterAddCallBack} />
            </>
        );
    }
}

const WrappedOrderForm = Form.create({ name: 'order_product_form' })(NormalProductForm);

export default WrappedOrderForm;
