import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Modal, Badge } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";

class Shippers extends Component {

    constructor(props) {
        super(props);

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    afterSave = (data) => {
        if(data.address && data.address != ''){
            Zenfy.getLatLng(`${data.address}, ${data.city}, ${data.state} ${data.postal}, Canada`).then((res) => {
                if(res.data.results && res.data.results[0]){
                    let postData = res.data.results[0].geometry;
                    postData.id = data.id;
                    postData.org_id = data.org_id;
    
                    Zenfy.post('clients', postData);
                }
            });
        }   
    }

    delete = (record) => {

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this customer?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`clients/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });
        
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: true,
        },{
            title: 'Contact Name',
            dataIndex: 'contact',
            key: 'contact',
        },{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },{
            title: 'Phone',
            dataIndex: 'telephone',
            key: 'telephone',
        },{
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            className: 'hide',
            //type: 'address',
        },{
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            className: 'hide',
        },{
            title: 'ZIP/Postal Code',
            dataIndex: 'postal',
            key: 'postal',
            className: 'hide',
        },{
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            className: 'hide',
        },{
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            className: 'hide',
        },{
            title: 'Lat',
            dataIndex: 'lat',
            key: 'lat',
            className: 'hide',
        },{
            title: 'Lng',
            dataIndex: 'lng',
            key: 'lng',
            className: 'hide',
        },{
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
            type: 'switch',
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
          }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm 
                columns={columns} 
                ref={this.drawerRef} 
                form={null} 
                type="shipper" 
                load="clients?is_customer=0" 
                afterSave={this.afterSave} 
            />
        </>
        );
    }
}

export default Shippers;