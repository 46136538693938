import React, { Component } from "react";

import {
    Layout, Menu
} from 'antd';

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import Campaigns from './Campaigns';
import Forms from './Forms';

const { SubMenu } = Menu;
const {
  Header, Content, Footer, Sider,
} = Layout;

class Marketing extends Component {

    constructor(props) {
        super(props);

        const url = props.location.pathname.split('/');

        this.state = {
            selectedTab: url[url.length-1]//url.length > 3 ? url[url.length-1] : 'jobs'
        }
    }

    render() {
        return (
            <>
                <Layout style={{ padding: '24px 0', background: '#fff' }}>

                    <HashRouter>
                        <Sider width={200} style={{ background: '#fff'}}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={this.state.selectedTab}
                                style={{ height: '100%' }}
                            >
                                <Menu.Item key="automations"><NavLink to={`/marketing/automations`}>Automations</NavLink></Menu.Item>
                                <Menu.Item key="campaigns"><NavLink to={`/marketing/campaigns`}>Campaigns</NavLink></Menu.Item>
                                <Menu.Item key="notes"><NavLink to={`/marketing/notes`}>Forms</NavLink></Menu.Item>
                                <Menu.Item key="events"><NavLink to={`/marketing/events`}>Events</NavLink></Menu.Item>
                                <Menu.Item key="newsletters"><NavLink to={`/marketing/newsletters`}>Newsletters</NavLink></Menu.Item>
                                <Menu.Item key="posts"><NavLink to={`/marketing/posts`}>Blog</NavLink></Menu.Item>
                            </Menu> 
                        </Sider>
                    </HashRouter>

                    <Content style={{ padding: '0 24px' }}>
                        <Route exact path={`/marketing/campaigns`} component={Campaigns} />
                        <Route exact path={`/marketing/notes`} component={Forms} />
                    </Content>

                </Layout>
            </>
        )
    }
}

export default Marketing;