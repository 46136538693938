import React, { Component } from "react";
import {
Form, Input, Tabs, DatePicker, Select, Avatar, Upload, Button, Icon, notification, Checkbox, Modal, InputNumber 
} from 'antd';
import moment from 'moment';
import Invoices from '../components/Invoices';
import Transactions from '../components/Transactions';
import Contract from '../components/Contract';
import Address from '../components/Address';
import Zenfy from "../libs/Zenfy";
import Webcam from "react-webcam";
import Attendances from "./Attendances";
import CCCard from '../components/CreditCard';
import SelectFromUrl from './SelectFromUrl';

import Labels from '../libs/Labels';

const { Option } = Select;

class NormalClientForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: props.client,
            loading: false,
            fileList: [],
            showWebCamModal: false,
            webCamModalLoading: false,
            showCreditCardModal: false,
            creditCardLoading: false,
            showChildModal: false,
            childLoading: false,
        }

        this.transactionsRef = React.createRef();
        this.invoiceRef = React.createRef();
        this.webCamRef = React.createRef();
        this.ccRef = React.createRef();
        window.clientForm = this;
        this.contacts = [];
        this.contactIds = [];
        this.creatingCient = false;
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.client.id !== this.state.client.id && !this.props.public){

            this.props.form.resetFields();
            
            this.setState({
                fileList: [],
                client: nextProps.client
            });

        }

    }

    handleUploadChange = info => {
        let fileList = [...info.fileList];
    
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
    
        this.setState({ fileList });
    };

    addClient = (data, callback) => {

        this.setState({
          loading: true
        });

        data.id = this.state.client.id ? this.state.client.id : 0;
        data.org_id = localStorage.orgId ? localStorage.orgId : 1;

        if(this.state.fileList && this.state.fileList.length > 0){
            data.picture = `${Zenfy.getRoot()}uploads/${this.state.fileList[0].response}`;
        }

        if(data.picture && data.picture.file){
            data.picture = `${Zenfy.getRoot()}uploads/${data.picture.file.response}`;
        }

        if(data.address && data.address.address && Zenfy.isType('object', data.address) && !data.address.id){
            data = {...data, ...data.address};
        }else{
            delete data.address;
        }

        if(data.emergency_name && data.emergency_name.emergency_name && Zenfy.isType('object', data.emergency_name) && !data.emergency_name.id){
            data = {...data, ...data.emergency_name};
        }else{
            delete data.emergency_name;
        } 

        if(data.child_name && data.child_name.child_name && Zenfy.isType('object', data.child_name) && !data.child_name.id){
            data.child_name.child_dob = data.child_name.child_dob.format('YYYY-MM-DD HH:mm');
            data = {...data, ...data.child_name};
        }else{
            delete data.child_name;
        }

        if(this.props.public){
            if(localStorage.Lang){
                data.lang = localStorage.Lang;
            }
        }

        Zenfy.post('clients', data).then((res) => {

            let client = this.state.client;
            client.id = res.data;
            client.picture = data.picture;

            // check for contacts
            this.contacts.map((contact) => {
                if(!contact.client_id || contact.client_id == 0){
                    Zenfy.post('contacts', {
                        id: contact.id,
                        client_id: client.id
                    });
                }

                if(data.contact_id == contact.id){
                    client.child_name = contact.name;
                }
            });

            this.setState({
                client,
                loading: false,
            });

            if(!this.props.public){
                notification.success({
                    message: `Member saved with success!`,
                    placement: 'bottomLeft',
                });
            }

            
            if(this.props.drawer){
            //    this.props.drawer.current.hideDrawer();
            }

            if(this.props.reLoad){
                this.props.reLoad(res.data);
            }

            if(callback){
                callback(res);
            }
        })

    }

    handleSubmit = (e, callback, errorCallBack) => {
        if(e){
            e.preventDefault();
        }

        this.props.form.validateFieldsAndScroll((err, values) => {

            /*if(this.props.isChild && !values.child_name || (this.props.isChild && values.child_name && !values.child_name.child_name)){
                this.props.form.setFields({
                    child_name: {
                        errors: [new Error('Child is required')],
                    },
                });

                err = true;
            }*/

            if (!err) {
                this.addClient(values, callback);
            }else{
                if(errorCallBack){
                    errorCallBack();
                }
            }
        });
    }

    reLoad = () => {
        if(this.props.reLoad){
            this.props.reLoad();
        }
    
        if(this.transactionsRef.current){
            this.transactionsRef.current.load();
        }
        
        if(this.invoiceRef.current){
            this.invoiceRef.current.load();
        }
    }

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();
        formData.append('file', file);

        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);
        });
    }

    nameOnChange = (e) => {
        if(e.target.value){
            let name = e.target.value;
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    name
                },
            }));
        }
    }
    
    emailOnChange = (e) => {
        if(e.target.value){
            let email = e.target.value;
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    email
                },
            }));

            if(Zenfy.validateEmail(email) && this.props.public){
                this.creatingCient = false;
                Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/clients`, {
                    email: email,
                    id: this.state.client.id
                }).then((res) => {
                   // this.creatingCient = false;
                    if(res.data && res.data.hasAccount){
                        this.setState({
                            clientExist: true,
                        });
                    }else{
                        this.setState({
                            clientExist: false,
                        });
                    }

                    setTimeout(() => {
                        // creating / updating the client
                        if(!this.creatingCient){
                            this.creatingCient = true;
                            Zenfy.post('clients', {
                                email: email,
                                org_id: localStorage.orgId,
                                id: this.state.client.id,
                            }).then((res) => {
                                this.creatingCient = false;
                                let id = res.data;
                                this.setState((prevState) => ({
                                    client: {
                                        ...prevState.client,
                                        id
                                    },
                                }));
                            });
                        }
                    }, 500);
                    
                });
            }
            
        }
    }

    handleWebCamModalOk = () => {
        const imageSrc = this.webCamRef.current.getScreenshot();

        let formData = new FormData();
        formData.append('file', imageSrc);

        this.setState({
            webCamModalLoading: true,
        })

        Zenfy.post('uploadfile', formData).then(res => {

            let client = this.state.client;
                client.picture = `${Zenfy.getRoot()}uploads/${res.data}`;

            this.setState({
                client,
                showWebCamModal: false,
                webCamModalLoading: false,
            });

        });
    }

    childOnChange = (e) => {

        /*let client = this.state.client;
            client.childs = e;

        this.setState({
            client
        });*/

        if(e.child_name){
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    ...e
                },
            }));
        }

    }

    handleCreditCardSave = () => {
        this.ccRef.current.handleOk(0, (res) => {
            this.setState({
                showCreditCardModal: false,
            });
        }, () => {

        }, true);
    }
    
    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 4,
              },
            },
        };


        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>
                {!this.props.public ? (
                    <Avatar size={100} icon="user" style={{marginBottom: '20px'}} src={this.state.client.picture} />
                ) : null}

                <Modal
                     title={Labels.get("Credit Card")}
                     visible={this.state.showCreditCardModal}
                     okText={Labels.get("Save")}
                     onOk={this.handleCreditCardSave}
                     onCancel={()=> this.setState({
                        showCreditCardModal: false,
                     })}
                     okButtonProps={{loading: this.state.creditCardLoading}}
                >
                    <CCCard 
                        ref={this.ccRef}
                        client={this.state.client} 
                        isAdmin 
                    />
                </Modal>

                <Modal
                     title={Labels.get("Capture")}
                     visible={this.state.showWebCamModal}
                     onOk={this.handleWebCamModalOk}
                     okText={Labels.get("Capture")}
                     onCancel={()=> this.setState({
                        showWebCamModal: false,
                     })}
                     okButtonProps={{loading: this.state.webCamModalLoading}}
                >
                    <Webcam
                        audio={false}
                        height={465}
                        ref={this.webCamRef}
                        screenshotFormat="image/jpeg"
                        style={{width: '100%'}}
                        videoConstraints={{
                            width: 800,
                            height: 800,
                            facingMode: "user"
                        }}
                    />
                </Modal>
                
                <Tabs defaultActiveKey="1" className={this.props.public ? 'public' : ''}>
                    <TabPane tab="Basics" key="1">

                        <Form onSubmit={this.handleSubmit} className="team-form">

                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Email")}
                            >
                            {getFieldDecorator('email', {
                                initialValue: this.state.client.email,
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail',
                                      },
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                ],
                            })(
                                <Input onChange={this.emailOnChange} />
                            )}
                        </Form.Item>

                        {this.state.clientExist ? (
                            <span style={{marginTop: '-26px', display: 'inherit', textAlign: 'left', color: 'red'}}>{Labels.get("Email already exists - if you are not creating a new account, do you want to ")}
                            <a href="javascript:;" style={{textDecoration: 'underline'}} onClick={() => {this.props.logIn(this.state.client.email)}}>{Labels.get("Sign In")}</a>?</span>
                        ) : null}    
                        
                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Name")}
                            >
                            {getFieldDecorator('name', {
                                initialValue: this.state.client.name,
                                rules: [
                                    { required: true, message: 'Name is required' }
                                ],
                            })(
                                <Input onChange={this.nameOnChange} />
                            )}
                        </Form.Item>

                        <Form.Item {...formItemLayout}
                            label={Labels.get("Gender")}>
                            {getFieldDecorator('gender', {
                                rules: [{ required: true, message: 'Please select your gender' }],
                                initialValue: this.state.client.gender,
                            })(
                                <Select
                                    placeholder={Labels.get("Select a gender")}
                                >
                                    <Option value="m">{Labels.get("Male")}</Option>
                                    <Option value="f">{Labels.get("Female")}</Option>
                                    <Option value="c">{Labels.get("Prefer not to answer")}</Option>
                                </Select>,
                            )}
                        </Form.Item>

                        {!this.props.isChild ? (
                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Date of birth")}
                            >
                            {getFieldDecorator('dob', {
                                initialValue: this.state.client.dob ? moment(this.state.client.dob, "YYYY-MM-DD HH:mm:ss") : null,
                                rules: [
                                    {
                                        required: this.props.public,
                                    },
                                ],
                            })(
                                <DatePicker />
                            )}
                        </Form.Item>) : null}

                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Phone")}
                            >
                            {getFieldDecorator('telephone', {
                                initialValue: this.state.client.telephone,
                                rules: [
                                    {
                                        required: false,
                                        message: 'Phone is required',
                                    },
                                ],
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        
                        {!this.props.isDigital ? (
                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Picture")}
                            >
                            {getFieldDecorator('picture', {
                                initialValue: this.state.client.picture,
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                            })(
                                <>
                                    <Upload name="picture" onChange={this.handleUploadChange} customRequest={this.uploadFile} showUploadList={true} fileList={this.state.fileList} >
                                        <Button>
                                            <Icon type="upload" /> {Labels.get("Click to Upload")}
                                        </Button>
                                    </Upload>
                                    <Button className="capture" onClick={() => {
                                        this.setState({
                                            showWebCamModal: true,
                                        });
                                    }}>
                                        <Icon type="picture" /> {Labels.get("Capture")}
                                    </Button>
                                </>
                            )}
                        </Form.Item>) : null}
                        
                        
                        {!this.props.public ? (
                            <Form.Item
                                {...formItemLayout}
                                label={Labels.get("Registration date")}
                                >
                                {getFieldDecorator('reg_at', {
                                    initialValue: this.state.client.reg_at ? moment(this.state.client.reg_at, "YYYY-MM-DD HH:mm:ss") : this.state.client.created_at ? moment(this.state.client.created_at, "YYYY-MM-DD HH:mm:ss") : moment(),
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <DatePicker />
                                )}
                            </Form.Item>
                        ) : null}

{                       this.props.isChild || !this.props.public ? (
                            <Form.Item
                                {...formItemLayout}
                                label="Child"
                                >
                                {getFieldDecorator('contact_id', {
                                    initialValue: this.state.client.contact_id == 0 ? undefined : this.state.client.contact_id,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <SelectFromUrl 
                                        afterSave={(id) => {
                                            this.contactIds.push(id);
                                        }}
                                        key={this.state.client.id}
                                        onChange={(value) => {
                                            this.contacts.map((contact) => {
                                                if(value == contact.id){  
                                                    let client = this.state.client; 
                                                    client.child_name = contact.name;
                                                    client.contact_id = value;
                                                    this.setState({
                                                        client
                                                    });
                                                }
                                            });
                                        }}
                                        url={`${localStorage.orgId ? localStorage.orgId : 2}/contacts?client_id=${this.state.client.id}&type=child`} 
                                        idName="id" 
                                        type="contacts"
                                        extraPost={{
                                            type: 'child'
                                        }}
                                        name="name" 
                                        addText="Add child"
                                        placeholder="Select a child or add a child below"
                                        keys={
                                            [{
                                                title: Labels.get('Name'),
                                                dataIndex: 'name',
                                                key: 'name',
                                                required: true,
                                            },{
                                                title: Labels.get('Gender'),
                                                dataIndex: 'gender',
                                                key: 'gender',
                                                type: 'gender',
                                                required: false,
                                            },{
                                                title: Labels.get('Date of birth'),
                                                dataIndex: 'dob',
                                                key: 'dob',
                                                type: 'date',
                                                required: false,
                                            },{
                                                title: Labels.get('Health Problems / Comments'),
                                                dataIndex: 'conditions',
                                                key: 'conditions',
                                                type: 'text',
                                                required: false,
                                            }]
                                        }
                                        row={this.state.client}
                                        preLoad={(items) => {
                                            if(!this.state.client.id){
                                                let newItems = [];
                                                items.map((item) => {
                                                    if(this.contactIds.includes(item.id)){
                                                        newItems.push(item);
                                                    }
                                                });
                                                items = newItems;
                                            }

                                            return items;
                                        }}
                                        afterLoad={(items) => {
                                            this.contacts = items;

                                            if(this.state.client && this.state.client.contact_id){
                                                items.map((contact) => {
                                                    if(contact.id == this.state.client.contact_id){
                                                        let client = this.state.client;
                                                            client.child_name = contact.name;

                                                            this.setState({
                                                                client
                                                            });
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                )}
                            </Form.Item>
                        ) : null}
                        
                        {!this.props.isDigital ? (
                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Address")}
                            >
                            {getFieldDecorator('address', {
                                initialValue: this.state.client,
                                rules: [
                                    {
                                        required: this.props.public,
                                        message: 'Address is required',
                                    },
                                ],
                            })(
                                <Address type="address" />
                            )}
                        </Form.Item>
                        ) : null}
                        
                        {!this.props.isDigital ? (
                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Emergency")}
                            >
                            {getFieldDecorator('emergency_name', {
                                initialValue: this.state.client,
                                rules: [
                                    {
                                        required: this.props.public,
                                        message: 'Emergency contact is required',
                                    },
                                ],
                            })(
                                <Address type="emergency" />
                            )}
                        </Form.Item>
                        ) : null}

                        <Form.Item
                            {...formItemLayout}
                            label={this.props.isDigital ? "Comments" : Labels.get("Health Problems / Comments")}
                            >
                            {getFieldDecorator('health', {
                                initialValue: this.state.client.health,
                                rules: [
                                    {
                                        required: false,
                                        message: 'Phone is required',
                                    },
                                ],
                            })(
                                <TextArea />
                            )}
                        </Form.Item>
                        
                        {!this.props.isDigital && (localStorage.orgId == 1 || this.props.contract || this.state.client.contract) ? (
                            <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Contract")}
                            >
                            {getFieldDecorator('contract', {
                                initialValue: this.state.client.contract,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Contract signature is required',
                                    },
                                ],
                            })(
                                <Contract client={this.state.client} contractHtml={this.props.contract} fileName="contract_" />
                            )}
                        </Form.Item>
                        ) : null}

                        {!this.props.isDigital && (this.props.covid || this.state.client.covid) ? (
                            <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Covid")}
                            >
                            {getFieldDecorator('covid', {
                                initialValue: this.state.client.covid,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Covid signature is required',
                                    },
                                ],
                            })(
                                <Contract client={this.state.client} contractHtml={this.props.covid} fileName="covid_" />
                            )}
                        </Form.Item>
                        ) : null}
                        
                        {!this.props.isDigital && localStorage.orgId != 23 ? (
                            <Form.Item 
                            {...tailFormItemLayout}
                        >
                            {getFieldDecorator('no_social_media', {
                                valuePropName: 'checked',
                                initialValue: this.state.client.no_social_media == 1,
                            })(
                                <Checkbox>
                                    {Labels.get("Don't appear on social media nor on pictures")}
                                </Checkbox>,
                            )}
                        </Form.Item>
                        ) : null}

                        {!this.props.public && this.state.client.id ? (
                        <Form.Item
                            {...formItemLayout}
                            label={Labels.get("Credit Card")}
                            >
                            {getFieldDecorator('customer_id', {
                                initialValue: this.state.client.customer_id,
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                            })(
                                <>
                                    <Button onClick={() => {
                                            this.setState({
                                                showCreditCardModal: true,
                                            });
                                        }}>
                                        {Labels.get("Add / Edit")}
                                    </Button>
                                </>
                            )}
                        </Form.Item>) : null}

                        {!this.props.public ? (
                             <Form.Item
                                {...formItemLayout}
                                label={Labels.get("Discount")}
                                help="A pourcentage of discount that will be applied on every reaccuring invoice"
                                >
                                {getFieldDecorator('discount', {
                                    initialValue: this.state.client.discount,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Discount is required',
                                        },
                                    ],
                                    
                                })(
                                    <InputNumber  max={100} />
                                )}
                            </Form.Item>
                        ) : null}
                        
                        <Form.Item>
                            
                            {!this.props.public ? (<>
                            <Button type="primary" htmlType="submit" className="pull-right" loading={this.state.loading}>
                                {!this.props.public ? 'Save' : 'Register' }
                            </Button>

                                <a href="javascript:;" className="pull-right" style={{margin: '-4px 20px 0px 0px'}} onClick={() => this.props.drawer.current.hideDrawer()} >Close</a>
                            </>) : null}

                        </Form.Item>

                    </Form>
                    </TabPane>
                    {!this.props.public ? (
                        <TabPane tab="Invoices" key="2">
                            <Invoices reLoad={this.reLoad} ref={this.invoiceRef} client={this.state.client}  key={this.state.client.id} />
                        </TabPane>
                    ) : null}          
                    {!this.props.public ? (
                        <TabPane tab="Transactions" key="3">
                            <Transactions clientId={this.state.client.id} reLoad={this.reLoad} ref={this.transactionsRef} key={this.state.client.id} />        
                        </TabPane>
                    ) : null}      
                    {!this.props.public ? (
                        <TabPane tab="Attendances" key="4">
                            <Attendances client={this.state.client} reLoad={this.reLoad} key={this.state.client.id} />        
                        </TabPane>
                    ) : null}     
                </Tabs>
            </>
        );
    }
}

const WrappedClientForm = Form.create({ name: 'client_form' })(NormalClientForm);

export default WrappedClientForm;
