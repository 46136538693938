import React, { Component } from "react";
import ClientForm from "../components/ClientForm";

import { Steps, Button, message, Row, Col, Select, Divider, Modal, DatePicker, Input, Alert } from 'antd';

import SelectFromUrl from '../components/SelectFromUrl';
import Zenfy from "../libs/Zenfy";
import CreditCard from '../components/CreditCard';
import PayForm from '../components/PayForm';
import moment from 'moment';

import Labels from '../libs/Labels';

const { Step } = Steps;

class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      productId: undefined,
      product: {},
      org: {},
      invoice: {},
      loading: false,
      client: {},
      amount: 0,
      showLoginModal: false,
      modalLoginLoading: false,
      email: '',
      error: null,
      result: null,
      day: '',
      year: '',
      month: '',
      showPasswordInput: false,
      showVerficationInput: false,
      allowOkModal: true,
      disableVerification: false,
      signInBtnText: Labels.get("Sign in"),
      showForgotPw: true,
    }

    this.newProductId = null;
    this.invoiceId = null;
    this.leet = false;
    this.payForm = React.createRef();
    this.productsSelect = React.createRef();

    let orgUrl = this.props.match.params.org;

    if(!orgUrl){
      this.props.history.push(`/carlsongraciemtl/register`);
      window.location.reload();
    }
    
    if(orgUrl == 'carlsongraciemtl'){
      localStorage.orgId = 1;
    }else if(orgUrl == 'elf' || orgUrl == 'undefined'){
      localStorage.orgId = 3;
      window.org = {};
      window.org.name = 'ELF';
      orgUrl = 'elf';
    }else if (orgUrl == 'ippon') {
      window.location.replace("https://activitymessenger.com/org/ippon");
    }

    Zenfy.get(`orgs/${orgUrl}`).then((res) => {
      this.setState({
        org: res.data
      });

      if(res.data.id){
        localStorage.orgId = res.data.id;
        window.org = res.data;

        if(window.org.cc_desc){
          localStorage.cc_desc = window.org.cc_desc;
        }
      }
    });
  }

  componentDidMount() {

    let language = window.navigator.userLanguage || window.navigator.language;

    if(language && !localStorage.Lang){
      language = language.substring(0,2);
      localStorage.Lang = language;
    }

    let token = Zenfy.getParameterByName('token');

    if(token){
      token = window.atob(token).split('|');

      if(token[2] && token[2] != ''){
        /*this.setState({
          productId: parseInt(token[2])
        });*/
        this.newProductId = parseInt(token[2]);
      }

      Zenfy.get(`${localStorage.orgId}/clients/${token[0]}`, {
        email: token[1]
      }).then((res) => {
        this.setState({
          client: res.data
        })
      });

    }

    let product = Zenfy.getParameterByName('product');

    if(product){
      if(product == 1337){
        this.leet = true;
      }
      this.newProductId = parseInt(product);
    }
    

    if(localStorage.zenfy_jwt == null && localStorage.client_email && localStorage.client_password && localStorage.orgId != 1){
      Zenfy.post(`${localStorage.orgId}/clientaccount`, {
        email: localStorage.client_email,
        password: localStorage.client_password,
      }).then((res) => {
        if(res.data){
          if(res.data.password){
            this.setState({
              client: res.data
            });
          }
        }
      });
    }
  }

  next() {
    const current = this.state.current + 1;

    if(current == 1){
        
      if(!this.state.productId){ // no product
          this.setState({
            current: 0
          });
          message.error(Labels.get("Chose a class first!"));
        }else{
          /*if(!this.state.client.id){ // client not connected
            this.setState({
              showLoginModal: true,
              current: 1
            });
          }else{*/
            this.setState({
              current: 1
            });
          //}
      }

    }else if(current == 2){
      
      this.setState({
        loading: true,
      });

      window.clientForm.handleSubmit(false, (res) => {

        if(this.state.productId){
          let values = {};
          values.client_id = res.data;
          values.org_id = this.state.product.org_id;
          values.product_id = this.state.productId;

          if(this.state.product.force_monthly && this.state.product.monthly_day && this.state.product.monthly_day != 0){
            let date = new Date();
            let numberOfDaysInAmonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
            
            if(window.monthlyPrice){
              this.state.product.price = window.monthlyPrice;
            }

            window.monthlyPrice = this.state.product.price;
            // how many days
            var currentDay = moment().startOf('day');
            let billingDate = moment().startOf('month').add(this.state.product.monthly_day, 'day');
        
            if(date.getDate() >= this.state.product.monthly_day){
              billingDate = billingDate.add(1, 'month');
            }

            this.state.product.ends_at = billingDate.format('YYYY-MM-DD HH:mm:ss');
            values.ends_at = billingDate.format('YYYY-MM-DD HH:mm:ss');
        
            let daysToBePaid = moment.duration(billingDate.diff(currentDay)).asDays();

            if(daysToBePaid < 30 && daysToBePaid != 0){
              this.state.product.price = (daysToBePaid * this.state.product.price) / numberOfDaysInAmonth;
              this.state.product.price = Zenfy.roundToTwo(this.state.product.price);
            }
            
          }else{
            window.monthlyPrice = this.state.product.price;
          }

          

          let client = window.clientForm.props.form.getFieldsValue();


          //values.amount = this.state.product.price*1 + (this.state.product.price * (0.14975 + 0.036))*1;
          values.amount = this.state.product.price + (this.state.product.taxes == 1 ? this.state.product.price * 0.14975 : 0);
          values.amount = Zenfy.roundToTwo(values.amount);
          values.count = this.state.product.count;

          if(client.contact_id != 0){
            values.contact_id = client.contact_id;
          }
          
          if(this.state.product.months && this.state.product.months > 0 && this.state.product.monthly_day == 0){
            values.ends_at = moment().add(this.state.product.months, 'months').format('YYYY-MM-DD HH:mm:ss');
          }else if(this.state.product.end_at && this.state.product.end_at != '0000-00-00 00:00:00' && !this.state.product.force_monthly){
            values.ends_at = this.state.product.end_at;
          }
          
          if(!this.state.invoice.id){
            Zenfy.post('invoices', values).then((res) => {
                this.invoiceId = res.data;
                values.id = res.data;
                values.balance = this.state.product.price;

                this.setState({
                  invoice: values
                });
                
                if(this.state.product.price == 0){
                  this.postTransaction({
                    client_id: client.id,
                    org_id: client.org_id,
                    invoice_id: this.invoiceId,
                    amount: this.state.product.price,
                    type: "cash"
                  });
                  
                }
            });
          }
          
          
          client.id = res.data;
          client.org_id = this.state.product.org_id;
          client.customer_id = this.state.client.customer_id;

          if(client.address && !client.address.address){
            delete client.address;
          }

          if(client.emergency_name && !client.emergency_name.emergency_name){
            delete client.emergency_name;
          }

          this.setState({
            loading: false,
            current,
            client,
            totalAmount: this.state.product.price * 1 + (this.state.product.price * (0.14975 + 0.036)) * 1,
          });

        }else {
          this.setState({
            loading: false,
          });
          message.success('Error!');
        }

      }, () => {
        this.setState({
          loading: false,
        });
      })

    }else if(current == 3){

      this.setState({
        loading: true,
      });

      this.payForm.current.validateFieldsAndScroll((err, values) => {
          if (!err) {

              values.client_id = this.state.client.id;
              values.org_id = this.state.client.org_id;
              values.invoice_id = this.invoiceId;

              if(!values.amount){
                values.amount = this.state.product.price;
              }

              if(this.state.product.price == 0){
                values.type = 'cash';
              }
              
              //values.amount = this.state.product.taxes == 1 ? this.props.amount * 1 * 0.14975 : 0;

              if(!values.type){
                values.type = 'credit';
              }
              
              if(values.type == 'credit'){

                let creditAmount = values.amount * 1 + (this.state.product.taxes == 1 ? values.amount * 1 * 0.14975 : 0);
                    creditAmount = (Math.round(creditAmount * 100) / 100).toFixed(2);

              //  creditAmount = creditAmount + (creditAmount * 0.04 + 0.3);

                window.creditCardForm.handleOk(creditAmount, (res) => {

                    values.txn_id = res.id;
                    if(this.state.product.force_monthly == 1 || res.recurring == 1){
                      values.recurring = 1;
                    }
                    
                    this.postTransaction(values);

                    /* need to change invoice amount
                    Zenfy.post('invoices', {
                      id: this.invoiceId,
                      amount: creditAmount
                    });*/

                }, () => {
                  this.setState({
                    loading: false,
                  });
                });
              }else{
                this.postTransaction(values);
              }

              /*if(window.creditCard && window.creditCard.current){
                  
              }else{
                  this.postTransaction(values);
              }*/
          }
      });

      /*window.creditCardForm.handleOk(this.state.totalAmount, (res) => {

        Zenfy.post('transactions', {
          client_id: this.state.client.id,
          org_id: localStorage.orgId ? localStorage.orgId : 1,
          invoice_id: this.invoiceId,
          type: 'credit',
          amount: this.state.totalAmount,
          txn_id: res.id,
        }).then((res) => {
          
          message.success('Payement passed with success!');

          setTimeout(() => {
            window.location.reload();
          }, 5000);

        });

      }, () => {

        this.setState({
          loading: false,
        });

      }); */

    }else{
      this.setState({ current });
    }

    
  }

  postTransaction = (values) => {

    values.amount = values.amount + (this.state.product.taxes == 1 ? values.amount * 1 * 0.14975 : 0);
    values.amount = Zenfy.roundToTwo(values.amount);

    Zenfy.post('transactions', values).then((res) => {

      let msg = "";

      if(values.type == 'credit'){
        msg = Labels.get(" Payment passed with success.");
      }

      Modal.success({
        content: (
          <>
            <p>{Labels.get("Thank you")} 🙏{msg} {Labels.get("You should receive an email soon.")}</p>
          </>
        ),
        onOk() {
          
        },
      });

      if(this.state.product.is_digital == 0){
        Zenfy.get(`${values.org_id}/gyminvoice/${values.invoice_id}`, {
          email: true,
        }).then(() => {
          window.location.reload();
        })
      }
    
      /*setTimeout(() => {
         Zenfy.get(`${record.org_id}/gyminvoice/${record.id}`, {
            email: true,
        }).then((res) => {
            notification.success({
                message: `Email sent with success!`,
                placement: 'bottomLeft',
            });
        });
      }, 5000);*/

    });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  productOnChange = (product) => {

    if(!product){
      product = {};
    }

    this.setState({
      product,
    });
  }
  
  onChange = (id) => {
    this.setState({
      productId: id,
    });
  }

  productsAfterLoad = () => {
    if(this.newProductId){
      this.setState({
        productId: this.newProductId
      }, () => {
        this.productsSelect.current.handleChange(this.newProductId);
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, () => {
      if(target.name == 'email' && Zenfy.validateEmail(target.value)){
        this.checkEmail(target.value);
      }else if(target.name == 'verification' && target.value.length > 3){
        this.checkVerification();
      }else if(target.name == 'password' && target.value.length > 1){
        this.setState({
          allowOkModal: false,
        });
      }
    });
  }

  checkVerification = () => {
    Zenfy.post(`${localStorage.orgId}/checkverification`, {
      email: this.state.email,
      verification: this.state.verification
    }).then((res) => {
      if(res.data){
        this.setState({
          showPasswordInput: true,
          success: Labels.get("Please create a new password below. You will be able to easily sign in later."),
          disableVerification: true,
          error: null,
        });
      }else{
        this.setState({
          error: Labels.get("Wrong verification code."),
          success: null,
        });
      }
    });
  }

  checkEmail = (email) => {
    Zenfy.get(`${localStorage.orgId}/clients`, {
      email
    }).then((res) => {

      /*if(!localStorage.zenfy_jwt){
        localStorage.client_email = this.state.email;
      }*/

      if(res){

        if(res.data.newUser){
          this.setState({
            showPasswordInput: true,
            showVerficationInput: false,
            allowOkModal: false,
            success: Labels.get('Create your account below.'),
            error: null,
            showForgotPw: false,
            signInBtnText: Labels.get("Create"),
          });
        }else if(!res.data.hasPw){
          Zenfy.post(`${localStorage.orgId}/verification`,{
            email 
          });

          this.setState({
            showPasswordInput: false,
            showVerficationInput: true,
            allowOkModal: true,
            password: null,
            error: null,
            showForgotPw: false,
            signInBtnText: Labels.get("Sign In"),
            success: Labels.get("We sent you a verification code, please enter it below.")
          });
        }else{
           this.setState({
            showPasswordInput: true,
            showVerficationInput: false,
            allowOkModal: false,
            success: Labels.get("Sign in below."),
            signInBtnText: Labels.get("Sign In"),
            error: null,
          });
        }

      }

      /*if(res && res.data.id){
        this.setState({
          client: res.data,
          showLoginModal: false,
          error: null,
        });
      }else{
        this.setState({
          error: "You don't have the right combination. Please contact the front desk."
        })
      }*/
      
    });
  }

  forgot = () => {
    Zenfy.post(`${localStorage.orgId}/verification`,{
      email: this.state.email 
    });

    this.setState({
      showVerficationInput: true,
      allowOkModal: true,
      error: null,
      showForgotPw: false,
      signInBtnText: Labels.get("Sign In"),
      success: Labels.get("We sent you a verification code, please enter it below.")
    });
  }

  handleModalOk = () => {
    if(this.state.email && this.state.email != '' && this.state.password && this.state.password != ''){

      Zenfy.post(`${localStorage.orgId}/clientaccount`, {
        email: this.state.email,
        password: this.state.password,
        verification: this.state.verification,
      }).then((res) => {
        if(res.data){
          if(res.data.password){
            localStorage.client_email = res.data.email;
            localStorage.client_password = this.state.password;

            this.setState({
              showLoginModal: false,
              client: res.data
            });
          }
        }else{
          this.setState({
            error: Labels.get('Wrong password!'),
            success: false,
          });
        }
      });

      /*Zenfy.get(`${localStorage.orgId}/clients`, {
        email: this.state.email,
        dob
      }).then((res) => {

        if(!localStorage.zenfy_jwt){
          localStorage.client_email = this.state.email;
          localStorage.client_dob = dob;
        }

        if(res.data.id){
          this.setState({
            client: res.data,
            showLoginModal: false,
            error: null,
          });
        }else{
          this.setState({
            error: "You don't have the right combination. Please contact the front desk."
          })
        }
      });*/
    }
  }

  handleScan = data => {
    if (data) {
      console.log(data);
      this.setState({
        result: data
      })
    }
  }

  getDropList = () => {
    const year = new Date('1940-01-01').getFullYear();
    return (
      Array.from( new Array(80), (v,i) =>
        <Select.Option key={i} value={year+i}>{year+i}</Select.Option>
      )
    );
  }

  logout = (e) => {
    e.preventDefault();

    delete localStorage.client_email;
    delete localStorage.client_password;

    this.setState({
      client: {}
    });

    this.forceUpdate();
  }

  render() {
    //localStorage.orgId = 1;
    const { current } = this.state;

    let steps = [
      <>
        {this.state.org.id ? (
          <SelectFromUrl isLeet={this.leet} treeSelect ref={this.productsSelect} afterLoad={this.productsAfterLoad} onChange={this.onChange} value={this.state.productId} placeholder={Labels.get("Choose your class")} style={{margin: '30px 0', width: '50%'}} itemChange={this.productOnChange} url={`${this.state.org.id}/products?active=1&class=true`} idName="id" name="name" category="parent" className="choose-class" />
        ) : null}
      </>
      ,
      <>
        <div style={{textAlign: 'center', margin: '40px 0'}}>
          {!this.state.client.id ? (
            <Button type="primary" onClick={() => {
              this.setState({
                showLoginModal: true,
                showVerficationInput: false,
                password: null,
                email: null,
                error: null,
                success: null,
                verification: null,
              });
            }}>{Labels.get("Sign In")}</Button>
            ) : 
            <span>{Labels.get("You are logged in as")} {this.state.client.name ? this.state.client.name : this.state.client.email} 👌 <a href="javascript:;" style={{textDecoration: 'underline'}} onClick={this.logout}>{Labels.get("Logout")}</a></span>
          }
        </div>
        <Divider style={{fontSize: '14px'}}>{!this.state.client.id ? Labels.get("Don't have an account?") : Labels.get("Feel free to change your information below")}</Divider>
        <ClientForm 
          key={this.state.client.id}
          contract={this.state.product.contract} 
          covid={this.state.product.covid ? this.state.org.covid : false} 
          client={this.state.client} 
          isChild={this.state.product && this.state.product.child == 1 ? true : false} 
          public 
          isDigital={this.state.product ? this.state.product.is_digital : false} 
          logIn={(email) => {
            this.setState({
              showLoginModal: true,
              email,
            }, () => {
              this.checkEmail(email);
            });
          }} 
        />
      </>,
      <>
        <Row>
          <Col xs={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} md={{span: 12, offset: 6}} lg={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
         
            <PayForm cur={this.newProductId && this.newProductId == 1337 ? 'usd' : undefined} invoice={this.state.invoice} client={this.state.client} ref={this.payForm} taxes={this.state.product ? this.state.product.taxes : false} force_monthly={this.state.product ? this.state.product.force_monthly : false} showRecap hideReference hideType={localStorage.zenfy_jwt == null} disabledAmount={localStorage.zenfy_jwt != null} />
            <div style={{ marginBottom: 20 }} />
            
          </Col>
        </Row>
      </>,
      /*<> 
        {this.state.product ? (
          (<Card title={this.state.product.name} bordered style={{ marginBottom: 20, borderRadius: 5 }} size="small">
          <p>Price: <Currency quantity={this.state.product.price} currency="CAD" /></p>
          <p>Taxes (TPS & TVQ): <Currency quantity={this.state.product.price * (0.14975 + 0.036)} currency="CAD" /></p>
          <p>Total: <Currency quantity={this.state.totalAmount} currency="CAD" /></p>
        </Card>)
        ) : null}
        <CreditCard client={this.state.client} />
        <div style={{ marginBottom: 20 }} />
      </>*/
    ];

     /*<QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '0%' }}
      />*/

    document.body.style['touch-action'] = "manipulation";

    return (
      <>
      <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport"/>

      <div className="btn-menu not-active" onClick={this.showSideMenu} style={{display: 'none'}}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      
    <Modal
      title={Labels.get("Sign in")}
      visible={this.state.showLoginModal}
      onOk={this.handleModalOk}
      okText={this.state.signInBtnText}
      cancelText={Labels.get("Cancel")}
      //closable={false}
      //maskClosable={false}
      //keyboard={false}
      //cancelButtonProps={{style: {display: 'none'}}}
      onCancel={()=> this.setState({
        showLoginModal: false,
      })}
      okButtonProps={{
        loading: this.state.modalLoginLoading, 
        disabled: this.state.allowOkModal,
      }}
    >   
        {this.state.error && this.state.error != '' ? (
          <Alert message={this.state.error} type="error" style={{marginBottom: '20px'}} />
        ): null}

        {this.state.success && this.state.success != '' ? (
          <Alert message={this.state.success} type="success" style={{marginBottom: '20px'}} />
        ): null}
        
        <Input
          placeholder={Labels.get("Email")}
          name="email"
          style={{marginBottom: 20}}
          value={this.state.email}
          onChange={this.handleInputChange}
        />

        {this.state.showVerficationInput ? (
          <Input
            placeholder={Labels.get("Verification Code")}
            name="verification"
            style={{marginBottom: 20}}
            value={this.state.verification}
            onChange={this.handleInputChange}
            disabled={this.state.disableVerification}
          />
        ) : null}

        {this.state.showPasswordInput ? (
          <>
            <Input.Password
              placeholder={Labels.get("Password")}
              name="password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
            {this.state.showForgotPw ? (
              <a href="javascript:;" style={{textDecoration: 'underline', display: 'block', marginTop: '5px', fontSize: '14px'}} onClick={this.forgot}>{Labels.get("Forgot your password?")}</a>
            ) : null}
          </>
        ) : null}

    </Modal>

    <Row type="flex" justify="space-around" align="middle" style={{margin: '0px 0 0 0'}}>
      <Col className="gutter-row" span={18}>
        
      <div style={{marginTop: '25px'}}>
        <Steps current={current}>
          <Step title={Labels.get("Choose Your Product")} />
          <Step title={Labels.get("Add Your Information")} />
          <Step title={Labels.get("Add Payment")} />
        </Steps>
        <div className="steps-content" style={{marginTop: '25px'}} >

          {steps[this.state.current]}
      
        </div>
        <div className="steps-action" style={{marginBottom: '20px'}}>
          {current < steps.length - 1 && (
            <Button type="primary" loading={this.state.loading} className="pull-right" onClick={() => this.next()}>
              {Labels.get("Next")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" className="pull-right" loading={this.state.loading} onClick={() => this.next()}>
              {Labels.get("Pay")}
            </Button>
          )}
          {current > 0 && (
            <Button className="pull-left" onClick={() => this.prev()}>
              {Labels.get("Previous")}
            </Button>
          )}
        </div>
      </div>

      </Col>
    </Row>

      </>
    );
  }
}

export default Register;