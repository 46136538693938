import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag, Modal } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";

import Currency from 'react-currency-formatter';

class Products extends Component {

    constructor(props) {
        super(props);

        this.state = {
            roleCategories: [{text: 'Driver', value: 'driver'}, {text: 'Broker', value: 'broker'}, {text: 'Yard', value: 'yard'}]
        }

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    delete = (record) => {
        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this product?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`products/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });
    }

    render() {

        const columns = [{
            title: 'Name/ID',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: true,
        },{
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
            type: 'switch',
        },{
            title: 'Fuel Surcharge',
            dataIndex: 'fuel',
            key: 'fuel',
            sorter: (a, b) => a.amount - b.amount,
            render: (text) => `${text}%`,
        },{
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm columns={columns} ref={this.drawerRef} form={null} type="product" load="products" />
        </>
        );
    }
}

export default Products;