import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag, Tooltip, Modal, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";

import WrappedInvoiceForm from "./InvoiceForm";

import PayForm from '../../components/PayForm';
import EmailModalForm from '../../components/EmailModalForm';

class Invoices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            showModal: false,
            selectedInvoice: {},
            modalLoading: false,
            showEmailModal: false,
        }

        this.drawerRef = React.createRef();
        this.payForm = React.createRef();
        this.emailModalRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);

        this.setState({
            selectedInvoice: record,
        });
    }

    payInvoice = (invoice) => {
        this.setState({
            showModal: true,
            selectedInvoice: invoice,
        })
    }

    sendEmail = (record, e) => {

        if(e){
            e.stopPropagation();
        }

        let form = this.emailModalRef.current.getForm();

        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            var files = [];

            this.setState({
                modalLoading: true,
            });

            // in the future we will save the files
            if(values.files && values.files.fileList.length > 0){
                values.files.fileList.map((file, index) => {
                    
                    Zenfy.post('files', {
                        invoice_id: this.state.selectedInvoice.id,
                        org_id: this.state.selectedInvoice.org_id,
                        file_name: `${Zenfy.getRoot()}uploads/${file.response}`,
                    }).then(res => {
                        if(values.files.fileList.length - 1 == index){
                            console.log('finished');
                        }
                    });
                    
                    files.push(file.response);
                });
            }

            let emailed = this.state.selectedInvoice.emailed + 1;

            Zenfy.post('invoices', {
                id: this.state.selectedInvoice.id, 
                org_id: this.state.selectedInvoice.org_id, 
                client_id: this.state.selectedInvoice.client_id,
                emailed: emailed,
                sent_at: moment().format('YYYY-MM-DD HH:mm:ss')
            });

            let emails = values.email.trim().split(',');

            if(emails.length > 0){
                emails = emails.map((email) => {
                    return email.trim().replace(/\s/g,'');
                });
            }

            let postData = {
                emails,
                include: values.include,
                files,
                email_text: values.email_text,
            };
    
            Zenfy.get(`${localStorage.orgId}/invoice/${this.state.selectedInvoice.id}?email=true`, postData).then(() => {
                this.setState({ 
                    showEmailModal: false,
                    modalLoading: false,
                });

                form.resetFields();

                notification.success({
                    message: `Sent email with success!`,
                    placement: 'bottomLeft',
                });

                Zenfy.post('invoices', {
                    id: this.state.selectedInvoice.id, 
                    org_id: this.state.selectedInvoice.org_id, 
                    client_id: this.state.selectedInvoice.client_id,
                    emailed: emailed,
                    sent_at: moment().format('YYYY-MM-DD HH:mm:ss')
                });

                Zenfy.post('histories', {
                    org_id: this.state.selectedInvoice.org_id, 
                    invoice_id: this.state.selectedInvoice.id,
                    data1: values.email,
                    data5: JSON.stringify(postData),
                    action: 'email',
                });

                this.drawerRef.current.load();
            });

            this.state.selectedInvoice.orders.map((order) => {
                Zenfy.post('orders', {
                    id: order.order_id, 
                    org_id: order.org_id, 
                    client_id: this.state.selectedInvoice.client_id,
                    status: 'invoiced',
                });
            });
        });

        /*Zenfy.get(`2/invoice/${record.id}?email=true`);

        notification.success({
            message: `Sent email with success!`,
            placement: 'bottomLeft',
        }); */
    }

    handlePayOk = () => {
        this.payForm.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                this.setState({
                    modalLoading: true,
                });

                values.client_id = this.state.selectedInvoice.client_id;
                values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                values.invoice_id = this.state.selectedInvoice.id;

                if(window.creditCard && window.creditCard.current){
                    window.creditCard.current.handleOk(values.amount, (res) => {
                        values.txn_id = res.id;
                        this.postTransaction(values);
                    }, () => {
                        this.setState({
                            modalLoading: false,
                        });
                    });
                }else{
                    this.postTransaction(values);
                }
            }
        });
    }

    postTransaction = (values) => {
        Zenfy.post('transactions', values).then((res) => {

            Zenfy.post('histories', {
                org_id: this.state.selectedInvoice.org_id, 
                invoice_id: this.state.selectedInvoice.id,
                data1: values.amount,
                action: 'paid',
            });

            this.drawerRef.current.load();
            
            this.setState({
                showModal: false,
                modalLoading: false,
            });

        });
    }

    viewInvoice = (invoice) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${invoice.id}?lang=${localStorage.Lang}`, '_blank');
        win.focus();
    }

    downloadInvoice = (invoice) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${invoice.id}?email=true&isFile=true&lang=${localStorage.Lang}`, '_blank');
        win.focus();
    }

    renderId = (text, record) => {
        return <>
            <a href="javascript:;" id={`invoice-${record.id}`} onClick={()=>this.recordOnClick(record)}>{text}</a> 
            {record.emailed > 0 ? (
                <Tooltip title={`Invoice emailed ${record.emailed} time(s)`}>
                    <Icon style={{color: '#52c41a', marginLeft: 3}} type="like" />
                </Tooltip>
            ) : null}
        </>;
    }

    getTitle = () => {
        if(this.state.selectedInvoice.id){
            return `${this.state.selectedInvoice.name} - ${this.state.selectedInvoice.id}`;
        }else{
            return 'Add a new invoice';
        }
    }

    searchOnChange = (e) => {
        this.setState({
            searchFilter: e.target.value,
        },() => {
            this.load();
        });
    }

    statusFilter = (value, record) => {

        let paid = false;

        if(record.amount_due && record.amount_due > 0 && record.paid >= record.amount_due){
            paid = true;
        }

        if(value == 'paid' && paid){
            return true;
        }else if(value == 'pending' && !paid){
            return true;
        }

        return false;
    }

    delete = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this invoice?',
            okText: 'Yes',
            onOk: () => {
                Zenfy.delete(`invoices/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    
    cleanRows = (rows) => {
        let exportRows = [];

        rows.map((row) => {
            delete row.orders;
            delete row.key;
            delete row.org_id;
            delete row.ends_at;
            delete row.sent_at;
            delete row.po;
            delete row.count;
            delete row.product_id;
            delete row.client_id;
            
            exportRows.push(row);
        });

        return exportRows;
    }

    render() {

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => this.renderId(text, record),
            sorter: (a, b) => a.id - b.id,
        },{
            title: 'Customer',
            dataIndex: 'name',
            key: 'name',
            //render: (text) => {return moment(text).format("MMMM Do YYYY")},
        },{
            title: 'Amount Due',
            dataIndex: 'amount_due',
            key: 'amount_due',
            sorter: (a, b) => a.amount_due - b.amount_due,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Paid Amount',
            dataIndex: 'paid',
            key: 'paid',
            sorter: (a, b) => a.paid - b.paid,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            sorter: (a, b) => a.balance - b.balance,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [{text: 'Paid', value: 'paid'}, {text: 'Pending', value: 'pending'}],
            onFilter: (value, record) => this.statusFilter(value, record),
            render: (text, record) => {
                if(record.amount_due && record.amount_due > 0 && record.paid >= record.amount_due){
                    return <Badge status="success" text="Paid" />;
                }else if(record.amount_due && record.amount_due > 0) {
                    return <Badge status="error" text="Pending" />;
                } 
            }
        },{
            title: 'Due Date',
            dataIndex: 'due_at',
            key: 'due_at',
            render: (text) => text ? moment(text).format("MMMM Do YYYY") : '',
            sorter: (a, b) => new Date(a.due_at) - new Date(b.due_at),
        },{
            title: 'Emailed Date',
            dataIndex: 'sent_at',
            key: 'sent_at',
            render: (text) => {return text ? moment(text).format("MMMM Do YYYY") : null},
            sorter: (a, b) => new Date(a.sent_at) - new Date(b.sent_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

            /*<Menu.Item key="3">
                                <Popconfirm title="Are you sure delete this team?" onConfirm={()=>this.deleteTeam(record)} okText="Yes" cancelText="No">
                                Delete
                                </Popconfirm>
                            </Menu.Item>*/

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={()=>this.recordOnClick(record)}>Edit</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.viewInvoice(record)}>View invoice</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.downloadInvoice(record)}>Download invoice</Menu.Item>
                  <Menu.Item key="3" onClick={() => this.payInvoice(record)}>Add payment</Menu.Item>
                  <Menu.Item key="4" onClick={() => {
                      this.setState({
                        showEmailModal: true,
                        selectedInvoice: record,
                      });
                  }}>Send email to customer</Menu.Item>
                  <Menu.Item key="5" onClick={() => this.delete(record)}>Delete invoice</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
          }
    ];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>

            <Modal
                title={`Pay invoice ${this.state.selectedInvoice.id}`}
                visible={this.state.showModal}
                onOk={this.handlePayOk}
                onCancel={()=> this.setState({
                    showModal: false,
                    modalLoading: false,
                })}
                okText="Pay"
                okButtonProps={{loading: this.state.modalLoading}}
            >
                <PayForm client={{name: this.state.selectedInvoice.name,id: this.state.selectedInvoice.client_id, org_id: this.state.selectedInvoice.org_id }} invoice={this.state.selectedInvoice} ref={this.payForm} /> 
            </Modal>

            <EmailModalForm 
                visible={this.state.showEmailModal}
                title={`Send Invoice ${this.state.selectedInvoice.id} to Customer`}
                onCancel={
                    () => {
                    this.setState({
                        showEmailModal: false,
                    })
                }}
                onCreate={this.sendEmail}
                record={this.state.selectedInvoice}
                ref={this.emailModalRef}
                emailProp={!this.state.selectedInvoice.email ? 'client_email' : false}
                attachement
                emailText
                files
                loading={this.state.modalLoading}
            />

            <DrawerTableForm 
                getTitle={() => this.getTitle()}
                recordOnClick={this.recordOnClick}
                columns={columns} 
                cleanRows={this.cleanRows}
                ref={this.drawerRef} 
                form={
                    <WrappedInvoiceForm drawer={this.drawerRef} invoice={this.state.selectedInvoice} />
                } 
                type="invoice" 
                load="invoices" 
                export
            />
        </>
        );
    }
}

export default Invoices;