import React, { Component } from "react";
import { Tag, Badge, Tooltip, Menu, Button, Icon, Dropdown, Modal, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import ClientForm from '../components/ClientForm';
import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";
import SelectFromUrl from "../components/SelectFromUrl";

import Labels from '../libs/Labels';

class Clients extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            selectedClient: {},
            searchVal: '',
            membershipCategories: [],
            membershipStatus: [{text: 'Active', value: 'Active'}, {text: 'Ended', value: 'Ended'}],
            statusCat: [{text: 'Paid', value: 'Paid'}, {text: 'Pending', value: 'Pending'}],
            showAttendanceModal: false,
        }

        this.drawerRef = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    load = (id, slient) => {

        // fix for not restarting the form :(
        let selectedClient = this.state.selectedClient;

        if(id){
            selectedClient.id = id;
        }

        this.setState({
            loading: !slient,
            selectedClient,
        });

        Zenfy.get(`clients/${localStorage.orgId ? localStorage.orgId : 1}`).then((res) => {

            res.data.map((client) => {

                client.status = "";

                if(client.total_amount_due && client.total_paid_amount > 0 && client.balance >= 0){
                    client.status = "Paid";
                }else if(client.total_amount_due && client.total_amount_due > 0) {
                    client.status = "Pending";
                }

                // finding the oldest membership
                let orderedMemberships = client.invoices.sort(function(a, b){
                    return new Date(a.ends_at) > new Date(b.ends_at) ? -1 : 1;
                });

                client.membershipCategories = [];

                orderedMemberships.map((product) => {
                    client.membershipCategories.push(product.name);
                    //console.log(product);
                    if(product.recurring == 1){
                        let recurringName = product.category + " recurring";
                        client.membershipCategories.push("recurring");
                        client.membershipCategories.push(recurringName);
                    }

                    if(product.type == 'e-transfer'){
                        if(product.confirmed == 0){
                            client.membershipCategories.push("not_confirmed");
                        }else{
                            client.membershipCategories.push("confirmed");
                        }
                    }
                    //console.log(membershipCategories);
                });
                
                if(orderedMemberships.length > 0 && orderedMemberships[0].balance >= 0) {
                    let recurring = orderedMemberships[0].recurring == 1 ? 'recurring' : '';
                    client.activeMembership = orderedMemberships[0];
                    client.activeMembershipName = orderedMemberships[0].name + " " + recurring;
                   // console.log(client.activeMembershipName);
                    client.activeMembershipEnd = orderedMemberships[0].ends_at;
                }else{
                    client.activeMembership = null;
                    client.activeMembershipName = '';
                    client.activeMembershipEnd = '';
                }


            });

            let membershipCategories = Zenfy.getCategoriesFromClients(res.data);

            membershipCategories.push({
                text: Labels.get('Recurring'),
                value: 'recurring'
            });

            membershipCategories.map((cat) => {
                if(cat.children && cat.children.length > 0){
                    cat.children.unshift({
                        text: cat.text + " " + Labels.get('recurring'),
                        value: cat.text + ' recurring',
                    })
                }
            });

            console.log(membershipCategories);

            if(window.localStorage.orgId == 1){
                membershipCategories.push({
                    text: 'Unconfirmed E-transfers',
                    value: 'not_confirmed'
                },{
                    text: 'Confirmed E-transfers',
                    value: 'confirmed'
                });
            }

            this.setState({
                loading: false,
                membershipCategories,//Zenfy.getCategoriesFilters(res.data, 'activeMembershipName'),
                rows: Zenfy.setKeysToList(res.data),
                selectedClient,
            }, () => {
                var base = document.querySelector('body'); // the container for the variable content
                var selector = '.ant-dropdown-menu-submenu-title'; // any css selector for children

                    base.addEventListener('click', function(event) {
                    // find the closest parent of the event target that
                    // matches the selector
                    var closest = event.target.closest(selector);
                    if (closest && base.contains(closest)) {
                        var menuId = closest.getAttribute('aria-owns');
                        if(document.getElementById(menuId)){
                            var elements = document.getElementById(menuId).getElementsByClassName("ant-dropdown-menu-item");
                            for(var i = 0; i < elements.length ; i++){
                                elements[i].click();
                            }
                        }
                    }
                });
            });

        });
    }

    recordOnClick = (record, e) => {

        if(e){
            e.stopPropagation();
        }

        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedClient: record,
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });
    
    }

    renderClasses = (record) => {

        let ended = false;

        let res = record.invoices ? record.invoices.map((invoice) => {
            if(new Date(invoice.ends_at) < new Date()){
                ended = true;
            }

            if(invoice.balance < 0){
                return '';
            }

            let text = ended ? "Ended" : "Active";
            let color = ended ? "red" : "green";

            if(invoice.type == 'e-transfer'){
                if(invoice.confirmed == 0){
                    color = "purple";
                    text += ' - Unconfirmed'; 
                }else{
                    text += ' - Confirmed'; 
                }
            }
            
            if(invoice.count > 0){
                return <Tooltip title={text}>
                    <Tag style={{marginBottom: '5px'}} >{invoice.name} - {invoice.count} classes left</Tag>
                </Tooltip>;
            }

            if(invoice.ends_at){
                return <Tooltip title={text}>
                    <Tag color={color} onClick={()=>{this.confirme(invoice)}} style={{marginBottom: '5px', cursor: 'pointer'}} >{invoice.name} <span style={{fontWeight: 'bold'}}>{invoice.recurring ? " (recurring)" : ""}</span></Tag>
                </Tooltip>;
            }
        }) : '';
        
        return res;
    } 

    confirme = (invoice) => {

        Zenfy.get(`${localStorage.orgId}/transactions`, {
            invoice_id: invoice.id
        }).then((res) => {
            let dataLength = res.data.length;
            let indexData = 0;
            res.data.map((row) => {
                if(row.type == 'e-transfer'){
                    Zenfy.post('transactions', {
                        id: row.id,
                        confirmed: !invoice.confirmed,
                    }).then(() => {
                        indexData++;

                        if(indexData == dataLength){
                            notification.success({
                                message: `Transaction ${invoice.confirmed == 0 ? "confirmed" : "undconfirmed"} for ${invoice.name}!`,
                                placement: 'bottomLeft',
                            });

                            this.load(false, true);
                        }
                    });
                }
            });

            
        });

    }

    renderMembership = (record) => {
        /*const res = record.act ? record.invoices.map((invoice) => {
            if(invoice.ends_at){
                return <span>{new Date(invoice.ends_at) > new Date() ? 'Ending' : 'Ended '} {moment(invoice.ends_at).fromNow()}</span>;
            }
        }) : '';*/

        const res = record.activeMembership && record.activeMembership.ends_at ? 
            <Tooltip title={moment(record.activeMembership.ends_at).format("MMMM Do YYYY")}>
                <span>
                    {new Date(record.activeMembership.ends_at) > new Date() ? 'Ending' : 'Ended '} {moment(record.activeMembership.ends_at).fromNow()}
                </span>
            </Tooltip> : '';

        return res;
    }

    delete = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this client?',
            okText: 'Yes',
            okType: 'danger',
            onOk: () => {
                Zenfy.delete(`clients/${record.id}`).then((res) => {
                    this.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    takeAttendance = (record, event) => {
        if(event){
            event.stopPropagation();
        }

        if(record.activeMembership && record.activeMembership.count > 0){
            Zenfy.post('invoices', {
                id: record.activeMembership.id,
                org_id: record.activeMembership.org_id, 
                product_id: record.activeMembership.product_id,
                client_id: record.activeMembership.client_id,
                count: record.activeMembership.count - 1,
            });
        }

        let active = false;

        if(record.activeMembership && (record.activeMembership.count > 0 || new Date(record.activeMembership.ends_at) > new Date())){
            active = true;
        }

        notification.success({
            message: `Attendance Taken! ${active ? 'With an Active Membership!' : 'With no Active Memebership!'}`,
            placement: 'bottomLeft',
        });

        Zenfy.post('attendances', {
            org_id: record.org_id, 
            client_id: record.id,
            product_id: active ? record.activeMembership.product_id : 0,
            passed: active ? 1 : 0,
        }).then(() => {
            this.load();
        });

        /*this.setState({
            showAttendanceModal: true,
            selectedClient: record,
        });*/
    }

    sendAccessCard = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        if(record.email != ""){
            Zenfy.get(`${record.org_id}/sendemail/${record.id}`,{
                type: 'card'
            }).then((res) => {
                notification.success({
                    message: `Email sent with success!`,
                    placement: 'bottomLeft',
                });
            });
        }
    }

    cleanRows = (rows) => {
        let exportRows = [];

        rows.map((row) => {
            exportRows.push({
                name: row.name,
                email: row.email,
                address: row.address ? row.address : "",
                city: row.city,
                postal: row.postal,
                "Registration Date": row.created_at,
                "Child Name": row.child_name ? row.child_name : '',
                "Child DOB": row.child_dob ? row.child_dob : '',
                "Child Gender": row.child_gender && row.child_name != '' ? row.child_gender : '',
                "Child Health/Comments": row.child_conditions ? row.child_conditions : '',
                gender: row.gender ? row.gender : '',
                dob: row.dob ? row.dob : '',
                phone: row.phone ? row.phone : '',
                "Active Membership": row.activeMembershipName,
                "Active Membership Ending": row.activeMembershipEnd,
                "Comments / Health": row.health ? row.health : '',
                "Emergency Contact": row.emergency_name ? row.emergency_name : '',
                "Emergency Contact Phone": row.emergency_phone ? row.emergency_phone : '',
            });
        });

        return exportRows;
    }

    renderName = (record) => {
        let name = record.name;

        if(localStorage.orgId == 9 && record.child_name && record.child_name != ''){
            name = record.child_name;
        }

        if(!name){
            name = record.email;
        }

        return name;
    }

    render() {

        let rows = this.state.rows.filter((obj) => {
            
            if (this.state.searchVal != '') {
                let name = obj.name ? obj.name : '';
                if(name){
                    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                }

                let childName = obj.child_name ? obj.child_name : '';
                if(childName){
                    childName = childName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                }

                let email = obj.email ? obj.email : '';

                let membership = obj.activeMembershipName ? obj.activeMembershipName : "";

                // record.activeMembershipName.includes(value)

                return membership.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 || 
                name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 || email.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 || childName.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
            }
        
            return true;
        });

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{this.renderName(record)}</a>,
        },{
            title: 'Registration Date',
            dataIndex: 'reg_at',
            key: 'reg_at',
            render: (text, record) => {return record.reg_at ? moment(record.reg_at).format("MMMM Do YYYY") : moment(record.created_at).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },{
            title: 'Status',
            dataIndex: 'status',
            key: 'Status',
            filters: this.state.statusCat,
            sorter: (a, b) => a.status > b.status,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            render: (text, record) => text === 'Pending' ? <span><Badge color="#ad6400" text={text} /></span> : text ? <span><Badge status="success" text={text} /></span> : '',
        },{
            title: 'Amount Due',
            dataIndex: 'total_amount_due',
            key: 'total_amount_due',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.total_amount_due - b.total_amount_due,
        },{
            title: 'Paid Amount',
            dataIndex: 'total_paid_amount',
            key: 'total_paid_amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.total_paid_amount - b.total_paid_amount,
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.balance - b.balance,
        },{
            className: localStorage.isDispatch == 1 ? 'hide' : '',
            title: 'Active Memberships',
            dataIndex: 'activeMembershipName',
            key: 'activeMembershipName',
            filters: this.state.membershipCategories,
            render: (text, record) => {return this.renderClasses(record)},
            onFilter: (value, record) => record.membershipCategories.includes(value),
        },{
            className: localStorage.isDispatch == 1 ? 'hide' : '',
            title: 'Membership Ends',
            dataIndex: 'activeMembershipEnd',
            key: 'activeMembershipEnd',
            filters: this.state.membershipStatus,
            filterMultiple: false,
            render: (text, record) => {return this.renderMembership(record)},
            sorter: (a, b) => {return a.activeMembership && b.activeMembership ? new Date(a.activeMembership.ends_at) - new Date(b.activeMembership.ends_at) : null },
            onFilter: (value, record) => {
                if (record.activeMembership){
                    if (new Date(record.activeMembership.ends_at) > new Date() && value == 'Active'){
                        return true;
                    }else if(new Date(record.activeMembership.ends_at) < new Date() && value == 'Ended') {
                        return true;
                    }
                }else{
                    return false;
                }
            },
        },{
            title: 'Comments / Health',
            dataIndex: 'health',
            key: 'health',
            sorter: (a, b) => a.health ? a.health.length : 0 - b.health ? b.health.length : 0,
        }, {
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={(e)=>this.recordOnClick(record, e.domEvent)}>Edit</Menu.Item>
                  <Menu.Item key="2" onClick={(e)=>this.sendAccessCard(record, e.domEvent)}>Send Access Card</Menu.Item>
                  <Menu.Item key="3" onClick={(e)=>this.takeAttendance(record, e.domEvent)}>Take Attendance</Menu.Item>
                  <Menu.Item key="4" onClick={(e)=>this.delete(record, e.domEvent)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <Modal
                title="Attendance"
                visible={this.state.showAttendanceModal}
                onOk={this.addAttendance}
                onCancel={() => {
                    this.setState({
                        showAttendanceModal: false,
                    });
                }}
                okText="Save"
                okButtonProps={{loading: this.state.modalAttendanceLoading}}
            >
                <SelectFromUrl 
                    itemChange={this.productOnChange} 
                    url={`${localStorage.orgId ? localStorage.orgId : 1}/products`} 
                    idName="id" 
                    name="name" 
                    category="category" 
                    style={{width: '100%'}}
                />
            </Modal>

            <DrawerTableForm 
                searchOnChange={this.searchOnChange} 
                showSearch 
                columns={columns} 
                rows={rows} 
                export
                email
                recordOnClick={this.recordOnClick} 
                ref={this.drawerRef} 
                form={<ClientForm drawer={this.drawerRef} reLoad={this.load} client={this.state.selectedClient} />} 
                type='member'
                cleanRows={this.cleanRows}
                loading={this.state.loading} 
                rowClassName={(record, index) => {
                        
                    if(record.activeMembershipEnd && new Date(record.activeMembership.ends_at) < new Date()){
                        return 'red';
                    }else if(record.status == 'Pending'){
                        return 'yellow';
                    }else{
                        return '';
                    }

                    // return `${index % 2 !== 0 ? 'impair' : ''}`;
                }}
            />
        </>
        );
    }
}

export default Clients;