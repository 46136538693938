import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

class Terminals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            roleCategories: [{text: 'Driver', value: 'driver'}, {text: 'Broker', value: 'broker'}, {text: 'Yard', value: 'yard'}]
        }

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    render() {

        const columns = [{
            title: 'Name/ID',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: true,
        },{
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
            type: 'switch',
        },{
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
        },{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <a href={`mailto:${record.email}`}>{text}</a>,
        },{
            title: 'Phone',
            dataIndex: 'telephone',
            key: 'telephone',
        },{
            title: 'System',
            dataIndex: 'system',
            key: 'system',
            className: 'hide',
        }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm columns={columns} ref={this.drawerRef} form={null} type="terminal" load="terminals" />
        </>
        );
    }
}

export default Terminals;