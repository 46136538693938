import React, { Component } from "react";
import {
Form, Input, Tabs, DatePicker, Select, Avatar, Upload, Button, Icon, notification, Checkbox, Modal, InputNumber, Timeline, Col, Row, Divider, Radio 
} from 'antd';
import moment from 'moment';
import InvoiceProducts from '../../components/InvoiceProducts';
import Transactions from '../../components/Transactions';
import Address from '../../components/Address';
import Contacts from '../../components/Contacts';
import Status from '../../components/Status';
import History from '../../components/History';

import Zenfy from "../../libs/Zenfy";
import Webcam from "react-webcam";

import Labels from '../../libs/Labels';
import SelectFromUrl from '../../components/SelectFromUrl';
import Orders from './OrdersNew';
import DynamicChilds from "../../components/DyamicChild";
import Files from '../../components/Files';


const { Option } = Select;

class NormalClientForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: props.client,
            loading: false,
            fileList: [],
            showWebCamModal: false,
            webCamModalLoading: false,
            responsible: {},
            president: {},
            addressError: false,
            downloadLoading: false,
        }

        this.addressId = null;
        this.transactionsRef = React.createRef();
        this.invoiceRef = React.createRef();
        this.webCamRef = React.createRef();
        window.clientForm = this;
        this.getContacts(props);
        //if(props.public){
            
        //}
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.client.id !== this.state.client.id){
            if(!this.props.isCarbon){
                this.props.form.resetFields();
            }

            this.setState({
                fileList: [],
                client: nextProps.client
            });

            this.getContacts(nextProps);
        }

    }

    getContacts = (props) => {
        Zenfy.get(`${localStorage.orgId}/contacts/${props.client.id}`).then((res) => {
            if(res.data.length > 0){

                let president = {};
                let responsible = {};

                res.data.map((contact) => {
                    if(contact.type == 'president'){
                        president = contact;
                    }else if(contact.type == 'responsible'){
                        responsible = contact;
                    }
                });

                this.setState({
                    responsible,
                    president
                });
            }
        });
    }

    componentDidMount = () => {
        let elements = document.getElementsByTagName('textarea');

        for (let item of elements) {
            this.textAreaAdjust(item);
        }            
    }

    handleUploadChange = info => {
        let fileList = [...info.fileList];
    
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
    
        this.setState({ fileList });
    };

    textAreaAdjust = (o) => {
        o.style.height = "1px";
        setTimeout(function() {
            o.style.height = (o.scrollHeight)+"px";
        }, 1);
    }

    downloadPdf = () => {

        this.setState({
            downloadLoading: true,
        });

        // get last invoice
        Zenfy.get(`${localStorage.orgId}/orders?client_id=${this.state.client.id}&include_products=true`).then((res) => {
           
            let invoices = res.data;
            let productsHtml = "";

            invoices = invoices.sort(function(a, b){
                return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1;
            });

            invoices.map((invoice) => {
                if(invoice.paid > 0 || invoice.balance == 0){
                    invoice.products.map((product) => {
                        if(product.name && product.created_at){
                            productsHtml += "<h5>" + moment(invoice.created_at).format('DD/MM/YYYY') + " - " + product.name + "</h5>";
                        }
                    });
                }
            });
           
            let html = `
          <style>
          body{
            font-family: Helvetica;
            font-size: 15px;
          }
          .ant-divider{
            margin-top: 20px;
            margin-bottom: 0px;
          }
          .ant-divider-inner-text{
            color: #007a9c;
            font-size: 20px;
          }
          .ant-form-item-label{
            padding-top: 10px;
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 0px;
          }
          .ant-form-explain,.ant-form-extra{
            display: none;
          }
          .documents, .hide-pdf{
            display: none;
          }
          .label{
              margin-right: 30px;
          }
          textarea{
            white-space: normal;
            padding-bottom: 30px;
            border: 0px;
            padding-left: 0px;
            font-family: Helvetica;
            width: 100% !important;
            height: auto!important;
          }
          input[type=text]{
            height: 32px;
            white-space: normal;
            border: 0px;
            padding: 0px;
            width: 100% !important;
            height: auto!important;
        }
        .checkbox{
            margin-top: 30px;
        }
        input[type=radio]{
            margin-right: 5px;
            margin-left: 0px;
            margin-top: 4px;
        }
        .ant-radio-wrapper span{margin-right: 10px;}
        input[type=checkbox]{
            margin-top: 4px;
            margin-right: 5px;
        }
        #client_form_crm_countries li:last-child{
            display: none;
        }
        .ant-checkbox-wrapper{margin-top: 15px;}
        a{color: #000000 !important;cursor: default; text-decoration: none;}
        </style>
        <div class="ant-divider ant-divider-horizontal ant-divider-with-text-center" role="separator"><span class="ant-divider-inner-text">Produits</span></div>
        ${productsHtml}
          ` + document.getElementById(`form-crm`).innerHTML;
        Zenfy.post('uploadpdfhtml', {
            html,
            filename: 'form_'
        }).then((res) => {
            var win = window.open(`${Zenfy.getRoot()}uploads/${res.data}`, '_blank');
            win.focus();
            this.setState({
                downloadLoading: false,
            });
        });
            
        });

        
    }

    addContact = (contact) => {

        if(!contact.name){
            return;
        }

        contact.org_id = localStorage.orgId;
        contact.client_id = this.state.client.id;

        Zenfy.post('contacts', contact).then((res) => {
            console.log(res);
        });
    }

    addClient = (data, callback) => {

        this.setState({
          loading: true
        });

        data.id = this.state.client.id ? this.state.client.id : 0;
        data.org_id = localStorage.orgId ? localStorage.orgId : 1;

        let president = data.president;
        let responsible = data.responsible;

        if(data.president){
            delete data.president;
        }

        if(data.responsible){
            delete data.responsible;
        }

       /* if(data.responsible){
            data.president.type = 'responsible';
            if(this.state.president && this.state.president.id){
                this.addContact(data.responsible);
            }
            delete data.responsible;
        } */

        if(this.state.fileList && this.state.fileList.length > 0){
            data.picture = `${Zenfy.getRoot()}uploads/${this.state.fileList[0].response}`;
        }

        if(data.picture && data.picture.file){
            data.picture = `${Zenfy.getRoot()}uploads/${data.picture.file.response}`;
        }

        if(data.address && data.address.address && Zenfy.isType('object', data.address) && !data.address.id){
            data = {...data, ...data.address};
        }else{
            delete data.address;
        }

        /*if(data.year && data.year.year && Zenfy.isType('object', data.year) && !data.year.id){
            data = {...data, ...data.year};
        }else{
            delete data.year;
        }*/

        if(data.countries){
            data.countries = data.countries.toString();
        }

        
        if(data.competences){
            data.competences = data.competences.toString();
        }

        if(data.membre_de){
            data.membre_de = data.membre_de.toString();
        }

        Zenfy.post('clients', data).then((res) => {

            let client = this.state.client;
            client.id = res.data;
            client.picture = data.picture;

            this.setState({
                client,
                loading: false,
            });

            if(this.props.isCarbon && this.addressId){
                Zenfy.post('addresses', {
                    id: this.addressId,
                    client_id: client.id
                });
            }

            if(president){
                president.id = this.state.president.id;
                president.type = 'president';
                this.addContact(president);
            }

            if(responsible){
                responsible.id = this.state.responsible.id;
                responsible.type = 'responsible';
                this.addContact(responsible);
            }

            if(!this.props.public){
                notification.success({
                    message: `Contact saved with success!`,
                    placement: 'bottomLeft',
                });
            }

            if(this.props.reLoad){
                this.props.reLoad(res.data);
            }

            if(callback){
                callback(res);
            }
        })

    }

    handleSubmit = (e, callback, errorCallBack) => {
        if(e){
            e.preventDefault();
        }

        this.props.form.validateFieldsAndScroll((err, values) => {

            if(this.props.isChild && !values.child_name || (this.props.isChild && values.child_name && !values.child_name.child_name)){
                this.props.form.setFields({
                    child_name: {
                        errors: [new Error('Child is required')],
                    },
                });

                err = true;
            }

            if(this.props.public && values.president && !values.president.name && !this.props.isCarbon){
                this.props.form.setFields({
                    president: {
                        errors: [new Error('Ceci est requis')],
                    },
                });

                err = true
            }

            if(this.props.public && values.responsible && !values.responsible.name && !this.props.isCarbon){
                this.props.form.setFields({
                    responsible: {
                        errors: [new Error('Ceci est requis')],
                    },
                });

                err = true
            }

            if(this.props.isCarbon && values.etape_1 == 1 && values.chiffre == 0 && !this.props.isGranby){
                Modal.error({
                    content: (
                    <>
                        <div style={{marginLeft: '40px'}}>
                            <p>Votre chiffre d’affaires doit être de 500 000 $ et plus.</p>
                        </div>
                    </>
                    ),
                    className: 'center-modal',
                });

                err = true;
                if(this.props.resize){
                    this.props.resize(true);
                }
            }

            if(this.props.isCarbon && values.etape_2 == 1 && values.chiffre_1mil == 0 && !this.props.isGranby){
                Modal.error({
                    content: (
                    <>
                        <div style={{marginLeft: '40px'}}>
                            <p>Votre chiffre d’affaires doit être de 1 million et plus.</p>
                        </div>
                    </>
                    ),
                    className: 'center-modal',
                });

                err = true;
                if(this.props.resize){
                    this.props.resize(true);
                }
            }

            

            /*if(this.props.isCarbon && values.is_montreal == 0 && !this.props.hideRegion){
                let region = "sur l’Île de Montréal";

                if(this.props.isGranby){
                    region = "dans la région de Granby";
                }

                if(this.props.isChau){
                    region = "dans la région de Chaudière-Appalaches";
                }

                if(this.props.carName){
                    region = this.props.carName;
                }

                Modal.error({
                    content: (
                    <>
                        <div style={{marginLeft: '40px'}}>
                            <p>L’entreprise doit être située {region} pour bénéficier de l’accompagnement et du soutien.</p>
                        </div>
                    </>
                    ),
                    className: 'center-modal',
                });

                err = true;
                if(this.props.resize){
                    this.props.resize(true);
                }
            }*/
            
            if((!values.autorise || !values.correct) && values.ready && !this.props.isCarbon){

                Modal.error({
                    content: (
                    <>
                        <div style={{marginLeft: '40px'}}>
                            <p>S'il vous plait assurez-vous de caucher les autorisations à la section 7.</p>
                        </div>
                    </>
                    ),
                    className: 'center-modal',
                });
                

                err = true;
            }

            if (!err) {
                this.addClient(values, callback);
            }else{
                if(errorCallBack){
                    errorCallBack();
                }
            }
        });
    }

    reLoad = () => {
        if(this.props.reLoad){
            this.props.reLoad();
        }
    
        if(this.transactionsRef.current){
            this.transactionsRef.current.load();
        }
        
        if(this.invoiceRef.current){
            this.invoiceRef.current.drawerRef.current.load();
        }
    }

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();
        formData.append('file', file);

        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);
        });
    }

    nameOnChange = (e) => {
        if(e.target.value){
            let name = e.target.value;
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    name
                },
            }));
        }
    }

    accompagnementOnChange = (e) => {
        let accompagnement = e.target.value;
        
        this.setState((prevState) => ({
            client: {
                ...prevState.client,
                accompagnement
            },
        }));
    }

    controverseOnChange = (e) => {
        let controverse = e.target.value;
        
        this.setState((prevState) => ({
            client: {
                ...prevState.client,
                controverse
            },
        }));
    }
    
    emailOnChange = (e) => {
        if(e.target.value){
            let email = e.target.value;
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    email
                },
            }));

            if(this.props.emailOnChange){
                this.props.emailOnChange(email);
            }

            if(Zenfy.validateEmail(email)){
                Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/clients`, {
                    email: email,
                }).then((res) => {
                    if(res.data && res.data.hasAccount){
                        this.setState({
                            clientExist: true,
                        });
                    }else{
                        this.setState({
                            clientExist: false,
                        });
                    }
                });
            }
            
        }
    }

    handleWebCamModalOk = () => {
        const imageSrc = this.webCamRef.current.getScreenshot();

        let formData = new FormData();
        formData.append('file', imageSrc);

        this.setState({
            webCamModalLoading: true,
        })

        Zenfy.post('uploadfile', formData).then(res => {

            let client = this.state.client;
                client.picture = `${Zenfy.getRoot()}uploads/${res.data}`;

            this.setState({
                client,
                showWebCamModal: false,
                webCamModalLoading: false,
            });

        });
    }
    
    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 4,
              },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        let region = "sur l’Île de Montréal";

        if(this.props.isGranby){
            region = "dans la région de Granby";
        }

        if(this.props.isChau){
            region = "dans la région de Chaudière-Appalaches";
        }

        if(this.props.carName){
            region = this.props.carName;
        }

        return (
            <>
                {!this.props.public ? (
                    <>
                        <Avatar size={100} icon="user" style={{marginBottom: '20px'}} src={this.state.client.picture} />

                        <Status step={this.state.client.crm_status} />

                        <div className="clearfix" />
                    </>
                ) : null}

                <Modal
                     title={Labels.get("Capture")}
                     visible={this.state.showWebCamModal}
                     onOk={this.handleWebCamModalOk}
                     okText={Labels.get("Capture")}
                     onCancel={()=> this.setState({
                        showWebCamModal: false,
                     })}
                     okButtonProps={{loading: this.state.webCamModalLoading}}
                >
                    <Webcam
                        audio={false}
                        height={465}
                        ref={this.webCamRef}
                        screenshotFormat="image/jpeg"
                        style={{width: '100%'}}
                        videoConstraints={{
                            width: 800,
                            height: 800,
                            facingMode: "user"
                        }}
                    />
                </Modal>
                
                <Tabs defaultActiveKey="1" className={this.props.public ? 'public' : ''}>
                    <TabPane tab="Info" key="1">

                        <Form onSubmit={this.handleSubmit} className={`team-form ${localStorage.Lang}`} id="form-crm">

                            {!this.props.public ? (
                            <>
                            <Divider>{Labels.get("Account")}</Divider>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={Labels.get("Email")}
                                        >
                                        {getFieldDecorator('email', {
                                            initialValue: this.state.client.email,
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: Labels.get('The input is not valid E-mail'),
                                                },
                                                {
                                                    required: this.props.public,
                                                    message: Labels.get('Email is required'),
                                                },
                                            ],
                                        })(
                                            <Input onChange={this.emailOnChange} />
                                        )}
                                    </Form.Item>

                                    </Col>

                                    <Col span={12}>
                                    <Form.Item
                                        label={Labels.get("Password")}
                                        style={{display: 'none'}}
                                        >
                                        {getFieldDecorator('password', {
                                            initialValue: this.state.client.password,
                                        })(
                                            <Input />
                                        )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>) : null}
                            
                            {!this.props.isCarbon ? (
                                <Divider orientation="left">{this.props.public ? '3.' : ''} {Labels.get("Coordonates")}</Divider>
                            ) :null}
                            
                            <Row gutter={24}>
                                <Col span={12}>

                                    <Form.Item
                                        label={Labels.get("Company name")}
                                    >
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.client.name,
                                        rules: [
                                            { 
                                                required: this.props.public,
                                                message: `${Labels.get("Company name")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Input onChange={this.nameOnChange} />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        //style={{display: this.props.isCarbon ? 'none' : 'block'}}
                                        label={Labels.get("Numéro d’entreprise du Québec (NEQ)")}
                                    >
                                    {getFieldDecorator('neq', {
                                        initialValue: this.state.client.neq,
                                        rules: [
                                            { 
                                                required: this.props.public, 
                                                message: `${Labels.get("Numéro d'enregistrement légal de l'entreprise")} ${Labels.get("is required")}`,
                                            }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>     

                                    {this.props.isCarbon ? (
                                    <>
                                        <Form.Item
                                            label={Labels.get("Nom de la personne responsable de la demande")}
                                        >
                                        {getFieldDecorator('name_acco', {
                                            initialValue: this.state.client.name_acco,
                                            rules: [
                                                { 
                                                    required: this.props.public,
                                                    message: `${Labels.get("Nom de la personne responsable de la demande")} ${Labels.get("is required")}` 
                                                }
                                            ],
                                        })(
                                            <Input />
                                        )}
                                        </Form.Item>

                                        <Form.Item
                                            label={Labels.get("Email")}
                                            >
                                            {getFieldDecorator('email', {
                                                initialValue: this.state.client.email,
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail',
                                                    },
                                                    {
                                                        required: this.props.public,
                                                        message: 'Email is required',
                                                    },
                                                ],
                                            })(
                                                <Input onChange={this.emailOnChange} />
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            style={{display: this.props.hideRegion || this.props.isMauricie ? 'none' : ''}} 
                                            label={<span>L’entreprise participante est située<br/><b>{region}</b></span>}
                                            //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                            >
                                                {getFieldDecorator('is_montreal', {
                                                    initialValue: this.state.client.is_montreal ? this.state.client.is_montreal : 0,
                                                })(
                                                    <Radio.Group name="radiogroup">
                                                        <Radio value={0}>{Labels.get("No")}</Radio>
                                                        <Radio value={1}>{Labels.get("Yes")}</Radio>
                                                    </Radio.Group>,
                                                )}
                                        </Form.Item>
                                    </>
                                    ) : null}    
                                    
                                    {this.props.action != 3 && !this.props.isCarbon ? (
                                    <>
                                    <Form.Item
                                        label={Labels.get("Traded company name")}
                                    >
                                    {getFieldDecorator('commercial_name', {
                                        initialValue: this.state.client.commercial_name,
                                        rules: [
                                            { 
                                                required: this.props.public,
                                                message: `${Labels.get("Traded company name")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Website")}
                                    >
                                    {getFieldDecorator('web', {
                                        initialValue: this.state.client.web,
                                        rules: [
                                            { 
                                                required: this.props.public && !this.props.isCarbon,
                                                message: `${Labels.get("Website")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>
                                    

                                   <Form.Item
                                        label={Labels.get("Logo")}
                                        className="hide-pdf"
                                            >
                                            {getFieldDecorator('picture', {
                                                initialValue: this.state.client.picture,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <>
                                                    <Upload name="picture" onChange={this.handleUploadChange} customRequest={this.uploadFile} showUploadList={true} fileList={this.state.fileList} >
                                                        <Button>
                                                            <Icon type="upload" /> {Labels.get("Click to Upload")}
                                                        </Button>
                                                    </Upload>
                                                </>
                                            )}
                                    </Form.Item>
                                    

                                    <Form.Item 
                                        label={Labels.get("Countries")}
                                        style={{display: this.props.isCarbon ? 'none' : 'block'}}
                                    >
                                        {getFieldDecorator('countries', {
                                            initialValue: this.state.client.countries ? Array.isArray(this.state.client.countries) ? this.state.client.countries : this.state.client.countries.split(',') : [],
                                            rules: [
                                                { 
                                                    required: this.props.public && !this.props.isCarbon,
                                                    message: `${Labels.get("Countries")} ${Labels.get("is required")}` 
                                                }
                                            ]
                                        })(
                                            <Select
                                                mode="multiple"
                                                showSearch
                                            >
                                                {Zenfy.getCountries().map((country, index) => 
                                                    <Option value={country} key={index}>{Labels.get(country)}</Option>
                                                )} 
                                            </Select>,
                                        )}
                                    </Form.Item>

                                    <Form.Item 
                                        label={Labels.get('Type of ownership')}
                                        style={{display: this.props.isCarbon ? 'none' : 'block'}}
                                    >
                                        {getFieldDecorator('ownership', {
                                            initialValue: this.state.client.ownership,
                                            rules: [{ required: this.props.public && !this.props.isCarbon, message: `${Labels.get("Type of ownership")} ${Labels.get("is required")}`  }],
                                        })(<Select
                                            placeholder={Labels.get("Select ownership")}
                                            onChange={(e) => {
                                                let client = this.state.client;
                                                console.log(e);
                                                if(e == 'Other'){
                                                    client.ownership_other = true;
                                                    this.setState({
                                                        client
                                                    });
                                                }else{
                                                    client.ownership_other = false;
                                                    this.setState({
                                                        client
                                                    });
                                                }
                                            }}
                                        >
                                            <Option value="Group">{Labels.get("Group")}</Option>
                                            <Option value="Holding">{Labels.get("Holding")}</Option>
                                            <Option value="Franchise">{Labels.get("Franchise")}</Option>
                                            <Option value="Actionnariat">{Labels.get("Actionnariat")}</Option>
                                            <Option value="Other">{Labels.get("Other")}</Option>
                                        </Select>)}
                                    </Form.Item>
                                    
                                    {this.state.client.ownership_other ? (
                                        <Form.Item
                                            label={Labels.get("Other ownership")}
                                        >
                                        {getFieldDecorator('ownership_other', {
                                            initialValue: this.state.client.ownership_other === true ? '' : this.state.client.ownership_other,
                                            rules: [
                                                { 
                                                    required: this.props.public,
                                                    message: `${Labels.get("Other ownership")} ${Labels.get("is required")}` 
                                                }
                                            ],
                                        })(
                                            <Input />
                                        )}
                                        </Form.Item>
                                    ) : null}
                                            

                                </>) : null}
                                </Col>
                        
                        <Col span={12}>    
                        {this.props.action != 3 ? (
                            <>
                            <Form.Item
                                label={Labels.get("Number of employees")}
                                style={{display: this.props.isCarbon ? 'none' : 'block'}}
                            >
                                    {getFieldDecorator('employees', {
                                        initialValue: this.state.client.employees,
                                        rules: [
                                            { 
                                                message: `${Labels.get("Number of employees")} ${Labels.get("is required")}`,
                                                required: this.props.public && !this.props.isCarbon,
                                            }
                                        ],
                                    })(
                                        <Input />
                                    )}
                            </Form.Item>

                            {this.props.isCarbon ? (
                                <Form.Item 
                                    label={<span>L'entreprise est certifiée ÉCORESPONSABLE<sup>MC</sup></span>}
                                >
                                {getFieldDecorator('ecoresponsable', {
                                    initialValue: this.state.client.ecoresponsable ? 1 : 0,
                                    rules: [{ required: this.props.public, message: `${Labels.get("is required")}` }],
                                })(<Radio.Group name="radiogroup">
                                    <Radio value={0}>{Labels.get("No")}</Radio>
                                    <Radio value={1}>{Labels.get("Yes")}</Radio>
                                </Radio.Group>)}
                            </Form.Item>

                            ) : null}            
                            
                            <Form.Item
                                label={Labels.get("Phone")}
                            >
                            {getFieldDecorator('telephone', {
                                initialValue: Zenfy.normalizeInputPhone(this.state.client.telephone),
                                normalize: (value) => {
                                    return Zenfy.normalizeInputPhone(value);
                                },
                                rules: [
                                    { 
                                        required: this.props.public, 
                                        message: `${Labels.get("Phone")} ${Labels.get("is required")}`,
                                    }
                                ],
                            })(
                                <Input />
                            )}
                            </Form.Item>

                            <Form.Item
                                label={Labels.get("Cell Phone")}
                            >
                            {getFieldDecorator('cell', {
                                initialValue: Zenfy.normalizeInputPhone(this.state.client.cell),
                                normalize: (value) => {
                                    return Zenfy.normalizeInputPhone(value);
                                },
                                rules: [
                                    { 
                                        required: this.props.public && !this.props.isCarbon, 
                                        message: `${Labels.get("Cell Phone")} ${Labels.get("is required")}`,
                                    }
                                ],
                            })(
                                <Input />
                            )}
                            </Form.Item>

                            </>) : null}

                            <Form.Item
                                    className="address-form"
                                    label={this.props.isGranby ? "Adresse de l'entreprise" : Labels.get("Address")}
                                    help={<div className="content" style={{display: this.props.isCarbon ? 'none' : 'block'}} dangerouslySetInnerHTML={{__html: Labels.get("Please list all the operation sites to be included in the certification.")}}></div>}
                                >
                                    {getFieldDecorator('address', {
                                        rules: [
                                            {
                                                required: this.props.public,
                                                message: `${Labels.get("Address")} ${Labels.get("is required")} (maximum de 5)`,
                                            },
                                        ],
                                    })(
                                        <DynamicChilds 
                                        afterAdd={(id)=>{
                                            this.addressId = id;
                                        }}
                                        max={this.props.isCarbon ? 1 : 5}
                                        onlyOne={(this.props.product && this.props.product.category != undefined && this.props.product.category.includes('Attestation')) || this.props.isCarbon}
                                        keys={
                                            [{
                                                title: Labels.get('Address'),
                                                dataIndex: 'address',
                                                key: 'address',
                                                required: true,
                                            },{
                                                title: Labels.get('City'),
                                                dataIndex: 'city',
                                                key: 'city',
                                                required: true,
                                            },{
                                                title: Labels.get("Province / State"),
                                                dataIndex: 'state',
                                                key: 'state',
                                                required: true,
                                            },{
                                                title: Labels.get('Country'),
                                                dataIndex: 'country',
                                                key: 'country',
                                                required: true,
                                            },{
                                                title: Labels.get("Postal Code"),
                                                dataIndex: 'postal',
                                                key: 'postal',
                                                required: true,
                                            }/*,{
                                                title: Labels.get("Include in certification"),
                                                dataIndex: 'include',
                                                key: 'include',
                                                type: 'yesno',
                                                hide: this.props.product && this.props.product.category.includes('Attestation')
                                            }*/]
                                        }
                                        key={!this.props.isCarbon ? this.state.client.id : 'carbone_1address'}
                                        type="addresses"
                                        client_id={this.state.client.id}
                                        url={this.state.client.id ? `${localStorage.orgId ? localStorage.orgId : 1}/addresses/${this.state.client.id}` : false} 
                                    />
                                    )}
                            </Form.Item>  

                            <Form.Item
                                style={{display: this.props.isCarbon ? 'none' : 'block'}}
                                label={Labels.get("Founding year")}
                            >
                            {getFieldDecorator('year', {
                                initialValue: this.state.client.year,
                                rules: [
                                    { 
                                        required: this.props.public && !this.props.isCarbon, 
                                        message: `${Labels.get("Founding year")} ${Labels.get("is required")}`,
                                    }
                                ],
                            })(
                                <Input />
                            )}
                            </Form.Item>            

                        </Col>
                    </Row>
                    
                {this.props.action != 3 && !this.props.isCarbon ? (
                    <>
                    <Divider orientation="left">{this.props.public ? '4.' : ''} {Labels.get("General description")}</Divider>

                    <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            onChange={(e) => {
                                let client = this.state.client;

                                if(e.target.value == 'Autres'){
                                    client.secteur_autres = true;
                                    this.setState({
                                        client
                                    });
                                }else{
                                    client.secteur_autres = false;
                                    this.setState({
                                        client
                                    });
                                }
                            }}
                            label={Labels.get("Sector of activity")}
                            >
                            {getFieldDecorator('secteur', {
                                initialValue: this.state.client.secteur,
                                rules: [
                                    {
                                        required: this.props.public,
                                        message: `${Labels.get("Sector of activity")} ${Labels.get("is required")}`,
                                    },
                                ],
                            })(
                                <Radio.Group name="radiogroup">
                                    <Radio value={"Betonnier"}>{Labels.get("Bétonnier, pétrolier ou minier")}</Radio>
                                    <Radio value={"Autres"}>{Labels.get("Autre, veuillez préciser")}</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        {this.state.client.secteur_autres ? (
                            <Form.Item 
                                label={Labels.get('Autre secteur')}>
                                {getFieldDecorator('secteur_autres', {
                                initialValue: this.state.client.secteur_autres === true ? '' : this.state.client.secteur_autres,
                                rules: [{ required: this.props.public, message: `${Labels.get("Autre secteur")} ${Labels.get("is required")}` }],
                                })(<Input />)}
                            </Form.Item>
                        ) : null}

                        <Form.Item
                            label={Labels.get("Product & Services description")}
                            >
                            {getFieldDecorator('produits', {
                                initialValue: this.state.client.produits,
                                rules: [
                                    {
                                        message: `${Labels.get("Product & Services description")} ${Labels.get("is required")}`,
                                        required: this.props.public,
                                    },
                                ],
                            })(
                                <TextArea />
                            )}
                        </Form.Item>

                        <Form.Item 
                            onChange={(e) => {
                                let client = this.state.client;
                                if(e.target.value == 1){
                                    client.franchise = 1;
                                    this.setState({
                                        client
                                    });
                                }else{
                                    client.franchise = 0;
                                    this.setState({
                                        client
                                    });
                                }
                            }}
                            label={Labels.get('Faites-vous partie d’un groupe ou d’une franchise')}>
                            {getFieldDecorator('franchise', {
                                initialValue: this.state.client.franchise ? 1 : 0,
                                rules: [{ required: this.props.public, message: `${Labels.get("Faites-vous partie d’un groupe ou d’une franchise")} ${Labels.get("is required")}` }],
                            })(<Radio.Group name="radiogroup">
                                <Radio value={0}>{Labels.get("No")}</Radio>
                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                            </Radio.Group>)}
                        </Form.Item>

                        {this.state.client.franchise == 1 ? (
                            <Form.Item 
                                label={Labels.get('Que voulez-vous faire certifier')}>
                                {getFieldDecorator('franchise_text', {
                                    initialValue: this.state.client.franchise_text,
                                    rules: [{ required: this.props.public, message: `${Labels.get("Que voulez-vous faire certifier")} ${Labels.get("is required")}` }],
                                })(<Select
                                    onChange={(e) => {

                                        let client = this.state.client;
                                        if(e == 'Autres'){
                                            client.franchise_other = true;
                                            this.setState({
                                                client
                                            });
                                        }else{
                                            client.franchise_other = false;
                                            this.setState({
                                                client
                                            });
                                        }

                                    /*if(e == 'Autres'){
                                        //this.props.row.franchise_other = true;
                                    }else if(this.state.client.franchise_other === true){
                                        //this.props.row.franchise_other = false;
                                    }*/
                                    }}
                                    placeholder={Labels.get("Select a type")}
                                >
                                    <Option value="Le groupe">{Labels.get("Le groupe")}</Option>
                                    <Option value="Le siège social">{Labels.get("Le siège social")}</Option>
                                    <Option value="Une succursale/site">{Labels.get("Une succursale/site")}</Option>
                                    <Option value="Autres">{Labels.get("Autres")}</Option>
                                </Select>)}
                            </Form.Item>
                        ) : null}

                        {this.state.client.franchise_other ? (
                            <Form.Item 
                                label={Labels.get('Autre type')}>
                                {getFieldDecorator('franchise_other', {
                                initialValue: this.state.client.franchise_other === true ? '' : this.state.client.franchise_other,
                                rules: [{ required: false, message: 'Please input the title of collection!' }],
                                })(<Input />)}
                            </Form.Item>
                            ) : null}

                            <Form.Item 
                                onChange={(e) => {
                                let client = this.state.client;
                                if(e.target.value == 1){
                                    client.portefeuille = 1;
                                    this.setState({
                                        client
                                    });
                                }else{
                                    client.portefeuille = 0;
                                    this.setState({
                                        client
                                    });
                                }
                            }}
                            label={Labels.get('Faites-vous partie d’une société de portefeuille (holding)')}>
                            {getFieldDecorator('portefeuille', {
                                initialValue: this.state.client.portefeuille ? 1 : 0,
                                rules: [{ required: this.props.public, message: `${Labels.get("Faites-vous partie d’une société de portefeuille (holding)")} ${Labels.get("is required")}` }],
                            })(<Radio.Group name="radiogroup">
                                <Radio value={0}>{Labels.get("No")}</Radio>
                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                            </Radio.Group>)}
                            </Form.Item>
                            
                            {this.state.client.portefeuille == 1 ? (
                            <Form.Item 
                                label={Labels.get('Quel est le nom de la société?')}>
                                {getFieldDecorator('portefeuille_text', {
                                initialValue: this.state.client.portefeuille_text,
                                rules: [{ required: this.props.public, message: `${Labels.get("Quel est le nom de la société?")} ${Labels.get("is required")}` }],
                                })(<Input />)}
                            </Form.Item>
                            ) : null}

                    </Col>
                        
                    <Col span={12}>    

                        <Form.Item
                            label={Labels.get("Social responsibility")}
                            extra={Labels.get("En quoi vos activités reflètent un sens éthique en lien avec votre responsabilité sociétale ?")}
                        >
                            {getFieldDecorator('resp', {
                                initialValue: this.state.client.resp,
                                rules: [
                                    {
                                        message: `${Labels.get("Social responsibility")} ${Labels.get("is required")}`,
                                        required: this.props.public,
                                    },
                                ],
                            })(
                                <TextArea />
                            )}
                        </Form.Item>

                        <Form.Item 
                            label={Labels.get("Tabac / armement / nucléaire")}
                            help={Labels.get("Vos produits et services sont-ils destinés à des marchés ou à des utilisations dans les secteurs d’activité du tabac, de l’armement ou des activités nucléaires?")}
                        >
                            {getFieldDecorator('tabac', {
                                initialValue: this.state.client.tabac,
                                rules: [{ required: this.props.public, message: `${Labels.get("Tabac / armement / nucléaire")} ${Labels.get("is required")}` }],
                            })(
                                <Radio.Group name="radiogroup">
                                    <Radio value={0}>{Labels.get("No")}</Radio>
                                    <Radio value={1}>{Labels.get("Yes")}</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>

                        </Col>
                    </Row>   
                    </>) : null}
                    
                    {!this.props.isCarbon ? (
                        <>
                            <Divider orientation="left">{this.props.public ? '5.' : ''} {Labels.get("Controverse / accompagnement")}</Divider>

                            <Row gutter={24}>
                            {this.props.action != 3 ? (
                            <Col span={12}>

                                <Form.Item 
                                    label={Labels.get("Controverse")}
                                    help={Labels.get("Votre entreprise a-t-elle subi une controverse majeure sur votre responsabilité sociétale dans quelque domaine d’action que ce soit ?")}
                                >
                                    {getFieldDecorator('controverse', {
                                        initialValue: this.state.client.controverse,
                                        rules: [
                                            {
                                                message: `${Labels.get("Controverse")} ${Labels.get("is required")}`,
                                                required: this.props.public,
                                            },
                                        ],
                                    })(
                                        <Radio.Group name="radiogroup" onChange={this.controverseOnChange}>
                                            <Radio value={0}>{Labels.get("No")}</Radio>
                                            <Radio value={1}>{Labels.get("Yes")}</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>

                                {this.state.client.controverse && this.state.client.controverse == 1 ? (
                                    <Form.Item
                                        label={Labels.get("Subject of Controversy")}
                                    >
                                    {getFieldDecorator('controverse_desc', {
                                        initialValue: this.state.client.controverse_desc,
                                        rules: [
                                            {
                                                message: `${Labels.get("Subject of Controversy")} ${Labels.get("is required")}`,
                                                required: this.props.public,
                                            },
                                        ],
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>
                                ) : null}
                                

                            </Col>) : null}
                                
                            <Col span={12}>    

                                <Form.Item 
                                    label={Labels.get("Accompagnement")}
                                    extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('accompagnement', {
                                            initialValue: this.state.client.accompagnement,
                                            rules: [
                                                {
                                                    message: `${Labels.get("Accompagnement")} ${Labels.get("is required")}`,
                                                    required: this.props.public,
                                                },
                                            ],
                                        })(
                                            <Radio.Group name="radiogroup" onChange={this.accompagnementOnChange}>
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    {this.state.client.accompagnement === 1 ? (
                                        <> 
                                            <Form.Item
                                                label={"Nom de la firme de consultance"}
                                            >
                                            {getFieldDecorator('name_firm', {
                                                initialValue: this.state.client.name_firm,
                                                rules: [
                                                    {
                                                        message: `${Labels.get("Nom de la firme de consultance")} ${Labels.get("is required")}`,
                                                        required: this.props.public,
                                                    },
                                                ],
                                            })(
                                                <Input />
                                            )}
                                            </Form.Item>

                                            <Form.Item
                                            label={"Contact de la firme de consultance"}
                                            >
                                            {getFieldDecorator('name_acco', {
                                            initialValue: this.state.client.name_acco,
                                            rules: [
                                                {
                                                    message: `${Labels.get("Contact de la firme de consultance")} ${Labels.get("is required")}`,
                                                    required: this.props.public,
                                                },
                                            ],
                                            })(
                                                <Input />
                                            )}
                                            </Form.Item>
                                        
                                        </>
                                    ) : null}

                                </Col>
                            </Row>   

                            {this.props.public ? (<Divider orientation="left">6. {Labels.get("Contacts")}</Divider>) : null}

                            <Row gutter={24}>
                            <Col span={12}>
                                        
                                    
                                        <Form.Item
                                            label={Labels.get("Presidency / general management")}
                                            >
                                            {getFieldDecorator('president', {
                                                initialValue: this.state.president,
                                                rules: [
                                                    {
                                                        required: this.props.public,
                                                        message: `${Labels.get("Presidency / general management")} ${Labels.get("is required")}`,
                                                    },
                                                ],
                                            })(
                                                <Address type="contact" key={this.state.client.id} />
                                            )}
                                        </Form.Item>
                                    
                            
                            </Col>
                                
                            <Col span={12}>    

                                
                                        <Form.Item
                                            label={Labels.get("Gestionnaire du comité écoresponsable")}
                                            >
                                                {getFieldDecorator('responsible', {
                                                    initialValue: this.state.responsible,
                                                    rules: [
                                                        {
                                                            required: this.props.public,
                                                            message: `${Labels.get("Gestionnaire du comité écoresponsable")} ${Labels.get("is required")}`,
                                                        },
                                                    ],
                                                })(
                                                    <Address type="contact" isEco key={this.state.client.id} />
                                                )}
                                        </Form.Item>
                                    
                                
                                </Col>
                            </Row>   
                        </>
                    ) : null}
                    
                    
                    {this.props.action != 3 && !this.props.isCarbon ? (
                    <>
                        <Divider orientation="left">{this.props.public ? '7.' : ''} {Labels.get("Confirmation de la demande")}</Divider>

                        <Row gutter={24}>
                            <Col span={16}>
                                        
                                <Form.Item 
                                    label={Labels.get("Nous sommes prêt à soumettre les documents requis pour l'analyse de notre dossier à ECOCERT Canada")}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('ready', {
                                            initialValue: this.state.client.ready,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        label=""
                                        className="checkbox"
                                        >
                                        {getFieldDecorator('autorise', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.client.autorise,
                                            rules: [
                                                {
                                                    required: this.props.public,
                                                    message: `Ceci ${Labels.get("is required")}`,
                                                },
                                            ],
                                        })(
                                            <Checkbox>
                                                J’autorise le Conseil des industries durables ainsi qu’ECOCERT à échanger des informations.
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label=""
                                        >
                                        {getFieldDecorator('correct', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.client.correct,
                                            rules: [
                                                {
                                                    required: this.props.public,
                                                    message: `Ceci ${Labels.get("is required")}`,
                                                },
                                            ],
                                        })(
                                            <Checkbox>Par la présente, je confirme que toutes les informations fournies sont correctes et complètes. </Checkbox>   
                                        )}
                                    </Form.Item>
                            
                            </Col>
                            
                            <Col span={8}>    
                            
                                            
                            </Col>
                        </Row>   
                    </>) : null}

                    {this.props.isCarbon && this.props.isMauricie ? (
                        <>
                            <Form.Item
                                label="L'entreprise est membre de"
                            >
                                {getFieldDecorator('membre_de', {
                                    initialValue: this.state.client.membre_de ? this.state.client.membre_de.split(",") : [],
                                    rules: [
                                        {
                                            required: this.props.public,
                                            message: "Vous devez être membre de l’une de ces 3 organisations pour participer.",
                                        },
                                    ],
                                })(
                                    <Checkbox.Group style={{ width: '100%' }}>
                                        <Row>
                                            <Col span={32}>
                                                <Checkbox value="muricie">GROUPÉ Mauricie + Rive-Sud</Checkbox>
                                            </Col>
                                            <Col span={32}>
                                                <Checkbox value="creneau">Créneau Design d’ameublement</Checkbox>
                                            </Col>
                                            <Col span={32}>
                                                <Checkbox value="machines">Créneau Machines</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>    
                                )}
                            </Form.Item>
                        </>
                    ) : null}   

                    {this.props.isCarbon && !this.props.isGranby ? (
                        <>
                            <Divider orientation="left">Inscription</Divider>
                            <Row gutter={24}>
                                

                                <Col span={12}>
                                    <h3>Étape 1 - 500 $</h3>
                                    <Form.Item>
                                        {getFieldDecorator('etape_1', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.client.etape_1,
                                        })(<Checkbox onClick={
                                            ({ target }) => {
                                                let client = this.state.client;
                                                    client.etape_1 = target.checked ? 1 : 0;
                                                this.setState({
                                                    client
                                                });
                                            }}>J’inscris mon entreprise pour la formation (étape 1)<br/>« Introduction au Programme CARBONE-LEADERSHIP<sup>MC</sup> »<br/>(5 cours / 5 ateliers).</Checkbox>)}
                                    </Form.Item>

                                    <Form.Item 
                                    label={<span>Mon entreprise correspond aux critères suivants:<br/>Chiffre d’affaires: 500 000 $ et plus</span>}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('chiffre', {
                                            initialValue: this.state.client.chiffre ? this.state.client.chiffre : 0,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col span={12}>
                                    <h3>Étape 2 - 1 500 $</h3>
                                    <Form.Item>
                                        {getFieldDecorator('etape_2', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(<Checkbox onClick={
                                            ({ target }) => {
                                                if(target.checked && this.state.client && !this.state.client.etape_1 && !this.props.isGranby){
                                                    Modal.info({
                                                        title: "Vous devez abosolument vous inscrire à l'étape 1 aussi.",
                                                        onOk: () =>{
                                                            let client = this.state.client;
                                                            client.etape_1 = 1;

                                                            this.setState({
                                                                client
                                                            });
                                                        },
                                                    });
                                                }
                                            }
                                        }>J’inscris mon entreprise pour l'étape 2 « Réaliser le bilan<br/>carbone et l’ACV d’un produit ».</Checkbox>)}
                                    </Form.Item>

                                    <Form.Item 
                                    label={<span>Mon entreprise correspond aux critères suivants:<br/>Chiffre d’affaires: 1 million et plus</span>}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('chiffre_1mil', {
                                            initialValue: this.state.client.chiffre_1mil ? this.state.client.chiffre_1mil : 0,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    <Divider orientation="left">Mon entreprise utilise</Divider>

                                    <Form.Item 
                                    label={Labels.get("Un système de conception assisté par ordinateur")}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('ordinateur', {
                                            initialValue: this.state.client.ordinateur ? this.state.client.ordinateur : 0,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    <Form.Item 
                                    label={Labels.get("Un module de nomenclature (BOM)")}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('bom', {
                                            initialValue: this.state.client.bom ? this.state.client.bom : 0,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    <Form.Item 
                                    label={Labels.get("Un module d’itinéraire (ROUTING)")}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('routing', {
                                            initialValue: this.state.client.routing ? this.state.client.routing : 0,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                    <Form.Item 
                                    label={Labels.get("Un système de ressources/inventaire d’entreprise")}
                                    //extra={Labels.get("Êtes-vous accompagné par un expert certifié CID dans le cadre de votre certification ou attestation ?")}
                                    >
                                        {getFieldDecorator('ressources', {
                                            initialValue: this.state.client.ressources ? this.state.client.ressources : 0,
                                        })(
                                            <Radio.Group name="radiogroup">
                                                <Radio value={0}>{Labels.get("No")}</Radio>
                                                <Radio value={1}>{Labels.get("Yes")}</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>
                                </Col>

                            </Row>
                        </>
                    ) : null}
                    
                    {this.props.public && this.props.product.category && !this.props.product.category.includes('Attestation') ? (
                    <div style={{fontSize: '12px'}} className="documents">
                        <p>*Documents qui seront à soumettre pour l’analyse de votre dossier</p>
                        <p>Tous les niveaux :</p>
                        <ul>
                            <li>Charte ou politique de développement durable – signée*</li>
                            <li>Diagnostique organisationnel</li>
                            <li>Plan stratégique de développement durable</li>
                            <li>Dernier compte rendu des réunions du comité de développement durable</li>
                            <li>Preuve de diffusion de la Charte de développement durable aux parties prenantes internes et
                        externes</li>
                            <li>Curriculum vitae de la « personne responsable » au sein de votre entreprise (si non accompagné par
                        un consultant)</li>
                        </ul>
                        <p>Niveau 2 ou supérieurs :</p>
                        <ul>
                            <li>Dernière revue de direction</li>
                            <li>Benchmark</li>
                            <li>Preuve d’activités de sensibilisation avec parties prenantes internes</li>
                        </ul>
                        <p>* Les documents non-signés ou signés de façon non-manuscrite ne sont pas valides.</p>
                    </div>) : null}

                    {!this.props.public ? (
                    <div className="hide-pdf">            
                        <Divider>Administration</Divider>       

                        <Row gutter={24}>
                            <Col span={12}>
                                
                                <Form.Item 
                                    label={Labels.get("Language")}
                                >
                                    {getFieldDecorator('lang', {
                                        initialValue: this.state.client.lang,
                                    })(
                                        <Select>
                                            <Option value="fr">Français</Option>
                                            <Option value="en">English</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                                <Form.Item 
                                    label={Labels.get("Status")}
                                >
                                {getFieldDecorator('crm_status', {
                                    initialValue: this.state.client.crm_status,
                                })(
                                    <SelectFromUrl url={`${localStorage.orgId ? localStorage.orgId : 1}/status`} idName="name" name="name" />,
                                )}
                                </Form.Item>

                                <Form.Item
                                    label="Certification"
                                    >
                                    {getFieldDecorator('certification', {
                                    initialValue: this.state.client.certification,
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>

                            </Col>
                            
                            <Col span={12}>    
                            
                            <Form.Item 
                                label={Labels.get("Registre")}
                            >
                                {getFieldDecorator('published', {
                                    valuePropName: 'checked',
                                    initialValue: this.state.client.published == 1,
                                })(
                                    <Checkbox>
                                        {Labels.get("Published")}
                                    </Checkbox>,
                                )}
                            </Form.Item>
                            
                            
                            <Form.Item
                                label={Labels.get("Comments")}
                                >
                                {getFieldDecorator('health', {
                                    initialValue: this.state.client.health,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Phone is required',
                                        },
                                    ],
                                })(
                                    <TextArea />
                                )}
                            </Form.Item>

                            <Form.Item
                                    label={Labels.get("Tags")}
                                    >
                                    {getFieldDecorator('tags', {
                                        initialValue: this.state.client.tags ? this.state.client.tags.map((tag) => {return tag.id}) : [],
                                        rules: [
                                            { 
                                                required: this.props.public, 
                                                message: `${Labels.get("Phone")} ${Labels.get("is required")}`,
                                            }
                                        ],
                                    })(
                                        <SelectFromUrl mode="multiple" url={`${localStorage.orgId ? localStorage.orgId : 1}/tags`} idName="id" name="name" />
                                    )}
                            </Form.Item>

                        </Col>
                        </Row>     
                    </div>
                    ) : null}


                    

                        <Form.Item className="hide-pdf">
                            
                            {!this.props.public ? (<>
                            <Button type="primary" htmlType="submit" className="pull-right" loading={this.state.loading}>
                                {!this.props.public ? Labels.get('Save') : 'Register' }
                            </Button>
                            <Button type="link" className="pull-right" loading={this.state.downloadLoading} onClick={() => this.downloadPdf()}>{Labels.get("Download PDF")}</Button>
                            <Button type="link" className="pull-right" onClick={() => this.props.drawer.current.hideDrawer()}>{Labels.get("Close")}</Button>
                            </>) : null}

                        </Form.Item>

                        

                    </Form>

                    

                    </TabPane>
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Contacts")} key="2">
                            <Contacts client={this.state.client} key={this.state.client.id} />
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Invoices")} key="3">
                            <Orders reLoad={this.reLoad} ref={this.invoiceRef} client={this.state.client} key={this.state.client.id} />
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Transactions")} key="4">
                            <Transactions clientId={this.state.client.id} reLoad={this.reLoad} ref={this.transactionsRef} key={this.state.client.id} />        
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Documents")} key="5">
                            <Files client_id={this.state.client.id} dynamicProp="client_id" key={this.state.client.id} />        
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("History")} key="6">
                            <History row={this.state.client} key={this.state.client.id} type="client" />
                        </TabPane>
                    ) : null}     
                </Tabs>
            </>
        );
    }
}

const WrappedClientForm = Form.create({ name: 'client_form_crm' })(NormalClientForm);

export default WrappedClientForm;
