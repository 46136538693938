import React, { Component } from "react";
import { Timeline, Icon, Button, Modal, notification } from 'antd';

import DynamicForm from '../components/DynamicForm';

import Zenfy from "../libs/Zenfy";
import Labels from "../libs/Labels";

import moment from 'moment';


class History extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          rows: [],
          loading: false,
          showModal: false,
          modalLoading: false,
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
      this.load();
    }

    load = () => {

      if(this.props.row && !this.props.row.id){
        return;
      }

      Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/histories?${this.props.type}_id=${this.props.row.id}`).then((res) => {

        let histories = [];

        console.log(this.props.row);

        histories.push({
          text: `${Zenfy.capitalizeFirstLetter(this.props.type)} created on ${this.props.row.created_at}`,
          action: 'created'
        });

        res.data.map((history) => {

          let action = Zenfy.capitalizeFirstLetter(history.action);

          histories.push({
            text: `${action} on ${history.created_at}`,
            desc: history.data1 == 1 ? "" : history.data1,
            action: history.action,
          });
        });

        //console.log(this.state.row);

        this.setState({
            loading: false,
            rows: Zenfy.setKeysToList(histories),
        });
      });
    }

    handleModalOk = () => {
      this.formRef.current.validateFieldsAndScroll((err, values) => {
        if (!err) {
          
          values[`${this.props.type}_id`] = this.props.row.id;
          values.org_id = localStorage.orgId ? localStorage.orgId : 1;

          if(!values.created_at){
            values.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
          }
        
          Zenfy.post('histories', values).then((res) => {
              this.load();

              notification.success({
                  message: Labels.get("History saved with success!"),
                  placement: 'bottomLeft',
              });

              this.setState({
                modalLoading: false,
                showModal: false,
              });

              this.formRef.current.getForm().resetFields();
          });


        }
      });
    }

    render() {
        
      return (
        <>
            
            <Modal
                title={Labels.get("Add history")}
                visible={this.state.showModal}
                onOk={this.handleModalOk}
                okText={Labels.get("Add")}
                onCancel={() => {
                  this.setState({
                    showModal: false,
                    modalLoading: false,
                  });
                }}
                okButtonProps={{loading: this.state.modalLoading}}
            >
              <DynamicForm 
                  keys={
                    [{
                        title: Labels.get('Event'),
                        dataIndex: 'action',
                        key: 'actiona',
                        required: true,
                    },{
                        title: Labels.get('Data'),
                        dataIndex: 'data1',
                        key: 'data1',
                        type: 'text',
                        required: false,
                    },{
                        title: Labels.get('Date'),
                        dataIndex: 'created_at',
                        key: 'created_at',
                        type: 'date',
                        required: false,
                    }]
                }
                type="history" 
                row={{}}
                ref={this.formRef} 
                hideFooter 
              />
            </Modal>

            <Button type="dashed" className="pull-right" style={{ marginBottom: 16 }} onClick={() => {
                    this.setState({
                      showModal: true,
                    });
                }}>
                    <Icon type="plus" /> {Labels.get("Add")}
            </Button>

            <div className="clearfix" />

            <Timeline mode="alternate">
                {this.state.rows.map((row, index) => (
                  <Timeline.Item color={row.action == 'paid' ? 'green' : undefined}>
                    <p>{row.text}</p>
                    <p>{row.desc}</p>
                  </Timeline.Item>
                ))}
            </Timeline>
        </>
      );
    }
  }
 
  export default History;