import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Table, Modal, Form, Input, notification } from 'antd';
import Currency from 'react-currency-formatter';
import moment from 'moment';
import Zenfy from "../libs/Zenfy";
import Labels from '../libs/Labels';
import DrawerTableForm from './DrawerTableForm';

class Transactions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            showModal: false,
            amount: 0,
            selectedRecord: {},
        }

        this.drawerRef = React.createRef();
    }

    load = () => {
        this.drawerRef.current.load();
    }

    delete = (record) => {
        Zenfy.delete(`transactions/${record.id}`).then((res) => {
            this.load();
            this.props.reLoad();
        });
    }

    refund = () => {

        const { confirm } = Modal;

        confirm({
            title: Labels.get("Do you want to refund this transaction?"),
            okText: Labels.get("Refund"),
            onOk: () => {
                this.state.selectedRecord.refund_amount = this.state.amount;
                Zenfy.post("refund", this.state.selectedRecord).then((res) => {
                    
                    this.setState({
                        showModal: false,
                    });

                    Zenfy.post("transactions", {
                        type: 'credit_note',
                        amount: -Math.abs(this.state.selectedRecord.refund_amount),
                        //txn_id: this.state.selectedRecord.txn_id,
                        org_id: this.state.selectedRecord.org_id,
                        invoice_id: this.state.selectedRecord.invoice_id,
                        client_id: this.state.selectedRecord.client_id,
                    }).then((res) => {
                        this.load();
                        this.props.reLoad();
                    });
                    
                    notification.success({
                        message: Labels.get("Refunded with success!"),
                        placement: 'bottomLeft',
                    });
                });
            },
            onCancel: () => {
              
            },
        });
        
    }

    render() {

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {return <a href="javascript:;" onClick={()=>this.drawerRef.current.rowClick(record)}>{record.txn_id != null ? record.txn_id : record.id}</a> },
            sorter: (a, b) => a.id - b.id,
            hide: true,
        },{
            title: Labels.get("Amount"),
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Name',
            className: 'hide',
            dataIndex: 'name',
            key: 'name',
        },{
            title: 'Message',
            className: 'hide',
            dataIndex: 'msg',
            key: 'msg',
            type: 'text'
        },{
            title: 'Invoice Id',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
            hide: true,
        },{
            title: 'Campaign',
            dataIndex: 'cmp_id',
            key: 'cmp_id',
            type: 'select_url',
            params: {url: `${localStorage.orgId ? localStorage.orgId : 1}/cmps`, idName: "id", name:"name" },
        },{
            title: 'Anonymous',
            dataIndex: 'anonymous',
            key: 'anonymous',
            type: 'switch',
        }/*,{
            title: 'Recurring',
            dataIndex: 'recurring',
            key: 'recurring',
            sorter: (a, b) => a.recurring - b.recurring,
            render: (text) => {return text == 1 ? "Yes" : "No"},
            type: 'yesno'
        },{
            title: 'Recurring End',
            dataIndex: 'recurring_end',
            key: 'recurring_end',
            render: (text) => {return text ? moment(text).format("MMMM Do YYYY") : ''},
            sorter: (a, b) => new Date(a.recurring_end) - new Date(b.recurring_end),
            type: 'date' 
        }*/,{
            title: Labels.get("Reference"),
            dataIndex: 'reference',
            key: 'reference',
            sorter: (a, b) => a.reference - b.reference,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type - b.type,
            render: (text) => Zenfy.capitalizeFirstLetter(text && text == 'credit_note' ? "Credit Note" : text),
            type: 'money_type'
        },{
            title: Labels.get('Created Date'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            type: 'date'
        },{
            title: 'Force LIVE',
            dataIndex: 'force_live',
            key: 'force_live',
            type: 'switch',
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

              const menu = (
                <Menu>
                    <Menu.Item key="3" onClick={() => this.drawerRef.current.rowClick(record)}>Edit</Menu.Item>
                    {record.type == 'credit' ? (
                        <Menu.Item key="1" onClick={() => {
                            this.setState({
                                amount: record.amount,
                                selectedRecord: record,
                                showModal: true,
                            });
                        }}>{Labels.get("Refund")}</Menu.Item>
                    ) : null}
                    <Menu.Item key="2" onClick={() => this.delete(record)}>{Labels.get("Delete")}</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }
    ];

    
    return (
        <>
            <Modal
                title={Labels.get("Refund transaction")}
                visible={this.state.showModal}
                onOk={this.refund}
                okText={Labels.get("Refund")}
                onCancel={()=> this.setState({
                    showModal: false,
                })}
                cancelText={Labels.get("Cancel")}
            >
                <p>{Labels.get("Refunds take 5-10 days to appear on a customer's statement.")}</p>
                <Form.Item
                    label={Labels.get("Amount")}
                >
                    <Input 
                        value={this.state.amount} 
                        onChange={(e) => {
                            this.setState({
                                amount: e.target.value
                            });
                        }}
                    />
                </Form.Item>
            </Modal>

            <DrawerTableForm 
                ref={this.drawerRef} 
                columns={columns} 
                hideFooter 
                loading={this.state.loading} 
                modal 
                type="transaction" 
                load={`transactions${this.props.clientId ? `?client_id=${ this.props.clientId}` : ''}`}
            />
        </>
    );

    }
}

export default Transactions;