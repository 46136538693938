import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag, notification, Modal } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";

class Drivers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            roleCategories: [{text: 'Driver', value: 'driver'}, {text: 'Broker', value: 'broker'}, {text: 'Yard', value: 'yard'}]
        }

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    copyToClipboard = (string) => {
        Zenfy.copyToClipboard(string);
        notification.success({
            message: `Copied with success!`,
            placement: 'bottomLeft',
        });
    }

    delete = (record) => {
        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this driver?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`staff/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });
    }

    beforeSave = (data) => {
        if(data.telephone){
            if(data.telephone.charAt(0) != '+'){
                if(data.telephone.charAt(1) != '1'){
                    data.telephone = '1' + data.telephone;
                }

                data.telephone = '+' + data.telephone;
            }

            data.telephone = data.telephone.replace(/ /g,'').replace("(", "").replace(")", "");
        }

        return data;
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: true,
        },{
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
            type: 'switch',
        },{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },{
            title: 'Phone',
            dataIndex: 'telephone',
            key: 'telephone',
            render: (text, record) => <>{Zenfy.normalizeInput(text)} <Icon type="copy" onClick={() => this.copyToClipboard(record.telephone)} /></>,
        },{
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (text, record) => <span>https://zenfy.co/#/driver/{record.id} <Icon type="copy" onClick={() => this.copyToClipboard(`https://zenfy.co/#/driver/${record.id}`)} /></span>,
            type: 'skip',
        },{
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            className: 'hide',
        },{
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            className: 'hide',
        },{
            title: 'ZIP/Postal Code',
            dataIndex: 'postal',
            key: 'postal',
            className: 'hide',
        },{
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            className: 'hide',
        },{
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            className: 'hide',
        },{
            title: 'License',
            dataIndex: 'license',
            key: 'license',
            className: 'hide',
        },{
            title: 'License Exp Date',
            dataIndex: 'license_exp',
            key: 'license_exp',
            className: 'hide',
            type: 'date',
        },{
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => <Tag color="blue">{Zenfy.capitalizeFirstLetter(text)}</Tag>,
            type: 'role',
            filters: this.state.roleCategories,
            onFilter: (value, record) => record.role.indexOf(value) === 0,
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.copyToClipboard(record)}>Copy driver link</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm columns={columns} ref={this.drawerRef} form={null} type="staff" load="staff" beforeSave={this.beforeSave} />
        </>
        );
    }
}

export default Drivers;