import React, { Component } from "react";
import {
  Route,
  NavLink,
  Redirect,
  HashRouter,
  Link
} from "react-router-dom";

import Labels from './libs/Labels';

import DashboardPage from "./pages/Dashboard";
import AdminPage from "./pages/admin/Admin";
import MarketingPage from "./pages/marketing/Marketing";

import AccountingPage from "./pages/accounting/Accounting";
import ReportsPage from "./pages/reports/Reports";
import TradingPage from "./pages/trading/Trading";

import SettingsPage from "./pages/Settings";
import ToolsPage from "./pages/Tools";
import LoginPage from "./pages/Login";
import ClientsPage from "./pages/Clients";
import ClientsCrm from "./pages/crm/Clients";
import PrivateAccess from "./components/PrivateAccess";
import RegisterPage from "./pages/Register";
import DonationPage from "./pages/Donation";
import FormCrm from "./pages/crm/FormCrm";
import Carbone from "./pages/crm/Carbone";
import Orders from "./pages/Orders";
import DriverPage from "./pages/Driver";
import Dispatch from "./pages/Dispatch";
import OrderPage from "./pages/Order";
import ScanPage from "./pages/Scan";

import DynamicForm from "./components/DynamicForm";
import Zenfy from "./libs/Zenfy";

import "antd/dist/antd.css";
import './App.css';
import './App.less';

import {
  Layout, Menu, Breadcrumb, Icon, Alert, Tooltip, Button, Dropdown, Modal
} from 'antd';

const { SubMenu } = Menu;
const {
  Header, Content, Footer, Sider,
} = Layout;

class App extends Component {

  constructor(props) {
      super(props);

      let urls = window.location.hash.split('/');

      let tab = urls[1] ? urls[1] : 'dashboard';

      if(urls.length > 2){
        //tab = urls.pop();
      }

      if(tab == 'login'){
        tab = 'dashboard';
      }

      this.state = {
          selectedTab: tab,
          showModal: false,
      };

      this.formRef = React.createRef();
  }


  logout = () => {
    delete localStorage.zenfy_jwt;
    delete localStorage.orgId;
    delete localStorage.zenfy_role;
    delete localStorage.url;
    delete localStorage.clientOrgId;

    this.forceUpdate();
  }

  handleModalOk = () => {
    this.formRef.current.validateFieldsAndScroll((err, values) => {
        if (!err) {
            this.setState({
              modalLoading: true,
            });
            values.id = localStorage.orgId;
            Zenfy.post('orgs', values).then((res) => {
              this.setState({
                  showModal: false,
                  modalLoading: false,
              });
            });
        }
    });
}

  language = (e) => {
    e.preventDefault();

    if(localStorage.Lang == 'en'){
      localStorage.Lang = 'fr';
    }else {
      localStorage.Lang = 'en';
    }

    this.forceUpdate();
  }

  render() {

    const menu = (
      <Menu>
        <Menu.Item key="0">
          Account
        </Menu.Item>
        <Menu.Item key="1" onClick={() => {
          this.setState({
            showModal: true,
          });
        }}>
          Organisation
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={this.logout}> 
          Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <>
      <HashRouter>

          <Layout>

          <Route path="/login" component={LoginPage} />

          <Route path="/register" component={RegisterPage} />

          <Route path="/:org/register" component={RegisterPage} />

          <Route path="/:org/donation" component={DonationPage} />
          
          <Route path="/:org/form" component={FormCrm} />

          <Route path="/:org/carbone" component={Carbone} />

          <Route path="/:org/scan" component={ScanPage} />

          <Route path="/driver/:id" component={DriverPage} />

          <Route path="/:org/order/:client" component={OrderPage} />

          <PrivateAccess>
              <Header className="header">

              <Modal
                title={Labels.get("Organisation")}
                visible={this.state.showModal}
                onOk={this.handleModalOk}
                okText={Labels.get("Save")}
                onCancel={() => {
                  this.setState({
                    showModal: false,
                    modalLoading: false,
                  });
                }}
                okButtonProps={{loading: this.state.modalLoading}}
            >
              <DynamicForm 
                  keys={
                    [{
                        title: Labels.get('Name'),
                        dataIndex: 'name',
                        key: 'org_name',
                        required: true,
                    },{
                        title: Labels.get('Website'),
                        dataIndex: 'website',
                        key: 'website',
                        required: false,
                    }]
                }
                type="orgs" 
                row={JSON.parse(localStorage.getItem('org'))}
                ref={this.formRef} 
                hideFooter 
              />
            </Modal>

                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={this.state.selectedTab}
                  style={{ lineHeight: '64px' }}
                >
                  <Menu.Item key="dashboard">
                    <NavLink to="/">{Labels.get("Dashboard")}</NavLink>
                  </Menu.Item>

                  {localStorage.type && localStorage.type == 'crm' ? (
                    <Menu.Item key="crm">
                      <NavLink to="/crm/clients">Contacts</NavLink>
                    </Menu.Item>
                  ) : null}

                  {localStorage.isDispatch == 1 ? (
                    <Menu.Item key="orders">
                      <NavLink to="/orders">{localStorage.type == 'crm' ? 'Proposals' : 'Orders'}</NavLink>
                    </Menu.Item>
                  ) : null}

                  {localStorage.isDispatch == 1 && localStorage.zenfy_role != 'orders' && (localStorage.type && localStorage.type != 'crm')  ? (
                    <Menu.Item key="dispatch">
                      <NavLink to="/dispatch">Dispatch</NavLink>
                    </Menu.Item>
                  ) : null}

                  {localStorage.type && localStorage.type != 'crm' && localStorage.isDispatch == 0 ? (
                    <Menu.Item key="clients">
                      <NavLink to="/clients">Members</NavLink>
                    </Menu.Item>
                  ) : null}

                  {(localStorage.isDispatch == 1 && localStorage.zenfy_role == 'admin') || localStorage.type == 'crm'  ? (
                    <Menu.Item key="accounting">
                    <NavLink to="/accounting/invoices">{Labels.get("Accounting")}</NavLink>
                  </Menu.Item> 
                  ) : null}

                  <Menu.Item key="marketing">
                    <NavLink to="/marketing/campaigns">Marketing</NavLink>
                  </Menu.Item>

                  {localStorage.isDispatch == 1 ? (
                    <Menu.Item key="admin">
                      <NavLink to="/admin/customers">Administration</NavLink>
                    </Menu.Item>
                  ) : (
                    <Menu.Item key="admin">
                      <NavLink to="/admin/products">Administration</NavLink>
                    </Menu.Item> 
                  )}

                  {localStorage.isDispatch == 1 && localStorage.zenfy_role == 'admin' ? (
                  <Menu.Item key="reports">
                    <NavLink to="/reports/statements">{Labels.get("Reports")}</NavLink>
                  </Menu.Item> 
                  ) :  (<Menu.Item key="reports">
                  <NavLink to={`/reports/${localStorage.type == 'crm' ? 'transactions' : 'attendances'}`}>{Labels.get("Reports")}</NavLink>
                </Menu.Item>) }

                <Dropdown overlay={menu} className="pull-right" >
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {localStorage.userName} <Icon type="down" />
                  </a>
                </Dropdown>    

                <a href="#" onClick={this.language} className="pull-right" style={{marginRight: '15px', textTransform: 'uppercase'}}>{localStorage.Lang ? (localStorage.Lang == 'en' ? 'fr' : 'en') : 'fr'}</a>  

                </Menu>

              </Header>
              <Content style={{ padding: '0 50px' }}>
                <Breadcrumb style={{ margin: '16px 0', display: 'none' }}>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>List</Breadcrumb.Item>
                  <Breadcrumb.Item>App</Breadcrumb.Item>
                </Breadcrumb>
                <Layout style={{ padding: '24px 0 0 0', background: '#fff' }}>
                  <Content style={{ padding: '0 24px', minHeight: 500 }}>
                      <Route exact path="/" component={DashboardPage}/>
                      <Route path="/admin" component={AdminPage}/>
                      <Route path="/settings" component={SettingsPage}/>
                      <Route path="/tools" component={ToolsPage}/>
                      <Route path="/clients" component={ClientsPage}/>
                      <Route path="/crm/clients" component={ClientsCrm}/>
                      <Route path="/orders" component={Orders}/>
                      <Route path="/dispatch" component={Dispatch}/>
                      <Route path="/accounting" component={AccountingPage}/>
                      <Route path="/reports" component={ReportsPage}/>
                      <Route path="/trading" component={TradingPage}/>
                      <Route path="/marketing" component={MarketingPage}/>
                  </Content>
                </Layout>
              </Content>
            </PrivateAccess>

              <Footer style={{ textAlign: 'center', display: 'none' }}>
                
              </Footer>

            </Layout>

        </HashRouter>
      </>
    );
  }
}

export default App;
