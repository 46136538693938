import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select
} from 'antd';

import SelectFromUrl from './SelectFromUrl';

import CreditCard from '../components/CreditCard';
import Zenfy from "../libs/Zenfy";

import moment from 'moment';

const { Option } = Select;

class NormalPayForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            invoice: props.invoice,
            loading: false,
            showCreditCardForm: false,
        }

        this.creditCard = React.createRef();
        window.creditCard = this.creditCard;
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.invoice.id !== this.state.invoice.id){

            this.props.form.resetFields();

            this.setState({
                invoice: nextProps.invoice,
                showCreditCardForm: false,
            });

        }

    }
    
    selectOnChange = (value, event) => {
        this.setState({
            showCreditCardForm: value == 'credit' ? true : false,
        });
    } 

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>

                <Form className="pay-form">

                    {!this.props.hideType ? (<>
                        <Form.Item {...formItemLayout}
                            label="Type">
                            {getFieldDecorator('type', {
                                rules: [{ required: false, message: 'Please select your type!' }],
                                initialValue: "cash",
                            })(
                                <Select
                                    placeholder="Select a option"
                                    onChange={this.selectOnChange}
                                >
                                    <Option value="cash">Cash</Option>
                                    <Option value="e-transfer">E-transfer</Option>
                                    <Option value="cheque">Check</Option>
                                    <Option value="credit">Credit Card</Option>
                                    <Option value="pledge">Pledge</Option>
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item
                        {...formItemLayout}
                        label="Date"
                        >
                        {getFieldDecorator('created_at', {
                            initialValue: moment(),
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <DatePicker />
                        )}
                    </Form.Item></>
                    ) : null}

                    {!this.props.hideType ? (
                    <Form.Item
                        {...formItemLayout}
                        label="Amount"
                        >
                        {getFieldDecorator('amount', {
                            initialValue: this.state.invoice && this.state.invoice.balance ? Math.abs(this.state.invoice.balance + (this.props.taxes ? this.state.invoice.balance * 0.14975 : 0)).toFixed(2) : 0,
                            rules: [
                                {
                                    required: true,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Input disabled={this.props.disabledAmount} />
                        )}
                    </Form.Item>) : null}

                    {!this.props.hideReference ? (
                        <Form.Item
                            {...formItemLayout}
                            label="Reference"
                            >
                            {getFieldDecorator('reference', {
                                initialValue: this.state.invoice.reference,
                                rules: [
                                    {
                                        required: false,
                                        message: 'Amount is required',
                                    },
                                ],
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    ) : null}

                    {this.props.showCmp ? (
                            <Form.Item
                                {...formItemLayout}
                                label="Campaign"
                            >
                                {getFieldDecorator('cmp_id', {
                            
                                })(
                                    <SelectFromUrl url={`${localStorage.orgId ? localStorage.orgId : 1}/cmps`} idName="id" name="title" />
                                )}
                            </Form.Item>
                    ) : null}
                    
                    {(this.state.showCreditCardForm || this.props.hideType) && !this.props.hideCredit ? (
                        <CreditCard discountCallback={(amount) => {
                            let invoice = this.state.invoice;
                            invoice.balance = amount;
                            this.setState({
                                invoice
                            });
                        }} 
                        cur={this.props.cur} 
                        client={this.props.client} 
                        amount={this.state.invoice ? Math.abs(this.state.invoice.balance) : 0} 
                        ref={this.creditCard} 
                        showRecap={this.props.showRecap} 
                        taxes={this.props.taxes} 
                        force_monthly={this.props.force_monthly} 
                        hideMonthly={this.props.hideMonthly} />
                    ) : null}
                    
                </Form>

            </>
        );
    }
}

const WrappedPayForm = Form.create({ name: 'pay_form' })(NormalPayForm);
export default WrappedPayForm;
