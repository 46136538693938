import React, { Component } from "react";
import {
Form, Input, Tabs, DatePicker, Select, Avatar, Upload, Button, Icon, notification, Checkbox, Modal, InputNumber, Timeline, Col, Row, Divider, Radio 
} from 'antd';
import moment from 'moment';
import InvoiceProducts from '../../components/InvoiceProducts';
import Transactions from '../../components/Transactions';
import Address from '../../components/Address';
import Contacts from '../../components/Contacts';
import Status from '../../components/Status';
import History from '../../components/History';

import Zenfy from "../../libs/Zenfy";
import Webcam from "react-webcam";

import Labels from '../../libs/Labels';
import SelectFromUrl from '../../components/SelectFromUrl';
import Orders from './OrdersNew';
import Files from '../../components/Files';

const { Option } = Select;

class NormalTriacomClientForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: props.client,
            loading: false,
            fileList: [],
            showWebCamModal: false,
            webCamModalLoading: false,
            responsible: {},
            president: {},
        }

        this.transactionsRef = React.createRef();
        this.invoiceRef = React.createRef();
        this.webCamRef = React.createRef();
        window.clientForm = this;
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.client.id !== this.state.client.id){

            this.props.form.resetFields();

            this.setState({
                fileList: [],
                client: nextProps.client
            });

        }

    }

    handleUploadChange = info => {
        let fileList = [...info.fileList];
    
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
    
        this.setState({ fileList });
    };

    addContact = (contact) => {

        if(!contact.name){
            return;
        }

        contact.org_id = localStorage.orgId;
        contact.client_id = this.state.client.id;

        Zenfy.post('contacts', contact).then((res) => {
            console.log(res);
        });
    }

    addClient = (data, callback) => {

        this.setState({
          loading: true
        });

        data.id = this.state.client.id ? this.state.client.id : 0;
        data.org_id = localStorage.orgId ? localStorage.orgId : 1;

        let president = data.president;
        let responsible = data.responsible;

        if(data.president){
            delete data.president;
        }

        if(data.responsible){
            delete data.responsible;
        }

        if(data.responsible){
            data.president.type = 'responsible';
            this.addContact(data.responsible);
            delete data.responsible;
        }

        if(this.state.fileList && this.state.fileList.length > 0){
            data.picture = `${Zenfy.getRoot()}uploads/${this.state.fileList[0].response}`;
        }

        if(data.picture && data.picture.file){
            data.picture = `${Zenfy.getRoot()}uploads/${data.picture.file.response}`;
        }

        if(data.address && data.address.address && Zenfy.isType('object', data.address) && !data.address.id){
            data = {...data, ...data.address};
        }else{
            delete data.address;
        }

        if(data.year && data.year.year && Zenfy.isType('object', data.year) && !data.year.id){
            data = {...data, ...data.year};
        }else{
            delete data.year;
        } 

        if(data.countries){
            data.countries = data.countries.toString();
        }

        Zenfy.post('clients', data).then((res) => {

            let client = this.state.client;
            client.id = res.data;
            client.picture = data.picture;

            this.setState({
                client,
                loading: false,
            });

            if(president){
                president.id = this.state.president.id;
                president.type = 'president';
                this.addContact(president);
            }

            if(responsible){
                responsible.id = this.state.responsible.id;
                responsible.type = 'responsible';
                this.addContact(responsible);
            }

            if(!this.props.public){
                notification.success({
                    message: `Contact saved with success!`,
                    placement: 'bottomLeft',
                });
            }

            if(this.props.reLoad){
                this.props.reLoad(res.data);
            }

            if(callback){
                callback(res);
            }
        })

    }

    handleSubmit = (e, callback, errorCallBack) => {
        if(e){
            e.preventDefault();
        }

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.addClient(values, callback);
            }else{
                if(errorCallBack){
                    errorCallBack();
                }
            }
        });
    }

    reLoad = () => {
        if(this.props.reLoad){
            this.props.reLoad();
        }
    
        if(this.transactionsRef.current){
            this.transactionsRef.current.load();
        }
        
        if(this.invoiceRef.current){
            this.invoiceRef.current.drawerRef.current.load();
        }
    }

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();
        formData.append('file', file);

        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);
        });
    }

    nameOnChange = (e) => {
        if(e.target.value){
            let name = e.target.value;
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    name
                },
            }));
        }
    }

    accompagnementOnChange = (e) => {
        let accompagnement = e.target.value;
        
        this.setState((prevState) => ({
            client: {
                ...prevState.client,
                accompagnement
            },
        }));
    }

    controverseOnChange = (e) => {
        let controverse = e.target.value;
        
        this.setState((prevState) => ({
            client: {
                ...prevState.client,
                controverse
            },
        }));
    }
    
    emailOnChange = (e) => {
        if(e.target.value){
            let email = e.target.value;
            this.setState((prevState) => ({
                client: {
                    ...prevState.client,
                    email
                },
            }));

            if(Zenfy.validateEmail(email)){
                Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/clients`, {
                    email: email,
                }).then((res) => {
                    if(res.data && res.data.hasAccount){
                        this.setState({
                            clientExist: true,
                        });
                    }else{
                        this.setState({
                            clientExist: false,
                        });
                    }
                });
            }
            
        }
    }

    handleWebCamModalOk = () => {
        const imageSrc = this.webCamRef.current.getScreenshot();

        let formData = new FormData();
        formData.append('file', imageSrc);

        this.setState({
            webCamModalLoading: true,
        })

        Zenfy.post('uploadfile', formData).then(res => {

            let client = this.state.client;
                client.picture = `${Zenfy.getRoot()}uploads/${res.data}`;

            this.setState({
                client,
                showWebCamModal: false,
                webCamModalLoading: false,
            });

        });
    }
    
    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 4,
              },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>
                {!this.props.public ? (
                    <>
                        <Avatar size={100} icon="user" style={{marginBottom: '20px'}} src={this.state.client.picture} />

                        <Status step={this.state.client.crm_status} />

                        <div className="clearfix" />
                    </>
                ) : null}

                <Modal
                     title={Labels.get("Capture")}
                     visible={this.state.showWebCamModal}
                     onOk={this.handleWebCamModalOk}
                     okText={Labels.get("Capture")}
                     onCancel={()=> this.setState({
                        showWebCamModal: false,
                     })}
                     okButtonProps={{loading: this.state.webCamModalLoading}}
                >
                    <Webcam
                        audio={false}
                        height={465}
                        ref={this.webCamRef}
                        screenshotFormat="image/jpeg"
                        style={{width: '100%'}}
                        videoConstraints={{
                            width: 800,
                            height: 800,
                            facingMode: "user"
                        }}
                    />
                </Modal>
                
                <Tabs defaultActiveKey="1" className={this.props.public ? 'public' : ''}>
                    <TabPane tab="Info" key="1">

                        <Form onSubmit={this.handleSubmit}>

                        <Row gutter={24}>
                                
                                <Col span={12}>

                                    <Form.Item
                                        label={Labels.get("Lead name")}
                                    >
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.client.name,
                                        rules: [
                                            { 
                                                required: true,
                                                message: `${Labels.get("Lead name")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Input onChange={this.nameOnChange} />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Website")}
                                    >
                                    {getFieldDecorator('web', {
                                        initialValue: this.state.client.web,
                                        rules: [
                                            { 
                                                required: this.props.public,
                                                message: `${Labels.get("Website")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Address")}
                                    >
                                    {getFieldDecorator('address', {
                                        initialValue: this.state.client,
                                        rules: [
                                            { 
                                                required: this.props.public,
                                                message: `${Labels.get("Address")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Address type="address" />
                                    )}
                                    </Form.Item>
                                    
                                   <Form.Item
                                        label={Labels.get("Logo")}
                                            >
                                            {getFieldDecorator('picture', {
                                                initialValue: this.state.client.picture,
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <>
                                                    <Upload name="picture" onChange={this.handleUploadChange} customRequest={this.uploadFile} showUploadList={true} fileList={this.state.fileList} >
                                                        <Button>
                                                            <Icon type="upload" /> {Labels.get("Click to Upload")}
                                                        </Button>
                                                    </Upload>
                                                </>
                                            )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Notes")}
                                    >
                                    {getFieldDecorator('health', {
                                        initialValue: this.state.client.health,
                                        rules: [
                                            { 
                                                required: this.props.public,
                                                message: `${Labels.get("Notes")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <Input.TextArea />
                                    )}
                                    </Form.Item>

                                    <Form.Item 
                                        label={Labels.get("Status")}
                                    >
                                    {getFieldDecorator('crm_status', {
                                        initialValue: this.state.client.crm_status,
                                    })(
                                        <SelectFromUrl url={`${localStorage.orgId ? localStorage.orgId : 1}/status`} idName="name" name="name" />,
                                    )}
                                    </Form.Item>
                                
                                </Col>

                                <Col span={12}>

                                    <Form.Item
                                        label={Labels.get("Email")}
                                        >
                                        {getFieldDecorator('email', {
                                            initialValue: this.state.client.email,
                                            rules: [
                                                /*{
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Email is required',
                                                },*/
                                            ],
                                        })(
                                            <Input onChange={this.emailOnChange} />
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Value")}
                                    >
                                    {getFieldDecorator('crm_value', {
                                        initialValue: this.state.client.crm_value,
                                        rules: [
                                            { 
                                                required: false,
                                                message: `${Labels.get("Lead name")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <InputNumber style={{width: '100%'}} />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Assign to")}
                                    >
                                    {getFieldDecorator('crm_assign', {
                                        initialValue: this.state.client.crm_assign,
                                        rules: [
                                            { 
                                                required: false,
                                                message: `${Labels.get("Lead name")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <SelectFromUrl url={`${localStorage.orgId ? localStorage.orgId : 1}/staff`} idName="name" name="name" />,
                                    )}
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label={Labels.get("Next Follow Up")}
                                    >
                                    {getFieldDecorator('crm_followup', {
                                        initialValue: this.state.client.crm_followup ? moment(this.state.client.crm_followup, "YYYY-MM-DD HH:mm") : null,
                                        rules: [
                                            { 
                                                required: false,
                                                message: `${Labels.get("Lead name")} ${Labels.get("is required")}` 
                                            }
                                        ],
                                    })(
                                        <DatePicker showTime={{minuteStep: 15, format: 'HH:mm'}} format={"YYYY-MM-DD HH:mm"} />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Phone")}
                                    >
                                    {getFieldDecorator('telephone', {
                                        initialValue: Zenfy.normalizeInputPhone(this.state.client.telephone),
                                        normalize: (value) => {
                                            return Zenfy.normalizeInputPhone(value);
                                        },
                                        rules: [
                                            { 
                                                required: this.props.public, 
                                                message: `${Labels.get("Phone")} ${Labels.get("is required")}`,
                                            }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                    </Form.Item>

                                    <Form.Item
                                        label={Labels.get("Tags")}
                                    >
                                    {getFieldDecorator('tags', {
                                        initialValue: this.state.client.tags ? this.state.client.tags.map((tag) => {return tag.id}) : [],
                                        rules: [
                                            { 
                                                required: this.props.public, 
                                                message: `${Labels.get("Phone")} ${Labels.get("is required")}`,
                                            }
                                        ],
                                    })(
                                        <SelectFromUrl mode="multiple" url={`${localStorage.orgId ? localStorage.orgId : 1}/tags`} idName="id" name="name" />
                                    )}
                                    </Form.Item>
                                
                                </Col>

                        </Row>

                        <Form.Item>
                            
                            {!this.props.public ? (<>
                            <Button type="primary" htmlType="submit" className="pull-right" loading={this.state.loading}>
                                {!this.props.public ? 'Save' : 'Register' }
                            </Button>

                                <a href="javascript:;" className="pull-right" style={{margin: '-4px 20px 0px 0px'}} onClick={() => this.props.drawer.current.hideDrawer()} >Close</a>
                            </>) : null}

                        </Form.Item>

                        

                    </Form>

                    

                    </TabPane>
                    {!this.props.public ? (
                        <TabPane tab="Contacts" key="2">
                            <Contacts client={this.state.client} />
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Invoices")} key="3">
                            <Orders reLoad={this.reLoad} ref={this.invoiceRef} client={this.state.client} key={this.state.client.id} />
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Transactions")} key="4">
                            <Transactions clientId={this.state.client.id} reLoad={this.reLoad} ref={this.transactionsRef} key={this.state.client.id} />        
                        </TabPane>
                    ) : null}    
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("Documents")} key="5">
                            <Files client_id={this.state.client.id} dynamicProp="client_id" key={this.state.client.id} />        
                        </TabPane>
                    ) : null}
                    {!this.props.public ? (
                        <TabPane tab={Labels.get("History")} key="6">
                            <History row={this.state.client} key={this.state.client.id} type="client" />
                        </TabPane>
                    ) : null}     
                </Tabs>
            </>
        );
    }
}

const WrappedTClientForm = Form.create({ name: 'client_form_crm' })(NormalTriacomClientForm);

export default WrappedTClientForm;
