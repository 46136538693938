import React, { Component } from "react";
import { Tag, Badge, Menu, Dropdown, Button, Icon, Checkbox, Modal, Input, notification, Tooltip, Radio } from 'antd';
import Currency from 'react-currency-formatter';

import Highlighter from 'react-highlight-words';

import moment from 'moment';

import OrderForm from '../components/OrderForm';
import SelectFromUrl from '../components/SelectFromUrl';
import EmailModalForm from '../components/EmailModalForm';
import Map from '../components/Map';
import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";

class Orders extends Component {

    constructor(props) {
        super(props);

        this.defaultFilters = ['tracing', 'ready_terminal', 'dropped_customer', 'ready_pickup', 'prepull_yard', 'customer_yard', 'dropped_terminal'];

        this.state = {
            rows: [],
            membershipCategories: [],
            selectedClient: {},
            view: 'list',
            showImportModal: false,
            modalImportLoading: false,
            importInput: '',
            filteredInfo: this.props.small ? {status : this.defaultFilters} : {},
            searchText:  '',
            searchFilter: Zenfy.getParameterByName('search') ? Zenfy.getParameterByName('search') : '',
            pagination: {},
            showEmailModal: false,
            dropChecked: false,
            routeChecked: false,
            yardChecked: false,
            fullYardChecked: false,
            emptyYardChecked: false,
            showTerminalModal: false,
            selectedTerminalId: undefined,
            selectedRecord: {},
        }

        this.drawerTablePageRef = React.createRef();
        this.emailModalRef = React.createRef();
        this.lastParams = null;
        this.forceId = null;
        this.isPrepull = false;
    }

    componentDidMount() {
        this.forceId = Zenfy.getParameterByName('forceId');
        this.load();
    }

    load = (params = {}, useLast) => {

        if(this.props.small){
            return;
        }

        /*if(params && this.lastParams && this.lastParams.status && this.lastParams.status.length > 0  && params.status && params.status.length == 0){
            params.status = this.lastParams.status;
        }*/

        if(params && params.results){
            this.lastParams = params;
        }

        if(useLast && !params && !this.state.searchFilter){
            //params = this.lastParams;
        }

        this.setState({
            loading: true,
        });

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/orders`, {
            results: 10,
            searchText: this.state.searchFilter,
            forceId: this.forceId,
            ...params,
        }).then((res) => {
    
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.total;
    
              this.setState({
                  loading: false,
                  rows: Zenfy.setKeysToList(res.data.rows),
                  pagination
              }, () => {
                if(this.forceId){
                    setTimeout(() => {
                        document.getElementById(`order-${this.forceId}`).click();
                        this.forceId = null;
                    }, 500);
                }    
              });
          });
        
    }

    recordOnClick = (record, e) => {
        if(e){
            e.stopPropagation();
        }

        this.drawerTablePageRef.current.rowClick(record);
        this.setState({
            selectedClient: record,
        });
    }

    renderClasses = (record) => {
        let res = record.invoices ? record.invoices.map((invoice) => {
            if(invoice.ends_at){
                return <Tag color="purple">{invoice.name}</Tag>;
            }
        }) : '';
        
        return res;
    } 

    renderMembership = (record) => {

        const res = record.activeMembership && record.activeMembership.ends_at ? <span>{new Date(record.activeMembership.ends_at) > new Date() ? 'Ending' : 'Ended '} {moment(record.activeMembership.ends_at).fromNow()}</span> : '';

        return res;
    }

    handleViewChange = (e) => {
        this.setState({ view: e.target.value });
    }

    viewPOD = (record, e) => {

        if(e){
            e.stopPropagation();
        }

        var win = window.open(`${Zenfy.getRoot()}pod/${record.id}`, '_blank');
        win.focus();
    }

    sendPod = (record, e) => {

        if(e){
            e.stopPropagation();
        }

        this.setState({
            showEmailModal: true,
            selectedClient: record,
        });

    }

    viewInvoice = (record, e) => {
        
        if(e){
            e.stopPropagation();
        }
        
        let url = `${Zenfy.getFrontEndRoot()}accounting/invoices?searchVal=${record.invoice_id}&openId=true`;
        window.open(url, '_blank');
    }

    sendEmail = () => {

        let form = this.emailModalRef.current.getForm();

        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            let emails = values.email.split(',')
    
            Zenfy.get(`pod/${this.state.selectedClient.id}?email=true`, {
                emails
            });

            // add emailed

            let emailed = this.state.selectedClient.emailed + 1;

            Zenfy.post('orders', {
                id: this.state.selectedClient.id, 
                org_id: this.state.selectedClient.org_id, 
                emailed: emailed,
            }).then(() => {

                notification.success({
                    message: `Sent email with success!`,
                    placement: 'bottomLeft',
                });
    
                this.load();
                this.drawerTablePageRef.current.load();

                form.resetFields();

                this.setState({ showEmailModal: false });

            });

        });

    }

    handleImport = () => {
        this.setState({
            showImportModal: true,
        })
    }

    inputOnChange = (e) => {
        //if(e.target.value){
            this.setState({
                importInput: e.target.value
            });
        //}
    }

    handleImportModalOk = () => {

        this.setState({
            modalImportLoading: true,
        });

        let urls = this.state.importInput.split('/');
        
        Zenfy.post('scrapJob', {
            id: urls[urls.length-2],
            date: this.props.date ? moment(this.props.date).format("YYYY-MM-DD") : null,
        }).then((res) => {

            notification.success({
                message: `${res.data.id ? res.data.id + ' ' : ''}Imported with success`,
                placement: 'bottomLeft',
            });

            this.setState({
                modalImportLoading: false,
                importInput: '',
            });

            this.load();
            this.drawerTablePageRef.current.load();
        });

    }

    renderRoute = (record) => {

        let route = "";

        /*if(record.legs.length == 2){
            return <>
                <p>Pickup From: {record.legs[0].terminal_name == 28 ? record.delivery_client_name : record.legs[0].terminal_name}</p>  
                <p>Deliver To: {record.legs[0].terminal_to_id == 28 ? record.delivery_client_name : record.legs[0].return_terminal_name}</p>   
                <p>Return To: {record.legs[1].terminal_to_id == 28 ? record.delivery_client_name : record.legs[1].return_terminal_name}</p>      
            </>
        }*/

        route = record.legs.map((leg) => {
            return <>
            <Tooltip title={`Dropped: ${leg.time_in}`}>
                <p className="small">
                {leg.type ? 
                <Icon type="check" /> : leg.driver_id != 0 ? <Icon type="sync" spin onClick={(e) => this.drop(leg, e, record)} /> : <Icon type="question" />} 
                &nbsp;{leg.terminal_id == 28 ? record.delivery_client_name : leg.terminal_name}&nbsp;-->&nbsp;
                {leg.terminal_to_id == 28 ? record.delivery_client_name : leg.return_terminal_name} {leg.driver_name ? <Tag onClick={(e) => this.props.driverClick ? this.props.driverClick(leg, e) : null}>{leg.driver_name}</Tag> : ''}</p>
            </Tooltip>
            </>
        });

        /*Zenfy.copyToClipboard(`
        Pickup ${item.size} 
        ${item.terminal_id == 28 ? item.delivery_client_name : item.terminal_name}
        \n${item.container} ${item.equipement_name ? '/ ' + item.equipement_name : ''} 
        ${item.pickup ? `PU# ${item.pickup}` : ''}\nDeliver to ${item.terminal_to_id == 28 ? 
            item.delivery_client_name : item.return_terminal_name}\n${item.delivery_address}, ${item.delivery_city}, 
            ${item.delivery_postal}\n\nhttps://zenfy.co/#/driver/${item.driver_id}`);*/

        return route;
    }

    drop = (leg, e, order) => {

        if(e){
            e.stopPropagation();
        }

        this.setState({
            loading: true,
        });

        Zenfy.post('legs', {
            id: leg.id, 
            org_id: leg.org_id, 
            order_id: leg.order_id,
            type: 'drop',
            time_in: moment().format('YYYY-MM-DD HH:mm:ss'),
            time_out: moment().format('YYYY-MM-DD HH:mm:ss'),
        }).then((res) => {

            this.setState({
                loading: false,
            });

            notification.success({
                message: `Changed status with success!`,
                placement: 'bottomLeft',
            });

            if(this.props.reLoad){
                this.props.reLoad();
            }
            
            let status = null;

            console.log(leg);

            if(leg.terminal_to_id == 28){
                status = 'dropped_customer';
            }else if(!leg.return_terminal_name.toLowerCase().includes("yard")){
                status = 'dropped_terminal';
                // send email
                Zenfy.sendEmailStatus(order.id, order.email, 'completed');
            }else if(leg.return_terminal_name.toLowerCase().includes("yard")){
                if(leg.terminal_id == 28){
                    status = 'customer_yard';
                }else{
                    status = 'prepull_yard';
                }
            }

            // change status of order
            if(status){
                Zenfy.post('orders', {
                    id: leg.order_id,
                    org_id: leg.org_id,
                    status,
                }).then(() => {
                    this.load();
                    this.drawerTablePageRef.current.load();
                })
            }else{
                this.load();
                this.drawerTablePageRef.current.load();
            }

            
        });
    }
    // 'tracing','ready_terminal','dropped_customer','ready_pickup','dropped_terminal'
    changeStatus = (record, status, e) => {

        if(e){
            e.stopPropagation();
        }

        //if(record.status == 'dropped_customer'){
            Zenfy.post('orders', {
                id: record.id, 
                org_id: record.org_id, 
                status: status,
                app_date: moment().format('YYYY-MM-DD HH:mm:ss')
            }).then((res) => {
                notification.success({
                    message: `Changed status with success!`,
                    placement: 'bottomLeft',
                });

                if(this.props.reLoad){
                    this.props.reLoad();
                }

                this.load();
                this.drawerTablePageRef.current.load();
            });
        /*}else{
            notification.error({
                message: ``,
                placement: 'bottomLeft',
            });
        }*/
    }

    filterRoute = (record, value) => {
        let routeStatus = "";
        let legString = "";
        let checkBoxValue = null;

        if(this.checkBoxInput.state.value && this.checkBoxInput.state.value[0]){
            checkBoxValue = this.checkBoxInput.state.value[0];
        }

        record.legs.map((leg) => {
            if(leg.type != 'drop' && leg.driver_id != 0){
                routeStatus = 'route';
            }else if(leg.type == 'drop'){
                routeStatus = 'drop';
            }
            legString += record.delivery_client_name + " " + leg.return_terminal_name + " " + leg.terminal_name + " " + leg.driver_name;
        });

        if(checkBoxValue){
            return legString.toString().toLowerCase().includes(value.toLowerCase()) && checkBoxValue == routeStatus;
        }
     
        return legString.toString().toLowerCase().includes(value.toLowerCase());
    }

    getSearchPropsNoRender = (dataIndex) => ({
        filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => { this.searchInput = node; }}
                //placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Checkbox.Group
                ref={node => { this.checkBoxInput = node; }}
                options={[
                    { label: 'Enroute', value: 'route' },
                    { label: 'Dropped', value: 'drop' },
                ]}
                style={{display: 'block', marginBottom: 10}}
                //placeholder={`Search ${dataIndex}`}
                //value={selectedKeys[0]}
                //onChange={e => setSelectedKeys(e ? " " : "")}
            />
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                Search
            </Button>
            <Button
                onClick={() => this.handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
            >
                Reset
            </Button>
        </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>  this.filterRoute(record, value),
        onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
            setTimeout(() => this.searchInput.select());
        }
        },
        //render: (record) => record ? parent.renderRoute(record) : '',
    })

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => { this.searchInput = node; }}
            //placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
            >
            Search
            </Button>
            <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            >
            Reset
            </Button>
        </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>  record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false,
        onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
            setTimeout(() => this.searchInput.select());
        }
        },
        render: (text, record) => (
            <span><Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : null}
        /><span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}}>{dataIndex != 'delivery_client_name' ? record.steamship : `${record.delivery_address ? record.delivery_address + ', ' : ''} ${record.delivery_city ? record.delivery_city : ''}`}</span></span>
        ),
    })

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    }

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
    }

    handleChange = (pagination, filters, sorter) => {

        if(!this.props.small){
            const pager = { ...this.state.pagination };
            pager.current = pagination.current;
            console.log(filters, pagination);
            this.setState({
              pagination: pager,
            });
            this.load({
              results: pagination.pageSize,
              page: pagination.current,
              sortField: sorter.field,
              sortOrder: sorter.order,
              ...filters,
            });
        }else{
            this.setState({
                filteredInfo: filters,
            });
        }

    }

    pull = (record, event, prepull) => {

        if(event){
            event.stopPropagation();
        }

        let legs = record.legs;
        let hasChange = false;

        if(prepull){
            console.log(legs);
            if(legs[0] && legs[0].terminal_to_id != this.state.selectedTerminalId){
                let oldTerminal = legs[0].terminal_to_id;
                legs[0].terminal_to_id = this.state.selectedTerminalId;

                if(legs[1].terminal_to_id != this.state.selectedTerminalId){
                    let oldLeg = {...legs[1]};
                    oldLeg.id = null;
                    legs[1].terminal_id = this.state.selectedTerminalId;
                    legs[1].terminal_to_id = oldTerminal;
                    legs[2] = oldLeg;
                }else if(legs[1].terminal_to_id == this.state.selectedTerminalId){
                   
                    let oldLeg = {...legs[0]};
                    oldLeg.terminal_id = this.state.selectedTerminalId;
                    oldLeg.terminal_to_id = 28;
                    oldLeg.id = null;
                    legs = Zenfy.insertInArray(legs, 1, oldLeg);
                    
                    legs.map((leg) => {
                        leg.id = null;
                    });

                    //record.legs = legs;
                }

                hasChange = true;
            }
        }else{
            if(legs[legs.length - 1] && legs[legs.length - 1].terminal_id != this.state.selectedTerminalId){
                // de client a pride yard
                // de pride yard a terminal
                if(legs[legs.length - 1].terminal_id == 28){
                    let lastLeg = {...legs[legs.length - 1]};
                    //let oldTerminal = legs[legs.length - 1].terminal_to_id;
                    legs[legs.length - 1].terminal_to_id = this.state.selectedTerminalId;
                    lastLeg.terminal_id = this.state.selectedTerminalId;
                    lastLeg.id = null;
                    legs.push(lastLeg);
                }

                hasChange = true;
            }
        }

        if(hasChange){
            Zenfy.post('orders', {
                id: record.id, 
                org_id: record.org_id, 
                //status: prepull ? 'prepull_yard' : 'customer_yard',
                legs
            }).then((res) => {
                notification.success({
                    message: `${prepull ? 'Prepulled' : 'Pull'} to yard successfully!`,
                    placement: 'bottomLeft',
                });

                if(this.props.reLoad){
                    this.props.reLoad();
                }

                this.load();
                this.drawerTablePageRef.current.load();
            });

        }else{
            notification.error({
                message: `${prepull ? 'Prepulled' : 'Pull'} cannot be done, please do it manually on the legs!`,
                placement: 'bottomLeft',
            });
        }

        this.setState({
            showTerminalModal: false,
        });
    }

    searchOnChange = (e) => {
        this.setState({
            searchFilter: e.target.value,
        },() => {
            this.load();
        });
    }

    renderReturn = (text, record) => {

        let return_date = '';

        record.legs.map((leg) => {
            if(leg.terminal_to_id == record.return_id){
                return_date = leg.time_in;
            }
        })
        return <span>{text}<span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}}>{return_date}</span></span>;
    }

    duplicate = (record, event) => {
        if(event){
            event.stopPropagation();
        }

        let obj = {...record};
        let legs = obj.legs;
        let products = obj.products;

        obj.id = null;
      
        delete obj.legs;
        delete obj.products;
        delete obj.pickup_terminal_name;
        delete obj.return_terminal_name;
        delete obj.client_name;
        delete obj.zone1;
        delete obj.zone2;
        delete obj.zone3;
        delete obj.port_fees;
        delete obj.email;
        delete obj.delivery_client_name;
        delete obj.delivery_address;
        delete obj.balance;
        delete obj.key;
        delete obj.lat;
        delete obj.delivery_postal;
        delete obj.delivery_city;
        delete obj.delivery_telephone;
        delete obj.lng;
        delete obj.invoice_id;
        delete obj.due_at;
        delete obj.terms;
        delete obj.po;
        delete obj.amount_due;
        delete obj.paid;
        delete obj.email_text;

        if(obj.terminal_name){
            delete obj.terminal_name;
            delete obj.return_terminal_name;   
        }

        Zenfy.post('orders', obj).then((res) => {
            console.log(res);

            let order_id = res.data;
            let lengsLenght = legs.length;
            let numberOfposted = 1;

            let productsLength = products.length;
            let numberOfPostedProducts = 1;

            legs.map((leg) => {
                leg.order_id = order_id;
                leg.id = null;
                
                delete leg.terminal_name;
                delete leg.return_terminal_name;
                delete leg.driver_name;
                delete leg.driver_lat;
                delete leg.driver_lng;
                delete leg.status;
                delete leg.driver_id;
                delete leg.type;

                Zenfy.post('legs', leg).then(() => {
                    if(numberOfposted == lengsLenght){

                        if(products.length == 0){
                            this.load();
                            this.drawerTablePageRef.current.load();
                        }else{

                        }
                        
                    }else{
                        numberOfposted++;
                    }
                });
            });

            if(record.amount_due > 0){
                // get products
                Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/products_orders?orderId=${record.id}`).then((res) => {
                    res.data.map((product) => {
                        if(product.deleted == 0){
                            Zenfy.post(`products_orders`,{
                                order_id,
                                org_id: product.org_id,
                                amount: product.amount,
                                product_id: product.product_id,
                                quantity: product.quantity,
                                unit: product.unit,
                                tax: product.taxes,
                                description: product.description,
                            });
                        }
                    });
                });
            }

            if(legs.length == 0 || products.length == 0){
                this.load();
                this.drawerTablePageRef.current.load();
            }

            notification.success({
                message: `Duplicated with success!`,
                placement: 'bottomLeft',
            });

        });
    }

    delete = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this order?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`orders/${record.id}`).then((res) => {
                    this.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    renderId = (text, record) => {
        return <>
            <a href="javascript:;" id={`order-${record.id}`} onClick={(e)=>this.recordOnClick(record, e)}>{text}</a> 
            {record.emailed > 0 ? (
                <Tooltip title={`POD emailed ${record.emailed} time(s)`}>
                    <Icon style={{color: '#52c41a', marginLeft: 3}} type="like" />
                </Tooltip>
            ) : null}
        </>;
    }

    rowClassName = (record, index) => {
        return `${index % 2 !== 0 ? 'impair' : ''} ${record.status} ${record.instructions || record.return_instructions ? 'hasInstructions' : ''}`;
    }

    handleExport = () => {
        if(!this.lastParams){
            alert('Please filter the list before exporting first.');
            return false;
        }

        let newParams = {...this.lastParams};
        delete newParams.results;
        delete newParams.page;
        
        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/orders`, {
            searchText: this.state.searchFilter,
            ...newParams,
        }).then((res) => {
            let cleanRows = [];

            res.data.map((order) => {
                cleanRows.push({
                    container: order.container,
                    size: order.size,
                    weight: order.weight,
                    steamship: order.steamship,
                    pickup_terminal_name: order.pickup_terminal_name,
                    created_at: order.created_at,
                    status: order.status,
                    delivery_address: order.delivery_address,
                    delivery_city: order.delivery_city,
                    delivery_client_name: order.delivery_client_name,
                    internal_id: order.medlog,
                });
            });

            Zenfy.exportDataToCsv(cleanRows, 'orders');
        });
    }

    render() {

        const columns = [{
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => this.renderId(text, record),
            sorter: (a, b) => a.id.length - b.id.length,
        }/*,{
            title: 'UTX id',
            dataIndex: 'utx_id',
            key: 'utx_id',
        }*/,{
            title: 'Container #',
            dataIndex: 'container',
            key: 'container',
            filters: this.props.small ? [
                {value: 'import', text: 'Impot'}, 
                {value: 'export', text: 'Export'},
            ] : [],
            onFilter: (value, record) => record.job.indexOf(value) === 0,
            render: (text, record) => (
                <>
                    <Tooltip title={`${Zenfy.renderStatus(record, true)} - ${record.job.charAt(0).toUpperCase() + record.job.slice(1)}`}>
                        <span>
                        {record.size} {record.pickup ? '- ' + record.pickup : ''} 
                            <span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}} className="container_num">{record.container} 
                            {record.hazard == 1 ? (
                                        <Tooltip title="Hazardous">
                                            <Icon type="fire" style={{color: 'red'}} theme="filled"/>
                                        </Tooltip>
                                ) : null} 
                            {record.job == 'export' ? (
                                <>
                                    &nbsp;<Icon type="twitter" style={{color: '#00ff95'}} />
                                </>
                            ) : null}
                            </span>
                        </span>
                </Tooltip>
               </>
            ),
            className: 'container'
        },{
            //className: !this.props.small ? 'hide' : '',MMMM Do YYYY HH:mm
            title: 'App Time',
            dataIndex: 'app_date',
            key: 'app_date',
            defaultSortOrder: !this.props.small ? '' : 'ascend',
            render: (text, record) => {return record.app_date ? <span>{moment(record.app_date).format("YYYY-MM-DD")}<span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}}>{moment(record.app_date).format("HH:mm")}</span></span>  : ''},
            sorter: (a, b) => new Date(b.app_date) - new Date(a.app_date),
        },{
            title: 'Pickup from',
            dataIndex: 'pickup_terminal_name',
            key: 'pickup_terminal_name',
            sorter: (a, b) => a.pickup_terminal_name !== b.pickup_terminal_name ? a.pickup_terminal_name < b.pickup_terminal_name ? -1 : 1 : 0,
            render: (text, record) => <span>{text}<span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}}>{record.steamship}</span></span>,
            ...this.getColumnSearchProps('pickup_terminal_name')
        },{
            title: 'Route',
            dataIndex: 'client_name',
            key: 'client_name',
            render: (text, record) => this.renderRoute(record),
            className: !this.props.small ? 'hide' : '',
            width: '30%',
            ...this.getSearchPropsNoRender("legString")
        },{
            title: 'Deliver to',
            dataIndex: 'delivery_client_name',
            key: 'delivery_client_name',
            sorter: (a, b) => a.delivery_client_name !== b.delivery_client_name ? a.delivery_client_name < b.delivery_client_name ? -1 : 1 : 0,
            render: (text, record) => (
                <span>{text} <span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}}>{record.client_name}</span></span>
            ),
            ...this.getColumnSearchProps('delivery_client_name')
            //render: (text, record) => <span>{text}<span style={{color: 'rgba(0,0,0,0.45)', display: 'block'}}>{record.client_name}</span></span>,
            //render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
        },/*,{
            title: 'Delivery Time',
            dataIndex: 'deliver_at',
            key: 'deliver_at',
            render: (text, record) => {return record.deliver_at ? moment(record.deliver_at).format("MMMM Do YYYY HH:mm") : ''},
            sorter: (a, b) => new Date(a.deliver_at) - new Date(b.deliver_at),
        }*/{
            title: 'Return to',
            dataIndex: 'return_terminal_name',
            key: 'return_terminal_name',
            render: (text, record) => this.renderReturn(text, record),
            sorter: (a, b) => a.return_terminal_name !== b.return_terminal_name ? a.return_terminal_name < b.return_terminal_name ? -1 : 1 : 0
        },{
            title: 'Price',
            dataIndex: 'amount_due',
            key: 'amount_due',
            render: (text) => text ? (<Currency quantity={text} currency="CAD" />) : null,
            sorter: (a, b) => a.amount_due - b.amount_due,
            className: localStorage.zenfy_role != 'admin' || this.props.small ? 'hide' : '',
        },{
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            //sorter: (a, b) => a.total_amount_due > b.total_amount_due,
            render: (text, record) => Zenfy.renderStatus(record),
            filteredValue: this.state.filteredInfo.status || null,
            filters: [
                {value: 'tracing', text: 'Tracing'}, 
                {value: 'ready_terminal', text: 'Ready at terminal'},
                {value: 'prepull_yard', text: 'Prepulled to yard'},
                {value: 'dropped_customer', text: 'Dropped at customer'},
                {value: 'ready_pickup', text: 'Ready at customer'},
                {value: 'customer_yard', text: 'Customer to yard'},
                {value: 'dropped_terminal', text: 'Dropped at terminal'},
                {value: 'invoiced', text: 'Invoiced'}
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        }, {
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={(e)=>this.recordOnClick(record, e.domEvent)}>Edit</Menu.Item>
                  <Menu.Item key="2" onClick={(e)=>this.viewPOD(record, e.domEvent)}>View POD</Menu.Item>
                  <Menu.Item key="3" onClick={(e)=>this.changeStatus(record, 'ready_pickup', e.domEvent)}>Ready at Customer</Menu.Item>
                  <Menu.Item key="33" onClick={(e)=>this.changeStatus(record, 'ready_terminal', e.domEvent)}>Ready at Terminal</Menu.Item>
                  <Menu.Item key="4" onClick={(e) => {
                        if(e){
                            e.domEvent.stopPropagation();
                        }
                        this.isPrepull = true;
                        this.setState({
                            showTerminalModal: true,
                            selectedRecord: record,
                        });
                    }}>Prepull to Yard</Menu.Item>
                  <Menu.Item key="5" onClick={(e) => {
                        if(e){
                            e.domEvent.stopPropagation();
                        }
                        this.isPrepull = false;
                        this.setState({
                            showTerminalModal: true,
                            selectedRecord: record,
                        });
                    }}>Customer to Yard</Menu.Item>
                  <Menu.Item key="6" onClick={(e)=>this.sendPod(record, e.domEvent)}>Send POD to Customer</Menu.Item>
                  <Menu.Item key="9" onClick={(e)=>this.duplicate(record, e.domEvent)}>Duplicate</Menu.Item>
                  {record.invoice_id ? (
                  <Menu.Item key="7" onClick={(e)=>this.viewInvoice(record, e.domEvent)}>View Invoice</Menu.Item>    
                  ) : null}
                  <Menu.Item key="8" onClick={(e)=>this.delete(record, e.domEvent)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
          }];

        return (
        <>  
            <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport"/>
            <Modal
                title="Import from UTX"
                visible={this.state.showImportModal}
                onOk={this.handleImportModalOk}
                onCancel={() => {
                    this.setState({
                        showImportModal: false,
                        modalImportLoading: false,
                    })
                }}
                okText="Import"
                okButtonProps={{loading: this.state.modalImportLoading}}
            >
                <Input placeholder="https://www.pridecontainerservices.com/reports/65994/pod" onChange={this.inputOnChange} value={this.state.importInput} /> 
            </Modal>

            <Modal
                title="Select Yard"
                visible={this.state.showTerminalModal}
                onOk={() => {
                    this.pull(this.state.selectedRecord, false, this.isPrepull);
                }}
                onCancel={() => {
                    this.setState({
                        showTerminalModal: false,
                    })
                }}
                okText="Ok"
            >
                <SelectFromUrl 
                    url={`${localStorage.orgId}/terminals`} 
                    idName="id" 
                    name="name"
                    preLoad={(rows) => {
                        rows = rows.filter((item) => {
                            return item.name && item.name.toLowerCase().includes("yard");
                        });

                        if(rows[0]){
                            this.setState({
                                selectedTerminalId: rows[0].id
                            });
                        }
                        
                        return rows;
                    }}
                    onChange={(value) => {
                        this.setState({
                            selectedTerminalId: parseInt(value),
                        });
                        /*if(this.dataSheet.current && this.activeCell){
                            this.dataSheet.current.setActiveCell(this.activeCell);
                        }*/
                    }} 
                    value={this.state.selectedTerminalId}
                    style={{width: '100%'}}
                />
            </Modal>

            <EmailModalForm 
                visible={this.state.showEmailModal}
                title="Send POD to Customer"
                onCancel={
                    () => {
                    this.setState({
                        showEmailModal: false,
                    })
                }}
                onCreate={this.sendEmail}
                record={this.state.selectedClient}
                ref={this.emailModalRef}
            />

            {!this.props.small ? (
                <Radio.Group value={this.state.view} onChange={this.handleViewChange} style={{marginBottom: '15px', display: 'none'}}>
                    <Radio.Button value="list">List</Radio.Button>
                    <Radio.Button value="map">Map</Radio.Button>
                </Radio.Group>
            ) : null}

            <div className="clearfix" /> 

            <DrawerTableForm 
                columns={columns} 
                rows={this.props.small ? this.props.rows : this.state.rows} 
                searchProp="id"
                recordOnClick={this.recordOnClick} 
                ref={this.drawerTablePageRef} 
                form={<OrderForm key={this.state.selectedClient.id} drawer={this.drawerTablePageRef} orders={this} reLoad={this.load} order={this.state.selectedClient} type="Order" small={this.props.small} />} 
                type="order" 
                sideSearch={this.props.small ? (
                    <div style={{float: 'right', marginTop: 5}}>
                        <Button
                            size="small"
                            icon={this.state.emptyYardCheck ? "close" : ""}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                this.props.onFilter("empty_yard");
                                this.setState({
                                    emptyYardCheck: !this.state.emptyYardCheck
                                });
                            }}
                        >
                            Empty @ yard
                        </Button>
                        <Button
                            size="small"
                            icon={this.state.fullYardChecked ? "close" : ""}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                this.props.onFilter("full_yard");
                                this.setState({
                                    fullYardChecked: !this.state.fullYardChecked
                                });
                            }}
                        >
                            Full @ yard
                        </Button>
                        <Button
                            size="small"
                            icon={this.state.yardChecked ? "close" : ""}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                //this.props.onFilter("route");
                                this.setState({
                                    filteredInfo: {status: !this.state.yardChecked ? ["prepull_yard", "customer_yard"] : this.defaultFilters},
                                    yardChecked: !this.state.yardChecked
                                });
                            }}
                        >
                            In yard
                        </Button>
                        <Button
                            size="small"
                            icon={this.state.routeChecked ? "close" : ""}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                this.props.onFilter("route");
                                this.setState({
                                    routeChecked: !this.state.routeChecked
                                });
                            }}
                        >
                            En route
                        </Button>
                        <Button
                            size="small"
                            icon={this.state.dropChecked ? "close" : ""}
                            //style={{ marginRight: 8 }}
                            //type={this.state.dropped ? null : "dashed"}
                            onClick={() => {
                                this.props.onFilter("drop");
                                this.setState({
                                    dropChecked: !this.state.dropChecked
                                });
                            }}
                        >
                            Dropped
                        </Button>
                    </div>
                ) : null}
                load={false}
                expandedRowRender={record => <p style={{ margin: 0 }}>{record.instructions} {record.return_instructions}</p>}
                loading={this.state.loading || this.props.loading} 
                hideTable={this.state.view == 'map'}
                handleImport={this.handleImport}
                small={this.props.small}
                onRowClick={this.props.onRowClick}
                onContextMenu={this.props.onContextMenu}
                //pagination={this.props.small ? {pageSize: 20000} : this.state.pagination}
                pagination={this.props.small ? {showSizeChanger: true, pageSizeOptions: [10, 15, 20, 30, 40, 100, 200], showTotal: (total) => {return `Total ${total} orders`;}} : this.state.pagination}
                searchOnChange={this.props.searchOnChange ? this.props.searchOnChange : this.searchOnChange}
                reLoad={this.props.reLoad}
                tableOnChange={this.handleChange}
                searchValue={this.props.searchValue ? this.props.searchValue : this.state.searchFilter}
                rowClassName={this.rowClassName}
                export={!this.props.small}
                handleExport={this.handleExport}
                //scroll={this.props.small ? {y: 700}: false}
            />
            {this.state.view == 'map' ? (
                <Map />
            ) : null}
            
        </>
        );
    }
}

export default Orders;