import React, { Component } from "react";
import { Button, Icon, Input, Table, Tooltip } from 'antd';
import Currency from 'react-currency-formatter';

import Zenfy from "../libs/Zenfy";

class Orders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            clientId: props.clientId,
            selectedOrders: props.selectedOrders,
            selectedRowKeys: [],
            filteredInfo:{ status:  localStorage.type == 'crm' ? [] : ['dropped_terminal', 'invoiced'] },
            searchText: '',
            searchedColumn: '',
        }
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {
        // this.componentHasGotData(newProps);
        if(this.state.clientId !== newProps.clientId || this.state.selectedOrders !== newProps.selectedOrders){
            this.setState({
                clientId: newProps.clientId,
                selectedOrders: newProps.selectedOrders,
            },() => {
               this.componentDidMount();
            });
        }

    }


    load = () => {

        /*if(!this.props.clientId){
            this.setState({
                rows: []
            });
            return;
        }*/

        this.setState({
            loading: true,
        });

        let selectedOrders = this.state.selectedOrders ? this.state.selectedOrders.map((ord) => {
            return ord.order_id;
        }) : [];

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/orders?noInvoice=1${this.props.clientId ? `&client_id=${this.props.clientId}` : ''}`,{
            orders: selectedOrders
        }).then((res) => {
            let selected = [];

            if(this.state.selectedOrders){
                res.data.map((order, index) => {
                
                    let found = this.state.selectedOrders.find((elem) => {
                        return elem.order_id == order.id;
                    });
    
                    if(found){
                        selected.push(index);
                    }
                });
            }

            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
                selectedRowKeys: selected,
            })
        });
    }

    delete = (record) => {
        Zenfy.delete(`transactions/${record.id}`).then((res) => {
            this.load();
            this.props.reLoad();
        });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon="search"
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => this.searchInput.select());
            }
        },
        /*render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),*/
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }

    
    viewPod = (record) => {
        var win = window.open(`${Zenfy.getRoot()}pod/${record.id}`, '_blank');
        win.focus();
    }

    viewOrder = (record) => {
        let searchVal = record.container == '.' ? record.reference : record.container;
        var win = window.open(`${Zenfy.getFrontEndRoot()}orders?search=${searchVal}&forceId=${record.id}`, '_blank');
        win.focus();
    }

    handleChange = (pagination, filters, sorter) => {
        //if(filters.length > 0){
            this.setState({
                filteredInfo: filters,
            });
        //}
    }

    render() {

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <Tooltip title="View Order"><a href="javascript:;" onClick={()=>this.viewOrder(record)}>{text}</a></Tooltip>,
            sorter: (a, b) => a.id - b.id,
            ...this.getColumnSearchProps('id'),
        },{
            title: localStorage.type == "crm" ? 'Order' : 'Container',
            dataIndex: 'container',
            key: 'container',
            render: (text, record) => <Tooltip title="View POD"><a href="javascript:;" onClick={()=>this.viewPod(record)}>{text}</a></Tooltip>,
            ...this.getColumnSearchProps('container'),
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Customer',
            dataIndex: 'client_name',
            key: 'client_name',
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Price',
            dataIndex: 'amount_due',
            key: 'amount_due',
            sorter: (a, b) => a.amount_due - b.amount_due,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            //sorter: (a, b) => a.total_amount_due > b.total_amount_due,
            render: (text, record) => Zenfy.renderStatus(record),
            filteredValue: this.state.filteredInfo.status || null,
            filters: [
                {value: 'tracing', text: 'Tracing'}, 
                {value: 'ready_terminal', text: 'Ready at terminal'},
                {value: 'prepull_yard', text: 'Prepulled to yard'},
                {value: 'dropped_customer', text: 'Dropped at customer'},
                {value: 'ready_pickup', text: 'Ready at customer'},
                {value: 'customer_yard', text: 'Customer to yard'},
                {value: 'dropped_terminal', text: 'Dropped at terminal'},
                {value: 'invoiced', text: 'Invoiced'}
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        }/*,{
            title: 'Price',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Amount',
            key: 'amount',
            render: (text, record) => (
              <span>
                <a href="javascript:;" onClick={()=>this.delete(record)}>Delete</a>
              </span>
            ),
        },*/
    ];

    const rowSelection = {
        selectedRowKeys: this.state.selectedRowKeys,
        onChange: this.onSelectChange,
    };
    
    return (
        <>
            <Table rowSelection={rowSelection} dataSource={this.state.rows} columns={columns} loading={this.state.loading} onChange={this.handleChange} size="small" pagination={false} />
        </>
    );

    }
}

export default Orders;