import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select, Card, Row, Col, Menu, Dropdown, Icon
} from 'antd';
import moment from 'moment';
import SelectFromUrl from '../components/SelectFromUrl';
import Zenfy from "../libs/Zenfy";

const { Option } = Select;

class NormalLegForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leg: props.leg,
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.leg.id !== this.state.leg.id){

            this.props.form.resetFields();

            this.setState({
                leg: nextProps.leg
            });

        }

    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            /*labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },*/
        };

        const menu = (
            <Menu>
              <Menu.Item key="0" onClick={this.props.remove}>
                Remove 
              </Menu.Item>
            </Menu>
          );

        return (
            <>
                
                <Card title={`Leg ${this.props.index * 1 + 1}`} size="small" extra={
                    <a href="javascript:;" onClick={this.props.remove}>Remove</a>
                } style={{marginBottom: 20, borderRadius: 5}}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={6}>

                            <Form.Item
                                {...formItemLayout}
                                label="Move From"
                                >
                                {getFieldDecorator('terminal_id', {
                                    initialValue: this.state.leg.terminal_id,
                                    rules: [
                                        { required: false, message: 'Product is required' }
                                    ],
                                })(
                                    <SelectFromUrl placeholder="Select a terminal" style={{width: 200}} url={`${localStorage.orgId ? localStorage.orgId : 1}/terminals`} idName="id" name="name" />
                                )}
                            </Form.Item>

                            <Form.Item
                                {...formItemLayout}
                                label="Drop Off Time In"
                                >
                                {getFieldDecorator('time_in', {
                                    initialValue: this.state.leg.time_in ? moment(this.state.leg.time_in, "YYYY-MM-DD HH:mm") : null,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <DatePicker showTime={{minuteStep: 15, format: 'HH:mm'}} format={"YYYY-MM-DD HH:mm"} />
                                )}
                            </Form.Item>

                            </Col>
                            <Col span={6}>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Driver"
                                    >
                                    {getFieldDecorator('driver_id', {
                                        initialValue: this.state.leg.driver_id == 0 ? undefined : this.state.leg.driver_id,
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Amount is required',
                                            },
                                        ],
                                    })(
                                        <SelectFromUrl placeholder="Select a driver" style={{width: 200}} url={`${localStorage.orgId ? localStorage.orgId : 1}/staff`} idName="id" name="name" />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Drop Off Time Out"
                                    >
                                    {getFieldDecorator('time_out', {
                                        initialValue: this.state.leg.time_out ? moment(this.state.leg.time_out, "YYYY-MM-DD HH:mm") : null,
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <DatePicker showTime={{minuteStep: 15, format: 'HH:mm'}} format={"YYYY-MM-DD HH:mm"} />
                                    )}
                                </Form.Item>
                                
                            </Col>
                            <Col span={6}>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Move To"
                                    >
                                    {getFieldDecorator('terminal_to_id', {
                                        initialValue: this.state.leg.terminal_to_id,
                                        rules: [
                                            { required: false, message: 'Product is required' }
                                        ],
                                    })(
                                        <SelectFromUrl placeholder="Select a destination" style={{width: 200}} url={`${localStorage.orgId ? localStorage.orgId : 1}/terminals`} idName="id" name="name" />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Status"
                                    >
                                    {getFieldDecorator('type', {
                                        initialValue: this.state.leg.type,
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <Select style={{width: 200}}>
                                            <Option value="drop">Dropped</Option>
                                            <Option value="live">Live Load</Option>
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Id"
                                    style={{display: 'none'}}
                                    >
                                    {getFieldDecorator('id', {
                                        initialValue: this.state.leg.id,
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={6}>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Chassis"
                                    >
                                    {getFieldDecorator('equipement_id', {
                                        initialValue: this.state.leg.equipement_id == 0 ? undefined : this.state.leg.equipement_id,
                                        rules: [
                                            {
                                                required: false,
                                            },
                                        ],
                                    })(
                                        <SelectFromUrl placeholder="Select a chassis" style={{width: 200}} url={`${localStorage.orgId ? localStorage.orgId : 1}/equipements?type=chassis`} idName="id" name="name" />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    {...formItemLayout}
                                    label="Special Instructions"
                                    >
                                    {getFieldDecorator('instructions', {
                                        initialValue: this.state.leg.instructions,
                                        rules: [
                                            { required: false, message: 'Product is required' }
                                        ],
                                    })(
                                        <Input style={{width: 200}} />
                                    )}
                                </Form.Item>

                                </Col>
                        </Row>

                    </Form>
                </Card>
                
            </>
        );
    }
}

const WrappedLegForm = Form.create({ name: 'leg_form' })(NormalLegForm);

export default WrappedLegForm;
