import React from 'react';
import { Redirect } from 'react-router-dom';

const hasAccess = () => !!localStorage.zenfy_jwt && localStorage.orgId == localStorage.clientOrgId;


class PrivateAccess extends React.Component {
  render() {
    if (!hasAccess()) {
      if(window.location.hash.indexOf('login') != -1 || window.location.hash.indexOf('carbone') != -1 || window.location.hash.indexOf('scan') != -1 || window.location.hash.indexOf('register') != -1 || window.location.hash.split('/')[1] == 'driver' || window.location.hash.split('/')[2] == 'order' || window.location.hash.split('/')[2] == 'donation' || window.location.hash.indexOf('donation') != -1 || (window.location.hash.split('/')[2] && window.location.hash.split('/')[2].indexOf('form')  != -1)){
        return null;
      }else {
        if(window.location.origin.indexOf('localhost') != -1){
          return (<Redirect to='/login' />);
        }else{
          window.location.href = 'https://go.zenfy.co/'; 
          return null;
        }
      }
    }

    if (window.location.hash.indexOf('scan') != -1 || window.location.hash.indexOf('register') != -1 || window.location.hash.split('/')[1] == 'driver'  || window.location.hash.split('/')[2] == 'order' || (window.location.hash.split('/')[2] && window.location.hash.split('/')[2].indexOf('form')  != -1) || (window.location.hash.split('/')[2] && window.location.hash.split('/')[2].indexOf('donation')  != -1) || window.location.hash.indexOf('donation') != -1 || window.location.hash.indexOf('carbone') != -1){
      return null;
    }else{
      return this.props.children;
    }
  }
}

export default PrivateAccess;