import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select
} from 'antd';
import moment from 'moment';
import SelectFromUrl from '../../components/SelectFromUrl';
import Zenfy from "../../libs/Zenfy";

const { Option } = Select;

class NormalChequeForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cheque: props.cheque,
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.cheque.id !== this.state.cheque.id){

            this.props.form.resetFields();

            this.setState({
                cheque: nextProps.cheque
            });

        }

    }

    clientOnChange = (item) => {
        this.setState((prevState, props) => ({
            cheque: {
                ...prevState.cheque,
                client_name: item.name,
                //ends_at: item.months > 0 ? moment().add(item.months, 'months').format('YYYY-MM-DD HH:mm:ss') : undefined,
            }
        }));
    }

    saveCheque = () => {

        /*this.setState({
            loading: true
        });*/

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                
                values.org_id = localStorage.orgId ? localStorage.orgId : 2;
                
                if(values.issue_at){
                    values.issue_at = values.issue_at.format('YYYY-MM-DD HH:mm');
                }

                if(values.invoice_at){
                    values.invoice_at = values.invoice_at.format('YYYY-MM-DD HH:mm');
                }

                if(this.state.cheque.id){
                    values.id = this.state.cheque.id;
                }

                Zenfy.post('cheques', values).then((res) => {

                    let cheque = this.state.cheque;
                    
                    if(res.data.id){
                        cheque.id = res.data.id;
                    }
        
                    this.setState({
                        cheque,
                        loading: false,
                    });
        
                    notification.success({
                        message: `Cheque saved with success!`,
                        placement: 'bottomLeft',
                    });
        
                    this.props.drawer.current.hideDrawer();
                    this.props.drawer.current.load();
                });
            }
        });

        /*Zenfy.post('invoices', values).then((res) => {

            let invoice = this.state.invoice;
          
            invoice.id = res.data.invoice_id ? res.data.invoice_id : res.data;
            invoice.client_id = values.client_id;

            this.setState({
                invoice,
                loading: false,
            });

            notification.success({
                message: `Invoice saved with success!`,
                placement: 'bottomLeft',
            });

            this.props.drawer.current.hideDrawer();
            this.props.drawer.current.load();
        });
*/
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>

                <Form className="cheque-form">
                
                    <Form.Item
                        {...formItemLayout}
                        label="Cheque Number"
                        >
                        {getFieldDecorator('cheque_number', {
                            initialValue: this.state.cheque.cheque_number,
                            rules: [
                                { required: true, message: 'Cheque number is required' }
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Vendor"
                        >
                        {getFieldDecorator('client_id', {
                            initialValue: this.state.cheque.client_id,
                            rules: [
                                { required: false, message: 'Cheque number is required' }
                            ],
                        })(
                            <SelectFromUrl itemChange={this.clientOnChange} url={`${localStorage.orgId ? localStorage.orgId : 2}/clients?is_customer=1`} idName="id" name="name" />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Name"
                        >
                        {getFieldDecorator('client_name', {
                            initialValue: this.state.cheque.client_name,
                            rules: [
                                {
                                    required: false,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Issue Date"// ERD 10/11 AND C/O 10/16
                        >
                        {getFieldDecorator('issue_at', {
                            initialValue: this.state.cheque.issue_at ? moment(this.state.cheque.issue_at, "YYYY-MM-DD HH:mm") : null,
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <DatePicker format={"YYYY-MM-DD"} />
                        )}
                    </Form.Item>
                    
                    <Form.Item
                        {...formItemLayout}
                        label="Amount"
                        >
                        {getFieldDecorator('amount', {
                            initialValue: this.state.cheque.amount,
                            rules: [
                                {
                                    required: false,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Vendor Invoice Number"
                        >
                        {getFieldDecorator('invoice_number', {
                            initialValue: this.state.cheque.invoice_number,
                            rules: [
                                {
                                    required: false,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label="Vendor Invoice Date"// ERD 10/11 AND C/O 10/16
                        >
                        {getFieldDecorator('invoice_at', {
                            initialValue: this.state.cheque.invoice_at ? moment(this.state.cheque.invoice_at, "YYYY-MM-DD HH:mm") : null,
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <DatePicker format={"YYYY-MM-DD"} />
                        )}
                    </Form.Item>

                    <Form.Item>

                        <Button type="primary" htmlType="submit" className="pull-right" loading={this.state.loading} onClick={this.saveCheque}>
                            Save
                        </Button>

                        <a href="javascript:;" className="pull-right" style={{margin: '-4px 20px 0px 0px'}} onClick={() => this.props.drawer.current.hideDrawer()} >Close</a>

                    </Form.Item>

                </Form>

            </>
        );
    }
}

const WrappedChequeForm = Form.create({ name: 'cheque_form_wapped' })(NormalChequeForm);

export default WrappedChequeForm;
