import React, { Component, Suspense } from "react";

import { ChartCard, MiniBar, Pie } from 'ant-design-pro/lib/Charts';
import { Tooltip, Icon, Col, Row, DatePicker, Card } from 'antd';
//import { Chart, Axis, Tooltip, Geom } from 'bizcharts';
//import SalesCard from '../components/SalesCard'
import 'ant-design-pro/dist/ant-design-pro.css';
import numeral from 'numeral';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import Zenfy from '../libs/Zenfy';

const { RangePicker } = DatePicker;
class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      revenue: [],
      loading: false,
      totalRevenue: 0,
      totalOrders: 0,
      invoiced: [],
      totalInvoiced: 0,
      pieData: [],
      pieOrdersData: [],
      pieRevenueByCustomers: [],
      pieBestProduct: [],
      mac: [],
      totalMac: 0,
      macLoading: false,
      start: moment().startOf('year').format('YYYY-MM-DD'),
      end: moment().endOf('year').format('YYYY-MM-DD'),
      macAndRevenue: [],
      grandTotal: 0,
      googleLoading: false,
      googleTotal: 0,
      google: [],
    }

    this.stats = [];
    this.revenue = [];
  }

  componentDidMount() {

    this.getStats();

  }

  getGoogle = () => {
    this.setState({
      googleLoading: true,
    });
    Zenfy.get(`https://sheets.googleapis.com/v4/spreadsheets/1PdMTMUJrM9smyP3ETEMW1IMhmMeyjGlhE2DleFRNH6k/?key=AIzaSyCdfAtIPIhGXSpqSJOh9iGUp-Ushd-iWJ8&includeGridData=true`).then((res) => {
      let sheets = res.data.sheets;
      let datas = [];
      let mining = [];

      let fullYear = new Date(this.state.end).getFullYear();

      sheets.map((sheet) => {
        if(sheet.properties.title == 'eth'){
          sheet.data[0].rowData.map((row) => {
            row = row.values;
            if(row && row[7] && row[7].formattedValue){
              if(fullYear == new Date(row[7].formattedValue).getFullYear()){
                datas.push({
                  date: row[7].formattedValue,
                  //year: new Date(row[7].formattedValue).getFullYear(),
                  //month: new Date(row[7].formattedValue).getMonth(),
                  yearMonth: (new Date(row[7].formattedValue).getMonth() + 1) + " " + new Date(row[7].formattedValue).getFullYear(),
                  amount: row[5].formattedValue,
                });
              }
            }
          });
        }

        
        
        if(sheet.properties.title == 'Payouts'){
          sheet.data[0].rowData.map((row) => {
            row = row.values;
            if(fullYear == new Date(row[0].formattedValue * 1000).getFullYear()){
              mining.push({
                yearMonth: (new Date(row[0].formattedValue * 1000).getMonth() + 1) + " " + new Date(row[0].formattedValue * 1000).getFullYear(),
                amount: row[3].formattedValue,
              });
            }
          });
          
        }
        
      });

      let months = [];
      let total = 0;

      datas = [...datas, ...mining];

      datas.map((data) => {
        if(!months[data.yearMonth]){
          months[data.yearMonth] = 0;
        }

        months[data.yearMonth] += parseFloat(data.amount);
        total += parseFloat(data.amount);
      });

      Zenfy.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=cad&ids=ethereum`).then((coin) => {
        let finalData = [];
        for (var key in months) {
          finalData.push({
            x: key,
            y: months[key] * coin.data[0].current_price,
          });
        }

        //finalData.reverse();

        this.setState({
          googleLoading: false,
          google: finalData,
          googleTotal: total * coin.data[0].current_price,
        });
      });

    });
  }

  getStats = () => {
    this.setState({
      loading: true,
    });

    let revenue = [];
    let totalRevenue = 0;
    let orders = [];
    let totalOrders = 0;

    Zenfy.get(`${localStorage.orgId}/stats/revenue?start=${this.state.start}&end=${this.state.end}`).then((res) => {
      res.data.map((res) => {
        totalRevenue += res.y;
        //res.y = Math.abs(res.y).toFixed(2);
      });
      revenue = res.data;

      this.setState({
        revenue,
        totalRevenue,
        loading: false,
      });

    });

    let mainStat = localStorage.isDispatch == 1 ? 'orders' : 'clients';

    if(localStorage.type && localStorage.type == 'crm'){
      mainStat = 'clients';
    }

    Zenfy.get(`${localStorage.orgId}/stats/${mainStat}?start=${this.state.start}&end=${this.state.end}`).then((res) => {
      res.data.map((res) => {
        totalOrders += res.y;
      });
      orders = res.data;

      this.setState({
        orders,
        totalOrders,
        loading: false,
      });

    });

    if(localStorage.isDispatch == 1){

      let invoiced = [];

      Zenfy.get(`${localStorage.orgId}/stats/invoiced?start=${this.state.start}&end=${this.state.end}`).then((res) => {

        let totalInvoiced = 0;
        let totalPaid = 0;
        let totalBalance = 0;

        res.data.map((res) => {
          totalInvoiced += res.amount_due;
          totalPaid += res.paid;
          totalBalance += Math.abs(res.balance);

          invoiced.push({
            x: res.x,
            y: res.amount_due
          });
        });

        this.setState({
          invoiced,
          totalInvoiced,
          pieData:[{
            x: 'Invoiced',
            y: totalInvoiced,
          },{
            x: 'Received',
            y: totalPaid,
          },{
            x: 'Outstading',
            y: totalBalance,
          }]
        }, () => {
          setTimeout(() => {
            if(document.querySelector(".pie ul li")){
              document.querySelector(".pie ul li").click();
            }
          }, 500);

          /*setTimeout(() => {
            let elements = document.querySelector(".invoice-sum .antd-pro-charts-pie-percent");
            console.log(elements);
            for(let i = 0; i <elements.length; i++){
                
                elements[i].innerHTML = "a";
            }
          }, 1000);*/
        });
      
      });

      Zenfy.get(`${localStorage.orgId}/stats/orderstype?start=${this.state.start}&end=${this.state.end}`).then((res) => {

        let data = [];

        res.data.map((res) => {

          console.log(res);

          let status = Zenfy.renderStatus({
            status: res.x
          }, true);

          data.push({
            x: status,
            y: res.y
          });

        });

        this.setState({
          pieOrdersData: data
        })

        /*this.setState({
          invoiced,
          totalInvoiced,
          pieData:[{
            x: 'Invoiced',
            y: totalInvoiced,
          },{
            x: 'Received',
            y: totalPaid,
          },{
            x: 'Outstading',
            y: totalBalance,
          }]
        });*/
      
      });

      Zenfy.get(`${localStorage.orgId}/stats/invoicedclients?start=${this.state.start}&end=${this.state.end}`).then((res) => {

        this.setState({
          pieRevenueByCustomers: res.data
        });
      
      });
      
    }else{

      Zenfy.get(`${localStorage.orgId}/stats/invoicedtype?start=${this.state.start}&end=${this.state.end}`).then((res) => {

        this.setState({
          pieData: res.data
        });
      
      });

      Zenfy.get(`${localStorage.orgId}/stats/clientsproduct?start=${this.state.start}&end=${this.state.end}`).then((res) => {

        this.setState({
          pieBestProduct: res.data
        });
      
      });
      
    }

    if(localStorage.orgId == 6){

      this.getGoogle();

      this.setState({
        macLoading: true,
      });

      let year = moment(this.state.end).year();

      Zenfy.get(`https://makeachamp.com/api/v1/stats_internal?year=${year}&rate=true`).then((res) => {
        
        let macData = this.calculateTips(res.data);

        console.log(macData);

        Zenfy.get(`https://sportfunder.com/api/v1/stats_internal?year=${year}&rate=true`).then((res) => {
          let sportData = this.calculateTips(res.data);
          let totalMac = 0;
          totalMac += macData.total + sportData.total;

          sportData.gfx.map((obj, index) => {
            macData.gfx[index].y = macData.gfx[index].y + sportData.gfx[index].y;
          });

          this.setState({
            mac: macData.gfx,
            macLoading: false,
            totalMac,
          }, () => {
            let macAndRevenue = [];

           // console.log(macData.gfx, this.state.revenue);
          
            macData.gfx.map((obj, index) => {
              macAndRevenue[index] = {y: 0, x: obj.x};
              let mRevenu = 0;
              this.state.revenue.map((rev) => {
                if(rev.month == obj.z){
                  mRevenu = rev.y;
                }
              });

              if (year < 2023) {
                if (year == 2022) {
                  if (index == 'September' || index == 'October' || index == 'November' || index == 'December') {
                    //obj.y = 0;
                  }
                }
              } else {
                //obj.y = 0;
              }

              let shouldAdd = false;

              if (year < 2023) {
                shouldAdd = true;
              }

              if (year == 2022 && (index == 8 || index == 9 || index == 10 || index == 11)){
                shouldAdd = false;
              }
            
              macAndRevenue[index].y = !shouldAdd ? mRevenu : obj.y + mRevenu;
            });
            console.log(macAndRevenue, year);
            let grandTotal = year < 2023 ? totalMac : 0 + this.state.totalRevenue;

            this.setState({
              macAndRevenue,
              grandTotal
            });
          });
        });
        
        
      });

    }

  }

  calculateTips = (data) => {
    let res = {
      gfx: [],
      total: 0,
    };

    data.map((obj) => {
      obj.tips = parseFloat(obj.tips.replace(/\,/g, ''), 10);
      res.gfx.push({
          x: moment().month(obj.month-1).format("MMMM"),
          y: obj.tips,
          z: obj.month
        });
      console.log(obj.tips);
      res.total += obj.tips;
    });

    return res;
  }

  datesOnChange = (e, date) => {
      console.log(date[1]);
      this.setState({
          start: date[0],
          end: date[1],
      }, () => {
          this.getStats();
      });
  }

  render() {

    let mainChartTitle = 'Orders';

    if(localStorage.isDispatch == 0){
      mainChartTitle = 'Members';
    }

    if(localStorage.type == 'crm'){
      mainChartTitle = 'Contacts';
    }

    return (
      <>

        <Row gutter={24}>
          <Col span={24}>
            <RangePicker 
              placeholder={['StartDate','EndDate']} 
              style={{ width: 256, marginBottom: 24, float: 'right' }} 
              onChange={this.datesOnChange}
              defaultValue={[moment().startOf('year'), moment().endOf('year')]}
              ranges={{
                  Today: [moment(), moment()],
                  'Last 3 months': [moment().startOf('month').subtract(2, 'month'), moment().endOf('month')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                  'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
                  'All Time': [moment().startOf('year').subtract(10, 'years'), moment()],
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <ChartCard
              loading={this.state.loading}
              title='Revenue'
              action={
                <Tooltip title="Total received">
                  <Icon type="exclamation-circle-o" />
                </Tooltip>
              }
                total={numeral(this.state.totalRevenue).format('0,0')}
                contentHeight={140}
              >
              <MiniBar height={100} data={this.state.revenue} />

            </ChartCard>

          </Col>
          <Col span={12}>

          <ChartCard
            title={mainChartTitle}
            loading={this.state.loading}
            action={
              <Tooltip title={'Total ' + mainChartTitle}>
                <Icon type="exclamation-circle-o" />
              </Tooltip>
            }
              total={numeral(this.state.totalOrders).format('0,0')}
              contentHeight={140}
            >
            <MiniBar height={100} data={this.state.orders} />

          </ChartCard>

          </Col>
        </Row>


        <Row gutter={24} style={{margin: '24px 0'}}>
          <Col span={12}>
            {localStorage.isDispatch == 1 ? (
              <ChartCard
                title="Invoiced"
                loading={this.state.loading}
                action={
                  <Tooltip title="Invoices total number">
                    <Icon type="exclamation-circle-o" />
                  </Tooltip>
                }
                  total={numeral(this.state.totalInvoiced).format('0,0')}
                  contentHeight={140}
                >
                <MiniBar height={100} data={this.state.invoiced} />

              </ChartCard>
            ) :  <Card className="pie invoice-sum" loading={this.state.loading}>
            <Pie
              hasLegend
              subTitle={localStorage.type == 'crm' ? "Contacts by Product" : "Members By Class"}
              data={this.state.pieBestProduct}
              valueFormat={val => <span dangerouslySetInnerHTML={{ __html: numeral(val).format('0,0') }} />}
              height={188}
            />
          </Card>}
            
          </Col>
          <Col span={12}>
            <Card className="pie invoice-sum" loading={this.state.loading}>
              <Pie
                hasLegend
                subTitle={localStorage.isDispatch == 1 ? "Invoice Summary" : "Revenue by Type"}
                data={this.state.pieData}
                valueFormat={val => <span dangerouslySetInnerHTML={{ __html: numeral(val).format('0,0') }} />}
                height={188}
              />
            </Card>
          </Col>
        </Row>
        
        {localStorage.isDispatch == 1 ? (
        <Row gutter={24} style={{margin: '24px 0'}}>
          <Col span={12}>

            <Card className="pie" loading={this.state.loading}>
              <Pie
                hasLegend
                subTitle="Orders by Status"
                data={this.state.pieOrdersData}
                valueFormat={val => <span dangerouslySetInnerHTML={{ __html: numeral(val).format('0,0') }} />}
                height={188}
              />
            </Card>
            
          </Col>
          <Col span={12}>
          <Card className="pie" loading={this.state.loading}>
              <Tooltip title="Top 5 Customers">
                <Icon type="exclamation-circle-o" />
              </Tooltip>
              <Pie
                hasLegend
                subTitle="Revenue by Customers"
                data={this.state.pieRevenueByCustomers}
                valueFormat={val => <span dangerouslySetInnerHTML={{ __html: numeral(val).format('0,0') }} />}
                height={188}
              />
            </Card>
          </Col>
        </Row>) : null}

        {localStorage.orgId == 6 ? (<>
          <Row gutter={24} style={{margin: '24px 0'}}>
            <Col span={24}>

              <ChartCard
                  title="Crowdfunding Tips"
                  loading={this.state.macLoading}
                  action={
                    <Tooltip title="Invoices total number">
                      <Icon type="exclamation-circle-o" />
                    </Tooltip>
                  }
                    total={numeral(this.state.totalMac).format('0,0')}
                    contentHeight={140}
                  >
                  <MiniBar height={100} data={this.state.mac} />

                </ChartCard>
              
            </Col>
            <Col span={24} style={{margin: '24px 0'}}>

              <ChartCard
                  title="Total"
                  loading={this.state.macLoading}
                  action={
                    <Tooltip title="Invoices total number">
                      <Icon type="exclamation-circle-o" />
                    </Tooltip>
                  }
                    total={numeral(this.state.grandTotal).format('0,0')}
                    contentHeight={140}
                  >
                  <MiniBar height={100} data={this.state.macAndRevenue} />

                </ChartCard>
              
            </Col>
            
            <Col span={24}>

              <ChartCard
                  title="Eth"
                  loading={this.state.googleLoading}
                  action={
                    <Tooltip title="Mining + trading">
                      <Icon type="exclamation-circle-o" />
                    </Tooltip>
                  }
                    total={numeral(this.state.googleTotal).format('0,0')}
                    contentHeight={140}
                  >
                  <MiniBar height={100} data={this.state.google} />

              </ChartCard>
              
            </Col>

          </Row>
          
        </>) : null}

      </>
    );
  }
}

export default Dashboard;