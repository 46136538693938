import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Badge, notification, Tooltip } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";
import NormalProductForm from "./ProductForm";

class Products extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            order: props.order,
            showModal: false,
            selectedInvoice: {}
        }

        this.drawerRef = React.createRef();
        this.formRef = React.createRef();
        this.payForm = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

        // this.componentHasGotData(newProps);
        if(this.state.order.id != newProps.order.id){

            this.setState({
                order: newProps.order
            },() => {
               this.componentDidMount();
            });
        }

    }


    load = () => {

        this.setState({
            loading: true,
        });

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/products_orders?orderId=${this.state.order.id}`).then((res) => {
            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
            })
        });
    }

    addProductOrder = (values) => {
        Zenfy.post('products_orders', values).then((res) => {
            this.load();
            this.drawerRef.current.hideDrawer();

            notification.success({
                message: `Item saved with success!`,
                placement: 'bottomLeft',
            });

            this.formRef.current.getForm().resetFields();
        });
    }

    onSave = () => {

        this.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.order_id = this.state.order.id;
                values.org_id = localStorage.orgId;
                values.id = this.state.selectedInvoice.id ? this.state.selectedInvoice.id : null;

                if(!this.state.order.id){
                    Zenfy.post('orders', {org_id: localStorage.orgId}).then((res) => {
                        values.order_id = res.data;
                        let order = this.state.order;
                        order.id = res.data;
                        this.setState({
                            order
                        }, () => {
                            this.addProductOrder(values);
                        });
                    });
                }else{
                    this.addProductOrder(values);
                }

            }
        });

        
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedInvoice: record,
        });
    }

    delete = (record) => {
        Zenfy.delete(`products_orders/${record.id}`).then((res) => {
            this.load();
        });
    }

    render() {

        const columns = [/*{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            sorter: (a, b) => a.id - b.id,
        },*/{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text ? text.replace(/<[^>]*>?/gm, '') : ''}</a>,
        },{
            title: 'Apply Tax',
            dataIndex: 'tax',
            key: 'tax',
            render: (text) => text == 0 ? 'No' : 'Yes',
        },{
            title: 'Recurring',
            dataIndex: 'recurring',
            key: 'recurring',
            render: (text, record) => text == 0 ? 'No' : <Tooltip title={record.ends_at ? moment(record.ends_at).format("MMMM Do YYYY") : ""}><span>Yes</span></Tooltip>,
        },{
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Fuel Surcharge',
            dataIndex: 'fuel',
            key: 'fuel',
            sorter: (a, b) => a.amount - b.amount,
            render: (text) => `${text}%`,
            className: localStorage.type == 'crm' ? 'hide' : '',
        }/*,{
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        }*/,{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                        <Menu.Item key="2" onClick={() => this.delete(record)}>Delete</Menu.Item>
                    </Menu>
                  );
          
                return (<Dropdown overlay={menu}>
                <Button size="small">
                    <Icon type="down" />
                </Button>
                </Dropdown>
                )
             
            },
          },
    ];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <div style={this.props.style}>
            <DrawerTableForm 
                forceSmall={this.props.forceSmall}
                title="Add new item" 
                modal 
                onSave={this.onSave} 
                columns={columns} 
                rows={this.state.rows} 
                recordOnClick={this.recordOnClick} 
                ref={this.drawerRef} 
                form={<NormalProductForm 
                    drawer={this.drawerRef} 
                    key={Math.random()}
                    reLoad={this.load} 
                    order={this.state.order}
                    product={this.state.selectedInvoice} 
                    ref={this.formRef} />} 
                    loading={this.state.loading
                } 
            />
        </div>
        );
    }
}

export default Products;