import React, { Component } from "react";
import { notification, Menu, Dropdown, Button, Icon } from 'antd';

import DrawerTableForm from './DrawerTableForm';

import Zenfy from "../libs/Zenfy";
import moment from "moment";

class Schedule extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            showModal: false,
            selected: {},
        }

        this.drawerRef = React.createRef();
        this.formRef = React.createRef();
        this.payForm = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);

        this.setState({
            selected: record,
        });
    }

    delete = (record) => {
        Zenfy.delete(`schedule/${record.id}`).then((res) => {
            this.drawerRef.current.load();
        });
    }

    duplicate = (record) => {

        record.id = null;
        delete record.key;

        Zenfy.post('schedule', record).then((res) => {
            this.drawerRef.current.load();

            notification.success({
                message: `Schedule duplicated with success!`,
                placement: 'bottomLeft',
            });
        });
    }

    onSave = () => {
        this.drawerRef.current.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.product_id = this.props.product.id;
                values.org_id = localStorage.orgId;
                values.id = this.state.selected.id ? this.state.selected.id : null;

                values.start = values.start.format('HH:mm');
                values.end = values.end.format('HH:mm');
              
                Zenfy.post('schedule', values).then((res) => {
                    this.drawerRef.current.hideDrawer();
                    this.drawerRef.current.load();

                    notification.success({
                        message: `Schedule saved with success!`,
                        placement: 'bottomLeft',
                    });

                    //this.drawerRef.current.formRef.current.getForm().resetFields();
                });

            }
        });
    }

    renderTime = (text) =>{
        if(text._d){
            return text.format('HH:mm');
        }else{
            return moment(text, "HH:mm").format('HH:mm');
        }
    }

    render() {

        const columns = [{
            title: 'Day',
            dataIndex: 'day',
            key: 'day',
            type: 'week',
            render: (text, record) => (
                <span>
                    <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{Zenfy.getDayOfTheWeek(text)}</a>
                </span>
            ),
            required: true,
        },{
            title: 'Start',
            dataIndex: 'start',
            key: 'start',
            type: 'time',
            required: true,
            render: this.renderTime,
        },{
            title: 'End',
            dataIndex: 'end',
            key: 'end',
            type: 'time',
            required: true,
            render: this.renderTime,
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.duplicate(record)}>Duplicate</Menu.Item>
                  <Menu.Item key="3" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        },
    ];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);
        return (
            <div style={this.props.style}>
                {this.props.product.id ? (
                    <DrawerTableForm 
                        hideSearch
                        forceSmall={this.props.forceSmall}
                        load={`schedule?product_id=${this.props.product.id}`}
                        title="Add new Schedule" 
                        modal 
                        columns={columns} 
                        recordOnClick={this.recordOnClick} 
                        ref={this.drawerRef} 
                        hideFormFooter
                        onSave={this.onSave}
                        loading={this.state.loading} 
                        key={this.props.product.id} 
                    />
                ) : (
                    <span>Please save the product before adding schedule.</span>
                )}
            </div>
        );
    }
}

export default Schedule;