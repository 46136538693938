import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select
} from 'antd';
import moment from 'moment';
import SelectFromUrl from './SelectFromUrl';
import CompanyModal from './CompanyModal';
import Zenfy from "../libs/Zenfy";
import Labels from "../libs/Labels";

const { Option } = Select;

class NormalContactForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contact: props.contact,
            loading: false,
            showCompanyModal: false,
            comapniesKey: Math.random(),
        }

        this.newCompany = null;
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.contact.id !== this.state.contact.id){

            this.props.form.resetFields();

            this.setState({
                contact: nextProps.contact,
                showCompanyModal: false,
            });

        }

    }

    afterAddCallBack = (values) => {
        this.newCompany = values;

        this.setState({
            showCompanyModal: false,
            comapniesKey: Math.random(),
        });
    }

    clientsAfterLoad = () => {
        if(this.newCompany){
            let contact = this.state.contact;
            
            contact.client_id = parseInt(this.newCompany); // for some reason it was reading as a string
            this.setState({
                contact,
            });

            this.newCompany = null;
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>

                <CompanyModal showModal={this.state.showCompanyModal} afterAddCallBack={this.afterAddCallBack} is_customer />

                <Form className="contact-form">

                    {this.props.showCompany ? (
                    <>
                    <Form.Item
                        {...formItemLayout}
                        label="Company"
                        >
                        {getFieldDecorator('client_id', {
                            initialValue: this.state.contact.client_id,
                            rules: [
                                {
                                    required: true,
                                    message: 'Company is required',
                                },
                            ],
                        })(
                            <SelectFromUrl afterLoad={this.clientsAfterLoad} url={`${localStorage.orgId ? localStorage.orgId : 2}/clients?is_customer=1`} idName="id" name="name" key={this.state.comapniesKey} />
                        )}
                    </Form.Item>
                    
                    <a href="javascript:;" style={{display: 'inherit', marginTop: '-26px', float: 'right'}} onClick={() => {
                        this.setState({
                            showCompanyModal: true,
                        });
                    }}>Add new company</a>
                    </>) : null}
                    

                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Contact name")}
                        >
                        {getFieldDecorator('name', {
                            initialValue: this.state.contact.name,
                            rules: [
                                {
                                    required: true,
                                    message: 'Name is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Title")}
                        >
                        {getFieldDecorator('title', {
                            initialValue: this.state.contact.title,
                            rules: [
                                {
                                    required: false,
                                    message: 'Email is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Email")}
                        >
                        {getFieldDecorator('email', {
                            initialValue: this.state.contact.email,
                            rules: [
                                {
                                    required: true,
                                    message: 'Email is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Phone")}
                        >
                        {getFieldDecorator('telephone', {
                            initialValue: this.state.contact.telephone,
                            rules: [
                                {
                                    required: false,
                                    message: 'Phone is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Ext")}
                        >
                        {getFieldDecorator('ext', {
                            initialValue: this.state.contact.ext,
                            rules: [
                                {
                                    required: false,
                                    message: 'Phone is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                                        
                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Fax")}
                        >
                        {getFieldDecorator('fax', {
                            initialValue: this.state.contact.fax,
                            rules: [
                                {
                                    required: false,
                                    message: 'Phone is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    
                    <Form.Item
                        {...formItemLayout}
                        label={Labels.get("Mobile")}
                        >
                        {getFieldDecorator('mobile', {
                            initialValue: this.state.contact.mobile,
                            rules: [
                                {
                                    required: false,
                                    message: 'Phone is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>

                </Form>

            </>
        );
    }
}

const WrappedContactForm = Form.create({ name: 'client_contact_form' })(NormalContactForm);

export default WrappedContactForm;
