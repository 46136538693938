import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Table, Input, DatePicker, notification } from 'antd';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

const { RangePicker } = DatePicker;

class Attendance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            showEmailModal: false,
            selectedInvoice: {},
            start: moment().startOf('week').format('YYYY-MM-DD'),
            end: moment().endOf('week').format('YYYY-MM-DD'),
        }

        this.emailModalRef = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

    }

    load = () => {

        this.setState({
            loading: true,
        });


        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/attendances?start=${this.state.start}&end=${this.state.end}`).then((res) => {
            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
            });
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => text,
        },{
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            render: (text) => moment(text).fromNow()
        },{
            title: 'Passed',
            dataIndex: 'passed',
            key: 'passed',
            sorter: (a, b) => a.passed.length - b.passed.length,
            render: (text) => text == 1 ? "Yes" : "No",
        }
    ];

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            return obj.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <Input placeholder={`Search for a member`} className="pull-left" style={{width: '400px', marginBottom: 16}} onChange={this.searchOnChange} />
            
            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange} 
                defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                ranges={{
                    Today: [moment(), moment()],
                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                    'Last Week': [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                    'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
                }}
            />

            <Table dataSource={rows} columns={columns} loading={this.state.loading} />
        </>
    );

    }
}

export default Attendance;