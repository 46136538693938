import React, { Component } from "react";
import { Modal, Form, Input, Checkbox, Icon, Tooltip, Upload, Button, notification } from 'antd';

import SelectFromUrl from './SelectFromUrl';

import Zenfy from "../libs/Zenfy";
import Labels from '../libs/Labels';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EmailModalForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
          loading: false,
          email: null,
      }
    }

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();

        /*if(this.props.container){
          file = new File([file], this.props.container + '_' + file.name);
        }*/
        
        formData.append('file', file);

        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);

            Zenfy.post('files', {
                invoice_id: this.props.record.id,
                org_id: localStorage.orgId ? localStorage.orgId : 2,
                client_id: this.props.record.client_id,
                file_name: `${Zenfy.getRoot()}uploads/${res.data}`,
            });
            /*notification.success({
                message: `File uploaded with success!`,
                placement: 'bottomLeft',
            });*/
        });
    }

    sendEmail = () => {

      this.setState({
        loading: true,
      });

      this.props.form.validateFieldsAndScroll((err, values) => {
        if(!err){

          let emails = values.email.trim().split(',');
          let emailHistory = values.email;

          if(emails.length > 0){
              emails = emails.map((email) => {
                  return email.trim().replace(/\s/g,'');
              });
          }

          values.invoiceUrl = `${Zenfy.getRoot()}${localStorage.orgId}/invoice/${this.props.record.invoice_id}?lang=${localStorage.Lang}`;

          values.email_text = Zenfy.replacer(values.email_text, values);
          values.email_text = values.email_text.replace(new RegExp("<p><br></p>", 'g'), '');

          values.email = true;
          values.emails = emails;

          Zenfy.get(this.props.emailUrl, values).then(() => {
            this.setState({
              loading: false,
            }, () => {
              this.props.onCancel();
              notification.success({
                  message: Labels.get('Sent email with success!'),
                  placement: 'bottomLeft',
              });

              Zenfy.post('histories', {
                org_id: localStorage.orgId, 
                invoice_id: this.props.record.invoice_id,
                client_id: this.props.record.client_id,
                data1: emailHistory,
                data5: JSON.stringify(values),
                action: 'email',
              });

            });
          });
        }else{
          this.setState({
            loading: false,
          });
        }
      });
      
    }

    contactOnChange = (item) => {
      if(item && item.email){
        /*this.setState({
          email: item.email,
        });*/

        this.props.form.setFieldsValue({
          email: item.email,
        });
      }
    }

    render() {
      const { visible, onCancel, onCreate, form, record } = this.props;
      const { getFieldDecorator } = form;

      let email = this.props.emailProp ? record[this.props.emailProp] : record.email;

      if(this.state.email){
        email = this.state.email;
      }

      let fileList = [];

      if(record.id && record.orders && record.orders.length > 0 && (!record.email_text || record.email_text == '')){

        let containerString = '';

        record.orders.map((order) => {
          containerString += order.container + ', ';
        });

        containerString = containerString.slice(0, -2);

        record.email_text = `<p>Hello,</p>
        <p>Please find the attached Invoice for ${containerString}.</p>
        <p>Please reply to this email if there is any issue.</p>
        <p>Thank you for your business.</p>`;
      }else if(!record.email_text){
        record.email_text = "";
      }

      return (
        <Modal
          visible={visible}
          title={this.props.title ? this.props.title : Labels.get("Email")}
          okText={this.props.okText ? this.props.okText : Labels.get("Send")}
          cancelText={Labels.get("Cancel")}
          onCancel={onCancel}
          onOk={onCreate ? onCreate : this.sendEmail}
          okButtonProps={{loading: this.props.loading ? this.props.loading : this.state.loading}}
        >
          <Form layout="vertical">
            <Form.Item label={Labels.get("Contact")} style={{margin: 0}}>
              {getFieldDecorator('contact', {
                //initialValue: this.props.emailProp ? record[this.props.emailProp] : record.email,
                rules: [{ required: false }],
              })(<SelectFromUrl  
                  url={`${localStorage.orgId ? localStorage.orgId : 2}/contacts?clientId=${record.client_id}`} 
                  idName="id" 
                  //key={Math.random()}
                  itemChange={this.contactOnChange}
                  altRender={(row) => {
                    return `${row.name} ${row.title ? '- ' + row.title : ''} ${row.email ? '(' + row.email + ') ' : ''}`
                  }}
                  name="name" 
                />
              )}
            </Form.Item>
            <p style={{fontSize: '12px'}}>{Labels.get("You can add more emails by adding ,")}</p>
            <Form.Item label={Labels.get("Emails")}>
              {getFieldDecorator('email', {
                initialValue: email,
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            
            {this.props.attachement ? (
              <Form.Item label={
                <span>
                  Include order attachements&nbsp;
                  <Tooltip title="Includes files that are uploaded to the order in the order section">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }>
                {getFieldDecorator('include', {
                  initialValue: record.include,
                  valuePropName: 'checked',
                  rules: [{ required: false }],
                })(<Checkbox />)}
              </Form.Item>
            ) : null}
            {this.props.files ? (
              <Form.Item label={Labels.get("Attachements")}>
                {getFieldDecorator('files', {
                  initialValue: record.files,
                  //valuePropName: 'checked',
                  rules: [{ required: false }],
                })(
                  <Upload multiple defaultFileList={fileList} customRequest={(e) => this.uploadFile(e)} className="full" style={{width: '100%'}} key={record.id}>
                    <Button type="dashed">
                        Upload
                    </Button>
                  </Upload>
                )}
              </Form.Item>
            ) : null}
            {this.props.subject ? (
              <Form.Item label={Labels.get("Subject")}>
                {getFieldDecorator('subject', {
                  initialValue: this.props.subject ? this.props.subject : '',
                  //valuePropName: 'checked',
                  rules: [{ required: true }],
                })(
                  <Input />
                )}
              </Form.Item>
            ) : null}
            {this.props.emailText ? (
              <Form.Item label={Labels.get("Email body text")}>
                {getFieldDecorator('email_text', {
                  initialValue: record.email_text,
                  //valuePropName: 'checked',
                  rules: [{ required: true }],
                })(
                  <ReactQuill theme="snow" />
                )}
              </Form.Item>
            ) : null}
            </Form>
        </Modal>
      );
    }
  },
);

/*class CollectionsPage extends React.Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          New Collection
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
}*/

export default EmailModalForm;