import React, { Component } from "react";
import {
Form, Icon, Input, Button, message
} from 'antd';

import { Redirect } from 'react-router-dom';
import Zenfy from '../libs/Zenfy';

class NormalLoginForm  extends Component {

    state = {
        loading: false,
    }

    login = (val) => {

        this.setState({
            loading: true
        });

        Zenfy.post('login', val).then((res) => {

            localStorage.zenfy_jwt = res.data.api_token;
            localStorage.orgId = res.data.org_id;
            localStorage.zenfy_role = res.data.role;

            /*if(res.data.org_id == 2 || res.data.org_id == 4 || res.data.org_id == 5 || res.data.org_id == 6){
                localStorage.isDispatch = 1;
            }else{
                localStorage.isDispatch = 0;
            }*/
            localStorage.isDispatch = res.data.is_dispatch;
            localStorage.type = res.data.type;
            localStorage.url = res.data.url;
            localStorage.clientOrgId = res.data.org_id;
            localStorage.cc_desc = res.data.org.cc_desc;
            localStorage.currency = res.data.org.currency ? res.data.org.currency : 'cad';
            localStorage.userName = res.data.name;
            
            localStorage.setItem('org', JSON.stringify(res.data.org));
            


            // this.forceUpdate();
            window.location.reload();

        }).catch(error => {
            this.setState({loading: false});
            console.log(error.response);
            message.error("...");
            /*if (error.response && error.response.data && error.response.data.msg) {
                message.error(error.response.data.msg);
            }else{
                
            }*/
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();

        const me = this;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                me.login(values);
            }
        });
    }
    
    render() {
        const { getFieldDecorator } = this.props.form;

        const hasAccess = () => !!localStorage.zenfy_jwt && localStorage.orgId == localStorage.clientOrgId;

        if(hasAccess()){
            return (<Redirect to='/' />);
        }

        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Email is required' }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email or username" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Password is required' }],
                    })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                    )}
                </Form.Item>
                <Form.Item>
                    <a className="login-form-forgot hide" href="">Forgot password</a>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm;


