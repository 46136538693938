import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Badge, notification, Modal } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";

import moment from 'moment';
import Currency from 'react-currency-formatter';

class Campaigns extends Component {

    constructor(props) {
        super(props);

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    copyToClipboard = (string) => {
        Zenfy.copyToClipboard(string);
        notification.success({
            message: `Copied with success!`,
            placement: 'bottomLeft',
        });
    }

    delete = (record) => {
        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this campaign?',
            okText: 'Yes',
            onOk: () => {
                Zenfy.delete(`cmps/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
                //alert('coming soon!');
            },
            onCancel: () => {
              
            },
        });
    }

    render() {

        const columns = [{
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: true,
        },{
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
            type: 'switch',
        },{
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            key: 'name',
        },{
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.length - b.email.length,
            key: 'email',
        },{
            title: 'Goal',
            dataIndex: 'goal',
            key: 'goal',
            type: 'number',
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },
        {
            title: 'Ends at',
            dataIndex: 'end_at',
            key: 'end_at',
            type: 'date',
            render: (text, record) => {return record.end_at ? moment(record.end_at).format("YYYY-MM-DD") : ''},
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                    <Menu.Item key="3" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm 
                columns={columns} 
                ref={this.drawerRef} 
                form={null} 
                type="campaign" 
                load="cmps"
                email
            />
        </>
        );
    }
}

export default Campaigns;