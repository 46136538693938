import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Badge, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";

class Prices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            client: props.client,
            showModal: false,
            selectedRow: {},
        }

        this.drawerRef = React.createRef();
        this.formRef = React.createRef();
        this.payForm = React.createRef();
    }

    componentWillReceiveProps(newProps) {

        // this.componentHasGotData(newProps);

        if(this.state.client.id !== newProps.client.id){
            this.setState({
                client: newProps.client
            },() => {
               //this.componentDidMount();
            });
        }

    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedRow: record,
        });
    }

    deleteRow = (record) => {
        Zenfy.delete(`prices/${record.id}`).then((res) => {
            this.drawerRef.current.load();
        });
    }

    onSave = () => {
        this.drawerRef.current.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.client_id = this.props.client.id;
                values.org_id = localStorage.orgId;
                values.id = this.state.selectedRow.id ? this.state.selectedRow.id : null;

                Zenfy.post('prices', values).then((res) => {
                    this.drawerRef.current.hideDrawer();
                    this.drawerRef.current.load();

                    notification.success({
                        message: `Price saved with success!`,
                        placement: 'bottomLeft',
                    });

                    //this.drawerRef.current.formRef.current.getForm().resetFields();
                });

            }
        });
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>
                </span>
              ),
        },{
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            type: 'number',
            sorter: (a, b) => a.price - b.price,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => (
              <span>
                <a href="javascript:;" onClick={()=>this.deleteRow(record)}>Delete</a>
              </span>
            ),
          },
    ];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
            <div style={this.props.style}>
                {this.state.client.id ? (
                    <DrawerTableForm 
                        hideSearch
                        forceSmall={this.props.forceSmall}
                        title="Add new price" 
                        load={`prices?clientId=${this.props.client.id}`}
                        modal 
                        type="prices"
                        columns={columns} 
                        recordOnClick={this.recordOnClick} 
                        ref={this.drawerRef} 
                        hideFormFooter
                        loading={this.state.loading} 
                        onSave={this.onSave}
                    />
                ) : (
                    <span>Please save before adding prices.</span>
                )}
            </div>
        );
    }
}

export default Prices;