import React, { Component } from "react";
import ClientForm from "./ClientForm";

import { Steps, Button, message, Row, Col, Select, Divider, Modal, Input, Alert, Radio } from 'antd';

import SelectFromUrl from '../../components/SelectFromUrl';
import Zenfy from "../../libs/Zenfy";
import CreditCard from '../../components/CreditCard';
import PayForm from '../../components/PayForm';
import moment from 'moment';

import Labels from '../../libs/Labels';

const { Step } = Steps;

class FormCrm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      productId: undefined,
      product: {},
      org: {},
      invoice: {},
      loading: false,
      client: {},
      amount: 0,
      showLoginModal: false,
      modalLoginLoading: false,
      email: '',
      error: null,
      result: null,
      day: '',
      year: '',
      month: '',
      showPasswordInput: false,
      showVerficationInput: false,
      allowOkModal: true,
      disableVerification: false,
      signInBtnText: Labels.get("Sign in"),
      showForgotPw: true,
      action: false,
    }

    this.newProductId = null;
    this.invoiceId = null;
    this.leet = false;
    this.payForm = React.createRef();
    this.productsSelect = React.createRef();
    this.pwInputRef = React.createRef();
    this.codeVRef = React.createRef();

    this.firstHtml = "";
    this.ioId = null;
    this.order_id = null;

    let orgUrl = this.props.match.params.org;
    this.isGranby = Zenfy.getParameterByName('granby');
    this.isChau = Zenfy.getParameterByName('isChau');
    this.carbonId = Zenfy.getParameterByName('carbonId');
    this.carName = Zenfy.getParameterByName('carName');
    this.carAmount = Zenfy.getParameterByName('carAmount');
    this.carBcc = Zenfy.getParameterByName('carBcc');
    this.isMauricie = Zenfy.getParameterByName('mauricie');

    Zenfy.get(`orgs/${orgUrl}`).then((res) => {
      this.setState({
        org: res.data
      }, () => {
        this.checkH();
      });

      if(res.data.id){
        localStorage.orgId = res.data.id;
        window.org = res.data;
      }
    });
  }

  componentDidMount() {
    window.isCarbon = true;
    let language = window.navigator.userLanguage || window.navigator.language;

    if(language && !localStorage.Lang){
      language = language.substring(0,2);
      localStorage.Lang = language;
    }

    let lang = Zenfy.getParameterByName('lang');

    if(lang){
      localStorage.Lang = lang;
    }

    let token = Zenfy.getParameterByName('token');

    if(token){
      token = window.atob(token).split('|');

      if(token[2] && token[2] != ''){
        /*this.setState({
          productId: parseInt(token[2])
        });*/
        this.newProductId = parseInt(token[2]);
      }

      Zenfy.get(`${localStorage.orgId}/clients/${token[0]}`, {
        email: token[1]
      }).then((res) => {
        this.setState({
          client: res.data
        })
      });

    }

    let product = Zenfy.getParameterByName('product');

    if(product){
      this.newProductId = parseInt(product);
    }
    

    /*if(localStorage.client_email && localStorage.client_password){
      Zenfy.post(`${localStorage.orgId}/clientaccount`, {
        email: localStorage.client_email,
        password: localStorage.client_password,
      }).then((res) => {
        if(res.data){
          if(res.data.password){
            this.setState({
              client: res.data
            });
          }
        }
      });
    }*/
  }

  checkH = (goTop) => {
    setTimeout(() => {
      window.parent.postMessage({h: document.getElementById("root").offsetHeight + 400},"*");
      if(goTop){
        window.parent.postMessage({scroll: 1},"*");
      }
    }, 100);
  }

  addProducts = (order_id, products, callback) => {
    if (products.length > 0) {
        let product = products.pop();

        Zenfy.post('products_orders', {
            org_id: localStorage.orgId,
            order_id,
            product_id: product.id,
            amount: product.amount,
            tax: 1
        }).then((res) => {
            this.addProducts(order_id, products, callback);
        })

    }else{
        if (callback) {
            console.log(callback);
            callback();
        }
    }
   
  }

  getPrice = (products) => {
      let price = 0;

      products.map((product) => {
          price += product.amount;
      });

      return price;
  }

  next() {
    const current = this.state.current + 1;

    if(current == 1){
      
      this.setState({
        loading: true,
      });

      window.clientForm.handleSubmit(false, (res) => {

        let client = window.clientForm.props.form.getFieldsValue();

        this.setState({
          email: client.email
        });

        //if(client.ready == 1){ // 3800
        let carProduct = 3720;

        if(this.isGranby){
          carProduct = 3787;
        }

        if(this.isChau){
          carProduct = 3800;
        }

        if(this.carbonId){
          carProduct = this.carbonId;
        }

        let carAmount = this.isGranby ? 35000 : 500;

        if(this.carAmount){
          carAmount = this.carAmount;
        }

        let products = [{
            id: carProduct,
            amount: carAmount,
        }];

        if(client.etape_2){
            products.push({
                id: 3721,
                amount: 1500,
            });
        }

        let client_id = res.data;
            client.id = res.data;
            client.org_id = localStorage.orgId;

            //if(!this.state.invoice.id){
                Zenfy.post('orders', {
                    org_id: localStorage.orgId,
                    client_id,
                }).then((res) => {
                    let order_id = res.data;
                    products = products.reverse();
                    this.addProducts(order_id, [...products], () => {
                        Zenfy.post('invoices', {
                            client_id: client_id,
                            org_id: localStorage.orgId,
                        }).then((res) => {
                        let invoice_id = res.data;
                        let balance = this.getPrice(products);
                        console.log(balance);
                        // create invoice_orders

                        Zenfy.post('invoices_orders', {
                            org_id: localStorage.orgId,
                            invoice_id: invoice_id,
                            order_id: order_id,
                        }).then((res) => {

                            if(this.isGranby || this.carbonId){
                              let bcc = [];
                              let region = "";

                              if(this.isGranby){
                                bcc = ["s.jette@granby-industriel.com"];
                                region = "Granby";
                              }
                              
                              if(this.isChau){
                                bcc = ["info@caeconomique.com"];
                                region = "Chaudière-Appalaches";
                              }

                              if(this.carBcc){
                                bcc = [this.carBcc];
                                region = this.carName;
                              }

                             Zenfy.get(`${localStorage.orgId}/invoice/${invoice_id}`, {
                                email: true,
                                email_text: Labels.get(`Nous avons bien reçu votre demande d’inscription. La personne responsable du Parcours CARBONE-LEADERSHIP<sup>MC</sup> | ${region} communiquera avec vous pour fixer les prochaines rencontres et établir les modalités de paiement.`),
                                subject: "Nous avons bien reçu votre demande d’inscription",
                                emails: [this.state.email],
                                noFile: true,
                                bcc,
                                lang: localStorage.Lang
                              }).then(() => {
                                Modal.success({
                                  closable: false,
                                  maskClosable: false,
                                  keyboard: false,
                                  okText: "Retournez",
                                  //okButtonProps: {disabled: true},
                                  onOk: () => {
                                    window.parent.postMessage({goBack: true},"*");
                                    return false;
                                    //window.location = "https://ecoresponsable.net/carbone-leadership/";
                                  },
                                  title: 'Merci pour votre inscription',
                                  content: (
                                    <>
                                      <p>Dès réception, la personne responsable du Parcours CARBONE-LEADERSHIP<sup>MC</sup> communiquera avec vous pour fixer les prochaines rencontres et établir les modalités de paiement.</p>
                                    </>
                                  ),
                                });
                              });
                              
                              return false;
                            }
        
                            this.setState({
                                loading: false,
                                current,
                                invoice: {
                                    id: invoice_id,
                                    balance: balance
                                },
                                client,
                                totalAmount: balance * 1 + (balance * (0.14975) * 1),
                            }, () => {
                                this.checkH(true);
                            });
                            
                        });
        
                        });
                                
                    });

                });
            /*}else{
                this.setState({
                    current,
                    loading: false,
                }, () => {
                    this.checkH(true);
                });
            }*/
    
            
          }, () => {
              this.setState({
                  loading: false,
              })
          });
          
       
        return;

    }else if(current == 2){

      this.setState({
        loading: true,
      });

      this.payForm.current.validateFieldsAndScroll((err, values) => {
          if (!err) {

              values.client_id = this.state.client.id;
              values.org_id = localStorage.orgId ? localStorage.orgId : 1;
              values.invoice_id = this.state.invoice.id;

              if(!values.type){
                values.type = 'credit';
              }
              
              if(values.type == 'credit'){

                let creditAmount = this.state.totalAmount;
              //  creditAmount = creditAmount + (creditAmount * 0.04 + 0.3);

                window.creditCardForm.handleOk(creditAmount, (res) => {

                    values.txn_id = res.id;
                    
                    this.postTransaction(values);

                    /* need to change invoice amount
                    Zenfy.post('invoices', {
                      id: this.invoiceId,
                      amount: creditAmount
                    });*/

                }, () => {
                  this.setState({
                    loading: false,
                  });
                });
              }else{
                this.postTransaction(values);
              }

              /*if(window.creditCard && window.creditCard.current){
                  
              }else{
                  this.postTransaction(values);
              }*/
          }
      });

    }else{
      this.setState({ current });
    }

    
  }

  generatePdfFile = (callback) => {
              let html = `
          <style>
          body{
            font-family: Arial, sans-serif;
          }
          .ant-divider{
            margin: 15px 0;
          }
          .ant-divider-inner-text{
            color: #000;
            font-size: 20px;
          }
          .ant-form-item-label{
            padding-top: 10px;
            font-size: 14px;
            font-weight: bold;
          }
          .ant-form-explain,.ant-form-extra{
            display: none;
          }
          .documents, .hide-pdf{
            display: none;
          }
          textarea{
            white-space: normal;
            padding-bottom: 30px;
          }
          input[type=text]{
              width: 300px;
          }
          input[type=radio]{
            margin-right: 10px;
            margin-left: 10px;
            }
          </style>
          </style>
          <h3>${this.state.product.category} - ${this.state.product.name}</h3>
          ` + document.getElementById(`form-crm`).innerHTML;
            Zenfy.post('uploadpdfhtml', {
                html,
                filename: 'form_'
            }).then((res) => {
                callback(res.data);
            });
  }

  postTransaction = (values) => {

    //values.amount = values.amount + (this.state.product.taxes == 1 ? values.amount * 1 * 0.14975 : 0);
    values.amount = Zenfy.roundToTwo(this.state.totalAmount);

    Zenfy.post('transactions', values).then((res) => {

      let msg = "";

      if(values.type == 'credit'){
        msg = Labels.get(" Payment passed with success.");
      }else{
        msg = " Nous avons bien reçu votre demande d’inscription.";
      }

      Zenfy.get(`${values.org_id}/invoice/${values.invoice_id}`, {
        email: true,
        email_text: Labels.get("Nous avons bien reçu votre demande d’inscription. Si pour quelques raisons que ce soit votre entreprise n’était pas retenue nous communiquerons avec la personne responsable pour procéder au remboursement, sans frais de pénalité."),
        subject: `${Labels.get('Invoice')} #${values.invoice_id}`,
        emails: [this.state.email],
        bcc: this.isGranby ? ["s.jette@granby-industriel.com"] : [],
        lang: localStorage.Lang
      }).then(() => {
        Modal.success({
          content: (
            <>
              <p>{Labels.get("Thank you")} 🙏{msg}</p>
            </>
          ),
          onOk() {
            window.location.reload();
          },
        });
      });

      //if(this.state.product.is_digital == 0){
        
      //}

    });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current }, () => {
      this.checkH(true);
    });
  }

  productOnChange = (product) => {

    if(!product){
      product = {};
    }else{
      localStorage.marque = 300;

      if(product.category && product.category.includes('Attestation')){
        localStorage.marque = 175;
      }
    }

    this.setState({
      product,
    });
  }
  
  onChange = (id) => {
    this.setState({
      productId: id,
    });
  }

  productsAfterLoad = () => {
    if(this.newProductId){
      this.setState({
        productId: this.newProductId
      }, () => {
        this.productsSelect.current.handleChange(this.newProductId);
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, () => {
      if(target.name == 'email' && Zenfy.validateEmail(target.value)){
        this.checkEmail(target.value);
      }else if(target.name == 'verification' && target.value.length > 3){
        this.checkVerification();
      }else if(target.name == 'password' && target.value.length > 1){
        this.setState({
          allowOkModal: false,
        });
      }
    });
  }

  checkVerification = () => {
    Zenfy.post(`${localStorage.orgId}/checkverification`, {
      email: this.state.email,
      verification: this.state.verification
    }).then((res) => {
      if(res.data){
        this.setState({
          showPasswordInput: true,
          success: Labels.get("Please create a new password below. You will be able to easily sign in later."),
          disableVerification: true,
          error: null,
        }, () => {
          if(this.pwInputRef.current){
            this.pwInputRef.current.focus();
          }
        });
      }else{
        this.setState({
          error: Labels.get("Wrong verification code."),
          success: null,
        });
      }
    });
  }

  checkEmail = (email) => {
    Zenfy.get(`${localStorage.orgId}/clients`, {
      email
    }).then((res) => {

      /*if(!localStorage.zenfy_jwt){
        localStorage.client_email = this.state.email;
      }*/

      if(res){

        if(res.data.newUser){
          this.setState({
            showPasswordInput: true,
            showVerficationInput: false,
            allowOkModal: false,
            success: Labels.get('Create your account below.'),
            error: null,
            showForgotPw: false,
            signInBtnText: Labels.get("Create"),
          }, () => {
            if(this.pwInputRef.current){
              this.pwInputRef.current.focus();
            }
          });
        }else if(!res.data.hasPw){
          Zenfy.post(`${localStorage.orgId}/verification`,{
            email 
          });

          this.setState({
            showPasswordInput: false,
            showVerficationInput: true,
            allowOkModal: true,
            password: null,
            error: null,
            showForgotPw: false,
            signInBtnText: Labels.get("Sign In"),
            success: Labels.get("We sent you a verification code, please enter it below."),
          },() => {
            if(this.codeVRef.current){
              this.codeVRef.current.focus();
            }
          });
        }else{
           this.setState({
            showPasswordInput: true,
            showVerficationInput: false,
            showForgotPw: true,
            allowOkModal: false,
            success: "Déjà inscrit.e ! Connectez-vous ci-dessous.",
            signInBtnText: "Suivant",
            error: null,
          }, () => {
            if(this.pwInputRef.current){
              this.pwInputRef.current.focus();
            }
          });
        }

      }

      /*if(res && res.data.id){
        this.setState({
          client: res.data,
          showLoginModal: false,
          error: null,
        });
      }else{
        this.setState({
          error: "You don't have the right combination. Please contact the front desk."
        })
      }*/
      
    });
  }

  forgot = () => {
    Zenfy.post(`${localStorage.orgId}/verification`,{
      email: this.state.email,
      lang: localStorage.Lang
    });

    this.setState({
      showVerficationInput: true,
      allowOkModal: true,
      error: null,
      showForgotPw: false,
      password: null,
      signInBtnText: Labels.get("Sign In"),
      success: Labels.get("We sent you a verification code, please enter it below.")
    }, () => {
      if(this.codeVRef.current){
        this.codeVRef.current.focus();
      }
    });
  }

  handleModalOk = () => {
    if(this.state.email && this.state.email != '' && this.state.password && this.state.password != ''){

      Zenfy.post(`${localStorage.orgId}/clientaccount`, {
        email: this.state.email,
        password: this.state.password,
        verification: this.state.verification,
      }).then((res) => {
        if(res.data){
          if(res.data.password){
            localStorage.client_email = res.data.email;
            localStorage.client_password = this.state.password;

            this.setState({
              showLoginModal: false,
              client: res.data,
              current: 0
            }, () => {
              this.checkH(true);
            });
          }
        }else{
          this.setState({
            error: Labels.get('Wrong password!'),
            success: false,
          });
        }
      });

      /*Zenfy.get(`${localStorage.orgId}/clients`, {
        email: this.state.email,
        dob
      }).then((res) => {

        if(!localStorage.zenfy_jwt){
          localStorage.client_email = this.state.email;
          localStorage.client_dob = dob;
        }

        if(res.data.id){
          this.setState({
            client: res.data,
            showLoginModal: false,
            error: null,
          });
        }else{
          this.setState({
            error: "You don't have the right combination. Please contact the front desk."
          })
        }
      });*/
    }
  }

  handleScan = data => {
    if (data) {
      console.log(data);
      this.setState({
        result: data
      })
    }
  }

  getDropList = () => {
    const year = new Date('1940-01-01').getFullYear();
    return (
      Array.from( new Array(80), (v,i) =>
        <Select.Option key={i} value={year+i}>{year+i}</Select.Option>
      )
    );
  }

  logout = (e) => {
    e.preventDefault();

    delete localStorage.client_email;
    delete localStorage.client_password;

    this.setState({
      client: {},
      current: 0,
    });

    this.forceUpdate();
  }

  onChangeAction = e => {
    this.setState({
      action: e.target.value,
    });
  };

  render() {
    //localStorage.orgId = 1;
    const { current } = this.state;

    let steps = [
      <>
        {this.state.client.name ? (
          <div style={{display: 'none'}}>
            <div style={{textAlign: 'center', margin: '40px 0'}}>
            <span>{Labels.get("You are logged in as")} {this.state.client.name ? this.state.client.name : this.state.client.email} 👌 <a href="javascript:;" style={{textDecoration: 'underline'}} onClick={this.logout}>{Labels.get("Logout")}</a></span>
            </div>
            <Divider style={{fontSize: '14px'}}>{!this.state.client.id ? Labels.get("Don't have an account?") : Labels.get("Feel free to change your information below")}</Divider>
          </div>
        ) : null}
        <ClientForm 
          isCarbon
          hideRegion={this.carName == 'Autre'}
          carName={this.carName}
          isGranby={this.isGranby}
          isMauricie={this.isMauricie}
          isChau={this.isChau}
          resize={this.checkH}
          action={this.state.action} 
          contract={this.state.product.contract} 
          covid={this.state.product.covid ? this.state.org.covid : false} 
          client={{id: undefined}} 
          isChild={this.state.product && this.state.product.child == 1 ? true : false} public 
          isDigital={this.state.product ? this.state.product.is_digital : false} 
          product={this.state.product}
        />
      </>,
      <>
        <Row>
          <Col xs={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} md={{span: 12, offset: 6}} lg={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
         
            <PayForm invoice={this.state.invoice} client={this.state.client} ref={this.payForm} taxes force_monthly={this.state.product ? this.state.product.force_monthly : false} showRecap hideReference hideType />
            <div style={{ marginBottom: 20 }} />
            
          </Col>
        </Row>
      </>,
    ];

    document.body.style['touch-action'] = "manipulation";

    return (
      <>
      <style dangerouslySetInnerHTML={{__html: `
        /*@media (max-width: 600px) {*/
          .ant-layout, body { background: #fff; }
          body #hubspot-messages-iframe-container{display: none !important;}
          .ant-modal-mask{background-color: rgb(255 255 255 / 80%);}
        /*}*/
      `}}></style>
      <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport"/>

      <div className="btn-menu not-active" onClick={this.showSideMenu} style={{display: 'none'}}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      
    <Modal
      title={"Inscrivez votre courriel ou connectez-vous à votre compte ÉCOLEADER"}
      visible={this.state.showLoginModal}
      onOk={this.handleModalOk}
      okText={this.state.signInBtnText}
      cancelText={Labels.get("Cancel")}
      closable={false}
      maskClosable={false}
      keyboard={false}
      //mask={false}
      //cancelButtonProps={{style: {display: 'none'}}}
      onCancel={()=> this.setState({
        //showLoginModal: false,
        error: null,
        success: null,
      })}
      okButtonProps={{
        loading: this.state.modalLoginLoading, 
        disabled: this.state.allowOkModal,
      }}
      cancelButtonProps={{
          style: {display: 'none'}
      }}
    >   
        {this.state.error && this.state.error != '' ? (
          <Alert message={this.state.error} type="error" style={{marginBottom: '20px'}} />
        ): null}

        {this.state.success && this.state.success != '' ? (
          <Alert message={this.state.success} type="success" style={{marginBottom: '20px'}} />
        ): null}
        
        <Input
          placeholder={Labels.get("Email")}
          name="email"
          style={{marginBottom: 20}}
          value={this.state.email}
          onChange={this.handleInputChange}
        />

        {this.state.showVerficationInput ? (
          <Input
            placeholder={Labels.get("Verification Code")}
            name="verification"
            style={{marginBottom: 20}}
            value={this.state.verification}
            onChange={this.handleInputChange}
            disabled={this.state.disableVerification}
          />
        ) : null}

        {this.state.showPasswordInput ? (
          <>
            <Input.Password
              placeholder={Labels.get("Enter your password")}
              name="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              ref={this.pwInputRef}
            />
            {this.state.showForgotPw ? (
              <a href="javascript:;" style={{textDecoration: 'underline', display: 'block', marginTop: '5px', fontSize: '14px'}} onClick={this.forgot}>{Labels.get("Forgot your password?")}</a>
            ) : null}
          </>
        ) : null}

    </Modal>

    <Row type="flex" justify="space-around" align="middle" style={{margin: '0px 0 0 0'}}>
      <Col className="gutter-row" span={18}>
        
      <div style={{marginTop: '25px'}}>
        <Steps current={current}>
          <Step title={Labels.get("Information")} />
          <Step title={this.state.action == 3 ? Labels.get("Download") : Labels.get("Payment")} />
        </Steps>
        <div className="steps-content" style={{marginTop: '25px'}} >

          {steps[this.state.current]}
      
        </div>
        <div className="steps-action" style={{marginBottom: '20px', marginTop: '20px'}}>
          {current < steps.length - 1 && (
            <Button type="primary" loading={this.state.loading} className="pull-right" onClick={() => this.next()}>
              {Labels.get("Next")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" className="pull-right" loading={this.state.loading} onClick={() => this.next()}>
              {Labels.get("Pay")}
            </Button>
          )}
          {current > 0 && (
            <Button className="pull-left" onClick={() => this.prev()}>
              {Labels.get("Previous")}
            </Button>
          )}
        </div>
      </div>

      </Col>
    </Row>

      </>
    );
  }
}

export default FormCrm;