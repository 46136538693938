import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Table, Input, DatePicker, notification } from 'antd';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

import Currency from 'react-currency-formatter';

const { RangePicker } = DatePicker;

class Sales extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            start: moment().startOf('year').subtract(10, 'years').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        }

    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

    }

    load = () => {

        this.setState({
            loading: true,
        });


        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/stats/sales?start=${this.state.start}&end=${this.state.end}`).then((res) => {

            let total = res.data.credit + res.data.tps + res.data.tvq;

            console.log(total);

            let result = Object.entries(res.data).map(( [k, v] ) => (
                {'name': k == 'fees' ? "Processing Fees" : Zenfy.capitalizeFirstLetter(k), 'amount' : v}
            ));
            
            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(result),
            });
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => text,
        },{
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.amount - b.amount,
        }
    ];

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            return obj.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange} 
                defaultValue={[moment().startOf('year').subtract(10, 'years'), moment()]}
                ranges={Zenfy.getRanges()}
            />

            <div style={{clear: 'both'}} />

            <Table dataSource={rows} columns={columns} loading={this.state.loading} />
        </>
    );

    }
}

export default Sales;