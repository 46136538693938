import React, { Component } from "react";

import { Steps, Button, message, Row, Col, Card, Menu, Dropdown, Icon, Modal, Spin, Divider, Tag, Select, Upload, Input, notification, Drawer, DatePicker } from 'antd';

import SignatureCanvas from 'react-signature-canvas';

import Zenfy from "../libs/Zenfy";

import DriverLegForm from '../components/DriverOrderForm';
import SelectFromUrl from '../components/SelectFromUrl';
import Messages from '../components/Messages';
import EmailModalForm from '../components/EmailModalForm';
import UploadModalForm from '../components/UploadModalForm';

import moment from 'moment';

const { Option } = Select;

class Driver extends Component {

  constructor(props) {
    super(props);

    this.state = {
        legs: [],
        showModal: false,
        selectedLeg: {},
        modalLoading: false,
        loading: false,
        showOrderModal: false,
        showContainerModal: false,
        containerNumber: null,
        showAll: false,
        showChassisModal: false,
        chassis: undefined,
        key: Math.random(),
        showMsg: false,
        showEmailModal: false,
        showUploadModal: false,
        drawer: false,
        driverName: null,
        nbHours: 0,
        showDateModal: false,
        date: null,
        startText: 'Start Now',
    }

    this.padRef = React.createRef();
    this.legRef = React.createRef();
    this.emailModalRef = React.createRef();
    this.uploadRef = React.createRef();
    this.orgId = 2;
    this.driverId = 0;
    this.timeId = null;
    this.emails = null;
  }

  componentDidMount() {
      if (navigator.geolocation) {
        this.getNav();
        setInterval(() => {
          this.getNav();
          this.getLegs(true);
        }, 30000);
      }

      this.getLegs();
      this.checkForTimeIn();
  }

  checkForTimeIn = () => {
    Zenfy.get(`0/times/${this.props.match.params.id}`).then((res) => {
      if(res.data){
        this.driverId = res.data.id;
        this.orgId = res.data.org_id;
        localStorage.orgId = this.orgId;
        
        this.setState({
          driverName: res.data.name,
          startText: res.data.time_id ? 'End Now' : 'Start Now',
          nbHours: res.data.week_hours,
          //timeIn: 
        });
      }
    });
  }

  getLegs = (noLoading) => {

    this.setState({
      loading: !noLoading,
    })

    if(this.props.match.params.id){

      this.driverId = this.props.match.params.id;
      let driverName = this.state.driverName;

      Zenfy.get(`legs/${this.props.match.params.id}`,{
        date: this.state.date ? this.state.date.format('YYYY-MM-DD') : null,
      }).then((res) => {

        if(res.data[0]){
          this.orgId = res.data[0].org_id;
          localStorage.orgId = this.orgId;
          this.driverId = res.data[0].driver_id;
          driverName = res.data[0].driver_name;
        } 

        let legs = Zenfy.setKeysToList(res.data);

       /* legs.map((leg) => {
          if(leg.chassis_id){
            leg.equipement_id = 
          }
        }); */

        this.setState({
          legs,
          loading: false,
          driverName
        })
      });
    }
  }

  getNav = () => {
    navigator.geolocation.getCurrentPosition((position) => {
        Zenfy.post('staff', {
            id: this.props.match.params.id,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        });
    });
  }

  showSign = (leg) => {
    this.setState({
      showModal: true,
      selectedLeg: leg,
    })
  }

  showOrderInfo = (event, leg) => {

    this.setState({
      selectedLeg: leg,
    }, () => {
      if(leg.terminal_to_id == 28){

        this.setState({
          showOrderModal: true,
        });
    
      }else {
  
        let values = {};
  
        values.id = this.state.selectedLeg.id;
        values.order_id = this.state.selectedLeg.order_id;
        values.org_id = this.orgId;
        values.type = "drop";
        values.time_in = moment().format('YYYY-MM-DD HH:mm');
        values.time_out = moment().format('YYYY-MM-DD HH:mm');
  
        this.postLeg(values)
  
      }
    });
    
    event.preventDefault();
  }

  clear = () => {
    this.padRef.current.clear();
  }

  handleModalOk = () => {

    this.setState({
     // modalLoading: true,
    });

    this.padRef.current.getTrimmedCanvas().toBlob((blob) => {
      var formData = new FormData()
    
      // append the blob to a multipart form
      formData.append('file', blob, 'signature.png')
    
        // send the form to the server using ajax
        Zenfy.post('uploadfile', formData).then(res => {

          Zenfy.post('orders', {
            id: this.state.selectedLeg.order_id, 
            signature: `${Zenfy.getRoot()}uploads/${res.data}`,
          }).then(res => {
            
            console.log(res.data);

            message.success('Signature saved with success!');

            this.getLegs(true);

            this.setState({
              modalLoading: false,
              showModal: false,
            });

          });
          //this.props.match.params.id

      });
    });

   // let formData = new FormData();
   // formData.append('file', new Blob([this.padRef.current.getTrimmedCanvas()], {type: 'image/png'}), 'signature.png');

    
    
    /*Zenfy.post('uploadpdfhtml', {
        html,
    }).then((res) => {
        this.handleChange(`${Zenfy.getRoot()}uploads/${res.data}`);
        this.setState({
            showModal: false,
            modalLoading: false,
        });
    });*/

  }

  saveLeg = () => {

    this.setState({
      modalLoading: true,
    });

    this.legRef.current.validateFieldsAndScroll((err, values) => {
      if (!err) {
          values.id = this.state.selectedLeg.id;
          values.order_id = this.state.selectedLeg.order_id;
          values.org_id = this.orgId;

          if(!values.time_in){
            values.time_in = moment().format("HH:mm");
          }

          if(!values.time_out){
            values.time_out = moment().format("HH:mm");
          }

          values.time_in = moment().format('YYYY-MM-DD ') + values.time_in;
          values.time_out = moment().format('YYYY-MM-DD ') + values.time_out;

          // Start: values.date.format('YYYY-MM-DD ') + values.time.format('HH:mm'),
          this.postLeg(values);
      }
  });

  }

  postLeg = (values) => {
    Zenfy.post('legs', values).then(res => {
      
      this.getLegs(true);

      this.setState({
        modalLoading: false,
        showOrderModal: false,
        showChassisModal: false,
      });

      message.success('Move saved with success!');

      let status = null;

      // change order status
      if(values.type == 'live' || values.type == 'drop'){
        if(this.state.selectedLeg.terminal_to_id == 28){
          status = 'dropped_customer';
        }else if(this.state.selectedLeg.terminal_to_id != 35){
          status = 'dropped_terminal';
          // sending email to customer?
          Zenfy.sendEmailStatus(values.order_id, this.state.selectedLeg.email, 'completed');
        }else if(this.state.selectedLeg.terminal_to_id == 35){
          if(this.state.selectedLeg.terminal_to_id == 28){
              status = 'customer_yard';
          }else{
              status = 'prepull_yard';
          }
      }

        if(values.type == 'live'){
          // assining next order to driver
          this.assignNext(values);
        }

        if(status){
          Zenfy.post('orders', {
            id: values.order_id,
            org_id: values.org_id,
            status,
          }).then((res) => {
            /* need to send an email with POD EMAILS is an array
            if(this.emails){
                Zenfy.get(`pod/${values.order_id}?email=true`, {
                  emails: this.emails
                });
                this.emails = null;
            }*/
        
          });
        }
        
      }

    });
  }

  assignNext = (values) => {

    Zenfy.get(`${this.orgId}/orders/${values.order_id}`).then((res) => {
      
      for (var i = 0; i < res.data[0].legs.length; i++) {

        let leg = res.data[0].legs[i];

        if (leg.driver_id == 0) {
          
          Zenfy.post('legs', {
            id: leg.id,
            order_id: leg.order_id,
            org_id: leg.org_id,
            driver_id: this.driverId,
          }).then((res) => {
            this.getLegs(true);
          });

          break;
        }
      }
      
    });
  }

  renderClient = (leg) => {
    return Zenfy.address(leg, true);
  }

  dropOnChange = (e, leg) => {
    console.log(e, leg)
  }

  saveContainer = () => {
    this.setState({
      modalLoading: true
    });

    Zenfy.post('orders', {
      id: this.state.selectedLeg.order_id,
      org_id: this.orgId,
      container: this.state.containerNumber ? this.state.containerNumber.toUpperCase() : this.state.containerNumber,
    }).then((res) => {
      this.setState({
        modalLoading: false,
        showContainerModal: false,
        containerNumber: null,
      });

      this.getLegs(true);

      message.success('Save order with success!');
    });
  }

  uploadFile = ({file, onSuccess}, leg) => {
    let formData = new FormData();
    formData.append('file', file);

    Zenfy.post('uploadfile', formData).then(res => {
        onSuccess(res.data, file);

        message.success('File upload with success!');

        Zenfy.post('files', {
          order_id: leg.order_id,
          org_id: this.orgId,
          staff_id: this.props.match.params.id,
          file_name: `${Zenfy.getRoot()}uploads/${res.data}`
        }).then(res => {
          
        });

    });
  }

  saveChassis = () => {

    this.setState({
      modalLoading: true,
    });

    let values = {};

    values.id = this.state.selectedLeg.id;
    values.order_id = this.state.selectedLeg.order_id;
    values.org_id = this.orgId;
    values.equipement_id = this.state.chassis;

    this.postLeg(values);

    Zenfy.post('orders', {
      id: this.state.selectedLeg.order_id, 
      chassis_id: this.state.chassis,
    });
  }

  sendEmail = () => {
    let form = this.emailModalRef.current.getForm();

    form.validateFieldsAndScroll((err, values) => {
        if (err) {
            return;
        }

        let emails = values.email.split(',');
        this.emails = emails;

        /*Zenfy.get(`pod/${this.state.selectedLeg.order_id}?email=true`, {
            emails
        });*/

        form.resetFields();

        this.setState({ showEmailModal: false });

        // add emailed
        notification.success({
            message: `Emails added with success, please complete the move so an email will be sent.`,
            placement: 'bottomLeft',
        });

        
    });

  }

  
  uploadFiles = () => {
      let form = this.uploadRef.current.getForm();

      form.validateFieldsAndScroll((err, values) => {
          if (err) {
              return;
          }

          values.images.fileList.map((file, index) => {
              Zenfy.post('files', {
                  order_id: this.state.selectedLeg.order_id,
                  org_id: this.state.selectedLeg.org_id,
                  file_name: `${Zenfy.getRoot()}uploads/${file.response}`,
                  type: values.type,
                  staff_id: this.props.match.params.id,
              }).then(res => {
                  if(values.images.fileList.length - 1 == index){
                      this.setState({
                          showUploadModal: false,
                      });
                      form.resetFields();
                      notification.success({
                          message: `File uploaded with success!`,
                          placement: 'bottomLeft',
                      });
                  }
              });
          });
      });
  }

  startDay = (e) => {

    if(this.state.startText == 'Start Now'){
      Zenfy.post('times', {
        org_id: this.orgId,
        driver_id: this.props.match.params.id,
        time_in: moment().format('YYYY-MM-DD HH:mm')
      });
    }else{
      Zenfy.post('times', {
        org_id: 4,
        driver_id: this.props.match.params.id,
        time_out: moment().format('YYYY-MM-DD HH:mm')
      }).then(res => {
        this.checkForTimeIn();
      });
    }
     
    this.setState({
      startText: this.state.startText == 'Start Now' ? 'End Now' : 'Start Now',
    });
  
  }

  render() {

    const menu = (
      <Menu>
        <Menu.Item key="0">
          Open In Google Maps
        </Menu.Item>
        <Menu.Item key="1">
          Sign POD
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          Arrived at Client
        </Menu.Item>
        <Menu.Item key="3">
          Complete at Client
        </Menu.Item>
      </Menu>
    );

    let hasCompleted = false;
    
    let filtered = this.state.legs.filter((leg) => {
      if(leg.type && !this.state.showAll){
        hasCompleted = true;
        return false;
      }else if(this.state.showAll && !leg.type){
        return false;
      }
      
      return true;
    });

    document.body.style['touch-action'] = "manipulation";

    return (
      <>

        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport"/>
      
        <UploadModalForm 
          visible={this.state.showUploadModal}
          title="Upload attachements"
          container={this.state.selectedLeg.container}
          onCancel={
              () => {
              this.setState({
                  showUploadModal: false,
              })
          }}
          onCreate={this.uploadFiles}
          record={{}}
          ref={this.uploadRef}
        />
        <Modal
            title="Signature"
            visible={this.state.showModal}
            onOk={this.handleModalOk}
            okText="Sign"
            onCancel={()=> this.setState({
                showModal: false,
            })}
            okButtonProps={{loading: this.state.modalLoading}}
        >   
            <SignatureCanvas 
                canvasProps={{height: 300, className: 'sigCanvas', style: {width: '100%', background: '#eee'}}} 
                ref={this.padRef}
            />
            <Button onClick={this.clear}>Clear</Button>
        </Modal>

        <Modal
            title="Change date"
            visible={this.state.showDateModal}
            onOk={() => {
              this.setState({
                showDateModal: false,
                drawer: false,
              }, () => {
                this.getLegs(true);
              })
            }}
            okText="Ok"
            onCancel={()=> this.setState({
              showDateModal: false,
            })}
        >   
          <DatePicker format={"YYYY-MM-DD"} onChange={(date) => {
            this.setState({
              date
            });
          }} />
        </Modal>

        <Modal
            title="Complete move"
            visible={this.state.showOrderModal}
            onOk={this.saveLeg}
            okText="Save"
            onCancel={()=> this.setState({
              showOrderModal: false,
            })}
            okButtonProps={{loading: this.state.modalLoading}}
        >   
           <DriverLegForm leg={this.state.selectedLeg} ref={this.legRef} />
        </Modal>

        <Modal
            title="Select Chassis"
            visible={this.state.showChassisModal}
            onOk={this.saveChassis}
            okText="Save"
            onCancel={()=> this.setState({
              showChassisModal: false,
            })}
            okButtonProps={{loading: this.state.modalLoading}}
        >   
           <SelectFromUrl onChange={(val) => {
            this.setState({
              chassis: val
            })
          }}
          key={this.state.key}
          style={{width: '100%'}} value={this.state.chassis} url={`${localStorage.orgId ? localStorage.orgId : 2}/equipements?type=chassis&status=available`} idName="id" name="name" />
        </Modal>

        <Modal
            title="Container Number"
            visible={this.state.showContainerModal}
            onOk={this.saveContainer}
            okText="Save"
            onCancel={()=> this.setState({
              showContainerModal: false,
            })}
            okButtonProps={{loading: this.state.modalLoading}}
        >   
          <Input onChange={(e) => {
            this.setState({
              containerNumber: e.target.value
            })
          }} value={this.state.containerNumber} />
        </Modal>

        <EmailModalForm 
            visible={this.state.showEmailModal}
            title="Send POD to Customer"
            okText="Ok"
            emailProp="delivery_email"
            onCancel={
                () => {
                this.setState({
                    showEmailModal: false,
                })
            }}
            onCreate={this.sendEmail}
            record={this.state.selectedLeg}
            ref={this.emailModalRef}
        />

        <Drawer
          className="driver"
          title={this.state.driverName ? `Hi ${this.state.driverName} 🤠, you've drove ${this.state.nbHours} hours this week!` : "Menu"}
          placement="top"
          onClose={() => {
            this.setState({
              drawer: false,
            })
          }}
          visible={this.state.drawer}
        >
          <div>
            <Button block style={{marginBottom: 10}} onClick={(e) => this.setState({
                    showMsg: !this.state.showMsg,
                    drawer: false,
            })}>
              {this.state.showMsg ? 'Hide messages' : 'Show messages'}
            </Button>
            <Button block onClick={() => {
              this.setState({
                showDateModal: true,
              })
            }}>
              Change date
            </Button>
            <Button block style={{marginTop: 10}} onClick={this.startDay}>
              {this.state.startText}
            </Button>
          </div>
        </Drawer>

        <Row type="flex" justify="space-around" align="middle" style={{marginTop: 10}}>
            <Col className="gutter-row driver" span={22}>
                <Row span={24}>

                  <Button style={{width: '100%', marginBottom: 10}} onClick={() => {
                    this.setState({
                      drawer: true,
                    });
                  }}>
                    Menu
                  </Button>

                  {this.state.showMsg ? (
                    <div style={{marginBottom: 10}} >
                      <Messages driver={{id: this.driverId}} key={this.driverId} from_id={this.driverId} />
                    </div>
                  ) : null}

                  {this.state.loading ? (
                    <center><Spin tip="Loading..." /></center>
                  ) : (
                    <>

                    {this.state.legs.length > 0 && (hasCompleted || this.state.showAll) ? (
                      <Button type="dashed" style={{width: '100%', marginBottom: 10}} onClick={(e) => {this.setState({
                        showAll: !this.state.showAll
                      })}}>
                        {this.state.showAll ? 'Hide completed moves' : 'Show completed moves'}
                      </Button>
                    ) : null}
                    
                      {filtered.map((leg) => (
                        <Card key={leg.id} title={`Order ${leg.order_id} - ${leg.job.toUpperCase()} - ${leg.delivery_client_name}`} extra={ <Dropdown overlay={menu} trigger={['click']} >
                            <a className="ant-dropdown-link" href="#" style={{display: 'none'}}>
                              Menu <Icon type="down" />
                            </a>
                          </Dropdown>} 
                          style={{marginBottom: 30, borderRadius: 5}}
                        >
                          <p>{leg.type ? <Tag color="green">Move Completed</Tag> : <Tag color="magenta">Not Completed</Tag>} {leg.signature ? <Tag color="orange">Client Signed</Tag> : ""} </p>
                          {leg.signature ? (
                            <img src={leg.signature} style={{width: '100%', marginBottom: 20, display: 'none'}} />
                          ) : null}
                          <div style={{display: 'none'}}>
                            <Divider>Container</Divider>
                              <p>{leg.container}</p>
                              <p>{leg.reference}</p>    
                              <p>{leg.weight}</p>
                              <p>{leg.size}</p>    
                              <p>{leg.instructions}</p>    
                          </div>

                          <Divider>Pickup {leg.id_terminal == 28 ? 'Full' : leg.job == 'import' ? 'Full' : 'Empty'}</Divider>

                          {leg.id_terminal == 28 ? (
                            <>
                              {this.renderClient(leg)}
                            </>
                          ) : (
                            <>
                              <p>{leg.terminal_name}</p>
                            </>
                          )}

                          <h1 style={{textAlign: ''}}>{leg.size} - {leg.container}</h1>
                          <p><b>{leg.job == 'export' ? 'Booking Number' : 'Pickup Number'}: </b>{leg.pickup}</p>
                          {leg.chassis ? <p><b>Chassis: </b>{leg.chassis} - {leg.chassis_plate}</p> : ''}

                          {leg.app_date ? <p><b>App Time: </b>{leg.app_date}</p> : ''}
                          {leg.instructions ? <p><b>Special Instructions: </b>{leg.instructions}</p> : ''}
                          {leg.steamship ? <p><b>Steamship: </b>{leg.steamship}</p> : ''}

                          <Divider>Deliver To</Divider>
                          {leg.id_terminal == 28 ? (
                            <>
                               <p>{leg.return_terminal_name}</p>
                            </>
                          ) : (
                            <>
                              {this.renderClient(leg)}
                            </>
                          )}

                          <Divider />
                          
                          <Select
                                placeholder="Select a drop type"
                                style={{width: '100%', marginBottom: 10, display: 'none'}}
                                onChange={(e) => this.dropOnChange(e, leg)}
                            >
                                <Option value="live">Live delivery</Option>
                                <Option value="drop">Drop</Option>
                          </Select>

                          <Button style={{width: '100%', marginBottom: 10}} onClick={(e) => {
                            this.setState({
                              selectedLeg: leg,
                              key: Math.random(),
                              showChassisModal: true,
                              chassis: undefined,
                            });
                          }}>
                            Select chassis
                          </Button>
                          
                          <Button style={{width: '100%', marginBottom: 10}} onClick={() => {
                            this.setState({
                              showUploadModal: true,
                              selectedLeg: leg,
                            });
                          }}>
                              Upload picture proof
                          </Button>
                          
                          {leg.job == 'export' && leg.terminal_to_id == 28 ? (
                            <Button style={{width: '100%', marginBottom: 10}} onClick={(e) => this.setState({
                              selectedLeg: leg,
                              showContainerModal: true,
                            })}>
                              Add container number
                            </Button>
                          ) : null}

                          {leg.terminal_to_id == 28 ? (
                            <Button style={{width: '100%', marginBottom: 10}} onClick={(e) => this.showSign(leg)}>
                              Customer signature
                            </Button>
                          ) : null}

                          {leg.terminal_to_id == 28 ? (
                            <Button style={{width: '100%', marginBottom: 10}} onClick={(e) => this.setState({
                              selectedLeg: leg,
                              showEmailModal: true,
                            })}>
                              Send POD to customer
                            </Button>
                          ) : null}
                        
                          <Button style={{width: '100%'}} onClick={(e) => this.showOrderInfo(e, leg)}>
                            {leg.terminal_to_id == 28 ? "Complete move" : "Arrived at terminal"}
                          </Button>
                          
                        </Card>

                        
                      ))}
                    </>
                  )}

                </Row>
            </Col>
        </Row>
      </>
    );
  }
}

export default Driver;