import React, { Component } from "react";

import {
Form, Input, Tabs, DatePicker as DatePicka, Select, Avatar, Upload, Button, Icon, notification, Alert, Timeline, Col, Row, Steps, Checkbox
} from 'antd';
import moment from 'moment';
import Products from '../components/Products';
import Files from '../components/Files';
import Zenfy from "../libs/Zenfy";

import SelectFromUrl from '../components/SelectFromUrl';
import CompanyModal from '../components/CompanyModal';
import ContactModal from '../components/ContactModal';
import Legs from '../components/Legs';

const { Option } = Select;
const { Step } = Steps;

class NormalOrderForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: props.order,
            loading: false,
            fileList: [],
            comapniesKey: Math.random(),
            contactsKey: Math.random(),
            showCompanyModal: false,
            containerExists: false,
            showContactModal: false,
        }

        this.legsRef = React.createRef();
        this.dateRef = React.createRef();
        this.exisitingContainerId = null;
        this.newAddressId = null;
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.order.id !== this.state.order.id){

            this.props.form.resetFields();

            this.setState({
                fileList: [],
                order: nextProps.order
            });

        }

    }

    componentDidMount() {
        // ant-calendar-input 
        console.log(this.dateRef.current);
    }

    handleUploadChange = info => {
        let fileList = [...info.fileList];
    
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
    
        this.setState({ fileList });
    };

    addOrder = (data) => {

        this.setState({
          loading: true
        });

        data.id = this.state.order.id ? this.state.order.id : 0;
        data.org_id = localStorage.orgId ? localStorage.orgId : 2;
        data.client_id = this.state.order.client_id;
        data.sendAppEmail = false;

        if(!data.return_id){
            data.return_id = 0;
        }

        if(data.container){
            data.container = data.container.toUpperCase();
        }

        if(!data.terminal_id){
            data.terminal_id = 0;
        }

        if(!data.address_id){
            data.address_id = 0;
        }

        if(this.props.clientId){
            data.client_id = this.props.clientId;
            data.sendEmail = true;
        }

        if(data.pod && data.pod.file){
            data.pod = `${Zenfy.getRoot()}uploads/${data.pod.file.response}`;
        }

        if(data.app_date){
            data.app_date = data.app_date.format('YYYY-MM-DD HH:mm');
        }

        if(data.app_date){
            // if priveously had app date and the app date is now different
            if(this.state.order.app_date){
                if(moment(this.state.order.app_date, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm') != data.app_date){
                    data.sendAppEmail = true;
                }
            }else{
                // if new app date
                data.sendAppEmail = true;
            }

            if(!this.state.order.id && !data.status){
                data.status = 'ready_terminal';
            }
        }

        //console.log(data.app_date);
        /*if(!this.state.order.app_date && data.app_date.format('YYYY-MM-DD HH:mm') != this.state.order.app_date.format('YYYY-MM-DD HH:mm')){
            console.log('yesss');
        }*/

        if(data.lfd){
            data.lfd = data.lfd.format('YYYY-MM-DD HH:mm');
        }

        if(data.cutoff){
            data.cutoff = data.cutoff.format('YYYY-MM-DD HH:mm');
        }

        if(this.legsRef.current){
            let legs = [];
            
            this.legsRef.current.formRefs.map((form) => {
                if(form){
                    form.validateFieldsAndScroll((err, values) => {
                        values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                        
                        if(values.time_in){
                            values.time_in = values.time_in.format('YYYY-MM-DD ') + values.time_in.format('HH:mm');
                        }
                        
                        if(values.time_out){
                            values.time_out = values.time_out.format('YYYY-MM-DD ') + values.time_out.format('HH:mm');
                        }
                    
                        if (!err) {
                            legs.push(values);
                        }
                    });
                }
            })

            data.legs = legs;  

        }else if(this.state.order.id && this.state.order.legs.length > 0){
            data.legs = this.state.order.legs;
        }

       // if(!data.legs || (data.legs && data.legs.length == 1 && !data.legs[0].terminal_id)){

        if(data.terminal_id && data.terminal_id != 0 && (!data.legs || data.legs && data.legs.length == 1 && !data.legs[0].terminal_id)){

            //if(!data.legs){
            data.legs = [];
            //}

            data.legs.push({
                terminal_id: data.terminal_id,
                terminal_to_id: 28,
                org_id: localStorage.orgId ? localStorage.orgId : 2,
            });
        }

        if(data.return_id && data.return_id != 0 && (!data.legs || data.legs.length == 1)){

            if(!data.legs){
                data.legs = [];
            }

            data.legs.push({
                terminal_id: 28,
                terminal_to_id: data.return_id,
                org_id: localStorage.orgId ? localStorage.orgId : 2,
            });
        }

        if(data.return_id && data.return_id != 0 && data.legs.length > 0){
            data.legs.map((leg) => {
                if(leg.terminal_to_id == 0){
                    leg.terminal_to_id = data.return_id;
                }
            });
            
            data.legs[data.legs.length-1].terminal_to_id = data.return_id;
        }

        if(!data.return_id && data.legs){
            // check if already have from customer to return
            let hasEmptyReturnLeg = false;

            data.legs.map((leg) => {
                if(leg.terminal_id == 28 || leg.terminal_id == 35 && (leg.terminal_to_id == 0 || !leg.terminal_to_id)){
                    hasEmptyReturnLeg = true;
                }
            });

            if(!hasEmptyReturnLeg){
                data.legs.push({
                    terminal_id: 28,
                    terminal_to_id: 0,
                    org_id: localStorage.orgId ? localStorage.orgId : 2,
                });
            }
        }

        if(data.app_date && data.status == 'tracing'){
            data.status = 'ready_terminal';
        }

        if(data.terminal_name){
            delete data.terminal_name;
            delete data.return_terminal_name;   
        }

        Zenfy.post('orders', data).then((res) => {

            let orderId = res.data.order_id ? res.data.order_id : res.data;

            let legs = res.data.legs ? res.data.legs : [];

            let order = this.state.order;
                order.id = orderId;
                order.legs = legs;
                order.pod = data.pod;
                order.status = data.status;
                order.app_date = data.app_date;

            if(data.sendAppEmail){
                Zenfy.sendEmailStatus(orderId, order.email, 'dispatched');
            }

            this.setState({
                order,
                loading: false,
            });

            notification.success({
                message: `Order ${orderId} saved with success!`,
                placement: 'bottomLeft',
            });

            if(this.props.orders){
                if(localStorage.zenfy_role == 'orders'){
                    this.props.orders.searchOnChange({target: {value: data.container}});
                }else{
                    this.props.orders.load(null, true);
                }
            }

            //if(this.props.small){
                if(this.props.drawer){
                    this.props.drawer.current.hideDrawer();
                    this.props.drawer.current.load();
                }
            //}
        });

    }

    handleSubmit = (e, close) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.addOrder(values, close);
          }
        });
    }

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();
        formData.append('file', file);

        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);
        });
    }
    
    jobTypeOnChange = (value) => {
        /*this.setState((prevState) => ({
            order: {
                ...prevState.order,
                job: value,
            }
        }));*/

        let order = this.state.order;
        order.job = value;

        this.setState({
            order
        });
    }

    contactOnChange = (contact) => {

        if(contact){
            let order = this.state.order;
            order.client_id = contact.client_id;
    
            this.setState({
                order
            });
        }
        
    }

    containerOnChange = (e) => {
        if(e.target.value && e.target.value.length > 2){

            Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/orders`, {
                results: 10,
                container: e.target.value,
            }).then((res) => {
                if(res.data.rows && res.data.rows.length > 0){

                    if(res.data.rows[0].id != this.state.order.id){
                        this.setState({
                            containerExists: true,
                        });

                        this.exisitingContainerId = res.data.rows[0].container;
                    }

                }else if(this.state.containerExists){
                    this.setState({
                        containerExists: false,
                    });
                }
            });

        }
    }

    afterAddCallBack = (data) => {
        this.newAddressId = data;

        this.setState({
            showCompanyModal: false,
            comapniesKey: Math.random(),
        });
    }

    contactAfterAddCallBack = (data) => {
        this.newContactId = data;

        this.setState({
            showContactModal: false,
            contactsKey: Math.random(),
        });
    }

    searchContainer = () => {
        
        let url = `${Zenfy.getFrontEndRoot()}orders?search=${this.exisitingContainerId}`;
        window.open(url, '_blank');

    }

    clientsAfterLoad = () => {
        if(this.newAddressId){
            let order = this.state.order;
            
            order.address_id = parseInt(this.newAddressId); // for some reason it was reading as a string
            this.setState({
                order,
            });

            this.newAddressId = null;
        }
    }

    contactsAfterLoad = (contacts) => {
        if(this.newContactId){
            let order = this.state.order;

            let selectedContact = contacts.find((contact) => {
                return contact.id == this.newContactId;
            });
            
            order.client_id = selectedContact.client_id;
            order.contact_id = parseInt(this.newContactId); // for some reason it was reading as a string

            this.setState({
                order,
            });

            this.newContactId = null;
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 20 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const TabPane = Tabs.TabPane;

        let steps = ['tracing', 'ready_terminal', 'prepull_yard', 'dropped_customer', 'ready_pickup', 'customer_yard', 'dropped_terminal', 'invoiced'];

        return (
            <>

                <Steps progressDot current={steps.indexOf(this.state.order.status)} style={{margin: '20px 0px 30px 0'}}>
                    <Step title="Tracing" description="" />
                    <Step title="Ready at terminal" description="" />
                    <Step title="Prepulled to yard" />
                    <Step title="Dropped at customer" />
                    <Step title="Ready at customer" />
                    <Step title="Customer to yard" />
                    <Step title="Dropped at terminal" />
                    <Step title="Invoiced" />
                </Steps>

                <CompanyModal showModal={this.state.showCompanyModal} afterAddCallBack={this.afterAddCallBack} />
                <ContactModal showModal={this.state.showContactModal} afterAddCallBack={this.contactAfterAddCallBack} />

                <Form onSubmit={this.handleSubmit} className="team-form"> 
                        
                        <Tabs defaultActiveKey="1" className={this.props.public ? 'public' : ''}>
                            <TabPane tab="Container" key="1">

                                <Row gutter={24}>
                                    <Col span={12}>

                                        <Form.Item
                                            label="Job type">
                                            {getFieldDecorator('job', {
                                                rules: [{ required: false, message: 'Please select your job!' }],
                                                initialValue: this.state.order.job ? this.state.order.job : 'import',
                                            })(
                                                <Select
                                                    placeholder="Select a job type"
                                                    onChange={this.jobTypeOnChange}
                                                >
                                                    <Option value="import">Container Import</Option>
                                                    <Option value="export">Container Export</Option>
                                                </Select>,
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            
                                            label="Reference number"
                                            >
                                            {getFieldDecorator('reference', {
                                                initialValue: this.state.order.reference,
                                                rules: [
                                                    /*{
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail',
                                                    },
                                                    {
                                                        required: false,
                                                        message: 'Email is required',
                                                    },*/
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        
                                        {!this.props.public ? (
                                        <Form.Item
                                            
                                            label="Customer / Bill to"
                                            >
                                            {getFieldDecorator('contact_id', {
                                                initialValue: this.state.order.contact_id,
                                                rules: [
                                                    { required: true, message: 'Contact is required' }
                                                ],
                                            })(
                                                <SelectFromUrl 
                                                    url={`${localStorage.orgId ? localStorage.orgId : 1}/contacts`} idName="id" name="name" 
                                                    itemChange={this.contactOnChange}
                                                    afterLoad={this.contactsAfterLoad}
                                                    key={this.state.contactsKey}
                                                    altRender={(row) => {
                                                        return `${row.name} ${row.email ? '(' + row.email + ') ' : ''} - ${row.client_name}`
                                                    }}
                                                />
                                            )}
                                        </Form.Item>) : null}

                                        {!this.props.public ? (
                                            <a href="javascript:;" style={{display: 'inherit', marginTop: '-26px', position: 'absolute', right: '10px'}} onClick={() => {
                                                this.setState({
                                                    showContactModal: true,
                                                });
                                            }}>Add new contact</a>
                                        ) : null}  

                                        <Form.Item
                                            label={this.state.order.job != 'export' ? 'Full container pick up terminal' : 'Empty container pick up terminal'}
                                            >
                                            {getFieldDecorator('terminal_id', {
                                                initialValue: this.state.order.terminal_id == 0 ? undefined : this.state.order.terminal_id,
                                                rules: [
                                                    { required: false, message: 'Terminal is required' }
                                                ],
                                            })(
                                                <SelectFromUrl url={`${localStorage.orgId ? localStorage.orgId : 1}/terminals`} idName="id" name="name" hideClient />
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            label={this.state.order.job != 'export' ? 'Container delivery address' : 'Empty container loading address'}
                                            >
                                            {getFieldDecorator('address_id', {
                                                initialValue: this.state.order.address_id,
                                                rules: [
                                                    { required: false, message: 'Container delivery' }
                                                ],
                                            })(
                                                <SelectFromUrl 
                                                    url={`${localStorage.orgId ? localStorage.orgId : 1}/clients?is_customer=0&active=1`} 
                                                    idName="id" 
                                                    name="address" 
                                                    key={this.state.comapniesKey}
                                                    afterLoad={this.clientsAfterLoad}
                                                    altRender={(row) => {
                                                        return `${row.name} - ${row.address ? row.address + ', ' : ''} 
                                                        ${row.city ? row.city + ', ' : ''} 
                                                        ${row.state ? row.state + ', ' : ''} 
                                                        ${row.postal ? row.postal + '' : ''}
                                                        ${row.country ? ', ' + row.country  : ''}`
                                                    }}
                                                />
                                            )}
                                        </Form.Item>

                                        <a href="javascript:;" style={{display: 'inherit', marginTop: '-26px', float: 'right'}} onClick={() => {
                                            this.setState({
                                                showCompanyModal: true,
                                            });
                                        }}>Add new company</a>

                                        <Form.Item
                                            label={this.state.order.job == 'export' ? 'Full container return terminal' : 'Empty container return terminal'}
                                            >
                                            {getFieldDecorator('return_id', {
                                                initialValue: this.state.order.return_id == 0 ? undefined : this.state.order.return_id,
                                                rules: [
                                                    { required: false, message: 'Terminal is required' }
                                                ],
                                            })(
                                                <SelectFromUrl url={`${localStorage.orgId ? localStorage.orgId : 1}/terminals`} idName="id" name="name" hideClient />
                                            )}
                                        </Form.Item>
                                        
                                        {!this.props.public ? (
                                            <Form.Item
                                                label={this.state.order.job == 'export' ? "Early Return Date (ERD)" : "Last Free Day (LFD)"}// ERD 10/11 AND C/O 10/16
                                                >
                                                {getFieldDecorator('lfd', {
                                                    initialValue: this.state.order.lfd ? moment(this.state.order.lfd, "YYYY-MM-DD HH:mm") : null,
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                })(
                                                    <DatePicka format={"YYYY-MM-DD"} />
                                                )}
                                            </Form.Item>
                                        ) : null}

                                        {this.state.order.job == 'export' ? (
                                            <Form.Item
                                                label="Cut Off Date (C/O)"// ERD 10/11 AND C/O 10/16
                                                >
                                                {getFieldDecorator('cutoff', {
                                                    initialValue: this.state.order.cutoff ? moment(this.state.order.cutoff, "YYYY-MM-DD HH:mm") : null,
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                })(
                                                    <DatePicka showTime={{minuteStep: 15, format: 'HH:mm'}} format={"YYYY-MM-DD HH:mm"} />
                                                )}
                                            </Form.Item>
                                        ) : null}

                                        {!this.props.public ? (
                                            <Form.Item
                                                label="App Date & Time"
                                                >
                                                {getFieldDecorator('app_date', {
                                                    initialValue: this.state.order.app_date ? moment(this.state.order.app_date, "YYYY-MM-DD HH:mm") : null,
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                })(
                                                    <DatePicka ref={this.dateRef} showTime={{minuteStep: 15, format: 'HH:mm'}} format={"YYYY-MM-DD HH:mm"} readOnly onOpenChange={(status) => {
                                                        setTimeout(() => {
                                                            /*if(status){
                                                                console.log(this.dateRef);
                                                                let cal = document.getElementsByClassName('ant-calendar-input');
                                                                if(cal[0]){
                                                                    console.log(cal[0]);
                                                                    cal[0].readOnly = true;
                                                                    cal[0].focus();
                                                                    this.dateRef.
                                                                }
                                                            }*/
                                                        }, 100);
                                                    }}/>
                                                )}
                                            </Form.Item>
                                        ) : null}

                                    </Col>
                                    
                                    <Col span={12}>

                                        <Form.Item
                                        
                                        label="Container number"
                                        >
                                        {getFieldDecorator('container', {
                                            initialValue: this.state.order.container,
                                            rules: [
                                                {
                                                    required: this.state.order.job != 'export',
                                                    message: 'Container is required',
                                                }
                                            ],
                                        })(
                                            <Input onChange={this.containerOnChange} />
                                        )}
                                        
                                    </Form.Item>

                                    
                                    {this.state.containerExists ? (
                                        <a href="javascript:;" onClick={this.searchContainer} style={{marginTop: '-26px', display: 'inherit', textAlign: 'right'}}>Container exists</a>
                                    ) : null}    

                                    
                                    <Form.Item
                                        
                                        label="Container size"
                                        >
                                        {getFieldDecorator('size', {
                                            initialValue: this.state.order.size,
                                            rules: [
                                                /*{
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail',
                                                },
                                                {
                                                    required: false,
                                                    message: 'Email is required',
                                                },*/
                                            ],
                                        })(
                                            <Select
                                                placeholder="Select a container size"
                                                showSearch
                                            >
                                                <Option value="20">20</Option>
                                                <Option value="20FT">20FT</Option>
                                                <Option value="20GP">20GP</Option>
                                                <Option value="20HS">20HS</Option>
                                                <Option value="20OT">20OT</Option>
                                                <Option value="20RE">20RE</Option>
                                                <Option value="20TK">20TK</Option>
                                                <Option value="40DR">40DR</Option>
                                                <Option value="40DV">40DV</Option>
                                                <Option value="40FR">40FR</Option>
                                                <Option value="40GOH">40GOH</Option>
                                                <Option value="40HC">40HC</Option>
                                                <Option value="40HC-GOH">40HC-GOH</Option>
                                                <Option value="40HC-OT">40HC-OT</Option>
                                                <Option value="40HC-RE">40HC-RE</Option>
                                                <Option value="40OT">40OT</Option>
                                                <Option value="40RE">40RE</Option>
                                                <Option value="45">45</Option>
                                                <Option value="45HC">45HC</Option>
                                                <Option value="45OT">45OT</Option>
                                                <Option value="45RE">45RE</Option>
                                                <Option value="53">53</Option>
                                                <Option value="53OT">53OT</Option>
                                                <Option value="53RE">53RE</Option>
                                            </Select>
                                        )}
                                    </Form.Item>

                                    
                                    <Form.Item
                                        label={this.state.order.job == 'export' ? 'Booking number' : 'Pickup number'}
                                        >
                                        {getFieldDecorator('pickup', {
                                            initialValue: this.state.order.pickup,
                                            rules: [
                                                {
                                                    required: this.state.order.job == 'export',
                                                    message: 'Booking number is required',
                                                },
                                            ],
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        
                                        label="Steamship"
                                        >
                                        {getFieldDecorator('steamship', {
                                            initialValue: this.state.order.steamship,
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Steamship is required',
                                                }
                                            ],
                                        })(
                                            <Select
                                                placeholder="Select a steamship"
                                                showSearch
                                            >
                                                <Option value="55546115">55546115</Option>
                                                <Option value="ACL">ACL</Option>
                                                <Option value="APL">APL</Option>
                                                <Option value="CMA">CMA</Option>
                                                <Option value="CN">CN</Option>
                                                <Option value="COSCO">COSCO</Option>
                                                <Option value="COSCO-OFFHIRE">COSCO-OFFHIRE</Option>
                                                <Option value="CP">CP</Option>
                                                <Option value="EVERGREEN">EVERGREEN</Option>
                                                <Option value="HAMBURG SUD">HAMBURG SUD</Option>
                                                <Option value="HANJIN">HANJIN</Option>
                                                <Option value="HAPAG">HAPAG</Option>
                                                <Option value="HYUNDAI">HYUNDAI</Option>
                                                <Option value="MAERSK" selected="selected">MAERSK</Option>
                                                <Option value="MELFI">MELFI</Option>
                                                <Option value="MELFI/PROTOS">MELFI/PROTOS</Option>
                                                <Option value="M-O">M-O</Option>
                                                <Option value="MONTSHIP">MONTSHIP</Option>
                                                <Option value="MSC">MSC</Option>
                                                <Option value="NYKLINE">NYKLINE</Option>
                                                <Option value="OCEANEX">OCEANEX</Option>
                                                <Option value="ONE LINE">ONE LINE</Option>
                                                <Option value="OOCL">OOCL</Option>
                                                <Option value="PACIFIC INT. LINE">PACIFIC INT. LINE</Option>
                                                <Option value="PIL">PIL</Option>
                                                <Option value="PROTOS">PROTOS</Option>
                                                <Option value="SAFMARINE">SAFMARINE</Option>
                                                <Option value="SEABOARDMARINE">SEABOARDMARINE</Option>
                                                <Option value="SEALAND">SEALAND</Option>
                                                <Option value="SEA/NDS">SEA/NDS</Option>
                                                <Option value="SM LINE">SM LINE</Option>
                                                <Option value="TROPICAL">TROPICAL</Option>
                                                <Option value="UNITED ARABS">UNITED ARABS</Option>
                                                <Option value="WESTWARD">WESTWARD</Option>
                                                <Option value="WESTWOOD">WESTWOOD</Option>
                                                <Option value="YANG MING">YANG MING</Option>
                                                <Option value="YANG MING/OFFHIRE">YANG MING/OFFHIRE</Option>
                                                <Option value="ZIM">ZIM</Option>
                                            </Select>
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        
                                        label="Weight"
                                        >
                                        {getFieldDecorator('weight', {
                                            initialValue: this.state.order.weight,
                                            rules: [
                                                /*{
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail',
                                                },
                                                {
                                                    required: false,
                                                    message: 'Email is required',
                                                },*/
                                            ],
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>

                                    <Form.Item 
                                    label="Hazardous container"
                                    >
                                        {getFieldDecorator('hazard', {
                                            valuePropName: 'checked',
                                            initialValue: this.state.order.hazard == 1,
                                        })(
                                            <Checkbox>
                                                Hazardous
                                            </Checkbox>,
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        label="Internal ID"
                                        >
                                        {getFieldDecorator('medlog', {
                                            initialValue: this.state.order.medlog,
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        label="Special instructions"
                                        >
                                        {getFieldDecorator('instructions', {
                                            initialValue: this.state.order.instructions,
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        label="Instructions for container"
                                        >
                                        {getFieldDecorator('return_instructions', {
                                            initialValue: this.state.order.return_instructions,
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>         

                                    {!this.props.public ? (
                                        <Form.Item
                                            label="Status">
                                            {getFieldDecorator('status', {
                                                rules: [{ required: false, message: 'Please select your job!' }],
                                                initialValue: this.state.order.status,
                                            })(
                                                <Select>
                                                    <Option value="tracing">Tracing</Option>
                                                    <Option value="ready_terminal">Ready at terminal</Option>
                                                    <Option value="prepull_yard">Prepulled to Yard</Option>
                                                    <Option value="dropped_customer">Dropped at customer</Option>
                                                    <Option value="ready_pickup">Ready at customer</Option>
                                                    <Option value="customer_yard">Customer to Yard</Option>
                                                    <Option value="dropped_terminal">Dropped at terminal</Option>
                                                    <Option value="invoiced">Invoiced</Option>
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    ): null}
                                        
                                    </Col>
                                </Row>
                        </TabPane>

                        <TabPane tab="Order Legs" key="2">

                            <Legs order={this.state.order} ref={this.legsRef} key={Math.random()} />
            
                        </TabPane>

                        <TabPane tab="Administration" key="3">
                            <Form.Item
                                {...formItemLayout}
                                label="Signed POD"
                                className="hide"
                                >
                                {getFieldDecorator('pod', {
                                    initialValue: this.state.order.pod,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <Upload name="picture" onChange={this.handleUploadChange} customRequest={this.uploadFile} showUploadList={true} fileList={this.state.fileList} >
                                        <Button>
                                            <Icon type="upload" /> Click to Upload
                                        </Button>
                                    </Upload>
                                )}
                            </Form.Item>

                            {this.state.order.pod ? <a style={{marginLeft: 16}} className="hide" target="_blank" href={this.state.order.pod}>View File</a> : null}
                            
                            <h3 style={{color: '#1890ff'}}>Documents / Attachements</h3>

                            <Files order_id={this.state.order.id} key={this.state.order.id} container={this.state.order.container} />

                            <h3 style={{marginTop: 30, color: '#1890ff'}}>Billing</h3>
                            
                            <Products order={this.state.order} style={{marginTop: 10}} />

                        </TabPane>

                        <TabPane tab="History" key="4">
                            
                            <Timeline mode="alternate">
                                <Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
                                <Timeline.Item color="green">Solve initial network problems 2015-09-01</Timeline.Item>
                                <Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                                beatae vitae dicta sunt explicabo.
                                </Timeline.Item>
                                <Timeline.Item color="red">Network problems being solved 2015-09-01</Timeline.Item>
                                <Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
                                <Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
                                Technical testing 2015-09-01
                                </Timeline.Item>
                            </Timeline>
                           
                        </TabPane>


                        </Tabs>

                        <Form.Item>

                            <Button type="primary" htmlType="submit" className="pull-right" loading={this.state.loading}>
                                {!this.props.public ? 'Save' : 'Submit'}
                            </Button>
                            {!this.props.public ? (
                            <a href="javascript:;" className="pull-right" style={{margin: '-4px 20px 0px 0px'}} onClick={() => this.props.drawer.current.hideDrawer()} >Close</a>
                            ) : null}
                        </Form.Item>

                    </Form>
                                    
            </>
        );
    }
}

const WrappedOrderForm = Form.create({ name: 'order_form' })(NormalOrderForm);

export default WrappedOrderForm;
