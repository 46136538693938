import React, { Component } from "react";
import ClientForm from "./ClientForm";

import { Steps, Button, message, Row, Col, Select, Divider, Modal, Input, Alert, Radio } from 'antd';

import SelectFromUrl from '../../components/SelectFromUrl';
import Zenfy from "../../libs/Zenfy";
import CreditCard from '../../components/CreditCard';
import PayForm from '../../components/PayForm';
import moment from 'moment';

import Labels from '../../libs/Labels';

const { Step } = Steps;

class FormCrm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      productId: undefined,
      product: {},
      org: {},
      invoice: {},
      loading: false,
      client: {},
      amount: 0,
      showLoginModal: false,
      modalLoginLoading: false,
      email: '',
      error: null,
      result: null,
      day: '',
      year: '',
      month: '',
      showPasswordInput: false,
      showVerficationInput: false,
      allowOkModal: true,
      disableVerification: false,
      signInBtnText: Labels.get("Sign in"),
      showForgotPw: true,
      action: false,
    }

    this.newProductId = null;
    this.invoiceId = null;
    this.leet = false;
    this.payForm = React.createRef();
    this.productsSelect = React.createRef();
    this.pwInputRef = React.createRef();
    this.codeVRef = React.createRef();
    this.client_id = null;

    this.firstHtml = "";
    this.ioId = null;

    let orgUrl = this.props.match.params.org;

    Zenfy.get(`orgs/${orgUrl}`).then((res) => {
      this.setState({
        org: res.data
      }, () => {
        this.checkH();
      });

      if(res.data.id){
        localStorage.orgId = res.data.id;
        window.org = res.data;
      }
    });

  }

  componentDidMount() {

    let language = window.navigator.userLanguage || window.navigator.language;
    window.isCarbon = false;

    if(language && !localStorage.Lang){
      language = language.substring(0,2);
      localStorage.Lang = language;
    }

    let lang = Zenfy.getParameterByName('lang');

    if(lang){
      localStorage.Lang = lang;
    }

    let token = Zenfy.getParameterByName('token');

    if(token){
      token = window.atob(token).split('|');

      if(token[2] && token[2] != ''){
        /*this.setState({
          productId: parseInt(token[2])
        });*/
        this.newProductId = parseInt(token[2]);
      }

      Zenfy.get(`${localStorage.orgId}/clients/${token[0]}`, {
        email: token[1]
      }).then((res) => {
        this.setState({
          client: res.data
        })
      });

    }

    let product = Zenfy.getParameterByName('product');

    if(product){
      this.newProductId = parseInt(product);
    }
    

    /*if(localStorage.client_email && localStorage.client_password){
      Zenfy.post(`${localStorage.orgId}/clientaccount`, {
        email: localStorage.client_email,
        password: localStorage.client_password,
      }).then((res) => {
        if(res.data){
          if(res.data.password){
            this.setState({
              client: res.data
            });
          }
        }
      });
    }*/
  }

  checkH = (goTop) => {
    setTimeout(() => {
      window.parent.postMessage({h: document.getElementById("root").offsetHeight + 400},"*");
      if(goTop){
        window.parent.postMessage({scroll: 1},"*");
      }
    }, 100);
  }

  next() {
    const current = this.state.current + 1;

    if(current == 1){
        
      if(!this.state.productId){ // no product
          this.setState({
            current: 0
          });
          message.error(Labels.get("Choose a product first!"));
        }else{
          
          this.firstHtml = document.getElementById(`first-part`).innerHTML;
          
          if(!this.state.client.id){ // client not connected
            this.setState({
              showLoginModal: true,
              success: Labels.get("At any time and throughout your certification process for a higher level, you can update your profile."),
              //current: 0
            });
          }else{
            this.setState({
              current: 1
            }, () => {
              this.checkH(true);
            });
          }
      }

    }else if(current == 2){
      
      this.setState({
        loading: true,
      });

      window.clientForm.handleSubmit(false, (res) => {

        let client = window.clientForm.props.form.getFieldsValue();
        let client_id = res.data;
        this.client_id = client_id;

        if(this.state.action == 3){
          const link = document.createElement('a');
          link.href = this.state.product.document;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.generatePdfFile((pdfFile) => {

            Zenfy.post(`${localStorage.orgId}/email`, {
              email_body: "Téléchargement PDF",
              email: 'mcoulombe@industries-durables.org',
              file: pdfFile,
              subject: `Notification: ${this.state.client.name ? this.state.client.name : ''} ${this.state.client.email} - ${this.state.product.category ? this.state.product.category : ""} - ${this.state.product.name}`,
            });

            Modal.success({
              content: (
                <>
                  <div style={{marginLeft: '40px'}}>
                    <p>Merci pour votre téléchargement!</p>
                    <p>Pour effectuer votre inscription, vous pouvez revenir au début du formulaire et choisir l'option « inscription ». Si vous avez des questions, veuillez communiquer avec nous à l'adresse suivante: <a href="mailto: mcoulombe@industries-durables.org">mcoulombe@industries-durables.org</a>.</p>
                  </div>
                </>
              )
            });

            Zenfy.post(`tags_values`, {
              client_id,
              tag_id: 604,
              org_id: localStorage.orgId
            });
            
            this.setState({
              loading: false
            }, () => {
              this.checkH(true);
            });

          });
          

          return;
        }

        //if(client.ready == 1){


              client.org_id = localStorage.orgId;

          // setting price from employes
          let productAmount = 0;

          switch(this.state.product.id){
            case 1431: 
              productAmount = 850;

              if(client.address && client.address.length > 0){
                productAmount += (client.address.length - 1) * 60;
              }

              this.state.product.price = productAmount;

            break;
            case 1432: // niveau 2
              if(client.employees > 0 && client.employees <= 10){
                productAmount = 1500;
              }else if(client.employees >= 11 && client.employees <= 50){
                productAmount = 1850;
              }else if(client.employees >= 51){
                productAmount = 2180;
              }

              if(client.address && client.address.length > 0){
                productAmount += (client.address.length - 1) * 140;
              }

              this.state.product.price = productAmount;
            break;
            case 1433: // niveau 3
              if(client.employees > 0 && client.employees <= 10){
                productAmount = 1730;
              }else if(client.employees >= 11 && client.employees <= 50){
                productAmount = 2190;
              }else if(client.employees >= 51){
                productAmount = 2540;
              }

              if(client.address && client.address.length > 0){
                productAmount += (client.address.length - 1) * 140;
              }

              this.state.product.price = productAmount;
            break;
            case 1669: // niveau 4
              if(client.employees > 0 && client.employees <= 10){
                productAmount = 1960;
              }else if(client.employees >= 11 && client.employees <= 50){
                productAmount = 2780;
              }else if(client.employees >= 51){
                productAmount = 3370;
              }

              if(client.address && client.address.length > 0){
                productAmount += (client.address.length - 1) * 140;
              }

              this.state.product.price = productAmount;
            break;
          }

          Zenfy.post('orders', {
            org_id: localStorage.orgId,
            client_id,
          }).then((res) => {
            let order_id = res.data;

            Zenfy.post('products_orders', {
              org_id: localStorage.orgId,
              order_id: order_id,
              product_id: this.state.productId,
              ends_at: moment().add(24, 'months').format('YYYY-MM-DD HH:mm:ss'),
              recurring: 1,
              amount: this.state.product.price,
              tax: 1
            }).then((res) => {

              this.ioId = res.data;

             /* Zenfy.post('products_orders', {
                org_id: localStorage.orgId,
                order_id: order_id,
                product_id: 1661,
                recurring: 1,
                ends_at: moment().add(this.state.product.months, 'months').format('YYYY-MM-DD HH:mm:ss'),
                amount: 300,
                tax: 1
              }).then((res) => {*/

                Zenfy.post('invoices', {
                    client_id: client_id,
                    //due_at: this.newData.due_at,
                    org_id: localStorage.orgId,
                    //terms: this.newData.terms,
                   // id: this.newData.invoice_id ? this.newData.invoice_id : null,
                }).then((res) => {
                  let invoice_id = res.data;

                  // create invoice_orders
                  Zenfy.post('invoices_orders', {
                    org_id: localStorage.orgId,
                    invoice_id: invoice_id,
                    order_id: order_id,
                  }).then((res) => {

                    this.setState({
                      loading: false,
                      current,
                      invoice: {
                        id: invoice_id,
                        balance: this.state.product.price
                      },
                      client,
                      totalAmount: this.state.product.price * 1 + (this.state.product.price * (0.14975 + 0.036)) * 1,
                    }, () => {
                      this.checkH(true);
                    });
                      
                  });

                });
                  

             // });

            });
            
          });
          
        /*}else{

          this.generatePdfFile((pdfFile) => {
            Zenfy.post(`${localStorage.orgId}/email`, {
              email_body: "Completé le formulaire mais n'a pas payé.",
              file: pdfFile,
              subject: `Notification: ${this.state.client.name ? this.state.client.name : ''} - ${this.state.client.email} - ${this.state.product.category} - ${this.state.product.name}`,
            });

            Modal.info({
              content: (
                <>
                  <div style={{marginLeft: '40px'}}>
                    <p>Pour compléter votre demande d’inscription, vous devez être prêt à soumettre les documents requis pour l’audit effectué par ECOCERT. Votre formulaire sera sauvegardé et vous pourrez y revenir ultérieurement pour compléter votre demande d’inscription.</p>
                  </div>
                </>
              ),
              //className: 'center-modal'
            });

            this.setState({
              loading: false
            }, () => {
              this.checkH(true);
            });
      
          });

        }*/

        return;

        if(this.state.productId){
          let values = {};
          values.client_id = res.data;
          values.org_id = localStorage.orgId ? localStorage.orgId : 1;
          values.product_id = this.state.productId;

          //values.amount = this.state.product.price*1 + (this.state.product.price * (0.14975 + 0.036))*1;
          values.amount = this.state.product.price + (this.state.product.taxes == 1 ? this.state.product.price * 0.14975 : 0);
          values.amount = Zenfy.roundToTwo(values.amount);
          values.count = this.state.product.count;

          if(this.state.product.months && this.state.product.months > 0){
            values.ends_at = moment().add(this.state.product.months, 'months').format('YYYY-MM-DD HH:mm:ss');
          }else if(this.state.product.end_at && this.state.product.end_at != '0000-00-00 00:00:00' && !this.state.product.force_monthly){
            values.ends_at = this.state.product.end_at;
          }
          
          if(!this.state.invoice.id){
            Zenfy.post('invoices', values).then((res) => {
                this.invoiceId = res.data;
                values.id = res.data;
                values.balance = this.state.product.price;

                this.setState({
                  invoice: values
                });
            });
          }
          
          
          client.id = res.data;
          client.customer_id = this.state.client.customer_id;

          if(client.address && !client.address.address){
            delete client.address;
          }

          if(client.emergency_name && !client.emergency_name.emergency_name){
            delete client.emergency_name;
          }

          this.setState({
            loading: false,
            current,
            client,
            totalAmount: this.state.product.price * 1 + (this.state.product.price * (0.14975 + 0.036)) * 1,
          }, () => {
            this.checkH(true);
          });

        }else {
          this.setState({
            loading: false,
          });
          message.success('Error!');
        }

      }, () => {
        console.log('error callback');
        this.setState({
          loading: false,
        }, () => {
          this.checkH(true);
        });
      })

    }else if(current == 3){

      this.setState({
        loading: true,
      });

      this.payForm.current.validateFieldsAndScroll((err, values) => {
          if (!err) {

              values.client_id = this.state.client.id;
              values.org_id = localStorage.orgId ? localStorage.orgId : 1;
              values.invoice_id = this.state.invoice.id;

              if(!values.amount){
                values.amount = this.state.product.price;
              }

              if(window.newAmount || window.discount){
                values.amount = window.newAmount;
                console.log(this.ioId);
                // modify product invoice
                Zenfy.post('products_orders', {
                  id: this.ioId,
                  amount: values.amount,
                  tax: 1
                });
                this.ioId = null;

                if(window.newAmount == 0){
                  values.type = 'cash';
                }

                window.newAmount = null;
              }
              
              //values.amount = this.state.product.taxes == 1 ? this.props.amount * 1 * 0.14975 : 0;

              if(!values.type){
                values.type = 'credit';
              }
              
              if(values.type == 'credit'){

                let creditAmount = values.amount * 1 + (this.state.product.taxes == 1 ? values.amount * 1 * 0.14975 : 0);
                console.log(creditAmount);
              //  creditAmount = creditAmount + (creditAmount * 0.04 + 0.3);

                window.creditCardForm.handleOk(creditAmount, (res) => {

                    values.txn_id = res.id;
                    values.recurring = this.state.product.force_monthly ? 1 : 0;
                    
                    this.postTransaction(values);

                    /* need to change invoice amount
                    Zenfy.post('invoices', {
                      id: this.invoiceId,
                      amount: creditAmount
                    });*/

                }, () => {
                  this.setState({
                    loading: false,
                  });
                });
              }else{
                this.postTransaction(values);
              }

              /*if(window.creditCard && window.creditCard.current){
                  
              }else{
                  this.postTransaction(values);
              }*/
          }
      });

    }else{
      this.setState({ current });
    }

    
  }

  generatePdfFile = (callback) => {
              let html = `
          <style>
          body{
            font-family: Arial, sans-serif;
          }
          .ant-divider{
            margin: 15px 0;
          }
          .ant-divider-inner-text{
            color: #000;
            font-size: 20px;
          }
          .ant-form-item-label{
            padding-top: 10px;
            font-size: 14px;
            font-weight: bold;
          }
          .ant-form-explain,.ant-form-extra{
            display: none;
          }
          .documents, .hide-pdf{
            display: none;
          }
          textarea{
            white-space: normal;
            padding-bottom: 30px;
          }
          input[type=text]{
              width: 300px;
          }
          input[type=radio]{
            margin-right: 10px;
            margin-left: 10px;
            }
          </style>
          </style>
          <h3>${this.state.product.category} - ${this.state.product.name}</h3>
          ` + document.getElementById(`form-crm`).innerHTML;
            Zenfy.post('uploadpdfhtml', {
                html,
                filename: 'form_'
            }).then((res) => {
                callback(res.data);
            });
  }

  postTransaction = (values) => {

    values.amount = values.amount + (this.state.product.taxes == 1 ? values.amount * 1 * 0.14975 : 0);
    values.amount = Zenfy.roundToTwo(values.amount);
    values.client_id = this.client_id;

    Zenfy.post('transactions', values).then((res) => {

      let msg = "";

      if(values.type == 'credit'){
        msg = Labels.get(" Payment passed with success.");
      }else{
        msg = " Nous avons bien reçu votre demande d’inscription.";
      }

      Zenfy.get(`${values.org_id}/invoice/${values.invoice_id}`, {
        email: true,
        email_text: Labels.get("Nous avons bien reçu votre demande d’inscription. Votre demande sera transmise à Ecocert Canada dans un délai de 24 heures. À réception Ecocert Canada vous contactera sous 5 jours ouvrables.<br><br>Bien noter que cette inscription ne garantit pas l'admissibilité de l’entreprise à une Certification ou Attestation du Programme ÉCORESPONSABLE. L’entreprise doit respecter les critères du référentiel correspondant au Programme sélectionné et son admissibilité doit être approuvée par ECOCERT."),
        subject: `${Labels.get('Invoice')} #${values.invoice_id}`,
        emails: [this.state.email],
        lang: localStorage.Lang
      }).then(() => {
        Modal.success({
          content: (
            <>
              <p>{Labels.get("Thank you")} 🙏{msg}</p>
            </>
          ),
          onOk() {
            window.location.reload();
          },
        });
      });

      //if(this.state.product.is_digital == 0){
        
      //}

    });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current }, () => {
      this.checkH(true);
    });
  }

  productOnChange = (product) => {

    if(!product){
      product = {};
    }else{
      localStorage.marque = 300;

      if(product.category && product.category.includes('Attestation')){
        localStorage.marque = 175;
      }
    }

    this.setState({
      product,
    });
  }
  
  onChange = (id) => {
    this.setState({
      productId: id,
    });
  }

  productsAfterLoad = () => {
    if(this.newProductId){
      this.setState({
        productId: this.newProductId
      }, () => {
        this.productsSelect.current.handleChange(this.newProductId);
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, () => {
      if(target.name == 'email' && Zenfy.validateEmail(target.value)){
        this.checkEmail(target.value);
      }else if(target.name == 'verification' && target.value.length > 3){
        this.checkVerification();
      }else if(target.name == 'password' && target.value.length > 1){
        this.setState({
          allowOkModal: false,
        });
      }
    });
  }

  checkVerification = () => {
    Zenfy.post(`${localStorage.orgId}/checkverification`, {
      email: this.state.email,
      verification: this.state.verification
    }).then((res) => {
      if(res.data){
        this.setState({
          showPasswordInput: true,
          success: Labels.get("Please create a new password below. You will be able to easily sign in later."),
          disableVerification: true,
          error: null,
        }, () => {
          if(this.pwInputRef.current){
            this.pwInputRef.current.focus();
          }
        });
      }else{
        this.setState({
          error: Labels.get("Wrong verification code."),
          success: null,
        });
      }
    });
  }

  checkEmail = (email) => {
    Zenfy.get(`${localStorage.orgId}/clients`, {
      email
    }).then((res) => {

      /*if(!localStorage.zenfy_jwt){
        localStorage.client_email = this.state.email;
      }*/

      if(res){

        if(res.data.newUser){
          this.setState({
            showPasswordInput: true,
            showVerficationInput: false,
            allowOkModal: false,
            success: Labels.get('Create your account below.'),
            error: null,
            showForgotPw: false,
            signInBtnText: Labels.get("Create"),
          }, () => {
            if(this.pwInputRef.current){
              this.pwInputRef.current.focus();
            }
          });
        }else if(!res.data.hasPw){
          Zenfy.post(`${localStorage.orgId}/verification`,{
            email 
          });

          this.setState({
            showPasswordInput: false,
            showVerficationInput: true,
            allowOkModal: true,
            password: null,
            error: null,
            showForgotPw: false,
            signInBtnText: Labels.get("Sign In"),
            success: Labels.get("We sent you a verification code, please enter it below."),
          },() => {
            if(this.codeVRef.current){
              this.codeVRef.current.focus();
            }
          });
        }else{
           this.setState({
            showPasswordInput: true,
            showVerficationInput: false,
            allowOkModal: false,
            success: Labels.get("Sign in below."),
            signInBtnText: Labels.get("Sign In"),
            error: null,
          }, () => {
            if(this.pwInputRef.current){
              this.pwInputRef.current.focus();
            }
          });
        }

      }

      /*if(res && res.data.id){
        this.setState({
          client: res.data,
          showLoginModal: false,
          error: null,
        });
      }else{
        this.setState({
          error: "You don't have the right combination. Please contact the front desk."
        })
      }*/
      
    });
  }

  forgot = () => {
    Zenfy.post(`${localStorage.orgId}/verification`,{
      email: this.state.email,
      lang: localStorage.Lang
    });

    this.setState({
      showVerficationInput: true,
      allowOkModal: true,
      error: null,
      showForgotPw: false,
      password: null,
      signInBtnText: Labels.get("Sign In"),
      success: Labels.get("We sent you a verification code, please enter it below.")
    }, () => {
      if(this.codeVRef.current){
        this.codeVRef.current.focus();
      }
    });
  }

  handleModalOk = () => {
    if(this.state.email && this.state.email != '' && this.state.password && this.state.password != ''){

      Zenfy.post(`${localStorage.orgId}/clientaccount`, {
        email: this.state.email,
        password: this.state.password,
        verification: this.state.verification,
      }).then((res) => {
        if(res.data){
          if(res.data.password){
            localStorage.client_email = res.data.email;
            localStorage.client_password = this.state.password;

            this.setState({
              showLoginModal: false,
              client: res.data,
              current: 1
            }, () => {
              this.checkH(true);
            });
          }
        }else{
          this.setState({
            error: Labels.get('Wrong password!'),
            success: false,
          });
        }
      });

      /*Zenfy.get(`${localStorage.orgId}/clients`, {
        email: this.state.email,
        dob
      }).then((res) => {

        if(!localStorage.zenfy_jwt){
          localStorage.client_email = this.state.email;
          localStorage.client_dob = dob;
        }

        if(res.data.id){
          this.setState({
            client: res.data,
            showLoginModal: false,
            error: null,
          });
        }else{
          this.setState({
            error: "You don't have the right combination. Please contact the front desk."
          })
        }
      });*/
    }
  }

  handleScan = data => {
    if (data) {
      console.log(data);
      this.setState({
        result: data
      })
    }
  }

  getDropList = () => {
    const year = new Date('1940-01-01').getFullYear();
    return (
      Array.from( new Array(80), (v,i) =>
        <Select.Option key={i} value={year+i}>{year+i}</Select.Option>
      )
    );
  }

  logout = (e) => {
    e.preventDefault();

    delete localStorage.client_email;
    delete localStorage.client_password;

    this.setState({
      client: {},
      current: 0,
    });

    this.forceUpdate();
  }

  onChangeAction = e => {
    this.setState({
      action: e.target.value,
    });
  };

  render() {
    //localStorage.orgId = 1;
    const { current } = this.state;

    let steps = [
      <>
        {this.state.org.id ? (
          <div id="first-part">
            
              <p>1. {Labels.get('I want to')}:</p>
              <Radio.Group style={{display: 'block'}} onChange={this.onChangeAction} value={this.state.action}>
                <Radio value={1}>{Labels.get('Register')}</Radio>
                <Radio value={2}>{Labels.get('Renew')}</Radio>
                <Radio value={3}>{Labels.get('Download')}</Radio>
              </Radio.Group>

              {this.state.action ? (
                <>
                  <p style={{marginTop: '20px'}}>2. {Labels.get('Choose your program')}:</p>
                  <SelectFromUrl action={this.state.action} showOnlyTitles isLeet={this.leet} treeSelect ref={this.productsSelect} afterLoad={this.productsAfterLoad} onChange={this.onChange} value={this.state.productId} placeholder={Labels.get("Choose your program")} style={{width: '50%'}} itemChange={this.productOnChange} url={`${this.state.org.id}/products?active=1&class=true`} idName="id" name="name" category="parent" className="choose-class" />
                </>
              ) : null}
          
          </div>
        ) : null}
      </>
      ,
      <>
        {this.state.client.name ? (
          <>
            <div style={{textAlign: 'center', margin: '40px 0'}}>
            <span>{Labels.get("You are logged in as")} {this.state.client.name ? this.state.client.name : this.state.client.email} 👌 <a href="javascript:;" style={{textDecoration: 'underline'}} onClick={this.logout}>{Labels.get("Logout")}</a></span>
            </div>
            <Divider style={{fontSize: '14px'}}>{!this.state.client.id ? Labels.get("Don't have an account?") : Labels.get("Feel free to change your information below")}</Divider>
          </>
        ) : null}
        <ClientForm 
        action={this.state.action} 
        contract={this.state.product.contract} 
        covid={this.state.product.covid ? this.state.org.covid : false} client={this.state.client} 
        isChild={this.state.product && this.state.product.child == 1 ? true : false} public 
        isDigital={this.state.product ? this.state.product.is_digital : false} 
        product={this.state.product}
        logIn={(email) => {
          this.setState({
            showLoginModal: true,
            email,
          }, () => {
            this.checkEmail(email);
          });
        }} />
      </>,
      <>
        <Row>
          <Col xs={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} md={{span: 12, offset: 6}} lg={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
         
            <PayForm cur={this.newProductId && this.newProductId == 1337 ? 'usd' : undefined} invoice={this.state.invoice} client={this.state.client} ref={this.payForm} taxes={this.state.product ? this.state.product.taxes : false} force_monthly={this.state.product ? this.state.product.force_monthly : false} showRecap hideReference hideType />
            <div style={{ marginBottom: 20 }} />
            
          </Col>
        </Row>
      </>,
    ];

    document.body.style['touch-action'] = "manipulation";

    return (
      <>
      <style dangerouslySetInnerHTML={{__html: `
        /*@media (max-width: 600px) {*/
          .ant-layout, body { background: #fff; }
          body #hubspot-messages-iframe-container{display: none !important;}
          .ant-modal-mask{background-color: rgb(255 255 255 / 80%);}
        /*}*/
      `}}></style>
      <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport"/>

      <div className="btn-menu not-active" onClick={this.showSideMenu} style={{display: 'none'}}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      
    <Modal
      title={Labels.get("Create an account or log in")}
      visible={this.state.showLoginModal}
      onOk={this.handleModalOk}
      okText={this.state.signInBtnText}
      cancelText={Labels.get("Cancel")}
      //closable={false}
      maskClosable={false}
      keyboard={false}
      //mask={false}
      //cancelButtonProps={{style: {display: 'none'}}}
      onCancel={()=> this.setState({
        showLoginModal: false,
        error: null,
        success: null,
      })}
      okButtonProps={{
        loading: this.state.modalLoginLoading, 
        disabled: this.state.allowOkModal,
      }}
    >   
        {this.state.error && this.state.error != '' ? (
          <Alert message={this.state.error} type="error" style={{marginBottom: '20px'}} />
        ): null}

        {this.state.success && this.state.success != '' ? (
          <Alert message={this.state.success} type="success" style={{marginBottom: '20px'}} />
        ): null}
        
        <Input
          placeholder={Labels.get("Email")}
          name="email"
          style={{marginBottom: 20}}
          value={this.state.email}
          onChange={this.handleInputChange}
        />

        {this.state.showVerficationInput ? (
          <Input
            placeholder={Labels.get("Verification Code")}
            name="verification"
            style={{marginBottom: 20}}
            value={this.state.verification}
            onChange={this.handleInputChange}
            disabled={this.state.disableVerification}
          />
        ) : null}

        {this.state.showPasswordInput ? (
          <>
            <Input.Password
              placeholder={Labels.get("Password")}
              name="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              ref={this.pwInputRef}
            />
            {this.state.showForgotPw ? (
              <a href="javascript:;" style={{textDecoration: 'underline', display: 'block', marginTop: '5px', fontSize: '14px'}} onClick={this.forgot}>{Labels.get("Forgot your password?")}</a>
            ) : null}
          </>
        ) : null}

    </Modal>

    <Row type="flex" justify="space-around" align="middle" style={{margin: '0px 0 0 0'}}>
      <Col className="gutter-row" span={18}>
        
      <div style={{marginTop: '25px'}}>
        <Steps current={current}>
          <Step title={Labels.get("Program")} />
          <Step title={Labels.get("Information")} />
          <Step title={this.state.action == 3 ? Labels.get("Download") : Labels.get("Payment")} />
        </Steps>
        <div className="steps-content" style={{marginTop: '25px'}} >

          {steps[this.state.current]}
      
        </div>
        <div className="steps-action" style={{marginBottom: '20px', marginTop: '20px'}}>
          {current < steps.length - 1 && (
            <Button type="primary" loading={this.state.loading} className="pull-right" onClick={() => this.next()}>
              {Labels.get("Next")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" className="pull-right" loading={this.state.loading} onClick={() => this.next()}>
              {Labels.get("Pay")}
            </Button>
          )}
          {current > 0 && (
            <Button className="pull-left" onClick={() => this.prev()}>
              {Labels.get("Previous")}
            </Button>
          )}
        </div>
      </div>

      </Col>
    </Row>

      </>
    );
  }
}

export default FormCrm;