import React, { Component } from "react";

import { Row, Col, Card, List, Avatar, Modal, Tooltip, notification, Radio, DatePicker, Input, Select, Badge, Tag, Menu, Dropdown, Icon, Checkbox } from 'antd';

import Zenfy from "../libs/Zenfy";
import moment from 'moment';

import Map from '../components/Map';
import Messages from '../components/Messages';
import Orders from '../pages/Orders';

const { Option } = Select;

const { TextArea } = Input;

class Dispatch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            drivers: [],
            chassis: [],
            jobs: [],
            loadingDrivers: true,
            loadingChassis: true,
            loadingJobs: true,
            selectedDriver: {},
            selectedChassis: {},
            selectedJob: {},
            start: localStorage.disptach_date_start ? moment(localStorage.disptach_date_start) : moment(),
            end: localStorage.disptach_date_end ? moment(localStorage.disptach_date_end) : moment(),
            date: localStorage.disptach_date ? moment(localStorage.disptach_date) : moment(),
            view: 'list',
            driverFilter: '',
            jobFilter: localStorage.job ? localStorage.job : '',
            chassiFilter: '',
            selectJobFilter: [],
            showJobModal: false,
            modalJobLoading: false,
            selectedLeg: undefined,
            showAvailibleDrivers: null,
            driverMsg: '',
            sendSms: true,
            liveLoad: false,
            allMoves: false,
            copyToText: false,
            fullMessage: '',
            globalFilter: null,
        }

        this.messages = null;
        this.numberOfNewMsgs = 0;
        this.ordersRef = React.createRef();
    }

    componentDidMount() {

      //  this.load();

    }

    componentWillUnmount() {
        clearInterval(this.messages);
    }

    getNotSeenMessages = () => {
        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/messagesnotread`).then((res) => {
            if(res.data.length == 0){
                return;
            }   

            let drivers = this.state.drivers;

            drivers.map((driver) => {
                driver.numberOfMsgs = 0;
                driver.lastMsgDate = "2019-01-02 20:57:59";
            });

            let hasMsg = false;
            let lastNumberOfMsgs = this.numberOfNewMsgs;
            this.numberOfNewMsgs = 0;
            
            res.data.map((row) => {
                drivers.map((driver) => {
                    if(driver.id == row.from_id){
                        driver.numberOfMsgs = row.numberOfMsgs;
                        driver.lastMsgDate = row.created_at;
                        hasMsg = true;
                        this.numberOfNewMsgs += row.numberOfMsgs;
                    }
                });
            });

            console.log(hasMsg, this.numberOfNewMsgs, lastNumberOfMsgs);

            if(hasMsg && this.numberOfNewMsgs > lastNumberOfMsgs){ 
                const audioEl = document.getElementsByClassName("audio-element")[0]
                audioEl.play();
            }

            drivers = drivers.sort((a, b) => new Date(b.lastMsgDate) - new Date(a.lastMsgDate));
       
            this.setState({
                drivers
            });
        });
    }

    load = () => {

        this.setState({
            loadingDrivers: true,
            loadingChassis: true,
            loadingJobs: true,
        });

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/orders?start=${this.state.start.format('YYYY-MM-DD')}&end=${this.state.end.format('YYYY-MM-DD')}`).then((res) => {

            let jobs = Zenfy.setKeysToList(res.data);

            /*jobs = jobs.filter((job) => {
                return !job.type;
            });*/

            if(this.state.selectedJob){
                jobs.map((job) => {
                    // refreshing job
                    if(job.id == this.state.selectedJob.id){
                        this.setState({
                            selectedJob: job
                        });
                    }
                })
            }

            Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/staff`).then((res) => {

                let drivers = Zenfy.setKeysToList(res.data);

                drivers.map((driver) => {
                    jobs.map((job) => {
                        job.legs.map((leg) => {
                            if(leg.driver_id == driver.id && !leg.type){
                                driver.job = leg;
                            }
                        });
                    });
                });

                this.setState({
                    loadingDrivers: false,
                    drivers,
                });

                this.getNotSeenMessages();

                if(!this.messages){
                    this.messages = setInterval(() => {
                        this.getNotSeenMessages();
                    }, 3000)
                }
    
            });
    
            Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/equipements?type=chassis`).then((res) => {

                Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/orderswithchassis`).then((chassisRes) => {

                    let ordersWithChassis = Zenfy.setKeysToList(chassisRes.data);

                    let chassis = Zenfy.setKeysToList(res.data);
    
                    chassis.map((chassi) => {

                        ordersWithChassis.map((order) => {
                            if(order.equipement_id == chassi.id){
                                chassi.order = order;
                            }
                        });

                        jobs.map((job) => {
                            job.legs.map((leg) => {
                                if(leg.equipement_id == chassi.id && !leg.type){
                                    chassi.job = leg;
                                }
                            });
                        });
                    });
    
                    this.setState({
                        loadingChassis: false,
                        chassis,
                    }); 
                });


    
            });
            
            
            this.setState({
                loadingJobs: false,
                 jobs,
            });

        });
    }

    onClickItem = (e, item, prop) => {

        /*let drivers = this.state.drivers.map((driver) => {
            if(driver.id == item.id){
                driver.selected = driver.selected ? false : true;
            }
            return driver;
        });*/

        //console.log(e);

        if(e.type === 'click'){
            this.setState({
                [prop]: item
            });
        }else if (e.type === 'contextmenu') {
            this.setState({
                [prop]: {}
            });
        }

    }

    afterGiveJob = () => {
        this.state.selectedJob.driver_id = this.state.selectedDriver.id;
        this.state.selectedJob.equipement_id = this.state.selectedChassis.id;

        if(this.state.selectedJob.status == "ready_pickup" && !moment(this.state.selectedJob.app_date).isSame(moment(), 'day')){
            Zenfy.post('orders', {
                id: this.state.selectedJob.id, 
                org_id: this.state.selectedJob.org_id, 
                app_date: moment().format('YYYY-MM-DD HH:mm:ss')
            });
        }

        /*if(this.state.selectedChassis.id){
            Zenfy.post('orders', {
                id: this.state.selectedJob.id, 
                org_id: this.state.selectedJob.org_id, 
                chassis_id: this.state.selectedChassis.id
            }).then((res) => {
                this.load();
            })
        }*/

        /* finding equipement name
        let equipement = this.state.chassis.find((element) => {
            return element.id == this.state.selectedJob.equipement_id;
        })

        if(equipement){
            this.state.selectedJob.equipement_name = equipement.name;
        }*/
    
        // this.copyToDriver(this.state.selectedJob, true);

        let fullMessage = this.state.fullMessage;

        if(this.state.driverMsg && this.state.driverMsg != ''){

            if(this.state.copyToText){
                fullMessage += this.state.driverMsg;
            }    

            let msg = `${this.state.driverMsg}\nhttps://zenfy.co/#/driver/${this.state.selectedDriver.id}`;

            Zenfy.copyToClipboard(msg);

            Zenfy.post(`messages`, {
                org_id: localStorage.orgId ? localStorage.orgId : 2,
                message: msg,
                to_id: this.state.selectedDriver.id,
                sendSms: this.state.sendSms,
                from_id: 0,
            }).then((res) => {
                this.setState({
                    driverMsg: ''
                });
            });
        }
        
        this.setState({
            selectedChassis: {},
            //selectedDriver: {},
            selectedJob: {},
            selectedLeg: null,
            driverFilter: '',
            fullMessage,
            allMoves: false,
        });

        this.load();
    }

    postLegs = (legs, callback) => {

        if (legs.length > 0) {
            let leg = legs.pop();
       
            Zenfy.post('legs', {
                id: leg.id,
                driver_id: this.state.selectedDriver.id,
                equipement_id: this.state.selectedChassis.id,
                assign_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            }).then((res) => {
                this.postLegs(legs, callback);
            });

        }else{
            callback();
        }
    
    }

    giveJob = () => {

        if(this.state.allMoves){
            let legs = this.state.selectedJob.legs;

            this.postLegs(legs, () => {
                this.afterGiveJob();
            });

            return;
        }

        Zenfy.post('legs', {
            id: this.state.selectedLeg,
            driver_id: this.state.selectedDriver.id,
            equipement_id: this.state.selectedChassis.id,
            assign_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        }).then((res) => {
            this.afterGiveJob();
        });
    }

    copyToDriver = (item, copyLink, leg) => {

        let legObj = null;

        // find leg 
        if(!leg){
            item.legs.map((leg) => {
                if(leg.id == this.state.selectedLeg){
                    legObj = leg;
                }
            });
        }else{
            legObj = leg;
        }
        

        let chassisName = this.state.selectedChassis.name ? this.state.selectedChassis.name : '';

        if(item.chassis_id){
            let equipement = this.state.chassis.find((element) => {
                return element.id == item.chassis_id;
            });

            if(equipement){
                chassisName = equipement.name;
            }
        }

        let showBooking = legObj.terminal_to_id == 28;

        let address = `${item.delivery_address ? item.delivery_address + ',' : ''} ${item.delivery_city ? item.delivery_city + ',' : ''} ${item.delivery_postal ? item.delivery_postal : ''}`;

        let msg = `Pickup ${item.size ? item.size : ''} ${legObj.terminal_id == 28 ? item.delivery_client_name : legObj.terminal_name} ${!showBooking && legObj.terminal_id != 35 && legObj.terminal_to_id != 35 ? `\n${item.delivery_address}, ${item.delivery_city}, ${item.delivery_postal}` : ''}
${item.container ? item.container : ''} ${chassisName ? `/ ${chassisName}` : ""}${legObj.instructions ? ` ${legObj.instructions}` : ''}${item.pickup && (showBooking && legObj.terminal_id != 35 || item.job == 'export' || (legObj.terminal_to_id == 35 && legObj.terminal_id != 28 && legObj.terminal_id != 35)) ? item.job == 'export' ? `${item.container || item.container ? '\n' : ''}Booking# ${item.pickup}` : `\nPU# ${item.pickup}` : ''}
${legObj.terminal_to_id == 28 ? item.delivery_client_name ? `${this.state.liveLoad ? `Deliver (Live Load) to` : `Drop at`} ${item.delivery_client_name}` : '' : legObj.return_terminal_name ? `Return to ${legObj.return_terminal_name}` : ''}
${showBooking ? `${address}\n` : ''}${showBooking ? `App time @ ${moment(item.app_date).format("HH:mm")}` : ''}
${copyLink ? `https://zenfy.co/#/driver/${legObj.driver_id == 0 ? item.driver_id : legObj.driver_id}` : ''}`

        Zenfy.copyToClipboard(msg);



        if(copyLink){
            notification.success({
                message: `Copied with success!`,
                placement: 'bottomLeft',
            });
        }

        return msg;
    }

    dateOnChange = (momentDates, dates) => {
        localStorage.disptach_date_start = dates[0];
        localStorage.disptach_date_end = dates[1];

        this.setState({
            start: momentDates[0],
            end: momentDates[1],
            selectedJob: {}
        }, () => {
            this.load();
        });
    }

    handleViewChange = (e) => {
        this.setState({ 
            view: e.target.value,
            selectedJob: {},
         });
    }

    filter = (prop, e) => {
        this.setState({
            [prop]: e.target.value
        });
    }

    showOrder = (marker) => {
        this.setState({
            view: 'list',
        }, () => {
            this.ordersRef.current.recordOnClick(marker.order);
        })
    }

    render() {

        let showModal = false;

        if(this.state.selectedJob.id && this.state.selectedDriver.id){

            this.state.selectedJob.legs.some((leg) => {
                if(leg.driver_id == 0 && !this.state.selectedLeg){

                    this.setState({
                        selectedLeg: leg.id
                    }, () => {
                        let text = this.copyToDriver(this.state.selectedJob);
                        this.setState({
                            driverMsg: text
                        });
                    });

                    return true;
                }
            });

            showModal = true;
        }

        const driverRows = this.state.drivers.filter((driver) => {

            if (this.state.driverFilter !== '') {
                return driver.name.toLowerCase().indexOf(this.state.driverFilter.toLowerCase()) !== -1;
            }

            if(this.state.selectedDriver && this.state.selectedDriver.id && this.state.selectedDriver.id == driver.id && this.state.view == 'sms'){
                driver.numberOfMsgs = 0;
            }

            if(this.state.showAvailibleDrivers !== null){
                if(this.state.showAvailibleDrivers){
                    return driver.job ? false : true;
                }else{
                    return driver.job ? true : false;
                }
            }
      
            return true;
        });

        const chassisRows = this.state.chassis.filter((chassi) => {

            if (this.state.chassiFilter !== '') {
                return chassi.name.toLowerCase().indexOf(this.state.chassiFilter.toLowerCase()) !== -1;
            }
      
            return true;
        });

        const jobs = this.state.jobs.filter((obj) => {

            if (this.state.jobFilter && this.state.jobFilter != '') {
                let hasDriver = false;
                //let legString = "";
                obj.legs.map((leg) => {
                    //legString += obj.delivery_client_name + " " + leg.return_terminal_name + " " + leg.terminal_name;
                    if(leg.driver_name && leg.driver_name.toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1){
                        hasDriver = true;
                    }
                });

                //obj.legString = legString;

                return obj.container && obj.container.toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1 
                || obj.id && obj.id.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1 
                || obj.medlog && obj.medlog.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1 
                || obj.pickup && obj.pickup.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1 
                || obj.utx_id && obj.utx_id.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1
                || obj.pickup_terminal_name && obj.pickup_terminal_name.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1
                || (obj.instructions ? obj.instructions.toString().indexOf(this.state.jobFilter.toLowerCase()) !== -1 : false)
                || (obj.return_instructions ? obj.return_instructions.toString().indexOf(this.state.jobFilter.toLowerCase()) !== -1 : false)
                || obj.client_name && obj.client_name.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1
                || hasDriver
                || (obj.delivery_client_name ? obj.delivery_client_name.toString().toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1 : false);
            }
            //console.log(this.state.globalFilter);
            if(this.state.globalFilter){
                let show = false;
                obj.legs.map((leg) => {
                    if(leg.type != 'drop' && leg.driver_id != 0 && this.state.globalFilter == 'route'){
                        show = true;
                    }else if(leg.type == 'drop' && this.state.globalFilter == 'drop'){
                        show = true;
                    }
                });

                if(obj.status == 'prepull_yard' && obj.job == 'import' && this.state.globalFilter == 'full_yard'){
                    show = true;
                }

                if(obj.status == 'prepull_yard' && obj.job == 'export' && this.state.globalFilter == 'empty_yard'){
                    show = true;
                }

                if(obj.status == 'customer_yard' && obj.job == 'export' && this.state.globalFilter == 'full_yard'){
                    show = true;
                }

                if(obj.status == 'customer_yard' && obj.job == 'import' && this.state.globalFilter == 'empty_yard'){
                    show = true;
                }

                return show;
            }
        
            return true;
        });

       /* const jobRows = this.state.jobs.filter((job) => {

            if(this.state.selectJobFilter.length > 0){

                let skip = false;

                this.state.selectJobFilter.map((filter) => {
                    if(filter == 'done' && job.type != 'Done'){
                        skip = true;
                    }else if(filter == 'executing' && job.driver_id == 0){
                        skip = true;
                    }else if(filter == 'terminal' && job.terminal_id == 28 && job.driver_id != 0){
                        skip = true;
                    }else if(filter == 'customer' && job.terminal_id != 28 && job.driver_id != 0){
                        skip = true;
                    }else if(filter == 'ready' && job.status != 'ready'){
                        skip = true;
                    }
                });

                if(skip){
                    return false;
                }
            }

            if (this.state.jobFilter !== '') {
                return job.container.toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1 || job.terminal_name.toLowerCase().indexOf(this.state.jobFilter.toLowerCase()) !== -1;
            }
      
            return true;
        });*/

        const menu = (
            <Menu>
              <Menu.Item key="0" onClick={() =>
                  this.setState({
                      showAvailibleDrivers: true
                  })
              }>
                Show Only Availible
              </Menu.Item>
              <Menu.Item key="1" onClick={() =>
                  this.setState({
                      showAvailibleDrivers: false
                  })
              }>
                Show Only Taken
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="3" onClick={() =>
                  this.setState({
                      showAvailibleDrivers: null
                  })
              }>
                Show All
              </Menu.Item>
            </Menu>
          );

        return (
            <>
                <audio className="audio-element">
                    <source src="https://www.redringtones.com/wp-content/uploads/2016/09/icq-uh-oh.mp3"></source>
                </audio>
                
                <Modal
                    title="Assign Job"
                    visible={this.state.showJobModal || showModal}
                    onOk={this.giveJob}
                    onCancel={() => {
                        this.setState({
                            showJobModal: false,
                            modalJobLoading: false,
                           // selectedChassis: {},
                           // selectedDriver: {},
                            selectedJob: {},
                            selectedLeg: null,
                            driverMsg: '',
                            allMoves: false,
                        });
                    }}
                    okText="Assign"
                    okButtonProps={{loading: this.state.modalJobLoading}}
                >
                    <p>Which move:</p>
                    <Select value={this.state.selectedLeg} placeholder="" style={{width: '60%'}} onChange={(value) => {
                        this.setState({
                            selectedLeg: value,
                        }, () => {
                            let text = this.copyToDriver(this.state.selectedJob);
                            this.setState({
                                driverMsg: text
                            });
                        });
                    }}>
                        {this.state.selectedJob.legs ? this.state.selectedJob.legs.map((leg, i) => 
                            <Option value={leg.id} key={i}>
                                {leg.terminal_id == 28 ? this.state.selectedJob.delivery_client_name : leg.terminal_name} --> {leg.terminal_to_id == 28 ? this.state.selectedJob.delivery_client_name : leg.return_terminal_name}
                            </Option>
                        ) : null}
                    </Select>

                    <Checkbox
                        checked={this.state.allMoves}
                        style={{marginLeft: 10}}
                        onChange={(e) => {
                            this.setState({
                                allMoves: e.target.checked
                            }, () => {
                                let text = '';
                                if(e.target.checked){
                                    let legsLength = this.state.selectedJob.legs.length;
                                    this.state.selectedJob.legs.map((leg, index) => {
                                        text += this.copyToDriver(this.state.selectedJob, false, leg);
                                        if (legsLength !== index + 1) {
                                            text += `\n`;
                                        }
                                    });
                                }else{
                                    text = this.copyToDriver(this.state.selectedJob);
                                }
                                
                                this.setState({
                                    driverMsg: text
                                });
                            });
                        }}
                    >
                        All moves
                    </Checkbox>

                    <div className="clearfix" />

                    <Checkbox
                        checked={this.state.sendSms}
                        style={{marginTop: 20}}
                        onChange={(e) => {
                            this.setState({
                                sendSms: e.target.checked
                            });
                        }}
                    >
                        Send SMS
                    </Checkbox>

                    <Checkbox
                        checked={this.state.liveLoad}
                        style={{marginTop: 20}}
                        onChange={(e) => {
                            this.setState({
                                liveLoad: e.target.checked
                            }, () => {
                                let text = this.copyToDriver(this.state.selectedJob);
                                this.setState({
                                    driverMsg: text
                                });
                            });
                        }}
                    >
                        Live Load (Deliver)
                    </Checkbox>

                    <Checkbox
                        checked={this.state.copyToText}
                        style={{marginTop: 20}}
                        onChange={(e) => {
                            this.setState({
                                copyToText: e.target.checked
                            }, () => {
                                /*let text = this.copyToDriver(this.state.selectedJob);
                                this.setState({
                                    driverMsg: text
                                });*/
                            });
                        }}
                    >
                        Copy To Message (Use Later)
                    </Checkbox>

                    <TextArea value={this.state.driverMsg} onChange={(e) => {
                        this.setState({
                            driverMsg: e.target.value
                        })
                    }} style={{height: 200}}/>     

                </Modal>

                <Radio.Group value={this.state.view} onChange={this.handleViewChange} style={{marginBottom: '15px', float: 'left'}}>
                    <Radio.Button value="list">List</Radio.Button>
                    <Radio.Button value="sms">Sms</Radio.Button>
                    <Radio.Button value="map">Map</Radio.Button>
                </Radio.Group>

                <DatePicker.RangePicker 
                    placeholder={['From','To']} 
                    style={{ width: 256, marginBottom: 24, float: 'right' }} 
                    onChange={this.dateOnChange} 
                    allowClear={false}
                    value={[this.state.start, this.state.end]}
                    ranges={{
                        Today: [moment(), moment()],
                        Tomorrow: [moment().add(1, 'day').endOf('day'), moment().add(1, 'day').endOf('day')],
                        Yerstoday: [moment().subtract(1, 'day').endOf('day'), moment().subtract(1, 'day').endOf('day')],
                        'Between 2 months': [moment().startOf('month').subtract(2, 'month'), moment().endOf('month').add(2, 'month')],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'This Year': [moment().startOf('year'), moment().endOf('year')],
                        'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
                    }}
                />
                

                <a href="javascript:;" style={{float: 'right', marginRight: 16, marginTop: 5 }} onClick={() => this.load()}>Refresh</a>

                <div className="clearfix" />

                <Row gutter={24} className={`disptach ${this.state.view}-view`}>
                    <Col xs={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} md={{span: 4}} lg={{span: 4}} xl={{span: 4}} style={{height: '80vh'}} className="side">
                        <Card size="small" 
                        className="drivers"
                        title={
                            <Dropdown overlay={menu}>
                                <a className="ant-dropdown-link" href="javascript:;" style={{color: '#333333'}}>
                                    Drivers <Icon type="down" />
                                </a>
                            </Dropdown>
                        } 
                        extra={
                            <Input value={this.state.driverFilter} placeholder="Filter" size="small" onChange={(e) => this.filter('driverFilter', e)} />
                        } style={{marginBottom: 24, height: '48%', borderRadius: 5}}>
                            <List
                                itemLayout="horizontal"
                                dataSource={driverRows}
                                loading={this.state.loadingDrivers}
                                renderItem={item => (
                                    <List.Item 
                                        onClick={(e) => this.onClickItem(e, item, 'selectedDriver')}
                                        onContextMenu={(e) => this.onClickItem(e, item, 'selectedDriver')}
                                        className={item.id == this.state.selectedDriver.id ? 'selected' : ''}
                                        key={item.id}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Tooltip title={item.role}>
                                                    <Badge count={item.numberOfMsgs ? item.numberOfMsgs : 0} showZero style={{ backgroundColor: item.job ? '' : '#87d068' }} > 
                                                        <Avatar icon="user" /> 
                                                    </Badge>
                                                </Tooltip>
                                            }
                                            title={<span>{item.name} <small>{Zenfy.capitalizeFirstLetter(item.role)}</small></span>}
                                            description={item.job ? `Taken for ${item.job.order_id}` : ''}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                        <Card size="small" className="chassis" title="Chassis" extra={<Input placeholder="Filter" size="small" onChange={(e) => this.filter('chassiFilter', e)} />} style={{height: '48%', borderRadius: 5}}>
                            <List
                                    itemLayout="horizontal"
                                    dataSource={chassisRows}
                                    loading={this.state.loadingChassis}
                                    renderItem={item => (
                                    <List.Item
                                        onClick={(e) => this.onClickItem(e, item, 'selectedChassis')}
                                        onContextMenu={(e) => this.onClickItem(e, item, 'selectedChassis')}
                                        className={item.id == this.state.selectedChassis.id ? 'selected' : ''}
                                        key={item.id}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Badge dot style={{ backgroundColor: item.job || item.order ? '' : '#87d068' }}> 
                                                    <Avatar icon="thunderbolt" />
                                                </Badge>
                                            }
                                            title={<>{item.name} <small>{item.model} {item.plate}</small></>}
                                            description={item.job ? `Taken for ${item.job.order_id}` : item.order ? `Taken for ${item.order.id}` : ''}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    <Col xs={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} md={{span: 20}} lg={{span: 20}} xl={{span: 20}} >
                        
                        {this.state.view == 'map' &&
                            <Map 
                                height="80vh" 
                                items={jobs} 
                                showOrder={this.showOrder}
                            />
                        || this.state.view == 'list' &&
                            <Orders 
                                ref={this.ordersRef}
                                small 
                                date={this.state.date}
                                rows={jobs}
                                onFilter={(filter) => {
                                    this.setState({
                                        globalFilter: this.state.globalFilter == filter ? null : filter,
                                    });
                                }}
                                onRowClick={(e, item) => this.onClickItem(e, item, 'selectedJob')} 
                                onContextMenu={(e) => this.onClickItem(e, null, 'selectedJob')}
                                rowClassName={(record, index) => {return record.id == this.state.selectedJob.id ? 'selected' : ''}}
                                searchOnChange={(e) => {
                                    localStorage.job = e.target.value;

                                    this.setState({
                                        jobFilter: e.target.value
                                    });
                                }}
                                searchValue={this.state.jobFilter}
                                loading={this.state.loadingJobs}
                                reLoad={this.load}
                                driverClick={(leg, e) => {
                                    if(e){
                                    //    e.stopPropagation();
                                    }
                                    
                                    this.setState({
                                        selectedLeg: leg.id,
                                    }, () => {
                                        this.copyToDriver(this.state.selectedJob, true);
                                    });
                                }}
                            />
                        || 
                            <Messages postMsg driver={this.state.selectedDriver} key={this.state.selectedDriver.id} fullMessage={this.state.fullMessage} restartFull={() => {
                                this.setState({
                                    fullMessage: '',
                                });
                            }} />
                        }

                    </Col>
                </Row>
            </>
        )
    }
}

export default Dispatch;