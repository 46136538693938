import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Table, Input, DatePicker, notification } from 'antd';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

import Currency from 'react-currency-formatter';

const { RangePicker } = DatePicker;

class Sales extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            start: moment().startOf('year').subtract(10, 'years').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            positions: [],
        }

    }

    componentDidMount() {
        this.load();

        this.interval = setInterval(() => {
            this.load(true);
        }, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    load = (salient) => {

        this.setState({
            loading: !salient,
        });


        Zenfy.get("trades").then((res) => {

            let positions = [];

            res.data.positions.map((pos) => {
                //if(pos.data.size > 0){
                    
                    res.data.market.map((market) => {
                        if(market.symbol == pos.data.symbol){
                            pos.data.unrealised_pnl_usd = pos.data.unrealised_pnl * market.ask_price;
                            pos.data.realised_pnl_usd = pos.data.realised_pnl * market.ask_price;
                            pos.data.cum_realised_pnl_usd = pos.data.cum_realised_pnl * market.ask_price;
                        }
                    });

                    positions.push(pos.data);
                //}
            });

            res.data.orders = res.data.orders.map((order) => {
                let pnl = res.data.pnl.find((element) => {
                    return element.order_id === order.order_id;
                });
                if(pnl){
                    let market = res.data.market.find((element) => {
                        return element.symbol === order.symbol;
                    });

                    order.closed_pnl_usd =  pnl.closed_pnl * market.ask_price;
                    order.closed_pnl = pnl.closed_pnl;
                    return order;
                    //return {...order, ...pnl};
                }else{
                    return order;
                }
            });

            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data.orders),
                positions
            });
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    copy = (record) => {
        alert("Coming soon!");
    }

    render() {

        const columns = [{
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
        },{
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <Currency quantity={text} currency="USD" />,
            sorter: (a, b) => a.price - b.price,
        },{
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
        },{
            title: 'Status',
            dataIndex: 'order_status',
            key: 'order_status',
        },{
            title: 'Type',
            dataIndex: 'order_type',
            key: 'order_type',
        },{
            title: 'Closed PNL',
            dataIndex: 'closed_pnl_usd',
            key: 'closed_pnl_usd',
            render: (text, record) => {return record.closed_pnl ? <><Currency quantity={text} currency="USD" /> ({record.closed_pnl})</> : ''},
        },{
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {return text ? moment(text).format("MMMM Do YYYY, h:mm:ss") : null},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.copy(record)}>Copy Trade</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }
    ];

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            return obj.order_id.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange} 
                defaultValue={[moment().startOf('year').subtract(10, 'years'), moment()]}
                ranges={Zenfy.getRanges()}
            />

            <div style={{clear: 'both'}} />

            <Table dataSource={rows} columns={columns} loading={this.state.loading} />

            <Table dataSource={this.state.positions} columns={[
                {
                    title: 'Symbol',
                    dataIndex: 'symbol',
                    key: 'symbol',
                },{
                    title: 'Side',
                    dataIndex: 'side',
                    key: 'side',
                },{
                    title: 'Price',
                    dataIndex: 'entry_price',
                    key: 'entry_price',
                    render: (text) => <Currency quantity={text} currency="USD" />,
                    sorter: (a, b) => a.entry_price - b.entry_price,
                },{
                    title: 'Qty',
                    dataIndex: 'size',
                    key: 'size',
                },{
                    title: 'PNL',
                    dataIndex: 'unrealised_pnl_usd',
                    key: 'unrealised_pnl_usd',
                    render: (text, record) => <><Currency quantity={text} currency="USD" /> ({record.unrealised_pnl})</>,
                },{
                    title: 'PNL day',
                    dataIndex: 'realised_pnl_usd',
                    key: 'realised_pnl_usd',
                    render: (text, record) => <><Currency quantity={text} currency="USD" /> ({record.realised_pnl})</>,
                },{
                    title: 'PNL total',
                    dataIndex: 'cum_realised_pnl_usd',
                    key: 'cum_realised_pnl_usd',
                    render: (text, record) => <><Currency quantity={text} currency="USD" /> ({record.cum_realised_pnl})</>,
                },{
                    title: 'Date',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (text) => {return text ? moment(text).format("MMMM Do YYYY, h:mm:ss") : null},
                    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
                }
            ]} loading={this.state.loading} />
        </>
    );

    }
}

export default Sales;