import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select
} from 'antd';
import moment from 'moment';
import SelectFromUrl from '../../components/SelectFromUrl';
import Orders from '../../components/Orders';
import History from '../../components/History';
import Transactions from './Transactions';

import Zenfy from "../../libs/Zenfy";

const { Option } = Select;

class NormalInvoiceForm  extends Component {

    constructor(props) {
        super(props);

        if(props.invoice && props.invoice.terms == 0){
            props.invoice.terms = "0";
        }

        this.state = {
            invoice: props.invoice,
            loading: false,
            selectedRowKeys: [],
        }

        this.ordersRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.invoice.id !== this.state.invoice.id){

            if(nextProps.invoice && nextProps.invoice.terms == 0){
                nextProps.invoice.terms = "0";
            }

            this.setState({
                invoice: nextProps.invoice,
                selectedRowKeys: [],
            });

        }

    }


    /*onSave = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              this.addinvoice(values);
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.addinvoice(values);
          }
        });
    }*/

    productOnChange = (item) => {
        this.setState((prevState, props) => ({
            invoice: {
                ...prevState.invoice,
                amount: item.price,
                ends_at: item.months > 0 ? moment().add(item.months, 'months').format('YYYY-MM-DD HH:mm:ss') : undefined,
            }
        }));
    }

    saveInvoice = () => {

        this.setState({
            loading: true
        });

        let values = {};

        let orders = [];
        let totalAmount = 0;

        let client_id = 0;

        this.ordersRef.current.state.selectedRowKeys.map((row) => {
            orders.push({
                order_id: this.ordersRef.current.state.rows[row].id,
               // amount: this.ordersRef.current.state.rows[row].amount,
                org_id: localStorage.orgId ? localStorage.orgId : 1,
            });

            client_id = this.ordersRef.current.state.rows[row].client_id;
            totalAmount += this.ordersRef.current.state.rows[row].amount;
        });

        values.id = this.state.invoice.id ? this.state.invoice.id : null;
        values.orders = orders;
        values.org_id = localStorage.orgId ? localStorage.orgId : 1;
        //values.amount = totalAmount;
        values.client_id = this.state.invoice.client_id ? this.state.invoice.client_id : client_id;
        values.terms = !this.state.invoice.terms ? 30 : this.state.invoice.terms;
        //values.due_at = moment().add('days', values.terms).format('YYYY-MM-DD HH:mm:ss');
        values.due_at = this.state.invoice.due_at ? this.state.invoice.due_at : moment().add('days', values.terms).format('YYYY-MM-DD HH:mm:ss');
        
        Zenfy.post('invoices', values).then((res) => {

            let invoice = this.state.invoice;
          
            invoice.id = res.data.invoice_id ? res.data.invoice_id : res.data;
            invoice.client_id = values.client_id;

            this.setState({
                invoice,
                loading: false,
            });

            notification.success({
                message: `Invoice saved with success!`,
                placement: 'bottomLeft',
            });

            this.props.drawer.current.hideDrawer();
            this.props.drawer.current.load();
        });

    }

    viewInvoice = () => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${this.state.invoice.id}`, '_blank');
        win.focus();
        return false;
    }

    render() {

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Invoice" key="1">
                        <p>Customer</p>
                        <SelectFromUrl 
                            placeholder="Filter list"
                            onChange={(val) => {
                                let invoice = this.state.invoice;
                                    invoice.client_id = val;
                                    this.setState({
                                        invoice,
                                    });
                            }} 
                            value={this.state.invoice.client_id} url={`${localStorage.orgId ? localStorage.orgId : 2}/clients?is_customer=1`} 
                            idName="id" 
                            name="name" 
                            style={{width: '40%', marginBottom: '20px'}} 
                        />
                        
                        <a href="javascript:;" onClick={() => {
                            let invoice = this.state.invoice;
                            invoice.client_id = undefined;
                            this.setState({
                                invoice,
                            });
                        }} style={{marginLeft: 16}}>Restart</a>

                        <div className="clearfix" />

                        <p>Terms</p>
                        <Select
                            placeholder="Terms"
                            style={{width: '40%', marginBottom: '20px'}}
                            onChange={(val) => {
                                let invoice = this.state.invoice;
                                    invoice.terms = val;
                                    invoice.due_at = moment().add('days', val).format('YYYY-MM-DD HH:mm:ss');
                                    this.setState({
                                        invoice,
                                    });
                            }}
                            value={this.state.invoice.terms || this.state.invoice.terms == 0 ? this.state.invoice.terms : '30'}
                        >
                            <Option value="0">No terms (0 days)</Option>
                            <Option value="15">15 days</Option>
                            <Option value="30">30 days</Option>
                            <Option value="45">45 days</Option>
                            <Option value="60">60 days</Option>
                            <Option value="90">90 days</Option>
                        </Select>

                        <p>Due Date</p>

                        <DatePicker 
                            value={this.state.invoice.due_at ? moment(this.state.invoice.due_at, "YYYY-MM-DD HH:mm:ss") : null }
                            onChange={((date) => {
                                let invoice = this.state.invoice;
                                invoice.due_at = date.format('YYYY-MM-DD HH:mm:ss');
                                this.setState({
                                    invoice,
                                });
                            })}
                        />

                        <p style={{width: '70%', marginBottom: '20px', marginTop: '20px'}}>Only shows orders that haven't been <u>INVOICED</u> yet. Or once invoiced, the orders that are attached to the invoice. You can filter the list using the filters on the right. By default it will filter the ones that are "Dropped at Terminal".</p>

                        <Orders clientId={this.state.invoice.client_id} ref={this.ordersRef} selectedOrders={this.state.invoice.orders} />
                    
                        <Button type="primary" htmlType="submit" className="pull-right" loading={this.state.loading} style={{marginTop: 20}} onClick={this.saveInvoice} >
                                Save
                        </Button>

                        {this.state.invoice && this.state.invoice.id ? (
                            <a href="javascript:;" onClick={this.viewInvoice} className="pull-right" style={{margin: 25}}>View Invoice</a>
                        ) : null}
                    </TabPane>
                    <TabPane tab="Transactions" key="2">
                        {this.state.invoice.id ? (
                            <Transactions invoiceId={this.state.invoice.id} key={this.state.invoice.id} clientId={this.state.invoice.client_id} />
                        ) : null}
                    </TabPane>
                    <TabPane tab="History" key="3">
                        <History row={this.state.invoice} key={this.state.invoice.id} type="invoice" />
                    </TabPane>
                </Tabs>
                
            </>
        );
    }
}

export default NormalInvoiceForm;
