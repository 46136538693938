import React, { Component } from "react";
import {
Form, Input, Button, Switch, Select, DatePicker, Tabs, TimePicker, InputNumber, Radio, AutoComplete
} from 'antd';

import moment from 'moment';
import Address from './Address';
import Contacts from './Contacts';
import Prices from './Prices';
import Schedule from './Schedule';
import Products from "./Products";
import SelectFromUrl from '../components/SelectFromUrl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import Labels from '../libs/Labels';

const { Option } = Select;

class NormalDynamicForm  extends Component {

    constructor(props) {
        super(props);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleSubmit();
            }
        });
    }

    switchOnChange = (checked, prop) => {
        this.props.row[prop] = checked ? 1 : 0;
    }

    termsOnChange = (val) => {
        this.props.row.due_at = moment().add('days', val).format('YYYY-MM-DD HH:mm:ss');
    }

    renderInput = (column, row) => {

        if(column.type && React.isValidElement(column.type)){
            return column.type;
        }
        
        switch(column.type) {
            case 'switch':
              return <Switch defaultChecked checked={row[column.dataIndex] == 1 ? true : false} onChange={(checked) => this.switchOnChange(checked, column.dataIndex)} />;
            case 'role':
                return <Select placeholder="Select a role">
                    <Option value="driver">Driver</Option>
                    <Option value="broker">Broker</Option>
                    <Option value="yard">Yard</Option>
                </Select>;
            case 'money_type':
                return <Select
                placeholder="Select a option"
            >
                <Option value="cash">Cash</Option>
                <Option value="e-transfer">E-transfer</Option>
                <Option value="pledge">Pledge</Option>
                <Option value="cheque">Cheque</Option>
                <Option value="credit">Credit Card</Option>
            </Select>;
            case 'type':
                return <Select placeholder="Select a type">
                    <Option value="chassis">Chassis</Option>
                    <Option value="truck">Truck</Option>
                    <Option value="container">Container</Option>
                </Select>;
            case 'status':
                return <Select placeholder="Select status">
                    <Option value="available">Available</Option>
                    <Option value="repair">Needs a repair</Option>
                </Select>;
            case 'terms':
                return <Select
                    placeholder="Terms"
                    onChange={this.termsOnChange}
                >
                    <Option value="0">No terms (0 days)</Option>
                    <Option value="15">15 days</Option>
                    <Option value="30">30 days</Option>
                    <Option value="45">45 days</Option>
                    <Option value="60">60 days</Option>
                    <Option value="90">90 days</Option>
                </Select>;
            case 'gender': 
                return <Select
                placeholder={Labels.get("Select a gender")}
            >
                <Option value="m">{Labels.get("Male")}</Option>
                <Option value="f">{Labels.get("Female")}</Option>
                <Option value="c">{Labels.get("Prefer not to answer")}</Option>
            </Select>;
            case 'lang': 
                return <Select
                placeholder={Labels.get("Select a language")}
            >
                <Option value="en">{Labels.get("en")}</Option>
                <Option value="fr">{Labels.get("fr")}</Option>
            </Select>;
            case 'date': 
                return <DatePicker />;
            case 'date_time': 
                return <DatePicker showTime={{minuteStep: 15, format: 'HH:mm'}} format={"YYYY-MM-DD HH:mm"} />;
            case 'time':
                return <TimePicker format={'HH:mm'} minuteStep={15} />;
            case 'address': 
                return <Address />;
            case 'contact': 
                return <Contacts client={this.props.row} forceSmall />;
            case 'prices': 
                return <Prices client={this.props.row} forceSmall />;
            case 'schedule': 
                return <Schedule product={this.props.row} forceSmall />;
            case 'product': 
                return <Products order={this.props.row} forceSmall />;
            case 'select_url':
                return <SelectFromUrl {...column.params} />;
            case 'text':
                return <Input.TextArea rows="10" />;
            case 'html':
                return <ReactQuill theme="snow" rows={15} />;
            case 'week':
                return <Select placeholder="Select a day">
                    <Option value="1">Monday</Option>
                    <Option value="2">Tuesday</Option>
                    <Option value="3">Wednesday</Option>
                    <Option value="4">Thursday</Option>
                    <Option value="5">Friday</Option>
                    <Option value="6">Saturday</Option>
                    <Option value="7">Sunday</Option>
                </Select>;
            case 'number':
                return <InputNumber />;
            case 'autocomplete':
                    return <AutoComplete 
                        dataSource={column.filters}
                        filterOption={(inputValue, option) =>
                            option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />;
            case 'yesno':
                return <Radio.Group name="radiogroup">
                    <Radio value={1}>{Labels.get("Yes")}</Radio>
                    <Radio value={0}>{Labels.get("No")}</Radio>
                </Radio.Group>;
            default:
              return <Input />;
        }
    }

    getFormItem = (column) => {

        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        if(column.type == 'date'){
            this.props.row[column.dataIndex] = this.props.row[column.dataIndex] ? moment(this.props.row[column.dataIndex], "YYYY-MM-DD HH:mm:ss") : null;
        }else if(column.type == 'time'){
            this.props.row[column.dataIndex] = this.props.row[column.dataIndex] ? moment(this.props.row[column.dataIndex], "HH:mm") : null;
        }else if(column.type == 'week' && this.props.row[column.dataIndex]){
            this.props.row[column.dataIndex] = "" + this.props.row[column.dataIndex];
        }else if(column.type == 'terms' && !this.props.row[column.dataIndex]){
            this.props.row[column.dataIndex] = "0";
            this.props.row.due_at = moment().add('days', 0).format('YYYY-MM-DD HH:mm:ss');
        }

        if(this.props.row[column.dataIndex] == undefined && (column.key == 'active' || column.key == 'include')){
            this.props.row[column.dataIndex] = 1;
        }

        

        /*let checked = {};
        if(column.type == 'switch'){
            checked = {valuePropName: 'checked'};
        }*/
        let displayVal = '';
        if(column.key == 'system' || column.hide){
            displayVal = 'none';
        }
        
        return <Form.Item
            {...formItemLayout}
            label={column.title}
            key={column.key}
            style={{display: displayVal}}
            help={column.help}
            className={`${column.key}_label`}
        >
            {getFieldDecorator(column.dataIndex, {
                initialValue: this.props.row[column.dataIndex],
                rules: [
                    { required: column.required }
                ]
            })(
                this.renderInput(column, this.props.row)
            )}

        </Form.Item>;
    }

    render() {
        
        let tabs = [];

        this.props.keys.map((column) => {
            if(column.key != 'action' && column.type != 'skip'){
                if(column.tab){
                    if(!tabs[column.tab]){
                        tabs[column.tab] = [];
                    }

                    tabs[column.tab].push(column);
                }
            }
        });

        let formItems = null;

        if(Object.keys(tabs).length == 0){
            formItems = this.props.keys.map((column) => {
                if(column.key != 'action' && column.type != 'skip'){
                    return this.getFormItem(column);
                }
            });
        }else{
            formItems = <Tabs defaultActiveKey="0">
                {Object.keys(tabs).map((key, index) => {
                    return <Tabs.TabPane tab={key} key={index}>
                    {tabs[key].map((formItem) => {
                        return this.getFormItem(formItem);
                    })}
                  </Tabs.TabPane>;
                })}
                
            </Tabs>;
        }

        return (
            <>
                <Form className="dynamo-form" onSubmit={this.handleSubmit}>
                    
                    {formItems}

                    {!this.props.hideFooter ? (
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="pull-right" loading={this.props.loading}>
                                Save
                            </Button>

                            <a href="javascript:;" className="pull-right" style={{margin: '-4px 20px 0px 0px'}} onClick={this.props.hideDrawer}>Close</a>
                        </Form.Item>
                    ) : null}
                </Form>
            </>
        );
    }
}

const WrappedDynamicForm = Form.create({ name: 'dynamo_form' })(NormalDynamicForm);

export default WrappedDynamicForm;
