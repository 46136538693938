import React, { Component } from "react";

import {
    Layout, Menu, Breadcrumb, Icon, Alert, Tooltip, Button, message
} from 'antd';

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import Transactions from './Transactions';
import Invoices from './Invoices';
import Cheques from './Cheques';
import Expenses from './Expenses';


const { SubMenu } = Menu;
const {
  Header, Content, Footer, Sider,
} = Layout;

class Accounting extends Component {

    constructor(props) {
        super(props);

        const url = props.location.pathname.split('/');

        this.state = {
            selectedTab: url[url.length-1]//url.length > 3 ? url[url.length-1] : 'jobs'
        }
    }

    render() {
        return (
            <>
                <Layout style={{ padding: '24px 0', background: '#fff' }}>

                    <HashRouter>
                        <Sider width={200} style={{ background: '#fff'}}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={this.state.selectedTab}
                                style={{ height: '100%' }}
                            >
                                <Menu.Item key="invoices"><NavLink to={`/accounting/invoices`}>Invoices</NavLink></Menu.Item>
                                <Menu.Item key="transactions"><NavLink to={`/accounting/transactions`}>Transactions</NavLink></Menu.Item>
                                <Menu.Item key="cheques"><NavLink to={`/accounting/cheques`}>Cheques</NavLink></Menu.Item>
                                <Menu.Item key="expenses"><NavLink to={`/accounting/expenses`}>Expenses</NavLink></Menu.Item>
                            </Menu>
                        </Sider>
                    </HashRouter>

                    <Content style={{ padding: '0 24px' }}>
                        <Route exact path={`/accounting/invoices`} component={Invoices} />
                        <Route exact path={`/accounting/transactions`} component={Transactions} />
                        <Route exact path={`/accounting/cheques`} component={Cheques} />
                        <Route exact path={`/accounting/expenses`} component={Expenses} />
                    </Content>

                </Layout>
            </>
        )
    }
}

export default Accounting;