import React, { Component } from "react";
import { Modal, Form, Input, Checkbox, Upload, Select, Icon, Button } from 'antd';

import Zenfy from '../libs/Zenfy';

const { Option } = Select;

const UploadModalForm = Form.create({ name: 'form_upload_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();

        if(this.props.container){
            file = new File([file], this.props.container + '_' + file.name);
        }
        
        formData.append('file', file);

        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);
    
            /*notification.success({
                message: `File uploaded with success!`,
                placement: 'bottomLeft',
            });
    
            Zenfy.post('files', {
              order_id: this.props.orderId,
              org_id: localStorage.orgId ? localStorage.orgId : 2,
              file_name: `${Zenfy.getRoot()}uploads/${res.data}`
            }).then(res => {
                this.load();
            });*/
        });
    }

    render() {
      const { visible, onCancel, onCreate, form, record } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title={this.props.title ? this.props.title : "Upload"}
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
                <Form.Item label="Files">
                    {getFieldDecorator('images', {
                        rules: [{ required: false, message: 'Please select your job!' }],
                    })(
                    <Upload multiple customRequest={(e) => this.uploadFile(e)} className="full" style={{width: '100%'}}>
                        <Button type="dashed" style={{ marginBottom: 16 }}>
                            <Icon type="plus" /> Upload
                        </Button>
                    </Upload>,
                )}
                </Form.Item>
                {this.props.container ? (
                  <Form.Item label="Type">
                    {getFieldDecorator('type', {
                        rules: [{ required: true, message: 'Please select the type' }],
                        initialValue: record.type,
                    })(
                    <Select>
                        <Option value="pod">Pod</Option>
                        <Option value="interchanges">Interchanges</Option>
                        <Option value="other">Other</Option>
                    </Select>,
                )}
                </Form.Item>
                ) : null}
            </Form>
        </Modal>
      );
    }
  },
);

export default UploadModalForm;