import React, { Component } from 'react';
import { Select, Modal, Button, Form, Input, Row, Col } from 'antd';

import Zenfy from "../libs/Zenfy";

const { Option } = Select;
const AddressForm = Form.create({ name: 'address_form' })(
    // eslint-disable-next-line
    class extends React.Component {
      render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
        };
        return (
            <Form layout="vertical">

            <Row gutter={24}>

                <Col span={12}>

                    <Form.Item 
                        {...formItemLayout}
                        label="Company Name">
                        {getFieldDecorator('name', {
                        //initialValue: this.props.row.address,
                        rules: [{ required: true }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item 
                        {...formItemLayout}
                        label={this.props.is_customer ? "Accountant Email" : "Email"}>
                        {getFieldDecorator('email', {
                        //initialValue: this.props.row.country,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Input />)}
                    </Form.Item>  

                    <Form.Item 
                        {...formItemLayout}
                        label="Phone">
                        {getFieldDecorator('telephone', {
                        //initialValue: this.props.row.state,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Input />)}
                    </Form.Item>  

                    <Form.Item 
                        {...formItemLayout}
                        label="Contact Name">
                        {getFieldDecorator('contact', {
                        //initialValue: this.props.row.postal,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Input />)}
                    </Form.Item>

                </Col>

                <Col span={12}>

                    <Form.Item 
                        {...formItemLayout}
                        label="Address">
                        {getFieldDecorator('address', {
                        //initialValue: this.props.row.address,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item 
                        {...formItemLayout}
                        label="City">
                        {getFieldDecorator('city', {
                        //initialValue: this.props.row.country,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Input />)}
                    </Form.Item>  

                    <Form.Item 
                        {...formItemLayout}
                        label="Country">
                        {getFieldDecorator('country', {
                        //initialValue: this.props.row.country,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Select
                            placeholder="Select a country"
                        >
                            <Option value="Canada">Canada</Option>
                            <Option value="United States">USA</Option>
                        </Select>)}
                    </Form.Item>  

                    <Form.Item 
                        {...formItemLayout}
                        label="Province / State">
                        {getFieldDecorator('state', {
                        //initialValue: this.props.row.state,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Select
                            placeholder="Select a state"
                        >
                            <Option value="Quebec">Quebec</Option>
                            <Option value="Ontario">Ontario</Option>
                            <Option value="New Brunswick">New Brunswick</Option>
                            <Option value="Alberta">Alberta</Option>
                            <Option value="Manitoba">Manitoba</Option>
                            <Option value="British Columbia">British Columbia</Option>
                            <Option value="Saskatchewan">Saskatchewan</Option>
                            <Option value="Nova Scotia">Nova Scotia</Option>
                            <Option value="Newfoundland and Labrador">Newfoundland and Labrador</Option>
                            <Option value="Prince Edward Island">Prince Edward Island</Option>
                            <Option value="Northwest Territories">Northwest Territories</Option>
                            <Option value="Nunavut">Nunavut</Option>
                            <Option value="Yukon">Yukon</Option>
                        </Select>)}
                    </Form.Item>  

                    <Form.Item 
                        {...formItemLayout}
                        label="Postal Code">
                        {getFieldDecorator('postal', {
                        //initialValue: this.props.row.postal,
                        rules: [{ required: false, message: 'Please input the title of collection!' }],
                        })(<Input />)}
                    </Form.Item>

              </Col>

              </Row>

            </Form>
        );
      }
    },
);  

class CompanyModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showModal: props.showModal,
            modalLoading: false,
        }

        this.form = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.showModal !== this.state.showModal){

            //this.props.form.resetFields();

            this.setState({
                showModal: nextProps.showModal
            });

        }

    }

    addCompany = (values) => {
        values.org_id = localStorage.orgId ? localStorage.orgId : 2;

        if(this.props.is_customer){
            values.is_customer = 1;
        }

        Zenfy.post('clients', values).then((res) => {
            
            if(this.props.afterAddCallBack){
                this.props.afterAddCallBack(res.data);
            }

            values.id = res.data;

            this.afterSave(values);
        });
    }

    afterSave = (data) => {
        if(data.address && data.address != ''){
            Zenfy.getLatLng(`${data.address}, ${data.city}, ${data.state} ${data.postal}, Canada`).then((res) => {
                if(res.data.results && res.data.results[0]){
                    let postData = res.data.results[0].geometry;
                    postData.id = data.id;
                    postData.org_id = data.org_id;
    
                    Zenfy.post('clients', postData);
                }
            });
        }   
    }

    handleModalOk = () => {
        this.form.current.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.addCompany(values);
                
                this.setState({
                    showModal: false,
                })
            }
        });
    }

    showModal = () => {
        this.setState({
            showModal: true,
        });
    }

    render() {

        return (
            <>
                <Modal
                    title="Add new company"
                    visible={this.state.showModal}
                    onOk={this.handleModalOk}
                    okText="Add"
                    onCancel={()=> {
                        if(this.props.afterAddCallBack){
                            this.props.afterAddCallBack();
                        }
                        
                        this.setState({
                            showModal: false,
                        });
                    }}
                    okButtonProps={{loading: this.state.modalLoading}}
                >   
                    <AddressForm ref={this.form} is_customer={this.props.is_customer} />
                </Modal>  
            </>
        );

    }
}

export default CompanyModal;