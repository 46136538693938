import axios from 'axios';
import React from "react";
import moment from 'moment';

import { Badge } from 'antd';

const Zenfy = {};

Zenfy.getRoot = (path) => {
    return window.location.href.indexOf('localhost') !== -1 ? `http://localhost/zenfy/api/${path ? path : 'public'}/` : `https://zenfy.co/api/${path ? path : 'public'}/`;
}

Zenfy.inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

Zenfy.getFrontEndRoot = () => {
    return window.location.href.indexOf('localhost') !== -1 ? `http://localhost:3006/#/` : `https://zenfy.co/#/`;
}

Zenfy.capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : false;
}

Zenfy.replacer = (tpl, data) => {
    var re = /\$\(([^\)]+)?\)/g, match;
    while(match = re.exec(tpl)) {
      tpl = tpl.replace(match[0], data[match[1]] ? data[match[1]] : '');
      re.lastIndex = 0;
    }
    return tpl;
}

Zenfy.hideKeyboard = () =>{
    //this set timeout needed for case when hideKeyborad
    //is called inside of 'onfocus' event handler
    setTimeout(function() {
  
      //creating temp field
      var field = document.createElement('input');
      field.setAttribute('type', 'text');
      //hiding temp field from peoples eyes
      //-webkit-user-modify is nessesary for Android 4.x
      field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
      document.body.appendChild(field);
  
      //adding onfocus event handler for out temp field
      field.onfocus = function(){
        //this timeout of 200ms is nessasary for Android 2.3.x
        setTimeout(function() {
  
          field.setAttribute('style', 'display:none;');
          setTimeout(function() {
            document.body.removeChild(field);
            document.body.focus();
          }, 14);
  
        }, 200);
      };
      //focusing it
      field.focus();
  
    }, 50);
}

Zenfy.getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

Zenfy.addLineBreaks = string =>
    string.split('\n').map((text, index) => (
        <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
        </React.Fragment>
));

Zenfy.insertInArray = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index)
];

Zenfy.getRanges = () => {
    return {
        'Today': [moment(), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
        'Last 3 Months': [moment().startOf('month').subtract(2, 'month'), moment().endOf('month')],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
        'All Time': [moment().startOf('year').subtract(10, 'years'), moment()],
    };
}

Zenfy.renderStatus = (record, text) => {

  if(!record.status){
      record.status = "tracing";
  }

  let status = {
      "tracing": {
          text: "Tracing",
          color: "#ff00f2",
      },
      "ready_terminal": {
          text: "Ready at terminal",
          color: "red",
      },
      "prepull_yard": {
            text: "In yard",
            color: "darkorange",
        },
      "dropped_customer": {
          text: "Dropped at customer",
          color: "orange",
      },
      "ready_pickup": {
          text: "Ready at customer",
          color: "blue",
      },
      "customer_yard": {
            text: "In yard",
            color: "darkorange",
        },
      "dropped_terminal": {
          text: "Dropped at terminal",
          color: "purple",
      },
      "invoiced": {
            text: "Invoiced",
            color: "lime",
        },
  };
  
  return !text ? <Badge color={status[record.status].color} text={status[record.status].text} /> : status[record.status].text;
} 

Zenfy.address = (leg, link) => {
  return <>
      <p>{leg.delivery_client_name}</p>
      <p>
        {link ? (
            <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${leg.delivery_address}, ${leg.delivery_city}, ${leg.delivery_postal}`}>{leg.delivery_address}, {leg.delivery_city}, {leg.delivery_postal}</a>
        ) : 
        <>{leg.delivery_address}, {leg.delivery_city}, {leg.delivery_postal}</>}
      </p>
      <p><a href={`tel:${leg.delivery_telephone}`}>{leg.delivery_telephone}</a></p>
    </>;
}

Zenfy.minTwoDigits = (n) => {
    return (n < 10 ? '0' : '') + n;
}

Zenfy.normalizeInput = (number) => {
    // return nothing if no value
    if (!number) return number; 
  
    return `${number.substring(0, 2)} (${number.substring(2, 5)}) ${number.substring(5, 8)} ${number.substring(8, number.length)}`;
};

Zenfy.isType = (type, val) => !!(val.constructor && val.constructor.name.toLowerCase() === type.toLowerCase());

Zenfy.sendEmailStatus = (id, emails, status) => {
    Zenfy.get(`pod/${id}`,{
        emails,
        status,
        email: true,
    });
}

Zenfy.roundToTwo = (num) => {    
    return +(Math.round(num + "e+2")  + "e-2");
}

Zenfy.validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

Zenfy.get = (url, params) => {

    let headers = {
        Authorization: localStorage.zenfy_jwt,
        "Content-Language": localStorage.Lang ? localStorage.Lang : 'en'
    };

    if(url && url.indexOf('https://') == -1 && url.indexOf('http://') == -1){
        url = `${Zenfy.getRoot()}${url}`;
    }

    return axios.get(url,{
        params,
        headers
    }).catch(error => {
        if(error.response && error.response.status){
            if(error.response.status == 401){
                console.log("Pas autorise!")
                delete localStorage.zenfy_jwt;
                delete localStorage.orgId;
                delete localStorage.zenfy_role;
                delete localStorage.url;
                delete localStorage.clientOrgId;
                if(localStorage.type){
                    delete localStorage.type;
                }
                if(localStorage.org){
                    delete localStorage.org;
                }
                window.location.reload();
            }
        }
    });
}

Zenfy.post = (url, params) => {

    let headers = {
        Authorization: localStorage.zenfy_jwt,
        "Content-Language": localStorage.Lang ? localStorage.Lang : 'en'
    };

    return axios.post(`${Zenfy.getRoot()}${url}`, params, {
        headers
    });
}

Zenfy.delete = (url, data) => {

    let headers = {
        Authorization: localStorage.zenfy_jwt
    };

    return axios.delete(`${Zenfy.getRoot()}${url}`, {
        headers,
        data,
      });
}



Zenfy.setKeysToList = (list) => {

    list.map((x, i) => {
      x.key = i;
    });
  
    return list;
}

Zenfy.getDayOfTheWeek = (day) => {

    day = parseInt(day);

    switch(day){
        case 1:
            day = 'Monday';    
        break;
        case 2: 
            day = 'Tuesday';  
        break;
        case 3: 
            day = 'Wednesday';  
        break;
        case 4: 
            day = 'Thursday';  
        break;
        case 5: 
            day = 'Friday';  
        break;
        case 6: 
            day = 'Saturday';  
        break;
        case 7: 
            day = 'Sunday';  
        break;
    }

    return day;
}

Zenfy.getCategoriesFromClients = (clients) => {
    
    let cats = [];
    
    clients.map((client) => {
        client.invoices.map((invoice) => {
            if(invoice.active && invoice.active == 1){
                if(!invoice.category){
                    invoice.category = 'Undefined Category';
                }
    
                //let catFound = cats.some(cat => cat.value === invoice.category);
                let catFound = cats.find(cat => {
                    return cat.value === invoice.category
                });
    
                if(invoice.name === null){
                    invoice.name = '';
                }
    
                if(invoice.category === null){
                    invoice.category = '';
                }
    
                if(!catFound){
                    cats.push({
                        text: invoice.category,
                        value: invoice.category,
                        children: [{
                            text: invoice.name,
                            value: invoice.name,
                        }]
                    });
                }else{
                    let childFound = catFound.children.find(child => {
                        return child.value === invoice.name
                    });
    
                    if(!childFound){
                        catFound.children.push({
                            text: invoice.name,
                            value: invoice.name,
                        });
                    }
                }
            }
        });
    });

    return cats;
}

Zenfy.normalizePostalCode = (postalCode) => {

    if (!postalCode) return postalCode; 

    var regex = /^([abceghjklmnprstvwxyz][0-9][abceghjklmnprstvwxyz])([0-9][abceghjklmnprstvwxyz][0-9])$/i;

    var normalized = postalCode.replace(/[^a-z0-9]/gi, '').toUpperCase();
    
    var matches = normalized.match(regex);

    if (matches) {
        return matches[1] + ' ' + matches[2];
    }else{
        return normalized;
    }
}

Zenfy.normalizeInputPhone = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value; 

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length; 

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue; 

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`; 

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
  }
};

Zenfy.createOrderInvoice = (client_id, org_id, product_id, amount, callback) => {
    Zenfy.post('orders', {
        org_id,
        client_id,
      }).then((res) => {
        let order_id = res.data;

        Zenfy.post('products_orders', {
          org_id,
          order_id,
          product_id,
          amount,
        }).then((res) => {

            let product_order_id = res.data;

            Zenfy.post('invoices', {
                client_id,
                org_id,
            }).then((res) => {
              let invoice_id = res.data;

              // create invoice_orders
              Zenfy.post('invoices_orders', {
                org_id,
                invoice_id,
                order_id,
              }).then((res) => {

                if(callback){
                    callback(invoice_id, product_order_id);
                }
                  
              });

            });

        });
        
      });
}

Zenfy.getFirstName = function(str) {
    if(!str){
        return;
    }
    var arr = str.split(' ');
    if( arr.length === 1 ) {
        return arr[0];
    }
    return arr.slice(0, -1).join(' '); // returns "Paul Steve"
}

Zenfy.getLastName = function(str, ifNone) {
    if(!str){
        return;
    }
    var arr = str.split(' ');
    if(arr.length === 1) {
        return ifNone || "";
    }
    return arr.slice(-1).join(' ');
}

Zenfy.exportDataToCsv = (stockData, fileName, columnDelimiter) => {
    var data, filename, link;
    var csv = Zenfy.convertArrayOfObjectsToCSV({
        data: stockData,
        columnDelimiter
    });
    if (csv == null) return;

    filename = fileName + '.csv' || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
}

Zenfy.convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
        ctr = 0;
        keys.forEach(function(key) {
            if (ctr > 0) result += columnDelimiter;
            result += item[key] ? item[key].toString().replace(/[&\/\\#,+()$~%'"*?<>{}]/g,' ') : "";
            ctr++;
        });
        result += lineDelimiter;
    });

    var BOM = "\uFEFF";
    var csvContent = BOM + result;

    return csvContent;
}

Zenfy.convertToCsv = (fName, rows) => {
    var csv = '';
    console.log(rows.length);
    for (var i = 0; i < rows.length; i++) {
        var row = rows[i];
        for (var j = 0; j < row.length; j++) {
            var val = row[j] === null ? '' : row[j].toString();
            val = val.replace(/\t/gi, " ");
            if (j > 0)
                csv += '\t';
            csv += val;
        }
        csv += '\n';
    }

    // for UTF-16
    var cCode, bArr = [];
    bArr.push(255, 254);
    for (var i = 0; i < csv.length; ++i) {
        cCode = csv.charCodeAt(i);
        bArr.push(cCode & 0xff);
        bArr.push(cCode / 256 >>> 0);
    }

    var blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fName);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = window.URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }
}


Zenfy.getCategoriesFilters = (list, key, valueKey) => {
    let cats = [];

    list.map((obj, i) => {
  
      let found = false;

      //console.log(obj);

      if(!obj[key]){
         
      }

      for (var i = 0;i < cats.length; i++){
        if(cats[i].text == obj[key]){
          found = true;
          break;
        }
      }
  
      if(!found && obj[key]){
        let valObj = {text: obj[key], value: obj[key]};
  
        if (valueKey){
          valObj.id = obj[valueKey];
        }
  
        cats.push(valObj);
      }
  
    });
  
    return cats;
}

Zenfy.scrollTo = (element, to, duration) => {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        Zenfy.scrollTo(element, to, duration - 10);
    }, 10);
}

Zenfy.copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text); 

  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      } finally {
          document.body.removeChild(textarea);
      }
  }
}

Zenfy.getLatLng = (address) => {
    return axios.get(`https://api.opencagedata.com/geocode/v1/json?key=1ccebccf9f4249a2890213e746f50a67&pretty=1`,{
        params: {q: address}
    });
}

Zenfy.getCountries = () => {

    return [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas (the)",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory (the)",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands (the)",
        "Central African Republic (the)",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands (the)",
        "Colombia",
        "Comoros (the)",
        "Congo (the Democratic Republic of the)",
        "Congo (the)",
        "Cook Islands (the)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic (the)",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (the) [Malvinas]",
        "Faroe Islands (the)",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories (the)",
        "Gabon",
        "Gambia (the)",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (the)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic (the)",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands (the)",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova (the Republic of)",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands (the)",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger (the)",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands (the)",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines (the)",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation (the)",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan (the)",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands (the)",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates (the)",
        "United Kingdom of Great Britain and Northern Ireland (the)",
        "United States Minor Outlying Islands (the)",
        "United States of America (the)",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
    ];    
}

export default Zenfy;