import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select
} from 'antd';
import moment from 'moment';
import SelectFromUrl from '../components/SelectFromUrl';
import Products from '../components/Products';

const { Option } = Select;

class NormalInvoiceForm  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            invoice: props.invoice,
            loading: false,
            showProrata: false,
            forceMonthly: false,
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.invoice.id !== this.state.invoice.id){

            this.props.form.resetFields();

            this.setState({
                invoice: nextProps.invoice
            });

        }

    }


    /*onSave = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              this.addinvoice(values);
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.addinvoice(values);
          }
        });
    }*/

    productOnChange = (item) => {
        this.setState((prevState, props) => ({
            invoice: {
                ...prevState.invoice,
                amount: Math.abs(item.price + (item.taxes ? item.price * 0.14975 : 0)).toFixed(2),
                ends_at: item.months > 0 ? moment().add(item.months, 'months').format('YYYY-MM-DD HH:mm:ss') : undefined,
            }
        }));
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>

                <Form className="invoice-form">

                    {!this.props.many ? (
                        <Form.Item
                            {...formItemLayout}
                            label={localStorage.type && localStorage.type == 'crm' ? 'Product' : 'Activity'}
                            >
                            {getFieldDecorator('product_id', {
                                initialValue: this.state.invoice.product_id,
                                rules: [
                                    { required: true, message: 'Product is required' }
                                ],
                            })(
                                <SelectFromUrl 
                                    itemChange={this.productOnChange} 
                                    url={`${localStorage.orgId ? localStorage.orgId : 1}/products`} 
                                    idName="id" 
                                    name="name" 
                                    category='category'
                                />
                            )}
                        </Form.Item>
                    ) : (
                        <Form.Item
                            {...formItemLayout}
                            label={localStorage.type && localStorage.type == 'crm' ? 'Products' : 'Activity'}
                            >
                            {getFieldDecorator('products', {
                                initialValue: this.state.invoice.products,
                                rules: [
                                    { required: false, message: 'Product is required' }
                                ],
                            })(
                                <Products order={this.state.invoice} many />
                            )}
                        </Form.Item>
                        
                    )}

                    {localStorage.type && localStorage.type == 'crm' ? (
                        null
                    ) : (
                        <>
                            <Checkbox 
                            style={{marginBottom: 30}} 
                            onChange={({ target })=>{this.setState({
                                showProrata: target.checked,
                            })}}>Pro rata</Checkbox>
                            {this.state.showProrata ? (
                                <Form.Item
                                    {...formItemLayout}
                                    label="Dates"
                                >
                                    {getFieldDecorator('range', {
                                        initialValue: [moment(), moment().date(1).add((moment().date() > 14 ? 1 : 0), "months").add(14,"days")],
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Amount is required',
                                            },
                                        ],
                                    })(
                                        <DatePicker.RangePicker  onChange={(datesMoment, dates) => {
                                            let start = datesMoment[0];
                                            let end = datesMoment[1];

                                            if(start && end){
                                                let days = start.diff(end, 'days');

                                                let amount = this.state.invoice.amount * days / 30;

                                                this.setState((prevState, props) => ({
                                                    invoice: {
                                                        ...prevState.invoice,
                                                        amount: Math.abs(amount).toFixed(2),
                                                    }
                                                }));

                                                this.props.form.setFieldsValue({
                                                    amount: Math.abs(amount).toFixed(2),
                                                });
                                            }
                                        }}/>
                                    )}
                                </Form.Item>
                        ) : null}
                        <Form.Item 
                            {...formItemLayout}
                        >
                            {getFieldDecorator('force_monthly_price', {
                                valuePropName: 'checked',
                                initialValue: this.state.invoice.force_monthly_price == 1,
                            })(
                                <Checkbox>
                                    Force Monthly Price
                                </Checkbox>,
                            )}
                        </Form.Item>
                        </>
                    )}
                    
                    {!this.props.many ? (
                    <Form.Item
                        {...formItemLayout}
                        label="Amount"
                        >
                        {getFieldDecorator('amount', {
                            initialValue: this.state.invoice.amount,
                            rules: [
                                {
                                    required: true,
                                    message: 'Amount is required',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    ) : null}
                    
                    {localStorage.type && localStorage.type == 'crm' ? (
                        null
                    ) : (
                        <>
                            <Form.Item
                                {...formItemLayout}
                                label="Drop Ins"
                                >
                                {getFieldDecorator('count', {
                                    initialValue: this.state.invoice.count,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Amount is required',
                                        },
                                    ],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        {...formItemLayout}
                        label="Due at"
                        >
                        {getFieldDecorator('due_at', {
                            initialValue: this.state.invoice.due_at ? moment(this.state.invoice.due_at, "YYYY-MM-DD HH:mm:ss") : null,
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <DatePicker />
                        )}
                    </Form.Item>
                    
                    <Form.Item
                        {...formItemLayout}
                        label="Ends at"
                        >
                        {getFieldDecorator('ends_at', {
                            initialValue: this.state.invoice.ends_at ? moment(this.state.invoice.ends_at, "YYYY-MM-DD HH:mm:ss") : null,
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(
                            <DatePicker />
                        )}
                    </Form.Item>
                 
                </Form>

            </>
        );
    }
}

const WrappedInvoiceForm = Form.create({ name: 'invoice_form' })(NormalInvoiceForm);

export default WrappedInvoiceForm;
