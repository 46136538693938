import React, { Component } from "react";
import {
    Table, Button, Icon, notification, Upload, Menu, Dropdown
} from 'antd';

import UploadModalForm from '../components/UploadModalForm';

import moment from 'moment';

import Zenfy from '../libs/Zenfy';

class Files extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            loading: false,
            showUploadModal: false,
            selectedRecord: {},
            dynamicProp: props.dynamicProp ? props.dynamicProp : "order_id",
        }

        this.uploadRef = React.createRef();
    }

    componentDidMount() {

        this.load();

    }

    load = () => {
        this.setState({
            loading: true,
        });

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/files?${this.state.dynamicProp}=${this.props[this.state.dynamicProp] ? this.props[this.state.dynamicProp] : ''}`).then((res) => {

            this.setState({
                files: Zenfy.setKeysToList(res.data),
                loading: false,
            });

        }).catch(error => {
            
        });
    }

    uploadFile = ({file, onSuccess}) => {
        let formData = new FormData();
        formData.append('file', file);
    
        Zenfy.post('uploadfile', formData).then(res => {
            onSuccess(res.data, file);
    
            notification.success({
                message: `File uploaded with success!`,
                placement: 'bottomLeft',
            });
    
            
        });
    }

    uploadFiles = () => {
        let form = this.uploadRef.current.getForm();

        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            let obj = {};
            obj[this.state.dynamicProp] = this.props[this.state.dynamicProp];


            values.images.fileList.map((file, index) => {
                Zenfy.post('files', {...obj,
                    org_id: localStorage.orgId ? localStorage.orgId : 2,
                    file_name: `${Zenfy.getRoot()}uploads/${file.response}`,
                    type: values.type
                }).then(res => {
                    if(values.images.fileList.length - 1 == index){
                        this.load();
                        this.setState({
                            showUploadModal: false,
                        });
                        form.resetFields();
                        notification.success({
                            message: `File uploaded with success!`,
                            placement: 'bottomLeft',
                        });
                    }
                });
            });
        });
    }

    deleteFile = (record) => {
        Zenfy.delete(`files/${record.id}`).then((res) => {
            this.load();
        });
    }

    render() {

        const columns = [{
            title: 'File Name',
            dataIndex: 'file_name',
            key: 'file_name',
            //...this.getColumnSearchProps('Name'),
            render: (text, record) => <a target="_blank" href={record.file_name}>{text}</a>,
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type !== b.type ? a.type < b.type ? -1 : 1 : 0,
            render: (text, record) => Zenfy.capitalizeFirstLetter(text),
        },{
            title: 'Driver',
            className: 'hide',
            dataIndex: 'driver_name',
            key: 'driver_name',
            sorter: (a, b) => a.driver_name.length - b.driver_name.length,
        },{
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="1" onClick={() => {alert('coming soon. please delete the item and add it again.')}}>Edit</Menu.Item>
                        <Menu.Item key="2" onClick={() => this.deleteFile(record)}>Delete</Menu.Item>
                    </Menu>
                  );
          
                return (<Dropdown overlay={menu}>
                <Button size="small">
                    <Icon type="down" />
                </Button>
                </Dropdown>
                )
             
            },
        }];

        return (
            <>  
                <UploadModalForm 
                    visible={this.state.showUploadModal}
                    title="Upload attachements"
                    onCancel={
                        () => {
                        this.setState({
                            showUploadModal: false,
                        })
                    }}
                    onCreate={this.uploadFiles}
                    record={this.state.selectedRecord}
                    ref={this.uploadRef}
                    container={this.props.container}
                />

                <Button type="dashed" className="pull-right" style={{ marginBottom: 16 }} onClick={() => {
                    this.setState({
                        showUploadModal: true,
                    });
                }}>
                    <Icon type="plus" /> Add
                </Button>

                <div className="clearfix" />
                
                <Table dataSource={this.state.files} columns={columns} loading={this.state.loading} />
            </>
        );
    }
}

export default Files;
