import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Table, Input, DatePicker, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

const { RangePicker } = DatePicker;

class Drivers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            showEmailModal: false,
            selectedInvoice: {},
            start: moment().startOf('week').format('YYYY-MM-DD'),
            end: moment().endOf('week').format('YYYY-MM-DD'),
        }

        this.emailModalRef = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

    }

    load = () => {

        this.setState({
            loading: true,
        });


        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/times?start=${this.state.start}&end=${this.state.end}`).then((res) => {
            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
            });
        });
    }
    
    viewStatement = (record) => {
        var win = window.open(`${Zenfy.getRoot()}${record.org_id}/statements/${record.client_id}`, '_blank');
        win.focus();
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    sendEmail = (record, e) => {

        if(e){
            e.stopPropagation();
        }

        let form = this.emailModalRef.current.getForm();

        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            let emails = values.email.split(',');
    
            Zenfy.get(`${this.state.selectedInvoice.org_id}/statements/${this.state.selectedInvoice.client_id}?email=true`, {
                emails  
            });

            notification.success({
                message: `Sent email with success!`,
                placement: 'bottomLeft',
            });

            form.resetFields();

            this.setState({ showEmailModal: false });
        });

    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => text,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Hours',
            dataIndex: 'hours',
            key: 'hours',
            sorter: (a, b) => a.hours - b.hours
        }/*,{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

            <Menu.Item key="3">
                                <Popconfirm title="Are you sure delete this team?" onConfirm={()=>this.deleteTeam(record)} okText="Yes" cancelText="No">
                                Delete
                                </Popconfirm>
                            </Menu.Item>

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={() => this.viewStatement(record)}>View Statement</Menu.Item>
                  <Menu.Item key="2" onClick={() => {
                      this.setState({
                        showEmailModal: true,
                        selectedInvoice: record,
                      });
                  }}>Send Email To Customer</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
          }*/
    ];

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            return obj.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <Input placeholder={`Search for a Driver`} className="pull-left" style={{width: '400px', marginBottom: 16}} onChange={this.searchOnChange} />
            
            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange} 
                defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                ranges={{
                    Today: [moment(), moment()],
                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                    'Last Week': [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                    'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
                }}
            />

            <Table dataSource={rows} columns={columns} loading={this.state.loading} />
        </>
    );

    }
}

export default Drivers;