import React, { Component } from "react";
import { Modal, Menu, Button, Icon, Dropdown } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Currency from 'react-currency-formatter';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

class Transactions extends Component {

    constructor(props) {
        super(props);
        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    delete = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this transaction? (All information will be lost)',
            okText: 'Yes',
            onOk: () => {
                Zenfy.delete(`transactions/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    render() {
        
        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            sorter: (a, b) => a.id - b.id,
            hide: true,
            //className: 'hide',
        },{
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Invoice Id',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
            hide: this.props.invoiceId,
            type: 'select_url',
            params: {url: `${localStorage.orgId ? localStorage.orgId : 1}/invoices`, idName: "id", name:"name" },
            render: (text) => this.props.invoiceId ? this.props.invoiceId : text,
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            type: 'money_type',
            sorter: (a, b) => a.type.length - b.type.length,
            render: (text) => {return Zenfy.capitalizeFirstLetter(text)},
        },{
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
            sorter: (a, b) => a.reference.length - b.reference.length,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            type: 'date',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                  <Menu.Item key="invoice" onClick={() => this.recordOnClick(record)}>View Invoice</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        },
    ];

        //const form = (<GameForm game={this.drawerRef.current ? this.drawerRef.current.selected : {}} />);

        return (
        <>
            <DrawerTableForm noAdd small={this.props.invoiceId ? true : false} hideFooter modal={this.props.invoiceId ? true : false} columns={columns} ref={this.drawerRef} form={null} type="transaction" load={`transactions${this.props.invoiceId ? `?invoice_id=${ this.props.invoiceId}` : ''}`} />
        </>
        );
    }
}

export default Transactions;