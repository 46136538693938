import React, { Component } from "react";
import QrReader from 'react-qr-reader';
import Zenfy from "../libs/Zenfy";
import { Spin } from 'antd';
import moment from 'moment';
import Emoji from 'react-emojis';
import {
    AutoComplete
} from 'antd';
    

class Scan extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            client: {},
            loading: false,
            status: "",
        }
    }

    componentDidMount() {

        const script = document.createElement("script");
        script.src = process.env.PUBLIC_URL + "/confetti.js";
        script.async = true;
        document.body.appendChild(script);
    
        if(this.props.match.params.org == 'carlsongraciemtl'){
            localStorage.orgId = 1;
        }else if(this.props.match.params.org == 'elf'){
            localStorage.orgId = 3;
        }

        let token = Zenfy.getParameterByName('token');

        if(token){
            token = window.atob(token).split('|');

            if(token[2] && token[2] != ''){
                /*this.setState({
                    productId: parseInt(token[2])
                });*/
                this.newProductId = parseInt(token[2]);
            }

            

        }

        setTimeout(() => {

            window.location.reload();
            
        }, 10*60000);

       
    }

    onScanChange = (name) => {
        if(name == ''){
            this.setState({
                dataSource: [],
            });
            return false;
        }
        Zenfy.get(`${localStorage.orgId}/scanclients/${name}`).then((res) => {
            let names = [];
            this.clients = res.data;
            res.data.map((client) => {
                names.push(client.name);
    
                if(client.name == name){
                    //this.entryLogic(client);
                }
            });

            this.setState({
                dataSource: names,
            });
        })
    }

    onSelect = (name, option) => {
        this.clients.map((client) => {
            if(name == client.name){
                this.entryLogic(client);
            }
        })
    }

    entryLogic = (client) => {
        if(!client.id){
            this.setState({
                loading: false
            });
            return;
        }

        Zenfy.get(`invoices/${client.id}`).then((res) => {

            let activeMembership = null;

            // should be done in the backend
            res.data.map((invoice) => {
                if(invoice.amount && invoice.amount > 0 && invoice.paid >= invoice.amount){
                    invoice.status = "Paid";
                }else if(invoice.amount && invoice.amount > 0) {
                    invoice.status = "Pending";
                } 
                if(new Date(invoice.ends_at) >= new Date()){
                    activeMembership = invoice;
                }
            });

            if(!activeMembership){
                res.data.map((invoice) => {
                    if(invoice.count > 0){
                        activeMembership = invoice;
                    }
                });

                if(activeMembership){
                    Zenfy.post('invoices', {
                        id: activeMembership.id,
                        org_id: activeMembership.org_id, 
                        product_id: activeMembership.product_id,
                        client_id: activeMembership.client_id,
                        count: activeMembership.count - 1,
                    });
                }
            }

            let status = [];

            if(activeMembership){

                window.startConfetti();

                document.getElementsByClassName("ant-layout")[0].style.backgroundColor = "#00ff40";
                document.getElementsByClassName("ant-layout-footer")[0].style.display = "none";
                document.body.style.backgroundColor = "#00ff40";

                if(activeMembership.count == 0){
                    status.push(<>{`Your membership "${activeMembership.name}" is expiring in ${moment(activeMembership.ends_at).fromNow()}`} <Emoji emoji="thumbs-up" size="30" /></>);
                }else{
                    status.push(<>{`You have ${activeMembership.count} "${activeMembership.name}" pass remaning(s)`} <Emoji emoji="thumbs-up" size="30" /></>);
                }
                
                const audioEl = document.getElementsByClassName("audio-element-good")[0]
                audioEl.play();
            }else{
                document.getElementsByClassName("ant-layout")[0].style.backgroundColor = "#f5b522";
                document.getElementsByClassName("ant-layout-footer")[0].style.display = "none";
                document.body.style.backgroundColor = "#f5b522";
                
                status.push(<>{`No active membership or drop ins have been found`} <Emoji emoji="nerd-face" size="30"/></>);

                const audioEl = document.getElementsByClassName("audio-element-bad")[0]
                audioEl.play();
            }

            if(this.state.client.id != client.id){
                Zenfy.post('attendances', {
                    org_id: client.org_id, 
                    client_id: client.id,
                    passed: activeMembership ? 1 : 0,
                    product_id: activeMembership ? activeMembership.product_id : 0,
                });
            }

            this.setState({
                client,
                loading: false,
                status
            });

            setTimeout(() => {

                document.getElementsByClassName("ant-layout")[0].style.backgroundColor = "#fff";
                document.body.style.backgroundColor = "#fff";

                this.setState({
                    client: {},
                    loading: false,
                    status: "",
                });
                
            }, 7000);

        });
    }

    getClientFromData = (id, email) => {
    
        if(this.state.client.id == id){
            //console.log("same guy");
            //return;
        }else {
            this.setState({
                loading: true
            });
        }

        Zenfy.get(`${localStorage.orgId}/clients/${id}`, {
            email
        }).then((res) => {

            let client = res.data;
            this.entryLogic(client);

        })
    }

    handleScan = data => {
        if (data) {
            let datas = data.split('|');
            if(datas.length == 2){
                this.getClientFromData(datas[0], datas[1]);
            }
        }
    }

    render() {
        
        //document.getElementById("root").style.backgroundColor = "green";
        
        //document.getElementsByClassName("ant-layout").style.backgroundColor = "green";
        return (
            <>
                <audio className="audio-element-good">
                    <source src="https://www.redringtones.com/wp-content/uploads/2019/11/wild-west-text-tone.mp3"></source>
                </audio>
                <audio className="audio-element-bad">
                    <source src="https://www.redringtones.com/wp-content/uploads/2020/02/sad-trombone-sms.mp3"></source>
                </audio>
                
                <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '22%' }}
                />
                <canvas id="confetti-canvas"></canvas>
                <Spin tip="Gettin the information..." spinning={this.state.loading}>
                    {this.state.client.id ? (
                        <div className="centered">
                            <h1 style={{textAlign: 'center', fontSize: '50px', color: '#fff'}}>Welcome {this.state.client.name} <Emoji emoji="ok-hand" size="30"/></h1>
                            <h1 style={{textAlign: 'center', fontSize: '30px', color: '#fff'}}>{this.state.status}</h1>
                        </div>
                    ) : null}
                </Spin>

                {!this.state.client.id ? (
                        <div className="centered">
                            <h1 style={{textAlign: 'center', fontSize: '50px', color: '#333'}}>Please scan your QR code on the left <Emoji emoji="waving-hand" size="50"/></h1>
                            <p style={{ fontSize: 20 }}>or find your name here</p>
                            <AutoComplete 
                                dataSource={this.state.dataSource}
                                filterOption={(inputValue, option) =>
                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onChange={this.onScanChange}
                                onSelect={this.onSelect}
                            />
                        </div>
                ) : null}
            </>
        );
    }
}

export default Scan;