import React, { Component } from "react";

import {
    Layout, Menu, Breadcrumb, Icon, Alert, Tooltip, Button, message
} from 'antd';

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import Drivers from './Drivers';
import Terminals from './Terminals';
import Customers from './Customers';
import Shippers from './Shippers';
import Equipements from './Equipements';
import Products from "./Products";
import GymProducts from "./GymProducts";
import Status from "./Status";
import Import from "./Import";
import Templates from "./Templates";
import Tags from "./Tags";

const { SubMenu } = Menu;
const {
  Header, Content, Footer, Sider,
} = Layout;

class Admin extends Component {

    constructor(props) {
        super(props);

        const url = props.location.pathname.split('/');

        this.state = {
            selectedTab: url[url.length-1]//url.length > 3 ? url[url.length-1] : 'jobs'
        }
    }

    render() {
        return (
            <>
                <Layout style={{ padding: '24px 0', background: '#fff' }}>

                    <HashRouter>
                        <Sider width={200} style={{ background: '#fff'}}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={this.state.selectedTab}
                                style={{ height: '100%' }}
                            >
                                <Menu.Item key="customers" className={localStorage.type == 'crm' || localStorage.type == 'gym' ? 'hide' : ''}><NavLink to={`/admin/customers`}>Customers</NavLink></Menu.Item>
                                <Menu.Item key="shippers" className={localStorage.type == 'crm' || localStorage.type == 'gym' ? 'hide' : ''}><NavLink to={`/admin/shippers`}>Shippers</NavLink></Menu.Item>
                                <Menu.Item key="drivers"><NavLink to={`/admin/drivers`}>Staff</NavLink></Menu.Item>
                                <Menu.Item key="equipements" className={localStorage.type == 'crm' || localStorage.type == 'gym' ? 'hide' : ''}><NavLink to={`/admin/equipements`}>Equipements</NavLink></Menu.Item>
                                <Menu.Item key="terminals" className={localStorage.type == 'crm' || localStorage.type == 'gym' ? 'hide' : ''}><NavLink to={`/admin/terminals`}>Terminals</NavLink></Menu.Item>
                                <Menu.Item key="products"><NavLink to={`/admin/products`}>Products</NavLink></Menu.Item>
                                <Menu.Item key="status"><NavLink to={`/admin/status`}>Status</NavLink></Menu.Item>
                                <Menu.Item key="templates"><NavLink to={`/admin/templates`}>Templates</NavLink></Menu.Item>
                                <Menu.Item key="tags"><NavLink to={`/admin/tags`}>Tags</NavLink></Menu.Item>
                                <Menu.Item key="import" className={localStorage.type == 'crm' || localStorage.type == 'gym' ? 'hide' : ''}><NavLink to={`/admin/import`}>Import</NavLink></Menu.Item>
                            </Menu>
                        </Sider>
                    </HashRouter>

                    <Content style={{ padding: '0 24px' }}>
                        <Route exact path={`/admin/customers`} component={Customers} />
                        <Route exact path={`/admin/shippers`} component={Shippers} />
                        <Route exact path={`/admin/drivers`} component={Drivers} />
                        <Route exact path={`/admin/equipements`} component={Equipements} />
                        <Route exact path={`/admin/terminals`} component={Terminals} />
                        <Route exact path={`/admin/status`} component={Status} />
                        <Route exact path={`/admin/import`} component={Import} />
                        <Route exact path={`/admin/templates`} component={Templates} />
                        <Route exact path={`/admin/tags`} component={Tags} />
                        <Route exact path={`/admin/products`} component={localStorage.type == 'crm' || localStorage.type == 'gym' ? GymProducts : Products} />
                    </Content>

                </Layout>
            </>
        )
    }
}

export default Admin;