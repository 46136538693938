import React, { Component } from "react";
import { Input, DatePicker, Button, Icon, Tooltip } from 'antd';

import { ChartCard, MiniBar, Pie } from 'ant-design-pro/lib/Charts';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

import Currency from 'react-currency-formatter';

import Labels from '../../libs/Labels';

import numeral from 'numeral';

const { RangePicker } = DatePicker;

class Moves extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            start: moment().startOf('week').format('YYYY-MM-DD'),
            end: moment().endOf('week').format('YYYY-MM-DD'),
            total: 0,
            totalFees: 0,
            totalNet: 0,
            totalBank: 0,
            totalDrops: 0,
        }

        this.data = [];
    }
    
    componentDidMount() {
        this.load();
    }

    load = () => {

        this.setState({
            loading: true,
        });


        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/stats/drops?start=${this.state.start}&end=${this.state.end}`).then((res) => {

            let totalDrops = 0;

            res.data.map((obj) => {
                totalDrops += obj.y;
            });
            
            this.setState({
                loading: false,
                rows: res.data,
                totalDrops,
            });
            
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    handleExport = () => {
        Zenfy.exportDataToCsv(this.state.rows, 'moves');
    }    

    render() {

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            //return obj.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <style dangerouslySetInnerHTML={{__html: `
                .ant-layout-content{overflow: hidden !important;}
            `}}></style>
          
             
            <Input placeholder={`Search`} className="pull-left" style={{width: '400px', marginBottom: 16}} onChange={this.searchOnChange} />

            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange}
                defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                ranges={Zenfy.getRanges()}
            />

            <Button onClick={this.handleExport} type="dashed" style={{ marginBottom: 16, marginRight: 16}} className="pull-right" >
              <Icon type="export" /> {Labels.get("Export")}
            </Button>

            <div style={{clear: 'both'}} />

            <ChartCard
                title="Number of drops"
                loading={this.state.loading}
                action={
                    <Tooltip title="Total of number of drops">
                        <Icon type="exclamation-circle-o" />
                    </Tooltip>
                }
                total={numeral(this.state.totalDrops).format('0,0')}
                contentHeight={600}
            >
                <MiniBar height={300} data={rows} />
            </ChartCard>
        </>
    );

    }
}

export default Moves;