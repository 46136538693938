import React, { Component } from "react";

import ReactDataSheet from 'react-datasheet';

import Zenfy from "../libs/Zenfy";

// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';

class Tools extends Component {

    constructor(props) {
        super(props);

        this.state = {
            grid: [
              [
                {readOnly: true, value: ''},
                {value: 'Container', readOnly: true},
                {value: 'Job type', readOnly: true},
                {value: 'Container size', readOnly: true},
                {value: 'Customer', readOnly: true},
                {value: 'Pick up terminal', readOnly: true},
                {value: 'Delivery address', readOnly: true},
                {value: 'Return terminal', readOnly: true},
                {value: 'Pickup number / Booking number', readOnly: true},
                {value: 'Steamship', readOnly: true},
                {value: 'Weight', readOnly: true},
                {value: 'Hazardous container', readOnly: true},
                {value: 'Special instructions', readOnly: true},
                {value: 'Instructions for container', readOnly: true},
                {value: 'Status', readOnly: true},
              ],
              [{readOnly: true, value: 1}, {value: 1}, {value: 3}, {value: 3}, {value: 3}],
              [{readOnly: true, value: 2}, {value: 2}, {value: 4}, {value: 4}, {value: 4}],
              [{readOnly: true, value: 3}, {value: 1}, {value: 3}, {value: 3}, {value: 3}],
              [{readOnly: true, value: 4}, {value: 2}, {value: 4}, {value: 4}, {value: 4}]
            ]
          }

          //Zenfy.get('post/1'); 
    }

    render() {
        const grid = [
            [{value:  5, expr: '1 + 4'}, {value:  6, expr: '6'}, {value: new Date('2008-04-10')}],
            [{value:  5, expr: '1 + 4'}, {value:  5, expr: '1 + 4'}, {value: new Date('2004-05-28')}]
         ]
         const onCellsChanged = (changes) => changes.forEach(({cell, row, col, value}) => console.log("New expression :" + value))
        return (
            <>
                <ReactDataSheet
                    data={this.state.grid}
                    valueRenderer={(cell) => cell.value}
                    onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
                    onCellsChanged={changes => {
                    const grid = this.state.grid.map(row => [...row])
                    changes.forEach(({cell, row, col, value}) => {
                        grid[row][col] = {...grid[row][col], value}
                    })
                    this.setState({grid})
                    }}
                />
              
            </>
        )
    }
}

export default Tools;