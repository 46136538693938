import _isUndefined from 'lodash/isUndefined';

/**
 * Base class to get localized labels form JSON
 */
class JsonLabels {
  /**
   * @param {Object} labels - Labels to use
   * @param {string} labels.en - Whith a JSON object as value ({string} labelKey: {string} labelValue)
   * @param {string} labels.fr - Whith a JSON object as value ({string} labelKey: {string} labelValue)
   */
  constructor(labels) {
    this.labels = labels;
  }

  /**
   * Get a label by a key
   * @param {string} labelKey Resource label key
   * @param {string} language Resource label language
   * @returns {string}
   */
  get(labelKey, language = localStorage.Lang ? localStorage.Lang : 'en') {
    if (_isUndefined(this.labels[language])) {
     // console.error('Undefined language', language);
      return labelKey;
    }

    if (_isUndefined(this.labels[language][labelKey])) {
      //console.warn('Undefined label key', labelKey);
      
      /*let local = 'en';
      if(localStorage.Lang == 'en'){
        local = 'fr';
      }

      if(_isUndefined(this.labels[local][labelKey])){
        return labelKey;
      }

      return this.labels[local][labelKey];*/
      return labelKey;
    }

    return this.labels[language][labelKey];
  }

  /**
   * Get a label by a key, as an HTML value
   * Use the "dangerouslySetInnerHTML" attribute in a tag, with GetHtml() as the parameter value.
   * @param {string} labelKey Resource label key
   * @returns {string}
   */
  getHtml(labelKey) {
    return { __html: this.get(labelKey) };
  }
}

export default JsonLabels;