import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Modal, notification, Tooltip, message } from 'antd';

import Labels from '../../libs/Labels';

import DrawerTableForm from '../../components/DrawerTableForm';
import EmailModalForm from '../../components/EmailModalForm';
import PayForm from '../../components/PayForm';

import Zenfy from "../../libs/Zenfy";

import moment from 'moment';

import Currency from 'react-currency-formatter';

class Orders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEmailModal: false,
            selectedRecord: {},
            modalLoading: false,
            showModal: false,
        };

        this.drawerRef = React.createRef();
        this.payForm = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    delete = (record) => {
        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this invoice?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`orders/${record.id}`).then((res) => {

                    Zenfy.delete(`invoices/${record.invoice_id}`).then((res) => {
                        this.drawerRef.current.load();
                        this.props.reLoad();
                    });

                    //this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });
    }

    beforeSave = (data) => {

        /*delete data.amount_due;

        delete data.balance;
        delete data.paid;*/

        this.newData = {...data};

        /*delete data.due_at;
        delete data.terms;
        delete data.invoice_id;
        delete data.invoices_order_id;*/

        return {
            id: data.id,
            org_id: data.org_id
        };
    }

    afterSave = (data) => {
        // check if we have a invoice id
        let orderId = data.id;

        if(!this.newData.terms){
            this.newData.terms = 0;
        }

        this.newData.client_id = this.props.client.id;

        let newInvoice = true;

        if(this.newData.invoice_id){
            //this.newData.id = this.newData.invoice_id;
            //delete this.newData.invoice_id;
            //delete this.newData.invoices_order_id;
            newInvoice = false;
        }
    
        Zenfy.post('invoices', {
            client_id: this.newData.client_id,
            due_at: this.newData.due_at,
            org_id: this.newData.org_id,
            terms: this.newData.terms,
            po: this.newData.po,
            id: this.newData.invoice_id ? this.newData.invoice_id : null,
        }).then((res) => {
            if(newInvoice){
                Zenfy.post('invoices_orders', {
                    org_id: data.org_id,
                    invoice_id: res.data,
                    order_id: orderId
                }).then((res) => {
                    this.drawerRef.current.load();
                    this.props.reLoad();
                });
            }else{
                this.drawerRef.current.load();
                this.props.reLoad();
            }
        })

        return false;
    }

    viewInvoice = (record) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${record.invoice_id}?lang=${localStorage.Lang}`, '_blank');
        win.focus();
    }

    viewProposal = (record) => {
        if(record.org_id == 6){
            var win = window.open("https://go.zenfy.co/ecosystemie/", '_blank');
            win.focus();
        }else{
            alert('coming soon');
        }
    }

    viewPacking = (record) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${record.invoice_id}?packing=true&lang=${localStorage.Lang}`, '_blank');
        win.focus();
    }

    handlePayOk = () => {
        this.payForm.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                this.setState({
                    modalLoading: true,
                });

                values.client_id = this.state.selectedRecord.client_id;
                values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                values.invoice_id = this.state.selectedRecord.invoice_id;

                if(window.creditCard && window.creditCard.current){
                    window.creditCard.current.handleOk(values.amount, (res) => {
                        values.txn_id = res.id;
                        this.postTransaction(values);
                    }, () => {
                        this.setState({
                            modalLoading: false,
                        });
                    });
                }else{
                    this.postTransaction(values);
                }
            }
        });
    }

    postTransaction = (values) => {
        Zenfy.post('transactions', values).then((res) => {

            this.drawerRef.current.load();
            this.props.reLoad();
            
            this.setState({
                showModal: false,
                modalLoading: false,
            });

            notification.success({
                message: `Paid with success`,
                placement: 'bottomLeft',
            });
        });
    }

    downloadInvoice = (invoice) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${invoice.invoice_id}?email=true&isFile=true&lang=${localStorage.Lang}`, '_blank');
        win.focus();
    }

    sendReceipt = (record) => {
        Zenfy.get(`${localStorage.orgId}/sendemail/${record.client_id}`, {
            amount: record.amount_due,
            type: 'donation',
            id: record.invoice_id,
        }).then(() => {
            notification.success({
                message: `Receipt sent`,
                placement: 'bottomLeft',
            });
        });
        
    }

    beforeLoad = (data) => {
        data.map((row) => {
            if(row.products){
                row.products.map((product) => {
                    if(product.recurring == 1){
                        row.recurring = 1;
                        console.log(product);
                        row.ends_at = product.ends_at;
                        row.next_at = product.next_at;
                    }
                });
                row.products = []; // error react
            }
        });

        console.log(data);
    }

    salesforce = (record, e) => {
        if(e){
            e.stopPropagation();
        }

        let hide = false;

        let client = this.props.client;
        client.invoice = record;

        const success = () => {
            hide = message.loading(Labels.get(`Pushing ${client.name} to Salesforce...`), 0);
            // Dismiss manually and asynchronously
            // setTimeout(hide, 2500);
        };

        success();

        Zenfy.post('salesforce', client).then((res)=>{
            if(hide){
                hide();
                notification.success({
                    message: `${client.name} saved with success on Salesforce.`,
                    placement: 'bottomLeft',
                });
            }
        }).catch(error => {

            let errMsg = "";

            if(error.response && error.response.data){
                errMsg = error.response.data.message ? error.response.data.message : error.response.data;
            }

            if(hide){
                hide();
            }

            Modal.error({
                title: 'Error',
                content: errMsg,
            });
        });
    }

    render() {

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id.length - b.id.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: false,
            hide: true,
        },{
            title: 'Products',
            dataIndex: 'products',
            className: 'hide',
            key: 'products',
            type: 'product',
        },{
            title: 'Terms',
            dataIndex: 'terms',
            key: 'terms',
            type: 'terms',
            className: 'hide',
           // render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
           // type: 'switch',
        },{
            title: 'Purchase Order Number',
            dataIndex: 'po',
            className: 'hide',
            key: 'po',
        },{
            hide: true,
            title: 'Has Recurring',
            dataIndex: 'recurring',
            key: 'recurring',
            render: (text, record) => text == 0 ? 'No' : <Tooltip title={record.ends_at ? moment(record.ends_at).format("MMMM Do YYYY") : ""}><span>Yes</span></Tooltip>,
            sorter: (a, b) => a.recurring - b.recurring,
            render: (text) => {return text == 1 ? "Yes" : "No"},
        },{
            title: 'Due Date',
            dataIndex: 'due_at',
            key: 'due_at',
            type: 'date',
            sorter: (a, b) => new Date(a.due_at) - new Date(b.due_at),
            render: (text, record) => {return record.due_at ? moment(record.due_at).format("MMMM Do YYYY") : ''},
           // render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
           // type: 'switch',
        },{
            title: 'Invoice Id',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
            hide: true,
            className: 'hide',
           // render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
           // type: 'switch',
        },{
            title: 'Amount Due',
            dataIndex: 'amount_due',
            key: 'amount_due',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            hide: true,
            sorter: (a, b) => a.amount_due - b.amount_due,
           // render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
           // type: 'switch',
        },{
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            hide: true,
            sorter: (a, b) => a.paid - b.paid,
           // render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
           // type: 'switch',
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            hide: true,
            sorter: (a, b) => a.balance - b.balance,
           // render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
           // type: 'switch',
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>{Labels.get('Edit')}</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.viewInvoice(record)}>{Labels.get('View Invoice')}</Menu.Item>
                    <Menu.Item key="2" onClick={() => this.viewProposal(record)}>{Labels.get('View Proposal')}</Menu.Item>
                    <Menu.Item key="4" onClick={() => {
                        record.email = this.props.client.email;
                        record.email_text = Labels.get('html_email');
                        this.setState({
                            selectedRecord: record,
                            showEmailModal: true,
                        });
                    }}>{Labels.get('Send Email')}</Menu.Item>
                    <Menu.Item key="5" onClick={() => this.downloadInvoice(record)}>{Labels.get('Download')}</Menu.Item>
                    <Menu.Item key="6" onClick={() => {
                        this.setState({
                            showModal: true,
                            selectedRecord: record,
                        })
                    }}>{Labels.get('Add Payment')}</Menu.Item>
                    <Menu.SubMenu title="Receipt">
                        <Menu.Item key="donation_receipt" onClick={() => this.sendReceipt(record)}>Donation Receipt</Menu.Item>
                        <Menu.Item key="normal_receipt" onClick={() => alert('coming soon.')}>Normal Receipt</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="API">
                        <Menu.Item key="api_salesforce" onClick={(e)=>this.salesforce(record, e.domEvent)}>{Labels.get("Push to Salesforce")}</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="7" onClick={() => this.delete(record)}>{Labels.get('Delete')}</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        return (
        <>  
            <Modal
                title={`Pay invoice #${this.state.selectedRecord.id}`}
                visible={this.state.showModal}
                onOk={this.handlePayOk}
                onCancel={()=> this.setState({
                    showModal: false,
                    modalLoading: false,
                })}
                okText="Pay"
                okButtonProps={{loading: this.state.modalLoading}}
            >
                <PayForm 
                    showCmp 
                    client={{name: this.props.client.name,id: this.state.selectedRecord.client_id, org_id: this.state.selectedRecord.org_id, customer_id: this.props.client.customer_id}} 
                    invoice={this.state.selectedRecord} 
                    ref={this.payForm} 
                    cur={localStorage.orgId == 18 ? 'usd' : 'cad'}
                /> 
            </Modal>

            <EmailModalForm 
                visible={this.state.showEmailModal}
                title={`${Labels.get('Send Invoice')} ${this.state.selectedRecord.id} ${Labels.get('to Customer')}`}
                onCancel={
                    () => {
                    this.setState({
                        showEmailModal: false,
                    })
                }}
                record={this.state.selectedRecord}
                //attachement
                emailText
                key={Math.random()}
                subject={`${Labels.get('Invoice')} #${this.state.selectedRecord.id}`}
                emailUrl={`${localStorage.orgId}/invoice/${this.state.selectedRecord.invoice_id}?email=true&lang=${localStorage.Lang}`}
                //files
                loading={this.state.modalLoading}
            />

            {this.props.client.id ? (
                <DrawerTableForm 
                    modal 
                    afterSave={this.afterSave}
                    beforeSave={this.beforeSave}
                    hideFooter 
                    columns={columns} 
                    ref={this.drawerRef} 
                    form={null} 
                    type="invoice" 
                    bigModal
                    beforeLoad={this.beforeLoad}
                    load={`orders?client_id=${this.props.client.id}&include_products=true`} 
                />
            ) : null}
        </>
        );
    }
}

export default Orders;