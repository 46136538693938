import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag, notification, Modal, message } from 'antd';

import Zenfy from "../../libs/Zenfy";
import Labels from "../../libs/Labels";
import SelectFromUrl from "../../components/SelectFromUrl";

import moment from 'moment';

import {
    DataSheetGrid,
    DynamicDataSheetGrid,
    checkboxColumn,
    textColumn,
    keyColumn,
    dateColumn,
    intColumn,
} from 'react-datasheet-grid'

class Import extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [
                { lfd: new Date(), app_date: new Date() }
                /*{ import: true, reference: 'Elon', container: 'Musk' },
                { import: false, reference: 'Jeff', container: 'Bezos' },*/
            ]
        };

        this.dataSheet = React.createRef();
        this.activeCell = null;
    }

    import = () => {
        const { confirm } = Modal;

        console.log(this.state.data);

        confirm({
            title: `Are you sure you want to import ${this.state.data.length} orders?`,
            okText: 'Yes',
            onOk: () => {

                let stateData = [...this.state.data];

                stateData.map((oldOrder) => {
                    let order = {...oldOrder};
                    order.job = order.job == 1 ? 'import' : 'export';
                    order.lfd = order.lfd ? moment(order.lfd).format('YYYY-MM-DD HH:mm') : null;
                    order.app_date = order.app_date ? moment(order.app_date).format('YYYY-MM-DD HH:mm') : null;
                    order.org_id = localStorage.orgId;

                    if(order.sizeLocation){
                        if(order.sizeLocation != ''){
                            let parts = order.sizeLocation.split('/');
                            if(parts[0] && parts[1]){
                                order.size = parts[0].replace(/[^a-zA-Z0-9]/g,'').trim();
                                order.terminal_id = parts[1].replace(/[^a-zA-Z0-9]/g,'').trim();
                            }
                        }
                        delete order.sizeLocation;
                    }

                    if(order.size && order.size != ''){
                        order.size = order.size.replace(/[^a-zA-Z0-9]/g,'').trim();
                    }

                    if(order.weight && order.weight != ''){
                        if(order.weight.toLowerCase().indexOf("kg") === -1){
                            order.weight += " kg";
                        }
                    }

                    /*if(order.terminal_id && order.terminal_id != '' && order.terminal_id.toLowerCase() == 'viau'){
                        order.terminal_id = 41;
                    }else if(order.terminal_id && order.terminal_id != '' && order.terminal_id.toLowerCase() == 'racine'){
                        order.terminal_id = 32;
                    }else if(order.terminal_id && order.terminal_id != '' && order.terminal_id.toLowerCase() == 'termont'){
                        order.terminal_id = 34;
                    }*/

                    Zenfy.post('orders', order).then((res) => {
                        message.success(`${order.container} imported with success.`);
                    });
                    
                });
            },
        });
    }

    render() {

        const SelectTerminalComponent = React.memo(({ rowData, focus }) => {
            return <SelectFromUrl 
                url={`${localStorage.orgId ? localStorage.orgId : 1}/terminals`} 
                idName="id" 
                name="name"
                onChange={(value) => {
                    rowData.terminal_id = parseInt(value);
                    if(this.dataSheet.current && this.activeCell){
                        this.dataSheet.current.setActiveCell(this.activeCell);
                    }
                }} 
                value={rowData.terminal_id}
                style={{width: '99%'}}
            />
        });

        const SelectClientComponent = React.memo(({ rowData }) => {
            return <SelectFromUrl 
            url={`${localStorage.orgId ? localStorage.orgId : 1}/clients?is_customer=0&active=1`} 
            idName="id" 
            name="address" 
            onChange={(value) => {
                rowData.address_id = parseInt(value);
                if(this.dataSheet.current && this.activeCell){
                    this.dataSheet.current.setActiveCell(this.activeCell);
                }
            }} 
            value={rowData.address_id}
            altRender={(row) => {
                return `${row.name} - ${row.address ? row.address + ', ' : ''} 
                ${row.city ? row.city + ', ' : ''} 
                ${row.state ? row.state + ', ' : ''} 
                ${row.postal ? row.postal + '' : ''}
                ${row.country ? ', ' + row.country  : ''}`
            }}
            style={{width: '99%'}}
        />
        });

        const SelectContactComponent = React.memo(({ rowData }) => {
            return <SelectFromUrl 
                url={`${localStorage.orgId ? localStorage.orgId : 1}/contacts`} 
                idName="id" 
                name="name" 
                onChange={(value) => {
                    rowData.contact_id = parseInt(value);
                    if(this.dataSheet.current && this.activeCell){
                        this.dataSheet.current.setActiveCell(this.activeCell);
                    }
                }} 
                value={rowData.contact_id}
                altRender={(row) => {
                    return `${row.name} ${row.email ? '(' + row.email + ') ' : ''} - ${row.client_name}`
                }}
                style={{width: '99%'}}
            />
        });

        let columns = [
            { ...keyColumn('job', checkboxColumn), title: 'Import' },
            { ...keyColumn('reference', textColumn), title: 'Reference' },
            { ...keyColumn('medlog', textColumn), title: 'Internal ID' },
            { ...keyColumn('container', textColumn), title: 'Container' },
            { ...keyColumn('pickup', textColumn), title: 'Pickup #' },
            //{ ...keyColumn('sizeLocation', textColumn), title: 'Size / Location'},
            { ...keyColumn('size', textColumn), title: 'Size' },
            {
                component: SelectTerminalComponent,
                title: 'Terminal',
                minWidth: 200,
                deleteValue: () => null,
                copyValue: ({ rowData }) => {
                    return rowData.terminal_id;
                },
                pasteValue: ({rowData, value}) => {
                    if(value){
                        rowData.terminal_id = parseInt(value);
                    }
                    return rowData
                },
            },
            //{ ...keyColumn('terminal_id', textColumn), title: 'Terminal' },
            { ...keyColumn('weight', textColumn), title: 'Weight' },
            { ...keyColumn('lfd', dateColumn), title: 'LFD' },
            { ...keyColumn('app_date', dateColumn), title: 'App Time' },
            //{ ...keyColumn('address_id', intColumn), title: 'Client ID' },
            {
                component: SelectClientComponent,
                title: 'Client',
                minWidth: 200,
                deleteValue: () => null,
                copyValue: ({ rowData }) => {
                    return rowData.address_id;
                },
                pasteValue: ({rowData, value}) => {
                    if(value){
                        rowData.address_id = parseInt(value);
                    }
                    return rowData
                },
            },
            {
                component: SelectContactComponent,
                title: 'Customer / Bill to',
                minWidth: 200,
                deleteValue: () => null,
                copyValue: ({ rowData }) => {
                    return rowData.contact_id;
                },
                pasteValue: ({rowData, value}) => {
                    if(value){
                        rowData.contact_id = parseInt(value);
                    }
                    return rowData
                },
            },
            //{ ...keyColumn('contact_id', intColumn), title: 'Customer / Bill to' },
            { ...keyColumn('instructions', textColumn), title: 'Instructions' },
            { ...keyColumn('return_instructions', textColumn), title: 'Return Instructions' },
        ]

        if(localStorage.orgId == 20){
            columns = [
                { ...keyColumn('job', checkboxColumn), title: 'Import' },
                { ...keyColumn('reference', textColumn), title: 'Reference' },
                { ...keyColumn('medlog', textColumn), title: 'Internal ID' },
                { ...keyColumn('container', textColumn), title: 'Container' },
                { ...keyColumn('pickup', textColumn), title: 'Pickup #' },
                //{ ...keyColumn('sizeLocation', textColumn), title: 'Size / Location'},
                { ...keyColumn('size', textColumn), title: 'Size' },
                {
                    component: SelectTerminalComponent,
                    title: 'Terminal',
                    minWidth: 200,
                    deleteValue: () => null,
                    copyValue: ({ rowData }) => {
                        return rowData.terminal_id;
                    },
                    pasteValue: ({rowData, value}) => {
                        if(value){
                            rowData.terminal_id = parseInt(value);
                        }
                        return rowData
                    },
                },
                //{ ...keyColumn('terminal_id', textColumn), title: 'Terminal' },
                { ...keyColumn('weight', textColumn), title: 'Weight KG' },
                { ...keyColumn('lfd', dateColumn), title: 'LFD' },
                { ...keyColumn('app_date', dateColumn), title: 'App Time' },
                //{ ...keyColumn('address_id', intColumn), title: 'Client ID' },
                {
                    component: SelectClientComponent,
                    title: 'Client',
                    minWidth: 200,
                    deleteValue: () => null,
                    copyValue: ({ rowData }) => {
                        return rowData.address_id;
                    },
                    pasteValue: ({rowData, value}) => {
                        if(value){
                            rowData.address_id = parseInt(value);
                        }
                        return rowData
                    },
                },
                {
                    component: SelectContactComponent,
                    title: 'Customer / Bill to',
                    minWidth: 200,
                    deleteValue: () => null,
                    copyValue: ({ rowData }) => {
                        return rowData.contact_id;
                    },
                    pasteValue: ({rowData, value}) => {
                        if(value){
                            rowData.contact_id = parseInt(value);
                        }
                        return rowData
                    },
                },
                //{ ...keyColumn('contact_id', intColumn), title: 'Customer / Bill to' },ok
                { ...keyColumn('instructions', textColumn), title: 'Instructions' },
                { ...keyColumn('return_instructions', textColumn), title: 'Return Instructions' },
            ]
        }
          
        return (
        <>  
            <Button 
                onClick={this.import}
                style={{ marginBottom: 16}} 
                className="pull-right" 
            >
                {Labels.get("Import")}
            </Button>

            <DataSheetGrid
                ref={this.dataSheet}
                height={600}
                //createRow={() => ({ lfd: new Date(), app_date: new Date() })}
                value={this.state.data}
                onChange={(data) => {
                    this.setState({
                        data
                    });
                }}
                columns={columns}
                onActiveCellChange={(obj) => {
                    if(obj.cell){
                        this.activeCell = obj.cell;
                    }
                }}
            />
        </>
        );
    }
}

export default Import;