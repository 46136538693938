import React, { Component } from "react";
import { Statistic, Card, Row, Col, Table, Input, DatePicker, Button, Icon } from 'antd';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

import Currency from 'react-currency-formatter';

import Labels from '../../libs/Labels';

const { RangePicker } = DatePicker;

class Transactions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            start: moment().startOf('year').subtract(10, 'years').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            total: 0,
            totalFees: 0,
            totalNet: 0,
            totalBank: 0,
            typeCat: []
        }

        this.data = [];
    }

    componentDidMount() {
        this.load();
    }

    load = () => {

        this.setState({
            loading: true,
        });


        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/stats/transactions?start=${this.state.start}&end=${this.state.end}`).then((res) => {
            
            /*let total = res.data.credit + res.data.tps + res.data.tvq;
            let result = Object.entries(res.data).map(( [k, v] ) => ({'name': Zenfy.capitalizeFirstLetter(k), 'amount' : v}));*/
            
            if(res){

                this.data = [...res.data];

                let totalFees = 0;
                let totalNet = 0;
                let total = 0;
                let totalCredit = 0;
                let totalOther = 0;
                let creditNote = 0;

                res.data.map((row) => {
                    total += row.amount;
                    totalNet += row.net;
                    totalFees += row.fees;

                    if(row.type == 'credit'){
                        totalCredit += row.net;
                    }else if(row.type == 'credit_note'){
                        creditNote += row.net;
                    }else{
                        totalOther += row.net;
                    }
                });
                
                console.log(totalCredit);

                totalCredit = totalCredit + creditNote; // should only be done via credit card

                console.log(totalCredit);

                this.setState({
                    loading: false,
                    rows: Zenfy.setKeysToList(res.data),
                    total,
                    totalFees,
                    totalNet,
                    totalCredit,
                    totalOther,
                    typeCat: Zenfy.getCategoriesFilters(res.data, 'type'),
                    productCat: Zenfy.getCategoriesFilters(res.data, 'product_name'),
                });

                Zenfy.get(`${localStorage.orgId}/payouts`).then((res) => {
                    let totalPaid = 0;
                    res.data.map((pay) => {
                        totalPaid += pay.amount;
                    });
                    
                    totalPaid = totalPaid / 100;

                    if(localStorage.orgId == 3){
                        totalPaid -= 10921.73;
                    }else if(localStorage.orgId == 23){
                        totalPaid -= 808.36;
                    }

                    console.log(totalCredit - totalPaid);

                    this.setState({
                        totalBank: totalPaid
                    });
                });
        
            }
            
        });
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    handleExport = () => {
        Zenfy.exportDataToCsv(this.cleanRows(this.state.rows), 'transactions_report');
    }    

    cleanRows = (rows) => {
        let exportRows = [];

        rows.map((row) => {
            exportRows.push({
                amount: row.amount,
                fees: row.fees,
                net: row.net,
                first_name: Zenfy.getFirstName(row.name),
                last_name: Zenfy.getLastName(row.name),
                anonymous: row.anonymous,
                email: row.email,
                name: row.name,
                address: row.address, 
                city: row.city,
                state: row.state,
                postal: row.postal,
                type: row.type,
                created_at: row.created_at,
                product_name: row.product_name
            });
        });

        return exportRows;
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => text,
        },{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => text,
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type > b.type,
            render: (text) => text ? Zenfy.capitalizeFirstLetter(text) : '',
            onFilter: (value, record) => record.type.indexOf(value) === 0,
            filters: this.state.typeCat,
        },{
            title: 'Recurring',
            dataIndex: 'recurring',
            key: 'recurring',
            sorter: (a, b) => a.recurring - b.recurring,
            render: (text) => {return text == 1 ? "Yes" : "No"},
            type: 'yesno'
        },{
            title: 'Recurring End',
            dataIndex: 'recurring_end',
            key: 'recurring_end',
            render: (text) => {return text ? moment(text).format("MMMM Do YYYY") : ''},
            sorter: (a, b) => new Date(a.recurring_end) - new Date(b.recurring_end),
            type: 'date'
        },{
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.amount - b.amount,
        },{
            title: 'Fees',
            dataIndex: 'fees',
            key: 'fees',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.fees - b.fees,
        },{
            title: 'Net',
            dataIndex: 'net',
            key: 'net',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.net - b.net,
        },{
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {return text ? moment(text).format("MMMM Do YYYY") : null},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        }
    ];

    if(localStorage.orgId && localStorage.orgId == 3){
        columns.push({
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name',
            sorter: (a, b) => a.product_name > b.product_name,
            //render: (text) => text ? Zenfy.capitalizeFirstLetter(text) : '',
            onFilter: (value, record) => record.product_name.indexOf(value) === 0,
            filters: this.state.productCat,
        });
    }

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            return obj.name && obj.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <Input placeholder={`Search`} className="pull-left" style={{width: '400px', marginBottom: 16}} onChange={this.searchOnChange} />

            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange} 
                defaultValue={[moment().startOf('year').subtract(10, 'years'), moment().add(1, 'day').endOf('day')]}
                ranges={Zenfy.getRanges()}
            />

            <Button onClick={this.handleExport} type="dashed" style={{ marginBottom: 16, marginRight: 16}} className="pull-right" >
              <Icon type="export" /> {Labels.get("Export")}
            </Button>

            <div style={{ background: '#ECECEC', padding: '16px', marginBottom: '16px' }}>
                <Row gutter={16}>
                <Col span={8}>
                    <Card>
                    <Statistic
                        title="Total"
                        value={this.state.total}
                        precision={2}
                        //valueStyle={{ color: '#3f8600' }}
                        //prefix={<Icon type="dollar" />}
                        suffix="$"
                    />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                    <Statistic
                        title="Fees"
                        value={this.state.totalFees}
                        precision={2}
                        //valueStyle={{ color: '#3f8600' }}
                        //prefix={<Icon type="dollar" />}
                        suffix="$"
                    />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                    <Statistic
                        title="Net"
                        value={this.state.totalNet}
                        precision={2}
                        //valueStyle={{ color: '#cf1322' }}
                        //prefix={<Icon type="dollar" />}
                        suffix="$"
                    />
                    </Card>
                </Col>
                </Row>

                <Row gutter={16} style={{marginTop: 16}}>
                    <Col span={8}>
                        <Card>
                            <Statistic
                                title="Total Net Credit Card"
                                value={this.state.totalCredit}
                                precision={2}
                                //valueStyle={{ color: '#3f8600' }}
                                //prefix={<Icon type="dollar" />}
                                suffix="$"
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic
                                title="Total Net Other"
                                value={this.state.totalOther}
                                precision={2}
                                //valueStyle={{ color: '#3f8600' }}
                                //prefix={<Icon type="dollar" />}
                                suffix="$"
                            />
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card>
                            <Statistic
                                title="Total Sent to Bank"
                                value={this.state.totalBank}
                                precision={2}
                                //valueStyle={{ color: '#3f8600' }}
                                //prefix={<Icon type="dollar" />}
                                suffix="$"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>       

            <div style={{clear: 'both'}} />

            <Table dataSource={rows} columns={columns} loading={this.state.loading} />
        </>
    );

    }
}

export default Transactions;