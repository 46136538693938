import React, { Component } from 'react';
import { Select, TreeSelect, Modal } from 'antd';
import Zenfy from '../libs/Zenfy';

import Labels from '../libs/Labels';

import DynamicForm from './DynamicForm';

class SelectFromUrl extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showAddModal: false,
            modalLoading: false,
            items: [],
            value: props.value,
        }

        this.formRef = React.createRef();
    }

    componentWillReceiveProps(newProps) {

        //if(newProps.value){
            this.setState({
                value: newProps.value
            });
        //}

    }

    componentDidMount() {

        this.load();

    }

    load = (callback) => {
        this.setState({loading: true});

        Zenfy.get(this.props.url).then((res) => {

            let items = res.data;

            if(this.props.hideClient){
                items = res.data.filter((item) => {
                    return item.name && item.name.toLowerCase() != 'client'
                });
            }

            if(this.props.preLoad){
                items = this.props.preLoad(items);
            }

            this.setState({
                items, 
                loading: false,
            }, () => {
                if(this.props.afterLoad){
                    this.props.afterLoad(items);
                }

                if(callback){
                    callback();
                }
            });
            

        }).catch(error => {
            this.setState({loading: false});
        });
    }

    changeState = (state) => {
        this.setState(state);
    }

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
           // console.log(Object.assign({}, this.state, changedValue));
          onChange(changedValue);
        }
    }

    addFromModal = (values) => {

        values.client_id = this.props.row.id;
        values.org_id = localStorage.orgId ? localStorage.orgId : 1;

        if(this.props.extraPost){
            values = {...values, ...this.props.extraPost};
        }
        
        Zenfy.post(this.props.type, values).then((res) => {
            this.load(() => {
                this.handleChange(parseInt(res.data));
            });
           
            /*notification.success({
                message: `Contact saved with success!`,
                placement: 'bottomLeft',
            });*/

            if(this.props.afterSave){
                this.props.afterSave(res.data);
            }

            this.setState({
                showAddModal: false,
            }, () => {
                this.formRef.current.getForm().resetFields();
            });

        });
    }

    handleSaveModal = () => {
        this.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.addFromModal(values);
            }
        });
    }

    handleChange = (value) => {

        this.setState({
            value: value,
        });

        if(this.props.itemChange){
            this.props.itemChange(this.state.items.find((element) => {
                return element[this.props.idName] == value;
            }));
        }

        this.triggerChange(value);
    }

    render() {

        const { Option, OptGroup } = Select;

        let items = [];

        if(!this.props.treeSelect){
             items = this.state.items.map((item, i) =>
                <Option value={item[this.props.idName]} key={i}>
                    {this.props.altRender ? this.props.altRender(item) : `${item[this.props.name]} ${this.props.category && item[this.props.category] ? `(${item[this.props.category]})` : ''}`}
                </Option>
            );
        }else{

          //  var data = [{ ticketId: 1, Category: "Driver", CategoryID: 29, SubCategory: "Monitor", SubCategoryID: 31 }, { ticketId: 2, Category: "Driver", CategoryID: 29, SubCategory: "Monitor", SubCategoryID: 31 }, { ticketId: 3, Category: "Hardware", CategoryID: 11, SubCategory: "Monitor", SubCategoryID: 32 }, { ticketId: 4, Category: "Hardware", CategoryID: 11, SubCategory: "phone", SubCategoryID: 13 }],
            let objects = this.state.items.reduce((r, a) => {
                let add = false;

                if(!this.props.isLeet){
                    add = true;
                }else if(this.props.isLeet && a.id == 1337){
                    add = true;
                }

                if(add){
                    r[a.category] = r[a.category] || {};
                    r[a.category][a.sub_category] = r[a.category][a.sub_category] || [];
                    r[a.category][a.sub_category].push(a);
                }
               
                return r;
            }, {});

            let i = 0;

            for (const property in objects) {

                let children = [];
                i++;
                let z = 0;

                for(const cProp in objects[property]){
                    z++;
                    let cObj = objects[property];

                    let pChildren = [];
                    
    
                    cObj[cProp].map((childObj) => {
                        let title = `${childObj.name} - $${childObj.price} ${childObj.force_monthly ? Labels.get(' / month') : ''} ${childObj.places && childObj.places > 0 ? `(${childObj.count_members}/${childObj.places})` : '' }`;
                        let disabled = childObj.count_members != 0 && childObj.places != 0 && childObj.count_members >= childObj.places;
                        
                        if(this.props.showOnlyTitles){
                            title = childObj.name;
                            //disabled = this.props.action == 2 && childObj.id == 1431;
                        }

                        pChildren.push({
                            title,
                            value: childObj.id,
                            key: `${childObj.name}-${childObj.id}-sub-sub`,
                            disabled
                        });
                    })

                    if(cProp != 'null' && cProp && cProp != ''){
                        children.push({
                            title: cProp,
                            selectable: false,
                            children: pChildren,
                            key: `${cProp}-sub-${z}-${i}`
                        })
                    }else{
                        children.push(...pChildren);
                    }
                }
                
                if(property != 'null' && property && property != ''){
                    items.push({
                        title: property,
                        selectable: false,
                        children,
                        key: `${property}-top-${i}`
                    });
                }else{
                    items.push(...children);
                }
            }
            
            /*items = [
                {
                  title: 'Node1',
                  value: '0-0',
                  children: [
                    {
                      title: 'Child Node1',
                      value: '0-0-1',
                    },
                    {
                      title: 'Child Node2',
                      value: '0-0-2',
                    },
                  ],
                },
                {
                  title: 'Node2',
                  value: '0-1',
                },
            ];*/
        }

        return (<>
            {this.props.treeSelect ? (
                <TreeSelect
                    allowClear
                    className={this.props.className} 
                    style={this.props.style}
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={items}
                    loading={this.state.loading} 
                    treeDefaultExpandAll
                    onChange={this.handleChange}
                    showSearch
                    filterTreeNode
                    treeNodeFilterProp={"title"}
                />
            ) : (
                <>
                    <Select 
                        allowClear
                        placeholder={this.props.placeholder} 
                        style={this.props.style} 
                        value={this.state.value} 
                        onChange={this.handleChange} 
                        loading={this.state.loading} 
                        mode={this.props.mode}
                        className={this.props.className} 
                        showSearch={this.props.noSearch ? false : true} 
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            {items}
                    </Select>
                    {this.props.addText ? (
                    <>
                        <a href="javascript:;" onClick={()=>{
                            this.setState({
                                showAddModal: true,
                            });
                        }}>{Labels.get(this.props.addText)}</a>
                        
                        <Modal
                            title={Labels.get("Child")}
                            visible={this.state.showAddModal}
                            okText={Labels.get("Save")}
                            onOk={this.handleSaveModal}
                            onCancel={()=> this.setState({
                                showAddModal: false,
                            })}
                            okButtonProps={{loading: this.state.modalLoading}}
                        >
                            <DynamicForm 
                                keys={this.props.keys} 
                                row={{}}
                                ref={this.formRef} 
                                hideFooter 
                            />
                        </Modal>
                </>) : null}
                </>
            )}
            
        </>);
    }
}

export default SelectFromUrl;