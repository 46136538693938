import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Statistic, Card, Row, Col, Table, Input, DatePicker, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";
import EmailModalForm from '../../components/EmailModalForm';

const { RangePicker } = DatePicker;

class Statements extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            searchVal: '',
            showEmailModal: false,
            selectedInvoice: {},
            start: "",
            end: "",
            totalIncome: 0,
            totalInvoices: 0,
            totalProfit: 0,
        }

        this.emailModalRef = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

    }

    load = () => {

        this.setState({
            loading: true,
        });


        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/statements?start=${this.state.start}&end=${this.state.end}`).then((res) => {

            let totalIncome = 0;
            let totalInvoices = 0;
            let totalProfit = 0;

            res.data.map((row) => {
                totalIncome += row.income;
                totalInvoices += row.invoices;
                totalProfit += row.total_paid_amount;
            });
            
            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
                totalIncome,
                totalInvoices,
                totalProfit
            });

        });
    }
    
    viewStatement = (record, hidePaid) => {
        var win = window.open(`${Zenfy.getRoot()}${record.org_id}/statements/${record.client_id}${hidePaid ? '?hidePaid=true' : ''}`, '_blank');
        win.focus();
    }

    searchOnChange = (e) => {

        this.setState({
            searchVal: e.target.value
        });

    }

    datesOnChange = (e, date) => {
        this.setState({
            start: date[0],
            end: date[1],
        }, () => {
            this.load();
        });
    }

    sendEmail = (record, e) => {

        if(e){
            e.stopPropagation();
        }

        let form = this.emailModalRef.current.getForm();

        form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            let emails = values.email.split(',');
    
            Zenfy.get(`${this.state.selectedInvoice.org_id}/statements/${this.state.selectedInvoice.client_id}?email=true&hidePaid=true`, {
                emails  
            });

            notification.success({
                message: `Sent email with success!`,
                placement: 'bottomLeft',
            });

            form.resetFields();

            this.setState({ showEmailModal: false });
        });

    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.viewStatement(record)}>{text}</a>,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Invoices',
            dataIndex: 'invoices',
            key: 'invoices',
            sorter: (a, b) => a.invoices - b.invoices
        },{
            title: 'Orders',
            dataIndex: 'orders',
            key: 'orders',
            sorter: (a, b) => a.orders - b.orders,
        },{
            title: 'Income',
            dataIndex: 'income',
            key: 'income',
            sorter: (a, b) => a.income - b.income,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Total Due',
            dataIndex: 'total_due',
            key: 'total_due',
            sorter: (a, b) => a.total_due - b.total_due,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Due',
            dataIndex: 'due',
            key: 'due',
            sorter: (a, b) => a.due - b.due,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Past Due',
            dataIndex: 'past_due',
            key: 'past_due',
            sorter: (a, b) => a.past_due - b.past_due,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Past Due Invoices',
            dataIndex: 'not_paid',
            key: 'not_paid',
            sorter: (a, b) => a.not_paid - b.not_paid,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

            /*<Menu.Item key="3">
                                <Popconfirm title="Are you sure delete this team?" onConfirm={()=>this.deleteTeam(record)} okText="Yes" cancelText="No">
                                Delete
                                </Popconfirm>
                            </Menu.Item>*/

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={() => this.viewStatement(record)}>View Full Statement</Menu.Item>
                  <Menu.Item key="1" onClick={() => this.viewStatement(record, true)}>View Only not Paid Statement</Menu.Item>
                  <Menu.Item key="2" onClick={() => {
                      record.email_text = `<p>Hello,</p>
                      <p>Please find the attached Statement as of today.</p>
                      <p>Please reply to this email if there is any issue.</p>
                      <p>Thank you for your business.</p>`;
                      this.setState({
                        showEmailModal: true,
                        selectedInvoice: record,
                      });
                  }}>Send Email To Customer</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
          }
    ];

    let rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {
            return obj.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1;
        }
    
        return true;
    });
    
    return (
        <>
            <Input placeholder={`Search for a customer`} className="pull-left" style={{width: '400px', marginBottom: 16}} onChange={this.searchOnChange} />
            <RangePicker 
                placeholder={['StartDate','EndDate']} 
                style={{ width: 256, marginBottom: 24, float: 'right' }} 
                onChange={this.datesOnChange} 
                ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                    'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
                }}
            />

            <div style={{ background: '#ECECEC', padding: '30px', marginBottom: '18px' }}>
                <Row gutter={16}>
                <Col span={8}>
                    <Card>
                    <Statistic
                        title="Total Invoiced"
                        value={this.state.totalIncome}
                        precision={2}
                        //valueStyle={{ color: '#3f8600' }}
                        //prefix={<Icon type="dollar" />}
                        suffix="$"
                    />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                    <Statistic
                        title="Total Revenue"
                        value={this.state.totalProfit}
                        precision={2}
                        //valueStyle={{ color: '#3f8600' }}
                        //prefix={<Icon type="dollar" />}
                        suffix="$"
                    />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                    <Statistic
                        title="Total Invoices"
                        value={this.state.totalInvoices}
                        //precision={2}
                        //valueStyle={{ color: '#cf1322' }}
                        //prefix={<Icon type="dollar" />}
                        suffix="Invoices"
                    />
                    </Card>
                </Col>
                </Row>
            </div>     

            <Table dataSource={rows} columns={columns} loading={this.state.loading} />

            <EmailModalForm 
                visible={this.state.showEmailModal}
                title="Send Statement to Customer"
                emailText
                onCancel={
                    () => {
                    this.setState({
                        showEmailModal: false,
                    })
                }}
                onCreate={this.sendEmail}
                record={this.state.selectedInvoice}
                ref={this.emailModalRef}
            />
        </>
    );

    }
}

export default Statements;