import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Divider, Modal, Tag, Badge, notification } from 'antd';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import PayForm from '../components/PayForm';
import DrawerTableForm from '../components/DrawerTableForm';

import Zenfy from "../libs/Zenfy";
import WrappedInvoiceForm from "./InvoiceForm";

class Invoices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: false,
            client: props.client,
            showModal: false,
            selectedInvoice: {}
        }

        this.drawerRef = React.createRef();
        this.formRef = React.createRef();
        this.payForm = React.createRef();
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(newProps) {

        // this.componentHasGotData(newProps);
        if(this.state.client.id !== newProps.client.id){
            this.setState({
                client: newProps.client
            },() => {
               this.componentDidMount();
            });
        }

    }


    load = () => {

        this.setState({
            loading: true,
        });

        Zenfy.get(`${localStorage.orgId}/orders/${this.state.client.id}`).then((res) => {

            /*res.data.map((invoice) => {
                if(invoice.amount && invoice.amount > 0 && invoice.paid >= invoice.amount){
                    invoice.status = "Paid";
                }else if(invoice.amount && invoice.amount > 0) {
                    invoice.status = "Pending";
                } 
            });*/

            this.setState({
                loading: false,
                rows: Zenfy.setKeysToList(res.data),
            })
        });
    }

    onSave = () => {

        this.formRef.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                values.client_id = this.state.client.id;
                values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                values.id = this.state.selectedInvoice.id ? this.state.selectedInvoice.id : null;
              
                Zenfy.post('invoices', values).then((res) => {
                    this.load();
                    this.props.reLoad();

                    this.drawerRef.current.hideDrawer();
                });

            }
        });

        
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
        this.setState({
            selectedInvoice: record,
        });
    }

    payInvoice = (invoice) => {
        this.setState({
            showModal: true,
            selectedInvoice: invoice,
        })
    }

    handlePayOk = () => {
        this.payForm.current.validateFieldsAndScroll((err, values) => {
            if (!err) {

                this.setState({
                    modalLoading: true,
                });

                values.client_id = this.state.client.id;
                values.org_id = localStorage.orgId ? localStorage.orgId : 1;
                values.invoice_id = this.state.selectedInvoice.id;

                if(window.creditCard && window.creditCard.current){
                    window.creditCard.current.handleOk(values.amount, (res) => {
                        values.txn_id = res.id;
                        values.recurring = res.recurring;
                        this.postTransaction(values);
                    }, () => {
                        this.setState({
                            modalLoading: false,
                        });
                    });
                }else{
                    this.postTransaction(values);
                }
            }
        });
    }

    postTransaction = (values) => {
        Zenfy.post('transactions', values).then((res) => {

            this.load();
            this.props.reLoad();
            
            this.setState({
                showModal: false,
                modalLoading: false,
            });

        });
    }

    deleteInvoice = (record) => {
        Zenfy.delete(`invoices/${record.id}`).then((res) => {
            this.load();
            this.props.reLoad();
        });
    }

    sendInvoiceByEmail = (record) => {
        Zenfy.get(`${record.org_id}/gyminvoice/${record.id}`, {
            email: true,
        }).then((res) => {
            notification.success({
                message: `Email sent with success!`,
                placement: 'bottomLeft',
            });
        });
    }

    viewInvoice = (record) => {
        var win = window.open(`${Zenfy.getRoot()}${record.org_id}/gyminvoice/${record.id}`, '_blank');
        win.focus();
    }

    render() {

        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            sorter: (a, b) => a.id - b.id,
        },{
            title: localStorage.type && localStorage.type == 'crm' ? 'Product' : 'Lesson',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {return <span>{text}  {record.recurring ? <Tag color="green">Recurring</Tag> : ''}</span>},
        },{
            title: 'Amount Due',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Paid Amount',
            dataIndex: 'paid',
            key: 'paid',
            sorter: (a, b) => a.paid - b.paid,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            sorter: (a, b) => a.balance - b.balance,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => text === 'Pending' ? <span><Badge status="error" text={text} /></span> : text ? <span><Badge status="success" text={text} /></span> : '',
        },{
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },{
            title: 'Ending Date',
            dataIndex: 'ends_at',
            key: 'ends_at',
            render: (text) => {return text ? moment(text).format("MMMM Do YYYY") : ''},
            sorter: (a, b) => new Date(a.ends_at) - new Date(b.ends_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={(e)=>this.recordOnClick(record)}>Edit</Menu.Item>
                    <Menu.Item key="2" onClick={(e)=>this.payInvoice(record)}>Pay</Menu.Item>
                    <Menu.Item key="3" onClick={(e)=>this.viewInvoice(record)}>View Invoice</Menu.Item>
                    <Menu.Item key="4" onClick={(e)=>this.sendInvoiceByEmail(record)}>Send Invoice</Menu.Item>
                    <Menu.Item key="6" onClick={(e)=>this.recurring(record)}>{record.recurring == 1 ? 'Cancel Recurring' : 'Make Recurring'}</Menu.Item>
                    <Menu.Item key="5" onClick={(e)=>this.deleteInvoice(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }
    ];

        return (
        <>
            <Modal
                title={`Pay invoice ${this.state.selectedInvoice.id}`}
                visible={this.state.showModal}
                onOk={this.handlePayOk}
                onCancel={()=> this.setState({
                    showModal: false,
                })}
                okText="Pay"
                okButtonProps={{loading: this.state.modalLoading}}
            >
                <PayForm invoice={this.state.selectedInvoice} ref={this.payForm} client={this.state.client} /> 
            </Modal>

            <DrawerTableForm 
                bigModal 
                title="Add new invoice" 
                modal 
                onSave={this.onSave} 
                columns={columns} 
                rows={this.state.rows} 
                recordOnClick={this.recordOnClick} 
                ref={this.drawerRef} 
                form={<WrappedInvoiceForm many drawer={this.drawerRef} reLoad={this.load} invoice={this.state.selectedInvoice} ref={this.formRef} />} loading={this.state.loading} 
            />
        </>
        );
    }
}

export default Invoices;