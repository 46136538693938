import React, { Component } from "react";
import ClientForm from "../components/ClientForm";

import { Steps, Button, message, Row, Col, Card } from 'antd';
import Currency from 'react-currency-formatter';

import SelectFromUrl from '../components/SelectFromUrl';
import Zenfy from "../libs/Zenfy";
import CreditCard from '../components/CreditCard';
import OrderForm from '../components/OrderForm';
import moment from 'moment';

const { Step } = Steps;

class Order extends Component {

  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      productId: null,
      product: {},
      invoice: {},
      loading: false,
      client: {},
      amount: 0,
    }

  }

  componentDidMount() {
    if(this.props.match.params.org == 'pride'){
      localStorage.orgId = 2;
    }
  }

  postTransaction = (values) => {
    Zenfy.post('transactions', values).then((res) => {
      
      console.log(res);
      
      message.success('Payement passed with success!');

      setTimeout(() => {
        window.location.reload();
      }, 5000);

    });
  }


  render() {

    return (
      <>
        <Row type="flex" justify="space-around" align="middle">
            <Col className="gutter-row" span={18}>
                
            <div style={{marginTop: '25px'}}>
                <OrderForm order={{}} public orgUrl={this.props.match.params.org} clientId={this.props.match.params.client}/>
            </div>

            </Col>
        </Row>
      </>
    );
  }
}

export default Order;