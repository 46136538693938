import React, { Component } from "react";
import { Timeline, Icon, Steps } from 'antd';

import Zenfy from "../libs/Zenfy";

class Status extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          steps: [],
          currentStep: null,
          loading: false,
        }
    }

    componentDidMount() {
      this.load();
    }

    load = () => {
        Zenfy.get(`${localStorage.orgId}/status`).then((res) => {
            this.setState({
                steps: res.data
            });
        })
    }

    render() {

        let steps = ['tracing', 'ready_terminal', 'prepull_yard', 'dropped_customer', 'ready_pickup', 'customer_yard', 'dropped_terminal', 'invoiced'];

        let position = 0;

        if(this.state.steps.length > 0 && this.props.step){
            this.state.steps.map((step, i) => {
                if(step.name == this.props.step){
                    position = i;
                }
            });
        }

        return (
            <>
                <Steps progressDot current={position} style={{width: '90%', float: 'right', marginTop: '40px'}}>
                    {this.state.steps.map((step) => (
                        <Steps.Step title={step.name} description="" />
                    ))}
                </Steps>
            </>
        );
    }
  }
 
  export default Status;