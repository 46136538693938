import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Modal } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Currency from 'react-currency-formatter';

import moment from 'moment';

import Zenfy from "../../libs/Zenfy";

import ChequeForm from "./ChequeForm";

class Cheques extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: {},
        }

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);

        this.setState({
            selected: record,
        });
    }

    view = (record) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/cheques/${record.id}`, '_blank');
        win.focus();
    }

    delete = (record) => {

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this cheque?',
            okText: 'Yes',
            onOk: () => {
                Zenfy.delete(`cheques/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    render() {
        
        const columns = [{
            title: 'Cheque Number',
            dataIndex: 'cheque_number',
            key: 'cheque_number',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            sorter: (a, b) => a.cheque_number - b.cheque_number,
        },{
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Vendor Name',
            dataIndex: 'client_name',
            key: 'client_name',
            sorter: (a, b) => a.client_name.length - b.client_name.length,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Issue Date',
            dataIndex: 'issue_at',
            key: 'issue_at',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.issue_at) - new Date(b.issue_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.view(record)}>View Cheque</Menu.Item>
                  <Menu.Item key="3" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        },
    ];

        return (
        <>
            <DrawerTableForm 
                columns={columns} 
                ref={this.drawerRef}
                recordOnClick={this.recordOnClick}
                searchProp="cheque_number"
                form={
                    <ChequeForm drawer={this.drawerRef} cheque={this.state.selected} />
                } 
                type="cheques" 
                load="cheques" 
            />
        </>
        );
    }
}

export default Cheques;