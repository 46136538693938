import React, { Component } from "react";
import {
Form, Input, Button, Tabs, notification, Checkbox, DatePicker, Select, TimePicker
} from 'antd';
import moment from 'moment';
import SelectFromUrl from '../components/SelectFromUrl';

const { Option } = Select;

class NormalDriverLegForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            leg: props.leg,
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.leg.id !== this.state.leg.id){

            this.props.form.resetFields();

            this.setState({
                leg: nextProps.leg
            });

        }

    }

    jobTypeOnChange = (type) => {
        let leg = this.state.leg;   
            leg.type = type;

        if(type == 'live'){
            //leg.time_in = moment().format('YYYY-MM-DD HH:mm:ss');
            //leg.time_out = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        this.setState({
            leg
        });
    }

    nowStart = () => {
        let leg = this.state.leg;
            leg.time_in = moment().format('YYYY-MM-DD HH:mm:ss');
        
        this.setState({
            leg
        });
    }

    finishStart = () => {
        let leg = this.state.leg;
            leg.time_out = moment().format('YYYY-MM-DD HH:mm:ss');
        
        this.setState({
            leg
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        const TabPane = Tabs.TabPane;
        const { TextArea } = Input;

        return (
            <>

                <Form className="leg-driver-form">
                
                    <Form.Item
                
                        label="Delivery Type"
                        >
                        {getFieldDecorator('type', {
                            initialValue: this.state.leg.type,
                            rules: [
                                { required: false, message: 'Product is required' }
                            ],
                        })(
                            <Select
                                placeholder="Select a type"
                                onChange={this.jobTypeOnChange}
                            >
                                <Option value="live">Live Delivery</Option>
                                <Option value="drop">Drop</Option>
                            </Select>
                        )}
                    </Form.Item>

                    {this.state.leg.type == 'live' ? (
                        <>
                            <Form.Item
                                label={<span>Start</span>}
                                style={{margin: 0}}
                                >
                                {getFieldDecorator('time_in', {
                                    initialValue: this.state.leg.time_in ? moment(this.state.leg.time_in).format("HH:mm") : null,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Amount is required',
                                        },
                                    ],
                                    placeholder: 'Write the time ex: 17:00'
                                })(
                                    <Input />
                                )}
                                
                            </Form.Item>
                            <a href="javascript:;" onClick={this.nowStart}>Now</a>        
                            <Form.Item
                                style={{margin: '15px 0 0 0'}}
                                label={<span>Finish</span>}
                                >
                                {getFieldDecorator('time_out', {
                                    initialValue: this.state.leg.time_out ? moment(this.state.leg.time_out).format("HH:mm") : null,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                    placeholder: 'Write the time ex: 17:00'
                                })(
                                    <Input />
                                )}
                               
                            </Form.Item>
                            <a href="javascript:;" onClick={this.finishStart}>Now</a>
                        </>
                    ) : null}
                        
                </Form>

            </>
        );
    }
}

const WrappedDriverLegForm = Form.create({ name: 'leg_driver_form' })(NormalDriverLegForm);

export default WrappedDriverLegForm;
