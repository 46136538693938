import React, { Component } from "react";
import { Row, Col, Avatar, Input, Button, Tooltip } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';

import moment from 'moment';

import Zenfy from "../libs/Zenfy";

const { TextArea } = Input;

class Messages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            loading: false,
            message: null,
        };

        this.scroll = React.createRef();
        this.interval = null;
        this.isScrolling = false;
    }

    componentDidMount() {

        this.load();

        this.interval = setInterval(() => {
            this.load(true);
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    load = (silent) => {
        if(!this.props.driver.id){
            return;
        }

        this.setState({
            loading: !silent,
        });

        Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 2}/messages?staff_id=${this.props.driver.id}${!this.props.from_id ? '&clearmsg=1' : ''}`).then((res) => {
            this.setState({
                messages: Zenfy.setKeysToList(res.data),
                loading: false,
            });

            if(this.scroll.current && !this.isScrolling){
                this.scroll.current.scrollToBottom();
            }
        });
    }

    postMsg = () => {
        if(!this.props.driver.id || !this.state.message || this.state.message == ''){
            return;
        }

        this.isScrolling = false;

        this.setState({
            loading: true,
        });

        Zenfy.post(`messages`, {
            org_id: localStorage.orgId ? localStorage.orgId : 2,
            message: this.state.message,
            to_id: this.props.driver.id == this.props.from_id ? 0 : this.props.driver.id,
            from_id: this.props.from_id ? this.props.from_id : 0,
        }).then((res) => {

            this.setState({
                message: null,
                loading: false,
            });

            this.load();
        });
    }

    handleScroll = (e) => {

        this.isScrolling = true;

        //console.log(e.target.clientHeight);
        //console.log(e.target.scrollHeight - e.target.scrollTop);

        if(e.target.clientHeight + 300 >= e.target.scrollHeight - e.target.scrollTop){
            //console.log('bottom');
            this.isScrolling = false;
        }

    }

    //width: 70%;position: relative;overflow: hidden;width: 100%;height: calc(100vh - 268px) !important;

    render() {
        return (
            <>
                {!this.props.driver.id ? (
                    <p>Please select a driver on the left in order to see your messages.</p>
                ) : null}

                <Scrollbars
                    style={{width: '100%'}}
                    className="scroll"
                    ref={this.scroll}
                    autoHide
                    onScroll={this.handleScroll}
                    id={`scroll${this.props.driver.id}`}
                    >
                        {this.state.messages.map((msg) => 
                        <div style={{marginBottom: '10px'}} key={msg.id}>
                            {msg.from_id != (this.props.from_id ? this.props.from_id : 0) ? (
                                <Row>
                                    <Col span={12}>
                                        <Avatar size="medium" icon="user" style={{display: 'none'}}/>
                                        <Tooltip title={moment(msg.created_at).format("HH:mm dddd DD-MM-YYYY")} placement="right">
                                            <div className="bubble">
                                                {msg.file_name ? (
                                                    <img src={msg.file_name} style={{width: '100%'}} />
                                                ) : Zenfy.addLineBreaks(msg.message)}
                                            </div>
                                        </Tooltip>
                                    </Col>
                                    <Col span={12}></Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col span={12}></Col>
                                    <Col span={12}>
                                        <Avatar size="medium" icon="user" style={{float: 'right', display: 'none'}}/>
                                        <Tooltip title={moment(msg.created_at).format("HH:mm dddd DD-MM-YYYY")} placement="left">
                                            <div className="bubble reverse" style={{float: 'right'}}>
                                                {msg.file_name ? (
                                                    <img src={msg.file_name} style={{width: '100%'}} />
                                                ) : Zenfy.addLineBreaks(msg.message)}
                                            </div>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        )}
                </Scrollbars>
                {this.props.driver.id ? (
                    <Row style={{marginTop: '15px', paddingTop: '15px', borderTop: 'solid 1px #eee'}}>
                        <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                            <TextArea value={this.state.message} style={{height: '100px'}} onChange={(e) => {
                                this.setState({
                                    message: e.target.value
                                })
                            }} />     
                        </Col>
                        <Col xs={24} sm={24} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1 }} xl={{ span: 2, offset: 1 }}>
                            <Button type="primary" className="pull-right" loading={this.state.loading} style={{width: '100%', marginBottom: 5, marginTop: 5}} onClick={this.postMsg}>
                                Send
                            </Button>
                            {this.props.postMsg ? (<>
                                <a href="javascript:;" style={{display: 'block', textAlign: 'center'}} onClick={() => {
                                    if(this.props.fullMessage != ''){
                                        this.setState({
                                            message: this.props.fullMessage,
                                        }, () => {
                                            this.props.restartFull();
                                        });
                                    }
                                }}>Post Message</a>
                            </>) : null}
                        </Col>
                    </Row>
                ) : null}        
            </>
        );
    }
}

export default Messages;