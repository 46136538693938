import React, { Component } from 'react';
import { Select, Modal, Button, Form, Input, Row, Col } from 'antd';

import Zenfy from "../libs/Zenfy";

const { Option } = Select;
const ProductForm = Form.create({ name: 'product_form' })(
    // eslint-disable-next-line
    class extends React.Component {
      render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
        };
        return (
            <Form layout="vertical">

            <Row gutter={24}>

                <Col span={12}>

                    <Form.Item 
                        {...formItemLayout}
                        label="Name">
                        {getFieldDecorator('name', {
                        //initialValue: this.props.row.address,
                        rules: [{ required: true }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item 
                        {...formItemLayout}
                        label="Price">
                        {getFieldDecorator('price', {
                        //initialValue: this.props.row.address,
                        rules: [{ required: false }],
                        })(<Input />)}
                    </Form.Item>

                </Col>

                <Col span={12}>

                </Col>

              </Row>

            </Form>
        );
      }
    },
);  

class ProductModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showModal: props.showModal,
            modalLoading: false,
        }

        this.form = React.createRef();
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.showModal !== this.state.showModal){

            //this.props.form.resetFields();

            this.setState({
                showModal: nextProps.showModal
            });

        }

    }

    addProduct = (values) => {
        values.org_id = localStorage.orgId ? localStorage.orgId : 2;

        Zenfy.post('products', values).then((res) => {
            
            if(this.props.afterAddCallBack){
                this.props.afterAddCallBack(res.data);
            }

            this.setState({
                modalLoading: false,
            });

            values.id = res.data;
        });
    }

    handleModalOk = () => {
        this.form.current.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    modalLoading: true,
                });

                this.addProduct(values);
            }
        });
    }

    showModal = () => {
        this.setState({
            showModal: true,
        });
    }

    render() {

        return (
            <>
                <Modal
                    title="Add new template"
                    visible={this.state.showModal}
                    onOk={this.handleModalOk}
                    okText="Add"
                    onCancel={()=> {
                        if(this.props.afterAddCallBack){
                            this.props.afterAddCallBack();
                        }
                        
                        this.setState({
                            showModal: false,
                        });
                    }}
                    okButtonProps={{loading: this.state.modalLoading}}
                >   
                    <ProductForm ref={this.form} />
                </Modal>  
            </>
        );

    }
}

export default ProductModal;