import React, { Component } from 'react';
import { Select, Modal, DatePicker, Form, Input, Radio, Alert } from 'antd';
import Zenfy from '../libs/Zenfy';
import moment from 'moment';

import Labels from '../libs/Labels';

const { Option } = Select;
const AddressForm = Form.create({ name: 'address_form' })(
    // eslint-disable-next-line
    class extends React.Component {
      render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
        };
        return (
            <Form layout="vertical">
              <Form.Item 
                {...formItemLayout}
                label={Labels.get('Address')}>
                {getFieldDecorator('address', {
                  initialValue: this.props.row.address,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input />)}
              </Form.Item>

              <Form.Item 
                {...formItemLayout}
                label={Labels.get('City')}>
                {getFieldDecorator('city', {
                  initialValue: this.props.row.city,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input />)}
              </Form.Item>

              <Form.Item 
                {...formItemLayout}
                label={Labels.get('Country')}>
                {getFieldDecorator('country', {
                  initialValue: this.props.row.country,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(
                  <Select
                      placeholder={Labels.get("Select a country")}
                      showSearch
                  >
                      {Zenfy.getCountries().map((country, index) => 
                          <Option value={country} key={index}>{Labels.get(country)}</Option>
                      )} 
                  </Select>)}
              </Form.Item>  

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Province / State")}>
                {getFieldDecorator('state', {
                  initialValue: this.props.row.state,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Select
                    placeholder={Labels.get("Select a state")}
                >
                    <Option value="Quebec">{Labels.get("Quebec")}</Option>
                    <Option value="Ontario">{Labels.get("Ontario")}</Option>
                    <Option value="New Brunswick">{Labels.get("New Brunswick")}</Option>
                    <Option value="Alberta">{Labels.get("Alberta")}</Option>
                    <Option value="Manitoba">{Labels.get("Manitoba")}</Option>
                    <Option value="British Columbia">{Labels.get("British Columbia")}</Option>
                    <Option value="Saskatchewan">{Labels.get("Saskatchewan")}</Option>
                    <Option value="Nova Scotia">{Labels.get("Nova Scotia")}</Option>
                    <Option value="Newfoundland and Labrador">{Labels.get("Newfoundland and Labrador")}</Option>
                    <Option value="Prince Edward Island">{Labels.get("Prince Edward Island")}</Option>
                    <Option value="Northwest Territories">{Labels.get("Northwest Territories")}</Option>
                    <Option value="Nunavut">{Labels.get("Nunavut")}</Option>
                    <Option value="Yukon">{Labels.get("Yukon")}</Option>
                </Select>)}
              </Form.Item>  

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Postal Code")}>
                {getFieldDecorator('postal', {
                  initialValue: this.props.row.postal,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                  normalize: (value) => {
                    return Zenfy.normalizePostalCode(value);
                  },
                })(<Input />)}
              </Form.Item>
              
              {this.props.isCrm ? (
                <>
                  <Form.Item 
                    {...formItemLayout}
                    label={Labels.get("Head Office")}>
                    {getFieldDecorator('head_office', {
                      initialValue: this.props.row.head_office,
                      rules: [{ required: false, message: 'Please input the title of collection!' }],
                    })(<Radio.Group name="radiogroup">
                    <Radio value={1}>{Labels.get("Yes")}</Radio>
                    <Radio value={0}>{Labels.get("No")}</Radio>
                </Radio.Group>)}
                  </Form.Item>

                  <Form.Item 
                    {...formItemLayout}
                    help="Veuillez lister tous les sites d’affaires qui sont sous la responsabilité de l’entité légale (usine, entrepôts, bureaux, etc.) et inscrire si le site sera inclus dans la certification."
                    label={Labels.get("Sites à inclure dans la Certification")}>
                    {getFieldDecorator('other_locations', {
                      initialValue: this.props.row.other_locations,
                      rules: [{ required: false, message: 'Please input the title of collection!' }],
                    })(<Input.TextArea placeholder={Labels.get("Names, addresses, number of employees") }/>)}
                  </Form.Item>

                </>
              ) : null}
              
            </Form>
        );
      }
    },
);  

const StructureForm = Form.create({ name: 'structure_form' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
      };
      return (
          <Form layout="vertical">
            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Founding year')}>
              {getFieldDecorator('year', {
                initialValue: this.props.row.year,
                rules: [{ required: false, message: 'Please input the title of collection!' }],
              })(<Input />)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('NEQ')}>
              {getFieldDecorator('neq', {
                initialValue: this.props.row.neq,
                rules: [{ required: false, message: 'Please input the title of collection!' }],
              })(<Input />)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Type of ownership')}>
              {getFieldDecorator('ownership', {
                initialValue: this.props.row.ownership,
                rules: [{ required: false, message: 'Please input the title of collection!' }],
              })(<Select
                placeholder={Labels.get("Select ownership")}
            >
                <Option value="Group">{Labels.get("Group")}</Option>
                <Option value="Holding">{Labels.get("Holding")}</Option>
                <Option value="Franchise">{Labels.get("Franchise")}</Option>
                <Option value="Actionnariat">{Labels.get("Actionnariat")}</Option>
            </Select>)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Legal form')}>
              {getFieldDecorator('legal', {
                initialValue: this.props.row.legal,
                rules: [{ required: false, message: 'Please input the title of collection!' }],
              })(<Select
                placeholder={Labels.get("Select legal form")}
            >
                <Option value="Incorporation">{Labels.get("Incoporation")}</Option>
                <Option value="NPO">{Labels.get("NPO")}</Option>
                <Option value="Sole proprietorship">{Labels.get("Sole proprietorship")}</Option>
                <Option value="Partnership">{Labels.get("Partnership")}</Option>
            </Select>)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              onChange={(e) => {
                if(e.target.value == 1){
                  this.props.row.franchise = 1;
                }else{
                  this.props.row.franchise = 0;
                }
              }}
              label={Labels.get('Faites-vous partie d’un groupe ou d’une franchise?')}>
              {getFieldDecorator('franchise', {
                initialValue: this.props.row.franchise ? 1 : 0,
                rules: [{ required: false, message: 'Please input the title of collection!' }],
              })(<Radio.Group name="radiogroup">
                  <Radio value={0}>{Labels.get("No")}</Radio>
                  <Radio value={1}>{Labels.get("Yes")}</Radio>
              </Radio.Group>)}
            </Form.Item>

            {this.props.row.franchise == 1 ? (
              <Form.Item 
                {...formItemLayout}
                  label={Labels.get('Que voulez-vous faire certifier?')}>
                  {getFieldDecorator('franchise_text', {
                    initialValue: this.props.row.franchise_text,
                    rules: [{ required: false, message: 'Please input the title of collection!' }],
                  })(<Select
                    onChange={(e) => {
                      if(e == 'Autres'){
                        this.props.row.franchise_other = true;
                      }else if(this.props.row.franchise_other === true){
                        this.props.row.franchise_other = false;
                      }
                    }}
                    placeholder={Labels.get("Select a type")}
                >
                    <Option value="Le groupe">{Labels.get("Le groupe")}</Option>
                    <Option value="Le siège social">{Labels.get("Le siège social")}</Option>
                    <Option value="Une succursale/site">{Labels.get("Une succursale/site")}</Option>
                    <Option value="Autres">{Labels.get("Autres")}</Option>
                </Select>)}
              </Form.Item>
            ) : null}

            {this.props.row.franchise_other ? (
              <Form.Item 
                {...formItemLayout}
                label={Labels.get('Autre type')}>
                {getFieldDecorator('franchise_other', {
                  initialValue: this.props.row.franchise_other === true ? '' : this.props.row.franchise_other,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input />)}
              </Form.Item>
            ) : null}

            <Form.Item 
              {...formItemLayout}
              onChange={(e) => {
                if(e.target.value == 1){
                  this.props.row.portefeuille = 1;
                }else{
                  this.props.row.portefeuille = 0;
                }
              }}
              label={Labels.get('Faites-vous partie d’une société de portefeuille (holding)?')}>
              {getFieldDecorator('portefeuille', {
                initialValue: this.props.row.portefeuille ? 1 : 0,
                rules: [{ required: false, message: 'Please input the title of collection!' }],
              })(<Radio.Group name="radiogroup">
                  <Radio value={0}>{Labels.get("No")}</Radio>
                  <Radio value={1}>{Labels.get("Yes")}</Radio>
              </Radio.Group>)}
            </Form.Item>
            
            {this.props.row.portefeuille == 1 ? (
              <Form.Item 
                {...formItemLayout}
                label={Labels.get('Quel est le nom de la société?')}>
                {getFieldDecorator('portefeuille_text', {
                  initialValue: this.props.row.portefeuille_text,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input />)}
              </Form.Item>
            ) : null}
            
          </Form>
      );
    }
  },
);  

const ContactForm = Form.create({ name: 'contact_form' })(
  // eslint-disable-next-line
  /*<Form.Item 
                {...formItemLayout}
                label={Labels.get("First Name")}>
                {getFieldDecorator('first_name', {
                  initialValue: this.props.row.first_name,
                  rules: [
                    { 
                      required: true
                  }],
                })(<Input />)}
              </Form.Item>

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Last Name")}>
                {getFieldDecorator('last_name', {
                  initialValue: this.props.row.last_name,
                  rules: [
                    { 
                      required: true
                  }],
                })(<Input />)}
              </Form.Item>*/
  class extends React.Component {
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
      };
      return (
        
          <Form layout="vertical">

            {this.props.isEco ? (
              <Alert style={{marginBottom: 20}} message="Le gestionnaire du comité écoresponsable doit démontrer ses compétences et connaissances en développement durable par sa formation académique ou son expérience de travail. Si les compétences de la personne ne sont pas suffisantes, celle-ci doit suivre avec succès la formation de compétences en gestion du développement durable offerte par le CID." type="success" />
            ) : null}

            <Form.Item 
              {...formItemLayout}
              label={Labels.get("Name")}>
              {getFieldDecorator('name', {
                initialValue: this.props.row.name,
                rules: [
                  { 
                    required: true
                }],
              })(<Input />)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Title')}>
              {getFieldDecorator('title', {
                initialValue: this.props.row.title,
                rules: [{ 
                  required: true
                }],
              })(<Input />)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Email')}>
              {getFieldDecorator('email', {
                initialValue: this.props.row.email,
                rules: [{ 
                  required: true
                }],
              })(<Input />)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Language')}>
              {getFieldDecorator('lang', {
                initialValue: this.props.row.lang,
                rules: [{ 
                  required: true, 
                }],
              })(<Select
                placeholder={Labels.get("Select language")}
            >
                <Option value="fr">{Labels.get("French")}</Option>
                <Option value="en">{Labels.get("English")}</Option>
            </Select>)}
            </Form.Item>

            <Form.Item 
              {...formItemLayout}
              label={Labels.get('Phone')}>
              {getFieldDecorator('telephone', {
                initialValue: Zenfy.normalizeInputPhone(this.props.row.telephone),
                normalize: (value) => {
                    return Zenfy.normalizeInputPhone(value);
                },
                rules: [{ 
                  required: true
                }],
              })(<Input />)}
            </Form.Item>

          </Form>
      );
    }
  },
);  

const Emergency = Form.create({ name: 'emergency_form' })(
    // eslint-disable-next-line
    class extends React.Component {
      render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
        };
        return (
            <Form layout="vertical">
              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Name")}>
                {getFieldDecorator('emergency_name', {
                  initialValue: this.props.row.emergency_name,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input />)}
              </Form.Item>

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Relation")}>
                {getFieldDecorator('emergency_relation', {
                  initialValue: this.props.row.emergency_relation,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Select
                    placeholder={Labels.get("Select relation")}
                >
                    <Option value="Father">{Labels.get("Father")}</Option>
                    <Option value="Mother">{Labels.get("Mother")}</Option>
                    <Option value="Grandfather">{Labels.get("Grandfather")}</Option>
                    <Option value="Grandmother">{Labels.get("Grandmother")}</Option>
                    <Option value="Uncle">{Labels.get("Uncle")}</Option>
                    <Option value="Aunt">{Labels.get("Aunt")}</Option>
                    <Option value="Colleague">{Labels.get("Colleague")}</Option>
                    <Option value="Friend">{Labels.get("Friend")}</Option>
                    <Option value="Significant other">{Labels.get("Significant other")}</Option>
                    <Option value="Child">{Labels.get("Child")}</Option>
                    <Option value="Other">{Labels.get("Other")}</Option>
                </Select>)}
              </Form.Item>  

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Phone")}>
                {getFieldDecorator('emergency_phone', {
                  initialValue: this.props.row.emergency_phone,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input />)}
              </Form.Item>

            </Form>
        );
      }
    },
);  

const Child = Form.create({ name: 'child_form' })(
    // eslint-disable-next-line
    class extends React.Component {
      render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
        };
        return (
            <Form layout="vertical">
              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Name")}>
                {getFieldDecorator('child_name', {
                  initialValue: this.props.row.child_name,
                  rules: [{ required: true, message: 'Name is required' }],
                })(<Input />)}
              </Form.Item>

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Gender")}>
                {getFieldDecorator('child_gender', {
                  initialValue: this.props.row.child_gender,
                  rules: [{ required: true, message: 'Gender is required' }],
                })(<Select
                    placeholder={Labels.get("Select a gender")}
                >
                    <Option value="m">{Labels.get("Male")}</Option>
                    <Option value="f">{Labels.get("Female")}</Option>
                    <Option value="c">{Labels.get("Prefer not to answer")}</Option>
                </Select>)}
              </Form.Item>  

              <Form.Item
                {...formItemLayout}
                label={Labels.get("Date of birth")}
                help="YYYY-MM-DD"
                >
                {getFieldDecorator('child_dob', {
                    initialValue: this.props.row.child_dob ? moment(this.props.row.child_dob, "YYYY-MM-DD HH:mm:ss") : null,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                })(
                    <DatePicker />
                )}
            </Form.Item>

              <Form.Item 
                {...formItemLayout}
                label={Labels.get("Special conditions")}>
                {getFieldDecorator('child_conditions', {
                  initialValue: this.props.row.child_conditions,
                  rules: [{ required: false, message: 'Please input the title of collection!' }],
                })(<Input.TextArea />)}
              </Form.Item>

            </Form>
        );
      }
    },
);  

class Address extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: props.value,
            showModal: false,
            modalLoading: false,
        }

        this.form = React.createRef();
    }

    componentWillReceiveProps(newProps) {

        //if(newProps.value){
            this.setState({
                value: newProps.value,
            });
        //}

    }

    componentDidMount() {
        /*this.setState({loading: true});

        Zenfy.get(this.props.url).then((res) => {

            this.setState({
                items: res.data, 
                loading: false,
            });

        }).catch(error => {
            this.setState({loading: false});
        });*/
    }

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
           // console.log(Object.assign({}, this.state, changedValue));
          onChange(changedValue);
        }
    }

    handleChange = (value) => {

        this.setState({
            value: value,
        });

       /* if(this.props.itemChange){
            this.props.itemChange(this.state.items.find((element) => {
                return element[this.props.idName] == value;
            }));
        }*/

        this.triggerChange(value);
    }

    handleModalOk = () => {
        this.form.current.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.handleChange(values);

                this.setState({
                    showModal: false,
                })
            }
        });
    }

    showModal = () => {
        this.setState({
            showModal: true,
        });
    }

    renderForm = () => {
        switch(this.props.type){
            case 'address':
                return <AddressForm row={this.state.value} ref={this.form} isCrm={this.props.isCrm} />;
            case 'emergency':
                return <Emergency row={this.state.value} ref={this.form} />;
            case 'child':
                return <Child row={this.state.value} ref={this.form} />;
            case 'structure':
                return <StructureForm row={this.state.value} ref={this.form} />;
            case 'contact':
                return <ContactForm row={this.state.value} ref={this.form} isEco={this.props.isEco} />;
        }
    }

    renderText = () => {
        switch(this.props.type){
            case 'address':
                return <>
                <a href="javascript:;" onClick={this.showModal}>{this.state.value && this.state.value.address ? `
                    ${this.state.value.address ? this.state.value.address + ', ' : ''} 
                    ${this.state.value.city ? this.state.value.city + ', ' : ''} 
                    ${this.state.value.state ? this.state.value.state + ', ' : ''} 
                    ${this.state.value.postal ? this.state.value.postal + ', ' : ''}
                    ${this.state.value.country ? this.state.value.country + '' : ''} 
                    ` : Labels.get('Add Address')}
                </a>
            </>;
            case 'structure':
              return <>
              <a href="javascript:;" onClick={this.showModal}>{
                  this.state.value && this.state.value.year ? `
                  ${this.state.value.year ? this.state.value.year + ', ' : ''} 
                  ${this.state.value.neq ? this.state.value.neq + ', ' : ''} 
                  ${this.state.value.ownership ? this.state.value.ownership + ', ' : ''} 
                  ${this.state.value.legal ? this.state.value.legal + ' ' : ''}
                  ` : Labels.get('Add Structure')}
              </a>
          </>;
            case 'emergency':
                return <>
                <a href="javascript:;" onClick={this.showModal}>{this.state.value && this.state.value.emergency_name ? `
                    ${this.state.value.emergency_name ? this.state.value.emergency_name + ' ' : ''} 
                    ${this.state.value.emergency_phone ? this.state.value.emergency_phone + ' ' : ''} 
                    ` : Labels.get('Add Emergency Contact')}
                </a>
            </>;
            case 'child':
                return <>
                    <a href="javascript:;" onClick={this.showModal}>{this.state.value && this.state.value.child_name && !this.state.value.child_name.child_name ? this.state.value.child_name : Labels.get('Add Child')}</a>
            </>;
            case 'contact':
              return <>
              <a href="javascript:;" onClick={this.showModal}>{
                  this.state.value && this.state.value.name ? `
                  ${this.state.value.name ? this.state.value.name + ' ' : ''} 
                  ${this.state.value.email ? this.state.value.email + ' ' : ''} 
                  ${this.state.value.telephone ? this.state.value.telephone + ' ' : ''} 
                  ${this.state.value.title ? this.state.value.title + ' ' : ''} 
                  ` : Labels.get('Add Contact')}
              </a>
          </>;
        }
    }

    render() {

        return (
        <>
            <Modal
                title={Zenfy.capitalizeFirstLetter(Labels.get(this.props.type))}
                visible={this.state.showModal}
                onOk={this.handleModalOk}
                okText={Labels.get("Save")}
                onCancel={()=> this.setState({
                    showModal: false,
                })}
                cancelText={Labels.get("Cancel")}
                okButtonProps={{loading: this.state.modalLoading}}
                className={`${Zenfy.inIframe() ? "center-element" : ""}`}
            >   
                {this.renderForm()}
            </Modal>

            
            {this.renderText()}
            
        </>);
    }
}

export default Address;