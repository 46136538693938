import React, { Component } from "react";

import { Table, Button, Icon, Menu, Dropdown, Drawer, message, Divider, Upload, Modal, Input, notification, Checkbox, Tabs, } from 'antd';

import DynamicForm from "./DynamicForm";
import SelectFromUrl from './SelectFromUrl';

import * as XLSX from 'xlsx';

import Zenfy from "../libs/Zenfy";
import Labels from "../libs/Labels";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class DrawerTableForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        drawerVisible: false,
        selected: {},
        rows: [],
        searchVal: Zenfy.getParameterByName('searchVal') ? Zenfy.getParameterByName('searchVal') : '',
        saveLoading: false,
        emailModal: false,
        emailModalLoading: false,
        templateChecked: true,
        sendSms: false,
        emailBody: '',
        subject: '',
    }

    this.escFunction = this.escFunction.bind(this);
    this.formRef = React.createRef();
    this.tableRows = [];
    this.rows = [];
    this.emailSent = 0;
    this.template = true;
    this.importFile = null;
  }

  escFunction(event) {
    if(event.keyCode === 27) {
      this.setState({
        drawerVisible: false,
      });
    }
  }

  componentDidMount() {
    this.load();

    document.addEventListener("keydown", this.escFunction, false);

    this.openId = Zenfy.getParameterByName('openId');
  }

  load = () => {
    if (this.props.load){
      
      this.setState({
          loading: true,
      });

      Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/${this.props.load}`).then((res) => {

        /*res.data.map((rows) => {
          if(rows.active){
            if(rows.active == 1){
              rows.active = true;
            }else{
              rows.active = false;
            }
          }
        });*/
         
          if(this.props.beforeLoad){
            this.props.beforeLoad(res.data);
          }

          this.setState({
              loading: false,
              rows: res && res.data ? Zenfy.setKeysToList(res.data) : [],
          }, () => {
            if(this.openId){
                setTimeout(() => {
                    document.getElementById(`${this.props.type}-${this.state.searchVal}`).click();
                    this.openId = null;
                }, 500);
            }    

            //this.filterClickOk();

          })
      });
    }else{
      if(this.props.reLoad){
        this.props.reLoad();
      }
    }
  }

  filterClickOk = () => {
    var base = document.querySelector('body'); // the container for the variable content
    var selector = '.ant-dropdown-menu-item'; // any css selector for children

        base.addEventListener('click', function(event) {
        // find the closest parent of the event target that
        // matches the selector
        var closest = event.target.closest(selector);
        if (closest && base.contains(closest)) {
            var confirm = document.getElementsByClassName("ant-table-filter-dropdown-link confirm");
            if(confirm && confirm.length > 0){
              confirm[0].click();
            }
        }
    });
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  componentWillReceiveProps(props) {

    //this.componentHasGotData(props);

  }

  handleAdd = () => {
    this.setState({
      drawerVisible: true,
      selected: {},
    });
    
    if(this.props.recordOnClick){
      this.props.recordOnClick({});
    }
  }

  onCloseDrawer = () => {
    this.setState({
      drawerVisible: false
    });
  }

  reloadGrid = () => {
    this.setState({
      loading: true
    });
  }

  rowClick = (record) => {

    if(this.formRef.current){
      this.formRef.current.getForm().resetFields();
    }

    this.setState({
      drawerVisible: true,
      selected: record
    });

    
  }

  hideDrawer = () => {
    this.setState({
      drawerVisible: false
    });
  }

  duplicate = (record) => {

    delete record.id;
    delete record.key;

    Zenfy.post(this.props.load ? this.props.load : this.props.onSaveUrl, record).then((res) => {
        notification.success({
            message: `${Zenfy.capitalizeFirstLetter(this.props.type)} duplicated with success!`,
            placement: 'bottomLeft',
        });
        this.load();
    }).catch(error => {
      if(error.response){
        notification.error({
          message: <div dangerouslySetInnerHTML={{ __html: error.response.data }} />,
          placement: 'bottomLeft',
        });
      }
    });

  }

  handleSubmit = (e) => {

    let data = this.formRef.current.getForm().getFieldsValue();

    data.id = this.state.selected.id ? this.state.selected.id : 0;
    data.org_id = localStorage.orgId ? localStorage.orgId : 1;

    if(this.props.beforeSave){
      data = this.props.beforeSave(data);
    }

    this.setState({
      saveLoading: true
    });

    Zenfy.post(this.props.load ? this.props.load : this.props.onSaveUrl, data).then((res) => {
        
        this.setState({
            saveLoading: false,
            drawerVisible: this.props.type == 'customer' || this.props.keepDrawerOpen,
            //drawerVisible: false,
        });
        
        notification.success({
            message: `${Zenfy.capitalizeFirstLetter(this.props.type)} saved with success!`,
            placement: 'bottomLeft',
        });

        if(this.props.afterSave){
          this.props.afterSave(data);
        }

        this.load();

        if(this.props.type != 'customer'){
          this.formRef.current.getForm().resetFields();
        }else{
          this.setState({
            selected: {id: res.data}
          });
        }
        
    }).catch(error => {
      this.setState({
        saveLoading: false,
      });
      if(error.response){
        notification.error({
          message: <div dangerouslySetInnerHTML={{ __html: error.response.data }} />,
          placement: 'bottomLeft',
        });
      }
    });

  }

  searchOnChange = (e) => {

    this.setState({
        searchVal: e.target.value
    });

  }

  getTitle = () => {

    let searchProp = this.props.searchProp;

    if(!this.props.searchProp){
      searchProp = 'name';
    }

    return this.state.selected[searchProp] ? this.state.selected[searchProp] : this.props.title ? this.props.title : `Add a new ${this.props.type}`;
  }

  handleExport = () => {
    if(this.props.handleExport){
      return this.props.handleExport();
    }

    let cleanRows = this.tableRows.length > 0 && this.state.searchVal == '' ? this.props.cleanRows(this.tableRows) : this.props.cleanRows(this.props.rows ? this.props.rows : this.rows);
    Zenfy.exportDataToCsv(cleanRows, this.props.type);
  }

  handleEmail = () => {
    this.setState({
      emailModal: true,
    });
  }

  handleTestEmail = () => {  
    let org = JSON.parse(localStorage.org);

    Zenfy.post(`${localStorage.orgId}/email`, {
      email_body: this.email_body,
      subject: `Preview: ${this.email_subject}`,
      template: this.state.templateChecked ? 1 : 0,
      sms: this.state.sendSms ? 1 : 0,
      telephone: org.sms_copy
    }).then((res) => {
      notification.success({
        message: Labels.get(`Test email sent with success to ${res.data.email}! Please look at your spam folder if you haven't received anything...`),
        placement: 'bottomLeft',
      });

      if(this.state.sendSms){
        notification.success({
          message: Labels.get(`SMS sent with success to ${org.sms_copy}!`),
          placement: 'bottomLeft',
        });
      }
    });

  }

  sendEmail = (rows, callback) => {

    if(rows.length > 0){

      let client = rows.pop();
      this.emailSent++;
      let pur = 100 * this.emailSent / this.cleanRows.length;
      document.querySelector('.sending_to span').innerHTML  = `${this.emailSent} / ${this.cleanRows.length} (${pur.toFixed()}%)`;

      Zenfy.post(`${localStorage.orgId}/email`, {
        email_body: this.email_body,
        subject: this.email_subject,
        email: client.email,
        client_id: client.id,
        telephone: client.telephone,
        template: this.state.templateChecked ? 1 : 0,
        sms: this.state.sendSms ? 1 : 0,
        row: client
        //email: 'michael.shpigelman@gmail.com'
      }).then(() => {
        this.sendEmail(rows, callback);
      }).catch(error => {
        if(error.response){
          notification.error({
            message: <div dangerouslySetInnerHTML={{ __html: error.response.data }} />,
            placement: 'bottomLeft',
          });
        }
        this.sendEmail(rows, callback);
      });

    }else{
      if(callback){
        callback();
      }
    }

  }

  emailModalhandleOk = () => {

    const { confirm } = Modal;
    this.cleanRows = this.tableRows.length > 0 && this.state.searchVal == '' ? this.tableRows : this.props.rows ? this.props.rows : this.rows;

    let cleanEmailRows = [];

    this.cleanRows.map((row) => {
      if(row.email){

        let found = false;

        cleanEmailRows.map((emailRow) => {
          if(emailRow.email == row.email){
            //found = true;
          }
        })

        if(!found){
          cleanEmailRows.push(row);
        }              
      }
    });

    confirm({
        title: `Do you want to email this to ${cleanEmailRows.length} ${this.props.type}(s)? (Please do not close the window when the emails are sent)`,
        okText: Labels.get('Yes'),
        okType: 'warning',
        onOk: () => {

          document.querySelector('.sending_to').style.display = 'block';
          
          this.setState({
            emailModalLoading: true,
          });

        
          this.sendEmail([...cleanEmailRows], () => {
            
            document.querySelector('.sending_to').style.display = 'none';

            Zenfy.post(`${localStorage.orgId}/email`, {
              email_body: this.email_body,
              subject: `You send: ${this.email_subject} to ${this.emailSent} ${this.props.type}(s)!`,
              template: this.state.templateChecked ? 1 : 0
            });

            notification.success({
              message: Labels.get(`Email sent with success to ${this.emailSent} ${this.props.type}(s)!`),
              placement: 'bottomLeft',
            });

            this.emailSent = 0;

            this.setState({
              emailModal: false,
              emailModalLoading: false,
            });
          })

        },
        onCancel: () => {
          
        },
    });

  }

  onFileChange = (e) => {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

        this.importFile = dataParse;

        //setFileUploaded(dataParse);
    };
    reader.readAsBinaryString(f)
  }

  render() {

    this.rows = this.state.rows.filter((obj) => {

        if (this.state.searchVal != '') {

            let searchProp = this.props.searchProp;

            if(!this.props.searchProp){
              searchProp = 'name';
            }

            return  obj[searchProp] && obj[searchProp].toString().toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 
            || (obj.id ? obj.id.toString().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.utx_id ? obj.utx_id.toString().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.instructions ? obj.instructions.toString().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.return_instructions ? obj.return_instructions.toString().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.container ? obj.container.toString().toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.client_name ? obj.client_name.toString().toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.reference ? obj.reference.toString().toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false)
            || (obj.delivery_client_name ? obj.delivery_client_name.toString().toLowerCase().indexOf(this.state.searchVal.toLowerCase()) !== -1 : false);
        }
    
        return true;
    });

    let searchInputProps = {
      value: this.state.searchVal
    };

    if(this.props.searchValue != null){
      searchInputProps = {
        value: this.props.searchValue
      }
    }

    if(this.props.showSearch){
      searchInputProps = {};
    }
    
    return (
      <>
        {(this.props.searchOnChange || this.props.load || this.props.showSearch) && !this.props.hideSearch ? (
          <Input {...searchInputProps} allowClear placeholder={`Search for a ${this.props.type}`} className="pull-left" style={{maxWidth: '400px', marginBottom: 16}} onChange={this.props.load ? this.searchOnChange : this.props.searchOnChange} />
        ) : null}

        {this.props.sideSearch}
        
        {!this.props.small && !this.props.noAdd ? (
          <Button onClick={this.props.handleAdd ? this.props.handleAdd : this.handleAdd} type="dashed" className="pull-right" style={{ marginBottom: 16 }}>
            <Icon type="plus" /> {Labels.get("Add")}
          </Button>
        ) : null}

        {this.props.rightBtns}

        {this.props.export ? (
          <>
            <Button onClick={this.handleExport} type="dashed" style={{ marginBottom: 16, marginRight: 16}} className="pull-right" >
              <Icon type="export" /> {Labels.get("Export")}
            </Button>
          </>
        ) : null}

        
        {this.props.import ? (
          <>
            <Modal
              title={Labels.get("Import")}
              visible={this.state.showImportModal}
              onOk={() => {
                if(this.importFile && this.props.importDatas){
                  this.props.importDatas(this.importFile, () => {
                    this.setState({
                      showImportModal: false,
                    });
                  });
                }
              }}
              okText={Labels.get("Import")}
              okButtonProps={{loading: this.state.importModalLoading}}
              onCancel={() => {
                this.setState({
                  showImportModal: false,
                })
              }}
            > 
              <p>{Labels.get("Please match the use this excel template to import your contacts:")}</p>
              <p style={{display: 'none'}} className="importing_number">Importing <span></span></p>
              <p className="errors"></p>
              <input type="text" placeholder="Donation date" onChange={({target}) => {
                if(this.props.dateOnChange){
                  this.props.dateOnChange(target.value);
                }
              }} />
              <br />
              <input type="file" onChange={this.onFileChange} /> 
            </Modal>

            <Button onClick={() => {
              this.setState({
                showImportModal: true,
              });
            }} type="dashed" style={{ marginBottom: 16, marginRight: 16}} className="pull-right" >
              <Icon type="import" /> {Labels.get("Import")}
            </Button>
          </>
        ) : null}

        {this.props.email ? (
          <>
             <Modal
                title={Labels.get("Email")}
                visible={this.state.emailModal}
                onOk={this.emailModalhandleOk}
                okText={Labels.get("Send")}
                okButtonProps={{loading: this.state.emailModalLoading}}
                onCancel={() => {
                  this.setState({
                    emailModal: false,
                  })
                }}
            > 
              <p style={{display: 'none'}} className="sending_to">Sending to <span></span></p>
              
              <Input
                name="email_subject"
                value={this.state.subject}
                onChange={({target}) => {
                  this.email_subject = target.value;
                  this.setState({
                    subject: target.value,
                  });
                }}
                placeholder={Labels.get("Subject")}
                style={{marginBottom: "5px"}}
              />

              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Editor" key="1">
                  <ReactQuill 
                    theme="snow" 
                    rows={15} 
                    onChange={(val) => {
                      this.email_body = val;
                    }} 
                    name="email_body" 
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="HTML" key="2">
                  <SelectFromUrl  
                    url={`${localStorage.orgId ? localStorage.orgId : 2}/templates`} 
                    idName="id"
                    style={{width: '100%', marginBottom: 5}}
                    //key={Math.random()}
                    itemChange={(item) => {
                      this.setState({
                        emailBody: item.html,
                        subject: item.subject,
                      });
                    }}
                    altRender={(row) => {
                      return `${row.name} ${row.lang}`
                    }}
                  />
                  <Input.TextArea value={this.state.emailBody} name="email_body" style={{height: 200}} onChange={({target}) => {
                    this.email_body = target.value;
                    this.setState({
                      emailBody: target.value
                    })
                  }} />
                </Tabs.TabPane>
              </Tabs>
              
              <Checkbox 
                style={{marginTop: '10px'}}
                checked={this.state.templateChecked}
                onChange={(e) => {
                  this.setState({
                    templateChecked: e.target.checked
                  });
                }} 
              >
                  {Labels.get("Send with ZENFY template")}
              </Checkbox>

              <Checkbox 
                style={{marginTop: '10px'}}
                checked={this.state.sendSms}
                onChange={(e) => {
                  this.setState({
                    sendSms: e.target.checked
                  });
                }} 
              >
                  {Labels.get("Send SMS")}
              </Checkbox>

              <a href="javascript:;" onClick={this.handleTestEmail} style={{ float: 'right', marginTop: '10px'}}>{Labels.get("Send a test email")}</a>
            </Modal>

            <Button onClick={this.handleEmail} type="dashed" style={{ marginBottom: 16, marginRight: 16}} className="pull-right" >
              <Icon type="mail" /> {Labels.get("Email")}
            </Button>
          </>
        ) : null}
        
        <div className="clearfix" />

        {!this.props.modal ? (

          <Drawer
          title={!this.props.getTitle ? this.getTitle() : this.props.getTitle()}
          width="75%"
          onClose={this.onCloseDrawer}
          visible={!this.props.modal && this.state.drawerVisible}
          style={{
           /*   overflow: 'auto',
          height: 'calc(100% - 108px)',
            paddingBottom: '108px',*/
          }}
          >
            {this.props.form ? this.props.form : (
                <DynamicForm type={this.props.type} keys={this.props.columns} row={this.state.selected} handleSubmit={this.handleSubmit} ref={this.formRef} loading={this.state.saveLoading} hideDrawer={this.hideDrawer} />
            )}
          </Drawer>

        ) : (
          
          <Modal
            title={this.state.selected.name ? this.state.selected.name.replace(/<[^>]*>?/gm, '') : this.props.title ? this.props.title : this.state.selected.id ? this.state.selected.id : 'Add a new'}
            visible={this.props.modal == true && this.state.drawerVisible}
            onOk={this.handleOk}
            onCancel={this.hideDrawer}
            width={this.props.bigModal ? '70%' : undefined}
            footer={this.props.hideFooter ? null :
                <>
                  <Button key="back" onClick={this.hideDrawer}>
                    {Labels.get("Cancel")}
                  </Button>
                  <Button key="submit" type="primary" onClick={this.props.onSave}>
                  {Labels.get("Save")}
                  </Button>
                </>
            }>

            {this.props.form ? this.props.form : (
                <DynamicForm 
                  type={this.props.type} 
                  keys={this.props.columns} 
                  row={this.state.selected} 
                  handleSubmit={this.props.handleSubmit ? this.props.handleSubmit : this.handleSubmit} 
                  ref={this.formRef} 
                  loading={this.state.saveLoading} 
                  hideDrawer={this.hideDrawer} 
                  hideFooter={this.props.hideFormFooter} 
                />
            )}

            </Modal>

        )}

        {!this.props.hideTable ? (
          <Table 
            locale={{
              filterConfirm: Labels.get("Filter"),
              filterReset: Labels.get("Reset"),
              emptyText: Labels.get("No Data"),
            }}
            scroll={this.props.scroll}
            size={this.props.small || this.props.forceSmall ? 'small' : 'default'} 
            dataSource={this.props.load ? this.rows : this.props.rows} 
            columns={this.props.columns} 
            loading={this.props.load ? this.state.loading : this.props.loading} 
            expandedRowRender={this.props.expandedRowRender} 
            onRow={(record) => {
                return {
                    onClick: event => this.props.onRowClick ? this.props.onRowClick(event, record) : {}, // click row
                    onContextMenu: event => this.props.onContextMenu ? this.props.onContextMenu(event, record) : {},
                };
            }}
            rowClassName={this.props.rowClassName}
            onChange={(pagination, filters, sorter, extra) => {
              if(this.props.tableOnChange){
                this.props.tableOnChange(pagination, filters, sorter, extra);
              }
              this.tableRows = extra.currentDataSource;
            }}
            pagination={this.props.pagination}
          />
        ) : null}

      </>
    );
  }
}

export default DrawerTableForm;