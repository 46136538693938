import React, { Component } from "react";
import { Form, Input, Icon, Button } from 'antd';

import LegForm from './LegForm';

class Legs extends React.Component {

    constructor(props) {
        super(props);

        if(!props.order.legs || props.order.legs.length == 0){
            props.order.legs = [{}];
        }

        this.state = {
            order: props.order,
            loading: false,
        }

        this.formRefs = [];
        window.legs = this;
    }

    /*componentWillReceiveProps(nextProps) {
        if(this.state.order.legs !== nextProps.order.legs){

            if(!nextProps.order.legs || nextProps.order.legs.length == 0){
                nextProps.order.legs = [{}];
            }

            this.setState({
                order: nextProps.order
            },() => {
               
            });
        }
    }*/

    add = () => {

        let legs = this.state.order.legs;
            legs.push({});

        this.setState((prevState) => ({
            order: {
                ...prevState.order,
                legs,
            }
        }));

        /*this.setState((prevState) => ({
            legs
            loading: false
        }));*/
    }

    remove = (index) => {

        var legs = [...this.state.order.legs]; // make a separate copy of the array
        legs.splice(index, 1);

        this.formRefs.splice(index, 1);

        let order = this.state.order;
        order.legs = legs;

        this.setState({
            order
        });

        /*this.setState((prevState) => ({
            order: {
                ...prevState.order,
                legs,
            }
        }));*/


    }
  
    render() {
        
      const formItems = this.state.order.legs ? this.state.order.legs.map((leg, index) => (
        <LegForm 
            ref={element => {
                this.formRefs[index] = element;
            }} 
            leg={leg} index={index} key={index} remove={() => this.remove(index)} 
        />
      )) : [];

      return (
        <>
            {formItems}
            
            <center>
                <Button type="dashed" onClick={this.add} style={{ width: '60%', marginBottom: 20 }}>
                    <Icon type="plus" /> Add
                </Button>
            </center>

        </>
      );
    }
  }
 
  export default Legs;