import React, { Component } from "react";
import { Button, Icon, Menu, Dropdown, Popconfirm, Badge, Tag, Modal } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Zenfy from "../../libs/Zenfy";

import Currency from 'react-currency-formatter';

class GymProducts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cat: [],
            subCat: [],
        }

        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    delete = (record) => {
        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this product?',
           // content: 'Some descriptions',
            onOk: () => {
                Zenfy.delete(`products/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });
    }

    duplicate = (record) => {
        record.id = null;
        delete record.count_members;
        delete record.key;
        record.name = 'Copy ' + record.name;

        // need to duplicate schedule
        Zenfy.post("products", record).then((res) => {
            this.drawerRef.current.load();
        });
    }

    copyLink = (record) => {
        let url = `${Zenfy.getFrontEndRoot()}${localStorage.url}/register?product=${record.id}`;
        window.open(url, '_blank');
    }

    render() {

        const columns = [{
            title: 'Name/ID',
            dataIndex: 'name',
            key: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            required: true,
            tab: 'Basic',
        },{
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            filters: [{text: 'Active', value: 1}, {text: 'Inactive', value: 0}],
            onFilter: (value, record) => record.active == value,
            render: (text, record) => text === 0 ? <span><Badge status="error" text="Inactive" /></span> : <span><Badge status="success" text="Active" /></span>,
            type: 'switch',
            tab: 'Basic',
        },{
            title: 'Description',
            dataIndex: 'desc',
            key: 'desc',
            className: 'hide',
            type: 'text',
            tab: 'Basic',
        },{
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            type: "autocomplete",
            filters: this.state.cat,
            sorter: (a, b) => a.category !== b.category ? a.category < b.category ? -1 : 1 : 0,
            // className: 'hide',
            tab: 'Basic',
            onFilter: (value, record) => record.category.indexOf(value) === 0,
        },{
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'sub_category',
            type: "autocomplete",
            filters: this.state.subCat,
            sorter: (a, b) => a.sub_category !== b.sub_category ? a.sub_category < b.sub_category ? -1 : 1 : 0,
            // className: 'hide',
            tab: 'Basic',
            onFilter: (value, record) => record.sub_category.indexOf(value) === 0,
        },{
            title: 'Email Subject',
            dataIndex: 'email_subject',
            key: 'email_subject',
            className: 'hide',
            tab: 'Email',
        },{
            title: 'Email Content',
            dataIndex: 'email_body',
            key: 'email_body',
            type: 'html',
            className: 'hide',
            tab: 'Email',
        },{
            title: 'Contract',
            dataIndex: 'contract',
            key: 'contract',
            type: 'html',
            className: 'hide',
            tab: 'Contract',
        },{
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <Currency quantity={text} currency="CAD" />,
            sorter: (a, b) => a.price - b.price,
            tab: 'Basic',
        },{
            title: 'Document',
            dataIndex: 'document',
            key: 'document',
            className: 'hide',
            tab: 'Basic',
        },{
            title: 'Taxes',
            dataIndex: 'taxes',
            key: 'taxes',
            className: 'hide',
            type: 'switch',
            tab: 'Basic',
        },{
            title: 'Digital',
            dataIndex: 'is_digital',
            key: 'is_digital',
            className: 'hide',
            type: 'switch',
            help: 'If its an online book or something similar',
            tab: 'Basic',
        },{
            title: 'Kids',
            dataIndex: 'child',
            key: 'child',
            className: 'hide',
            type: 'switch',
            help: 'If its a kids class, allow people to add a child',
            tab: 'Basic',
        },{
            title: 'Force Recurring Monthly',
            dataIndex: 'force_monthly',
            key: 'force_monthly',
            className: 'hide',
            type: 'switch',
            help: 'Force the reccuring of the product every month (first month price will be calculated within the days left)',
            tab: 'Basic',
        },{
            title: 'Collecting Monthly Day',
            dataIndex: 'monthly_day',
            key: 'monthly_day',
            className: 'hide',
            help: 'The day you want to collect monthly payments. Leave at 0 if you want the person to be billed at the day of registration.',
            tab: 'Basic',
        },{
            title: 'Covid',
            dataIndex: 'covid',
            key: 'covid',
            className: 'hide',
            type: 'switch',
            help: 'Show COVID related contract',
            tab: 'Contract',
        },{
            title: 'Months',
            dataIndex: 'months',
            key: 'months',
            className: 'hide',
            help: 'How many months this product is valid (ex: 12 months - write 12)',
            tab: 'Basic',
        },{
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            className: 'hide',
            sorter: (a, b) => a.count - b.count,
            help: 'How many passes this product gives (ex: 10 passes - write 10)',
            tab: 'Basic',
        },{
            title: 'Inscriptions / Places',
            dataIndex: 'places',
            key: 'places',
            help: 'Limit inscriptions to this number of places',
            render: (text, record) => {return record.places > 0 ? `${record.count_members} / ${text}` : record.count_members},
            tab: 'Basic',
        },{
            title: 'End date',
            dataIndex: 'end_at',
            key: 'end_at',
            className: 'hide',
            type: 'date',
            help: 'Ending date of the class',
            tab: 'Basic',
        },{
            title: 'Schedule',
            dataIndex: 'schedule',
            key: 'schedule',
            type: 'schedule',
            className: 'hide',
            tab: 'Schedule',
            //type: 'address',
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {
      
              const menu = (
                <Menu>
                    <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                    <Menu.Item key="3" onClick={()=>this.copyLink(record)}>View Registration Link</Menu.Item>   
                    <Menu.Item key="4" onClick={()=>this.duplicate(record)}>Duplicate</Menu.Item>   
                    <Menu.Item key="2" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );
      
              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        }];

        return (
        <>
            <DrawerTableForm columns={columns} ref={this.drawerRef} form={null} type="product" load="products" 
                beforeLoad={(data) => {
                    this.setState({
                        cat: Zenfy.getCategoriesFilters(data, 'category'),
                        subCat: Zenfy.getCategoriesFilters(data, 'sub_category')
                    });
                }}
                rightBtns={<>
                    {localStorage.type != 'crm' ? (
                        <>
                        <Button type="primary" style={{float: 'right', marginRight: 10}} onClick={() => {
                            let url = `${Zenfy.getFrontEndRoot()}${localStorage.url}/register`;
                            window.open(url, '_blank');
                        }}>View Public Form</Button>
                        <Button type="primary" style={{float: 'right', marginRight: 10}} onClick={() => {
                            let url = `${Zenfy.getFrontEndRoot()}${localStorage.url}/scan`;
                            window.open(url, '_blank');
                        }}>View Scan Page</Button>
                        </>
                    ) : null} 
                </>}
            />
        </>
        );
    }
}

export default GymProducts;