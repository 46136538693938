import React, { Component } from "react";
import { Modal, Menu, Button, Icon, Dropdown } from 'antd';

import DrawerTableForm from '../../components/DrawerTableForm';

import Currency from 'react-currency-formatter';

import moment from 'moment';
import Zenfy from "../../libs/Zenfy";

class Expenses extends Component {

    constructor(props) {
        super(props);
        this.drawerRef = React.createRef();
    }

    recordOnClick = (record) => {
        this.drawerRef.current.rowClick(record);
    }

    viewInvoice = (invoice) => {
        var win = window.open(`${Zenfy.getRoot()}${localStorage.orgId}/invoice/${invoice.invoice_id}?lang=${localStorage.Lang}`, '_blank');
        win.focus();
    }

    delete = (record, event) => {

        if(event){
            event.stopPropagation();
        }

        const { confirm } = Modal;

        confirm({
            title: 'Do you want to delete this transaction? (All information will be lost)',
            okText: 'Yes',
            onOk: () => {
                Zenfy.delete(`expenses/${record.id}`).then((res) => {
                    this.drawerRef.current.load();
                });
            },
            onCancel: () => {
              
            },
        });

    }

    render() {
        
        const columns = [{
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <a href="javascript:;" onClick={()=>this.recordOnClick(record)}>{text}</a>,
            sorter: (a, b) => a.id - b.id,
            hide: true,
            //className: 'hide',
        },{
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            //render: (text) => <Currency quantity={text} currency="CAD" />,
        },{
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            type: 'date',
            render: (text) => {return moment(text).format("MMMM Do YYYY")},
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },{
            title: 'Actions',
            key: 'action',
            render: (text, record) => {

              const menu = (
                <Menu>
                  <Menu.Item key="1" onClick={() => this.recordOnClick(record)}>Edit</Menu.Item>
                  <Menu.Item key="invoice" onClick={() => this.viewInvoice(record)}>View Invoice</Menu.Item>
                  <Menu.Item key="2" onClick={() => this.delete(record)}>Delete</Menu.Item>
                </Menu>
              );

              return (<Dropdown overlay={menu}>
                <Button size="small">
                  <Icon type="down" />
                </Button>
              </Dropdown>
              )
            }
        },
    ];

        return (
        <>
            <DrawerTableForm  
                hideFooter 
                columns={columns} 
                ref={this.drawerRef} 
                form={null} 
                type="expense" 
                load="expenses" 
            />
        </>
        );
    }
}

export default Expenses;