import JsonLabels from '../libs/JsonLabels';
import LabelsEn from '../lang/en.json';
import LabelsFr from '../lang/fr.json';

const Labels = new JsonLabels({
  en: LabelsEn,
  fr: LabelsFr,
});

export default Labels;
