import React, { Component } from "react";
import ReactMapGL, {Marker, Popup, NavigationControl, FullscreenControl} from 'react-map-gl';

import Zenfy from "../libs/Zenfy";

import CityPin from "./CityPin";

const MARKERS = [{
  lat: 45.443830,
  lng: -73.661180,
  info: 'Office!',
},{
  lat: 45.502430,
  lng: -73.679340,
  info: 'Ar7',
}];

class Map extends Component {

  state = {
    viewport: {
      width: '100%',
      height: this.props.height ? this.props.height : 600,
      latitude: 45.5017,
      longitude: -73.5673,
      zoom: 10
    },
    marker: null,
    markers: [],
  };

  componentDidMount() {

    this.loadMarkers(this.props.items);

    /*res.data.map((marker) => {
      marker.lng = Number(marker.lng);
      marker.lat = Number(marker.lat);
    });

    this.setState({
        loading: false,
        markers: Zenfy.setKeysToList(res.data),
    });*/

  }

  componentWillReceiveProps(newProps) {

      // this.componentHasGotData(newProps);
      console.log(newProps);
      this.loadMarkers(newProps.items);
  }

  loadMarkers = (items) => {

    let markers = [];

    items.map((order) => {
      if(order.lat && order.lng){
        let color = "#f5222d";
        switch(order.status){
          case "ready_pickup":
            color = "#1890ff";
          break;
          case "dropped_customer":
            color = "#faad14";
          break;
        }

        markers.push({
          lng: Number(order.lng),
          lat: Number(order.lat),
          name: order.delivery_client_name,
          color,
          order
        });

        // gotta check the if there is a driver that doesnt do this leg
      }

      // get drivers markers
      order.legs.map((leg) => {
        if(leg.driver_lat && leg.driver_lng){
          markers.push({
            lng: Number(leg.driver_lng),
            lat: Number(leg.driver_lat),
            name: leg.driver_name,
            color: "black"
          });
        }
      });
     
    });

    //if(markers.length > 0){
    this.setState({
      markers: Zenfy.setKeysToList(markers)
    });
  }

  render() {
    return (
      <ReactMapGL
        {...this.state.viewport}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken="pk.eyJ1IjoibXNocGlnZWxtYW4iLCJhIjoiY2swN2puaGJ3MDgzMzNtcDBnb2J5aHI5NiJ9.Vu8XWZA2ueTHgboIkMkcCw"
        onViewportChange={(viewport) => this.setState({viewport})}
      >
      
      {this.state.markers.map((marker, index) => {
        if(marker.lng && marker.lat){
          return <Marker key={`marker-${index}`} longitude={marker.lng} latitude={marker.lat}>
            <CityPin size={20} onClick={() => this.setState({marker})} color={marker.color ? marker.color : null} />
          </Marker>;
        }
      })}

      {this.state.marker ? (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={this.state.marker.lng}
          latitude={this.state.marker.lat}
          closeOnClick={false}
          onClose={() => this.setState({marker: null})}
        >
          {this.state.marker.name}
          <br/>
          {this.state.marker.order ? (
            <a href="javascript:;" onClick={() => this.props.showOrder(this.state.marker)}>View Order</a>
          ) : null}
        </Popup>
      ) : null}
      
      </ReactMapGL>
    );
  }
}

export default Map;