import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Currency from 'react-currency-formatter';

import moment from 'moment';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
} from '../libs/Utils';

import {
    Select, Divider, Form, Input, Row, Col, Alert, Card, Spin, Checkbox
} from 'antd';
import Zenfy from '../libs/Zenfy';

import Labels from '../libs/Labels';

class CreditCard extends Component {

    constructor(props){
        super(props);

        this.state = {
            number: '',
            expiry: '',
            cvc: '',
            issuer: '',
            focused: '',
            formData: null,
            error: null,
            success: false,
            loading: false,
            client: props.client,
            loadingCard: false,
            hideCard: false,
            recurring_end: null,
        }

        window.creditCardForm = this;
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.client.id !== this.state.client.id || nextProps.client.name !== this.state.client.name){
            this.setState({
                client: nextProps.client,
                number: '',
                expiry: '',
                cvc: '',
                issuer: '',
                focused: '',
            });

            this.loadStripeCard(nextProps.client.customer_id);
        }

    }

    loadStripeCard = (customerId) => {

        if(customerId){

            this.setState({
                loadingCard: true
            });

            Zenfy.get(`${localStorage.orgId ? localStorage.orgId : 1}/creditcards`,{
                customerId
            }).then((res) => {
                if(!res.data){
                    return;
                }
                
                let card = res.data.data[0];

                this.setState({
                    number: '•••• •••• •••• ' + card.last4,
                    cvc: '•••',
                    expiry: Zenfy.minTwoDigits(card.exp_month) + '/' + card.exp_year.toString().substr(card.exp_year.toString().length -2),
                    loadingCard: false,
                    error: null,
                });
                
            });

        }
    }

    componentDidUpdate(prevProps) { 
        /*if(this.props.visible !== prevProps.visible || this.props.user !== prevProps.user){
           if(this.props.user != ''){
            this.setState({
                    visible: this.props.visible,
                    name: this.props.user ? this.props.user.name : '',
                    email: this.props.user ? this.props.user.email : '',
            });
           }
        }*/
    }
    
    componentDidMount() {
        this.loadStripeCard(this.props.client.customer_id);
    }

    handleOk = (amount, callback, errorCallback, isSave) => {

        if(window.location.origin.indexOf('localhost') != -1){
            window.Stripe.setPublishableKey('pk_test_T6Mj6C9KVxUqMHW4zkzLbwGn');
            //this.config.tnt = true;
        }else{
            if(localStorage.orgId == 18){
                window.Stripe.setPublishableKey('pk_live_sgONQharqdAKj3KKSH0gYHv2');
            }else if(localStorage.orgId == 29) {
                window.Stripe.setPublishableKey('pk_live_LL90ECd4QsmsRakIfvozHx2S00VEzMCwiP');
            }else{
                window.Stripe.setPublishableKey('pk_live_Bdb7mVGMQ8ciSmoAfY7Znc74');
            }
        }

        if(localStorage.orgId == 13 && (!this.state.frais || !this.state.deplacement) && !window.isCarbon){
            
            this.setState({
                error: "S'il vous plait assurez-vous de caucher les acceptations.",
            });

            if(errorCallback){
                errorCallback();
            }

            return;
        }

        /*this.setState({
            loading: true,
        });*/

        if(this.state.number.indexOf("•") !== -1 || this.state.cvc.indexOf("•") !== -1){

            if(!isSave){
                this.zenfyPay(amount, callback, errorCallback, null);
            }else{
                this.setState({
                    error: "Cannot save card with • - Please replace with full numbers.",
                });
            }
        
        }else {

            let exp = this.state.expiry.split("/");

            window.Stripe.card.createToken({
                name: this.state.name,
                number: this.state.number,
                cvc: this.state.cvc,
                exp_month: exp[0],
                exp_year: exp[1],
            }, (status, res) => {
                if (res.error && res.error.message){

                    if(errorCallback){
                        errorCallback(res.error.message);
                    }

                    this.setState({
                        error: res.error.message,
                        //loading: false,
                    });

                } else {
                    if(!isSave){
                        this.zenfyPay(amount, callback, errorCallback, res.id);
                    }else{
                        this.zenfySaveCreditCard(callback, errorCallback, res.id);
                    }
                }
            });

        }

    }

    zenfySaveCreditCard = (callback, errorCallback, token) => {

        let postData = {
            ...this.state,
            customer_id: this.state.client.customer_id,
            token,
        };

        Zenfy.post('ccsave', postData).then((res) => {

            if(callback){
                callback(res.data);
            }

        }).catch(error => {

            this.setState({
                error: "An error accured, please try again.",
            });

            if(errorCallback){
                errorCallback(error);
            }
        });
    }

    zenfyPay = (amount, callback, errorCallback, token) => {

        this.setState({
            error: null,
        });

        let stripeAmount = amount ? amount : this.props.amount;
            //stripeAmount = (Math.round(stripeAmount * 100) / 100).toFixed(2);

        let postData = {
            ...this.state,
            customer_id: this.state.client.customer_id,
            cur: this.props.cur,
            token,
            org_id: localStorage.orgId,
            amount: Math.round(stripeAmount * 100) / 100,
        };

        if(this.props.force_monthly){
            postData.recurring = 1;
        }

        if(localStorage && localStorage.cc_desc){
            postData.cc_desc = localStorage.cc_desc;
        }

        Zenfy.post('pay', postData).then((res) => {

            res.data.recurring = this.state.recurring;

            if(this.state.recurring_end){
                res.data.recurring_end = this.state.recurring_end;
            }

            if(callback){
                callback(res.data);
            }

            /*this.setState({
                loading: false,
                success: true,
            });*/
        }).catch(error => {

            let errMsg = "An error accured, please try again.";

            if(error.response && error.response.data){
                errMsg = error.response.data.message ? error.response.data.message : error.response.data;
            }

            this.setState({
                //loading: false,
                error: errMsg,
            });

            if(errorCallback){
                errorCallback(error);
            }
        });

    }

    handleInputChange = ({ target }) => {

        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
        }else if(target.name === 'recurring' || target.name === 'deplacement' || target.name === 'frais'){
            target.value = target.checked ? 1 : 0;
        }else if(target.name === 'promo'){
            if(target.value == 'PDDMONTREAL' && localStorage.orgId == 13 && !window.isCarbon){
                window.discount = true;
                window.withoutDiscountAmount = this.props.amount;
                window.discountAmount = 675;
                window.newAmount = this.props.amount - window.discountAmount;
                this.props.discountCallback(window.newAmount);
                window.notPur = true;

                if(window.newAmount == 0){
                    this.setState({
                        hideCard: true,
                    });
                }
                //this.props.amount = this.props.amount - window.discountAmount
            }else if(target.value == '1000NIVEAU2' && localStorage.orgId == 13){
                window.discount = true;
                window.withoutDiscountAmount = this.props.amount;
                window.discountAmount = 1000;
                window.newAmount = this.props.amount - window.discountAmount;
                this.props.discountCallback(window.newAmount);
                window.notPur = true;

                if(window.newAmount == 0){
                    this.setState({
                        hideCard: true,
                    });
                }
                //this.props.amount = this.props.amount - window.discountAmount
            } else if (target.value == 'NIVEAU1' && localStorage.orgId == 13 && !window.isCarbon){
                window.discount = true;
                window.withoutDiscountAmount = this.props.amount;
                window.discountAmount = 850;
                window.newAmount = this.props.amount - window.discountAmount;
                this.props.discountCallback(window.newAmount);
                window.notPur = true;

                if(window.newAmount == 0){
                    this.setState({
                        hideCard: true,
                    });
                }
                //this.props.amount = this.props.amount - window.discountAmount
            }else{
                window.discount = null;
                window.discountAmount = 0;
                window.newAmount = null;
                window.notPur = false;
            }
        }

       

        this.setState({ [target.name]: target.value });
    };

    handleInputFocus = ({ target }) => {
        this.setState({
          focused: target.name,
        });
    };

    render(){

        const { name, number, expiry, cvc, focused, email } = this.state;

        let tps = this.props.amount * 1 * 0.14975;
        let creditAmount = this.props.amount * 1 + (this.props.taxes == 1 ? this.props.amount * 1 * 0.14975 : 0);
        let totalCreditAmount = creditAmount + (creditAmount * 0.04 + 0.3);

        return (
            <>      
                <Spin tip="Loading Card..." spinning={this.state.loadingCard} >
                    
                    <Cards
                        number={number}
                        name={this.state.client.name ? this.state.client.name : Labels.get("YOUR NAME")}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focused}
                        callback={this.handleCallback}
                    />

                    {this.props.showRecap ? (<>

                        <Input
                            name="promo"
                            className="form-control"
                            placeholder={Labels.get("Promo code")}
                            onChange={this.handleInputChange}
                            style={{marginTop: 30}}
                        />

                        <Card bordered style={{ marginTop: 30, borderRadius: 5 }} size="small">
                            <p>
                                {!this.props.force_monthly && localStorage.orgId != 3 ? Labels.get("Price") : Labels.get("First month")}: <Currency quantity={window.discount ? window.withoutDiscountAmount : this.props.amount} currency="CAD" />
                                {this.props.force_monthly ? (<>
                                    &nbsp;({<Currency quantity={window.monthlyPrice} currency="CAD" />} {Labels.get("recurring every month")})
                                </>) : null}
                            </p>
                            {window.discount ? (
                                <p>{Labels.get("Discount")}: <Currency quantity={-Math.abs(window.discountAmount)} currency="CAD" /> {!window.notPur ? `(${window.discount} * 100%)` : ''}</p>
                            ) : null}
                            {this.props.taxes ? (
                            <>
                                <p>{Labels.get("GST")}: <Currency quantity={this.props.amount * 1 * 0.05} currency="CAD" /></p>
                                <p>{Labels.get("QST")}: <Currency quantity={this.props.amount * 1 * 0.09975} currency="CAD" /></p>
                            </>
                            ) : null}
                            <Divider style={{margin: '15px 0'}}/>
                            <p style={{display: 'none'}}>Credit card processing fees: <Currency quantity={creditAmount * 0.04 + 0.3} currency="CAD" /></p>
                            <p>
                                {Labels.get("Total")}: <Currency quantity={creditAmount} currency="CAD" /> 
                            </p>
                        </Card>
                    </>) : null}

                    {this.state.error ? (
                        <Alert
                            message={Labels.get("Error")}
                            description={this.state.error}
                            type="error"
                            showIcon
                            style={{marginTop: '25px'}}
                        />   
                    ) : null}   

                    <Form ref={c => (this.form = c)} onSubmit={this.handleSubmit} className="credit-card-form" style={{marginTop: '25px'}}>
                        
                        {!this.props.force_monthly && !this.props.isAdmin && localStorage.orgId != 13 && !this.props.hideMonthly ? (
                            <>
                                <Checkbox style={{marginBottom: '25px'}} name="recurring" onChange={this.handleInputChange}>
                                    {Labels.get("Make this payment recurring")}
                                </Checkbox>    
                                {this.state.recurring && (localStorage.orgId == 18 || localStorage.orgId == 17) ? (
                                    <Select
                                        style={{marginBottom: '25px'}}
                                        placeholder="Select end date (optional)"
                                        onChange={(val) => {
                                            if(val != '0'){

                                                let recurring_end = moment().add('months', val).format('YYYY-MM-DD HH:mm:ss');

                                                this.setState({
                                                    recurring_end
                                                });
                                            }                                         
                                        }}
                                    >
                                        <Select.Option value="0">Till Cancelled</Select.Option>
                                        <Select.Option value="3">3 Months</Select.Option>
                                        <Select.Option value="6">6 Months</Select.Option>
                                        <Select.Option value="12">1 Year</Select.Option>
                                    </Select>
                                ) : null}
                            </>
                        ) : null}

                        {localStorage.orgId == 13 && !window.isCarbon ? (
                            <>
                                <Checkbox style={{marginBottom: '25px', fontSize: '12px'}} name="deplacement" onChange={this.handleInputChange}>
                                    Je comprends et j’accepte que les frais de séjour et de déplacement de l’auditeur ne soient pas
                                    inclus dans le tarif pour l’évaluation sur site. Ils seront calculés en fonction de la localisation des
                                    sites à auditer. Dans l’éventualité où plusieurs sites distants doivent être contrôlés, un devis
                                    personnalisé vous sera transmis.
                                </Checkbox>   
                                <Checkbox style={{marginBottom: '25px', fontSize: '12px', marginLeft: '0'}} name="frais" onChange={this.handleInputChange}>
                                Je comprends et j’accepte les frais annuels de ${localStorage.marque} pour l’utilisation de la marque de commerce, de l’adhésion et le rayonnement au sein du réseau des ÉCOLEADERS. Si vous êtes une entreprise nouvellement inscrite, ce frais vous sera facturé suite à l’obtention de votre {localStorage.marque == 175 ? "attestation" : 'certification'}. Si vous êtes une entreprise détenant actuellement la {localStorage.marque == 175 ? "attestation" : 'certification'}, ce frais est facturé à la date d’obtention de votre première {localStorage.marque == 175 ? "attestation" : 'certification'}.
                                </Checkbox>     
                            </>
                        ) : null}

                        {window.isCarbon ? (
                            <Alert
                                description="Si pour quelques raisons que ce soit votre entreprise n’était pas retenue nous communiquerons avec la personne responsable pour procéder au remboursement, sans frais de pénalité."
                                type="info"
                                showIcon
                                style={{marginTop: '25px', marginBottom: '25px'}}
                            />  
                        ) : null}
                       

                        {!this.state.hideCard ? (
                        <>
                            <Input
                                type="tel"
                                name="number"
                                className="form-control"
                                placeholder={Labels.get("Card Number")}
                                pattern="[\d| ]{16,22}"
                                required
                                value={number}
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                autocomplete="cc-number"
                            />

                            <Row gutter={16} style={{marginTop: '25px'}}>
                                <Col className="gutter-row" span={12}>
                                    <Input
                                        type="tel"
                                        name="expiry"
                                        className="form-control"
                                        placeholder={`${Labels.get('Valid Thru')} (MM/YY)`}
                                        pattern="\d\d/\d\d"
                                        required
                                        value={expiry}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        autocomplete="cc-exp"
                                    />
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Input
                                        type="tel"
                                        name="cvc"
                                        className="form-control"
                                        placeholder="CVC"
                                        pattern="\d{3,4}"
                                        required
                                        value={cvc}
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        autocomplete="cc-csc"
                                    />
                                </Col>
                            </Row>       
                        </>
                        ) : null}
                        

                    </Form>
            </Spin>
        </>)
    }  

}

export default CreditCard;